var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.props.compType === "tdItem",
          expression: "props.compType === 'tdItem'"
        }
      ]
    },
    [
      _c(
        "el-form-item",
        { attrs: { label: "单元格背景" } },
        [
          _c("el-color-picker", {
            attrs: { predefine: _vm.predefineColors },
            model: {
              value: _vm.props.style.background,
              callback: function($$v) {
                _vm.$set(_vm.props.style, "background", $$v)
              },
              expression: "props.style.background"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "表格宽度(%)" } },
        [
          _c("el-inputNumber", {
            staticClass: "input",
            attrs: { min: 0, max: 100 },
            model: {
              value: _vm.props.style.width,
              callback: function($$v) {
                _vm.$set(_vm.props.style, "width", $$v)
              },
              expression: "props.style.width"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }