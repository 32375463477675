// 在此注册组件
var components = ['input', 'select', 'selectDepartment', 'selectStaff', 'radio', 'checkbox', 'Switch', 'inputNumber', 'textarea', 'slider', 'rate', 'date', 'time', 'editor', 'colorPicker', 'cascader', 'cascaderRegion', 'upload', 'button', 'divider', 'alert', 'link', 'dialogList', 'barCode', 'text', 'html', 'row', 'dynamicTable', 'table', 'tdItem'];
var comps = [];

for (var i in components) {
  var comp = {
    name: components[i],
    content: null
  };
  comps.push(comp);
}

export default comps;