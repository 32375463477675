import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
import { getToken } from '@/utils/auth'; // 模态框

export var remoteData = {
  methods: {
    getRemoteData: function getRemoteData() {
      var _this = this;

      // 动态数据
      if (this.conf.dataType === 'dynamic') {
        // console.log('dynamic')
        var config = {
          headers: {
            'access-token': getToken()
          }
        };

        if (this.conf.options.length === 0) {
          this.$axios.get(this.conf.action, config).then(function (res) {
            // console.log(res.data)
            _this.conf.options = _this.conf.options.concat(res.data.data);
          });
        }
      } else if (this.conf.dataType === 'store') {
        // console.log('store')
        // 缓存数据
        this.$store.dispatch(this.conf.storeDispatchName).then(function (res) {
          if (_this.conf.options.length === 0) {
            _this.conf.options = _this.conf.options.concat(res); // console.log(this.conf.options)
          }
        });
      }
    }
  }
};
export var changeId = {
  methods: {
    handlerChangeId: function handlerChangeId(val) {
      var idArray = this.getFormId(this.props._id);
      console.log(idArray);

      if (idArray.includes(val)) {
        // 如果存在id相等，则提示
        this.$message.error('该ID已经存在，请修改');
        this.props.id = this.props._id;
      } else {
        this.props._id = val;
      }
    }
  }
};