//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { changeId } from "../mixin";
/**
 * input的配置项
 */

export default {
  name: 'InputConfig',
  components: {},
  mixins: [changeId],
  // eslint-disable-next-line vue/require-prop-types
  props: ['props', 'getFormId'],
  data: function data() {
    return {};
  },
  methods: {}
};