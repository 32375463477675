//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { changeId } from "../mixin";
export default {
  name: 'Rate',
  components: {},
  mixins: [changeId],
  // eslint-disable-next-line vue/require-prop-types
  props: ['props', 'getFormId'],
  data: function data() {
    return {};
  },
  mounted: function mounted() {},
  methods: {}
};