var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "div",
        { staticClass: "left-board" },
        [
          _c("div", { staticClass: "d-logo-wrapper" }, [
            _c("div", { staticClass: "d-logo" }, [
              _vm._v(" " + _vm._s(_vm.title) + " ")
            ])
          ]),
          _c("el-scrollbar", { staticClass: "left-scrollbar" }, [
            _c(
              "div",
              { staticClass: "components-list" },
              [
                _c("div", { staticClass: "components-title" }, [
                  _vm._v(" 常用组件 ")
                ]),
                _c(
                  "draggable",
                  {
                    staticClass: "components-draggable",
                    attrs: {
                      list: _vm.formItems,
                      group: {
                        name: "componentsGroup",
                        pull: "clone",
                        put: false
                      },
                      clone: _vm.cloneComponent,
                      draggable: ".components-item",
                      sort: false
                    },
                    on: { start: _vm.onStart, end: _vm.onEnd }
                  },
                  _vm._l(_vm.formItems, function(element, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "components-item",
                        on: {
                          click: function($event) {
                            return _vm.addComponent(element)
                          }
                        }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "components-body",
                            class: {
                              "dynamicTable-tips": _vm.dynamicTableExist(
                                element
                              )
                            }
                          },
                          [
                            _c("icon", {
                              attrs: {
                                code: element.compIcon,
                                text: element.compName
                              }
                            })
                          ],
                          1
                        )
                      ]
                    )
                  }),
                  0
                ),
                _vm.enableLayoutForm
                  ? [
                      _c("div", { staticClass: "components-title" }, [
                        _vm._v(" 布局组件 ")
                      ]),
                      _c(
                        "draggable",
                        {
                          staticClass: "components-draggable",
                          attrs: {
                            list: _vm.layoutFormItems,
                            group: {
                              name: "componentsGroup",
                              pull: "clone",
                              put: false
                            },
                            clone: _vm.cloneComponent,
                            draggable: ".components-item",
                            sort: false
                          },
                          on: { start: _vm.onStart, end: _vm.onEnd }
                        },
                        _vm._l(_vm.layoutFormItems, function(element, index) {
                          return _c(
                            "div",
                            {
                              key: index,
                              staticClass: "components-item",
                              on: {
                                click: function($event) {
                                  return _vm.addComponent(element)
                                }
                              }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "components-body" },
                                [
                                  _c("icon", {
                                    attrs: {
                                      code: element.compIcon,
                                      text: element.compName
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          )
                        }),
                        0
                      )
                    ]
                  : _vm._e(),
                _vm.enableAssistForm
                  ? [
                      _c("div", { staticClass: "components-title" }, [
                        _vm._v(" 辅助组件 ")
                      ]),
                      _c(
                        "draggable",
                        {
                          staticClass: "components-draggable",
                          attrs: {
                            list: _vm.assistFormItems,
                            group: {
                              name: "componentsGroup",
                              pull: "clone",
                              put: false
                            },
                            clone: _vm.cloneComponent,
                            draggable: ".components-item",
                            sort: false
                          },
                          on: { start: _vm.onStart, end: _vm.onEnd }
                        },
                        _vm._l(_vm.assistFormItems, function(element, index) {
                          return _c(
                            "div",
                            {
                              key: index,
                              staticClass: "components-item",
                              on: {
                                click: function($event) {
                                  return _vm.addComponent(element)
                                }
                              }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "components-body" },
                                [
                                  _c("icon", {
                                    attrs: {
                                      code: element.compIcon,
                                      text: element.compName
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          )
                        }),
                        0
                      )
                    ]
                  : _vm._e()
              ],
              2
            )
          ])
        ],
        1
      ),
      _c("designer", {
        ref: "designer",
        attrs: {
          list: _vm.designList,
          "form-config": _vm.formConfig,
          "active-data": _vm.activeData
        },
        on: { clear: _vm.clearAll, updateJSON: _vm.handlerUpdateJSON }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }