import Vue from 'vue';
import Router from 'vue-router';
import { createPageVisitStat } from '@/api/page-visit-statistics';
Vue.use(Router);
/* Layout */

import Layout from '@/layout';
/* Router Modules */
// import componentsRouter from './modules/components'
// import chartsRouter from './modules/charts'
// import tableRouter from './modules/table'
// import nestedRouter from './modules/nested'

/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    noCache: true                if set true, the page will no be cached(default is false)
    affix: true                  if set true, the tag will affix in the tags-view
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */

export var constantRoutes = [{
  path: '/redirect',
  component: Layout,
  hidden: true,
  children: [{
    path: '/redirect/:path*',
    component: function component() {
      return import('@/views/redirect/index');
    }
  }]
}, {
  path: '/login',
  component: function component() {
    return import('@/views/login/index');
  },
  hidden: true
}, {
  path: '/sso/:router',
  hidden: true,
  name: 'SsoLogin',
  component: function component() {
    return import('@/views/sso/index');
  }
}, {
  path: '/sso',
  hidden: true,
  name: 'SsoSaas',
  component: function component() {
    return import('@/views/sso/saas.vue');
  }
}, {
  path: '/auth-redirect',
  component: function component() {
    return import('@/views/login/auth-redirect');
  },
  hidden: true
}, {
  path: '/bank-statement',
  component: function component() {
    return import('@/views/finance/bank-statement/bank-statement-list');
  },
  name: 'BankStatementList',
  hidden: true
}, {
  path: '/404',
  component: function component() {
    return import('@/views/error-page/404');
  },
  hidden: true
}, {
  path: '/401',
  component: function component() {
    return import('@/views/error-page/401');
  },
  hidden: true
}, {
  path: '/',
  component: Layout,
  redirect: '/dashboard',
  children: [{
    path: 'dashboard',
    component: function component() {
      return import('@/views/dashboard/index');
    },
    name: 'Dashboard',
    meta: {
      title: '主页',
      icon: 'nb_zy_rcsx',
      affix: true
    }
  }, {
    path: 'guide',
    component: function component() {
      return import('@/views/dashboard/guide');
    },
    name: 'Guide',
    hidden: true,
    meta: {
      title: '操作流程图',
      icon: 'nb_zy_rcsx'
    }
  }]
}, {
  path: '/workbench',
  component: Layout,
  redirect: '/workbench',
  children: [{
    path: 'workbench',
    component: function component() {
      return import('@/views/workbench');
    },
    name: 'Workbench',
    meta: {
      title: '工作台',
      icon: 'workbench',
      noCache: true
    }
  }]
}, {
  path: '/daily-routine',
  component: Layout,
  redirect: '/daily-routine/daily-routine-page',
  name: 'DailyRoutine',
  children: [{
    path: 'daily-routine-page',
    component: function component() {
      return import('@/views/daily-routine/daily-routine-page');
    },
    name: 'DailyRoutinePage',
    meta: {
      title: '日常事项',
      icon: 'nb_rcsx',
      noCache: true
    }
  }]
}, {
  path: '/profile',
  component: Layout,
  redirect: '/profile/index',
  meta: {
    title: '个人中心'
  },
  hidden: true,
  children: [{
    path: 'index',
    component: function component() {
      return import('@/views/profile/index');
    },
    name: 'Profile',
    meta: {
      title: '个人财务',
      icon: 'user',
      noCache: true
    }
  }, {
    path: 'password-change',
    component: function component() {
      return import('@/views/profile/password-change');
    },
    name: 'PasswordChange',
    meta: {
      title: '密码修改',
      statisticsName: '个人中心 / 密码修改',
      icon: 'user',
      noCache: true
    }
  }, // {
  //   path: 'my-applied-fee',
  //   component: () => import('@/views/business/fee/fee-list'),
  //   name: 'FeeList',
  //   meta: { title: '我的费用申请', icon: 'qq', noCache: true }
  // },
  {
    path: 'profile-finance',
    component: function component() {
      return import('@/views/finance/profile-matters/matters-list');
    },
    name: 'ProfileMattersList',
    meta: {
      title: '个人财务管理',
      statisticsName: '个人中心 / 个人财务管理',
      icon: 'qq',
      noCache: true
    }
  }, {
    path: 'confirm-income',
    component: function component() {
      return import('@/views/finance/profile-matters/confirm-income-list');
    },
    name: 'ConfirmIncomeList',
    meta: {
      title: '确认收入',
      icon: 'qq',
      noCache: true
    }
  }, {
    path: 'handover',
    component: function component() {
      return import('@/views/profile/handover');
    },
    name: 'Handover',
    meta: {
      title: '离职交接',
      icon: 'qq',
      noCache: true
    }
  }]
}];
/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
 */

export var asyncRoutes = [{
  path: '/kanban',
  name: 'Kanban',
  component: function component() {
    return import('@/views/kanban/index');
  },
  hidden: true,
  meta: {
    roles: ['大屏看板']
  }
}, {
  path: '/logistics',
  component: Layout,
  redirect: '/logistic/list',
  name: 'Logistic',
  meta: {
    title: '物流情报',
    icon: 'nb_wlqb_p',
    roles: ['物流情报']
  },
  children: [{
    path: '/logistic/list',
    component: function component() {
      return import('@/views/logistics/logistic-list.vue');
    },
    name: 'LogisticList',
    meta: {
      title: '物流情报',
      icon: 'nb_wlqb',
      noCache: true,
      roles: ['物流情报']
    }
  }, {
    path: '/crawler/list',
    component: function component() {
      return import('@/views/logistics/crawler-list.vue');
    },
    name: 'CrawlerListt',
    meta: {
      title: '爬虫信息',
      icon: 'nb_wlqb_pc',
      noCache: true,
      roles: ['物流情报:爬虫信息']
    }
  }]
}, {
  path: '/user/system',
  component: Layout,
  redirect: '/system/role-list',
  alwaysShow: true,
  name: 'System',
  meta: {
    title: '系统管理',
    icon: 'nb_xtgl',
    roles: ['系统管理']
  },
  children: [{
    path: '/operation-log',
    component: function component() {
      return import('@/views/user/system/operation-log/audit-log-list');
    },
    name: 'OperationLog',
    meta: {
      title: '操作日志',
      icon: 'nb_xtgl_jsgl',
      roles: ['系统管理']
    }
  }, {
    path: '/short-message-send-record',
    component: function component() {
      return import('@/views/short-message-send-record/record-list');
    },
    name: 'ShortMessageSendRecord',
    meta: {
      title: '短信发送记录',
      icon: 'nb_xtgl_jsgl',
      roles: ['系统管理']
    }
  }, {
    path: '/generate',
    component: function component() {
      return import('@/views/calendar/generate-calendar');
    },
    name: 'GenerateCalendar',
    meta: {
      title: '节假日安排',
      icon: 'nb_xtgl_jsgl',
      roles: ['系统管理']
    }
  }, {
    path: 'init-contract-project',
    component: function component() {
      return import('@/views/contract/init/init-contract-project.vue');
    },
    name: 'InitContractProject',
    meta: {
      title: '初始化项目',
      icon: 'nb_xtgl_cshxm',
      roles: ['系统管理']
    }
  }, {
    path: 'init-project-finance',
    component: function component() {
      return import('@/views/contract/init/init-project-finance.vue');
    },
    name: 'InitProjectFinance',
    meta: {
      title: '初始化项目款',
      icon: 'nb_xtgl_cshxmk',
      roles: ['系统管理']
    }
  }, {
    path: 'data-import',
    component: function component() {
      return import('@/views/user/system/import/data-import.vue');
    },
    name: 'DataImport',
    meta: {
      title: '各种导入数据',
      icon: 'nb_xtgl_gsdr',
      roles: ['系统管理']
    }
  }, {
    path: 'system-config',
    component: function component() {
      return import('@/views/user/system/config/config-list');
    },
    name: 'SystemConfig',
    meta: {
      title: '系统配置',
      icon: 'nb_xtgl_gsdr',
      roles: ['系统管理']
    }
  }, {
    path: 'wechat-config',
    component: function component() {
      return import('@/views/wechat-bot/template/index');
    },
    name: 'WechatMessageTemplate',
    meta: {
      title: '微信消息配置',
      icon: 'nb_xtgl_gsdr',
      roles: ['系统管理']
    }
  }, {
    path: 'wechat-message-record',
    component: function component() {
      return import('@/views/wechat-bot/message/record-list');
    },
    name: 'WechatMessageRecordList',
    meta: {
      title: '微信消息记录',
      icon: 'nb_xtgl_gsdr',
      roles: ['系统管理']
    }
  }]
}, {
  path: '/organization',
  component: Layout,
  redirect: '/organization/role-group-list',
  alwaysShow: true,
  name: 'Organization',
  meta: {
    title: '公司管理',
    icon: 'nb_gsgl',
    roles: ['公司管理', '集团管理']
  },
  children: [{
    path: 'company-admin-list',
    component: function component() {
      return import('@/views/user/company/company-admin-list');
    },
    name: 'Company',
    meta: {
      title: '集团结构',
      icon: 'nb_gsgl_qyhl',
      roles: ['集团管理']
    }
  }, {
    path: 'management',
    component: function component() {
      return import('@/views/user/department/department');
    },
    name: 'Department',
    meta: {
      title: '部门管理',
      icon: 'nb_gsgl_bmgl',
      roles: ['公司管理', '集团管理']
    }
  }, {
    path: 'role-list',
    component: function component() {
      return import('@/views/user/system/role/role-list');
    },
    name: 'RoleList',
    meta: {
      title: '角色管理',
      icon: 'nb_xtgl_jsgl',
      roles: ['公司管理', '集团管理']
    }
  }, {
    path: 'process-template-list',
    component: function component() {
      return import('@/views/project/process-config/process-template-list.vue');
    },
    name: 'ProcessTemplateList',
    meta: {
      title: '业务流程设置',
      icon: 'nb_xtgl_ywlcsz',
      roles: ['公司管理', '集团管理']
    }
  }]
}, {
  path: '/transport-resource',
  component: Layout,
  redirect: '/truck/truck-list',
  alwaysShow: true,
  name: 'TransportResource',
  meta: {
    title: '运力资源',
    icon: 'nb_ylzy',
    roles: ['运力资源']
  },
  children: [{
    path: 'supplier-list',
    component: function component() {
      return import('@/views/user/marketing/supplier/supplier-list');
    },
    name: 'SupplierList',
    meta: {
      title: '供应商',
      statisticsName: '运力资源 / 供应商',
      icon: 'nb_ylzy_gys',
      noCache: true,
      roles: ['运力资源:供应商', '运力资源:供应商年度评审', '运力资源:供应商日常评审']
    }
  }, {
    path: 'platform-truck-page',
    component: function component() {
      return import('@/views/user/platform-truck/platform-truck-page');
    },
    name: 'PlatformTruckPage',
    meta: {
      title: '平台车辆',
      statisticsName: '运力资源 / 平台车辆',
      icon: 'nb_ylzy_clpt',
      roles: ['运力资源:平台车辆']
    }
  }, {
    path: 'manual-review-list',
    component: function component() {
      return import('@/views/user/truck/manual-review/manual-review-list');
    },
    name: 'ManualReviewList',
    meta: {
      title: '人工审核',
      icon: 'nb_ylzy_clsh',
      roles: ['运力资源:人工审核']
    }
  }, {
    path: 'company-truck-page',
    component: function component() {
      return import('@/views/user/company-truck/company-truck-page');
    },
    name: 'CompanyTruckPage',
    meta: {
      title: '企业车辆',
      statisticsName: '运力资源 / 企业车辆',
      icon: 'nb_ylzy_qycl',
      roles: ['运力资源:企业车辆']
    }
  }, {
    path: 'bulk-config',
    component: function component() {
      return import('@/views/project/bulk-config/index.vue');
    },
    name: 'BulkConfig',
    meta: {
      title: '大件自有车配置',
      statisticsName: '运力资源 / 大件自有车配置',
      icon: 'nb_xtgl_dazyc',
      roles: ['运力资源:大件自有车配置']
    }
  }]
}, {
  path: '/inventory',
  component: Layout,
  redirect: '/inventory/work-order/work-order-list',
  alwaysShow: true,
  name: 'InventoryManage',
  meta: {
    title: '仓库',
    icon: 'nb_ck',
    roles: ['仓库']
  },
  children: [// {
  //   path: 'index',
  //   component: () => import('@/views/inventory/index'),
  //   name: 'Inventory',
  //   hidden: true,
  //   meta: {
  //     title: '控制台',
  //     icon: 'nb_ck_kzt',
  //     roles: ['仓库']
  //   }
  // },
  {
    path: 'work-order-list',
    component: function component() {
      return import('@/views/inventory/work-order/work-order-list');
    },
    name: 'WorkOrderList',
    meta: {
      title: '台账列表',
      statisticsName: '仓库 / 台账列表',
      icon: 'nb_ck_tzlb',
      noCache: true,
      roles: ['仓库:台账列表']
    }
  }, {
    path: 'part-item-list',
    component: function component() {
      return import('@/views/inventory/work-order/part-item-list');
    },
    name: 'PartItemList',
    meta: {
      title: '综合查询',
      statisticsName: '仓库 / 综合查询',
      icon: 'nb_ck_zhcx',
      noCache: true,
      roles: ['仓库:综合查询']
    }
  }, {
    path: 'in-storage',
    component: function component() {
      return import('@/views/inventory/in-storage/in-storage');
    },
    name: 'InStorage',
    meta: {
      title: '入库',
      statisticsName: '仓库 / 入库',
      icon: 'nb_ck_rk',
      roles: ['仓库:入库']
    }
  }, {
    path: 'transship-in',
    component: function component() {
      return import('@/views/inventory/transship-in/transship-in');
    },
    name: 'TransshipIn',
    meta: {
      title: '转运入库',
      statisticsName: '仓库 / 转运入库',
      icon: 'nb_ck_zyrk',
      roles: ['仓库:转运入库']
    }
  }, {
    path: 'material-in-storage',
    component: function component() {
      return import('@/views/inventory/material-in-storage/material-in-storage');
    },
    name: 'MaterialInStorage',
    meta: {
      title: '材料入库',
      statisticsName: '仓库 / 材料入库',
      icon: 'nb_ck_clrk',
      roles: ['仓库:材料入库']
    }
  }, {
    path: 'delivery',
    component: function component() {
      return import('@/views/inventory/delivery/delivery');
    },
    name: 'Delivery',
    meta: {
      title: '出库',
      statisticsName: '仓库 / 出库',
      icon: 'nb_ck_ck',
      roles: ['仓库:出库']
    }
  }, {
    path: 'transport-demand',
    component: function component() {
      return import('@/views/inventory/transport-demand/transport-demand-list');
    },
    name: 'InvTransportDemandList',
    meta: {
      title: '用车计划',
      statisticsName: '仓库 / 用车计划',
      icon: 'nb_ck_ycjh',
      roles: ['仓库:用车计划']
    }
  }, {
    path: 'material-in-storage-list',
    component: function component() {
      return import('@/views/inventory/material-in-storage/material-in-storage-list');
    },
    name: 'MaterialReviewList',
    hidden: true,
    meta: {
      title: '材料入库历史',
      statisticsName: '仓库 / 材料入库 / 入库历史',
      icon: 'nb_ck_clrk',
      noCache: true,
      roles: ['仓库']
    }
  }, {
    path: 'stat-workload',
    component: function component() {
      return import('@/views/inventory/stat/workload-list');
    },
    name: 'WorkloadStat',
    meta: {
      title: '作业量统计',
      statisticsName: '仓库 / 作业量统计',
      icon: 'nb_ck_zyltj',
      roles: ['仓库:作业量统计']
    }
  }, {
    path: 'stat-work-time',
    component: function component() {
      return import('@/views/inventory/stat/work-time-list');
    },
    name: 'WorkTimeStat',
    meta: {
      title: '工时统计',
      statisticsName: '仓库 / 工时统计',
      icon: 'nb_ck_gstj',
      noCache: true,
      roles: ['仓库:工时统计']
    }
  }, {
    path: 'stat-material',
    component: function component() {
      return import('@/views/inventory/stat/material-list');
    },
    name: 'MaterialStat',
    meta: {
      title: '材料统计',
      icon: 'nb_ck_cltj',
      noCache: true,
      roles: ['仓库:材料统计']
    }
  }, {
    path: 'stat-storage-in',
    component: function component() {
      return import('@/views/inventory/stat/storage-in-list');
    },
    name: 'StorageInStat',
    meta: {
      title: '入库清单',
      statisticsName: '仓库 / 入库清单',
      icon: 'nb_ck_rkqd',
      noCache: true,
      roles: ['仓库:入库清单']
    }
  }, {
    path: 'stat-delivery-out',
    component: function component() {
      return import('@/views/inventory/stat/delivery-out-list');
    },
    name: 'DeliveryOutStat',
    meta: {
      title: '出库清单',
      statisticsName: '仓库 / 出库清单',
      icon: 'nb_ck_ckqd',
      noCache: true,
      roles: ['仓库:出库清单']
    }
  }, {
    path: 'stat-transship',
    component: function component() {
      return import('@/views/inventory/stat/transship-list');
    },
    name: 'TransshipStat',
    meta: {
      title: '转运清单',
      statisticsName: '仓库 / 转运清单',
      icon: 'nb_ck_zyqd',
      noCache: true,
      roles: ['仓库:转运清单']
    }
  }, {
    path: 'inventory-config',
    component: function component() {
      return import('@/views/inventory/config/inventory-config');
    },
    name: 'InventoryConfigTab',
    meta: {
      title: '基础配置',
      statisticsName: '仓库 / 基础配置',
      icon: 'nb_ck_jcpz',
      roles: ['仓库:基础配置']
    }
  }]
}, {
  path: '/business/clue',
  component: Layout,
  redirect: '/business/clue/clue-list',
  alwaysShow: true,
  name: 'Business',
  meta: {
    title: '商务',
    icon: 'nb_sw',
    roles: ['商务']
  },
  children: [{
    path: 'invitation-list',
    component: function component() {
      return import('@/views/user/marketing/customer-invitation/invitation-list');
    },
    name: 'InvitationList',
    meta: {
      title: '受邀入围',
      statisticsName: '商务 / 受邀入围',
      icon: 'nb_sw_khlb',
      noCache: true,
      roles: ['商务:受邀入围']
    }
  }, {
    path: 'customer-list',
    component: function component() {
      return import('@/views/user/marketing/customer/customer-list');
    },
    name: 'CustomerList',
    meta: {
      title: '客户列表',
      statisticsName: '商务 / 客户列表',
      icon: 'nb_sw_khlb',
      noCache: true,
      roles: ['商务:客户列表']
    }
  }, {
    path: 'clue-list',
    component: function component() {
      return import('@/views/business/clue/clue-list');
    },
    name: 'ClueList',
    meta: {
      title: '线索',
      statisticsName: '商务 / 线索 / 自建线索',
      icon: 'nb_sw_xs',
      noCache: true,
      roles: ['商务:线索']
    }
  }, {
    path: 'enquiry-list',
    component: function component() {
      return import('@/views/business/enquiry/enquiry-list');
    },
    name: 'EnquiryList',
    meta: {
      title: '成本询价',
      statisticsName: '商务 / 成本询价',
      icon: 'nb_sw_cbxj',
      noCache: true,
      roles: ['商务:成本询价']
    }
  }, {
    path: 'enquiry-seq-quote-list',
    component: function component() {
      return import('@/views/business/enquiry-seq-quote/quote-list');
    },
    name: 'EnquirySeqQuoteList',
    meta: {
      title: '成本报价',
      statisticsName: '商务 / 成本报价',
      icon: 'nb_sw_cbbj',
      noCache: true,
      roles: ['商务:成本报价']
    }
  }, {
    path: 'technical-plan-list',
    component: function component() {
      return import('@/views/business/technical-plan/technical-plan-list');
    },
    name: 'TechnicalPlanList',
    meta: {
      title: '技术方案',
      statisticsName: '商务 / 技术方案',
      icon: 'nb_sw_jsfa',
      noCache: true,
      roles: ['商务:技术方案', '商务:线索:报告提交人员']
    }
  }, {
    path: 'enquiry-statistics',
    component: function component() {
      return import('@/views/business/enquiry-statistics/enquiry-statistics');
    },
    name: 'EnquiryStatistics',
    meta: {
      title: '招标统计',
      icon: 'nb_sw_jsfa',
      noCache: true,
      roles: ['商务:招标统计']
    }
  }, {
    path: 'enquiry-seq-quote-message',
    component: function component() {
      return import('@/views/business/enquiry-seq-quote-message/index');
    },
    name: 'EnquirySeqQuoteMessage',
    hidden: true,
    meta: {
      title: '供方答疑',
      icon: 'nb_sw_jsfa',
      noCache: true
    }
  }, {
    path: '/income',
    component: function component() {
      return import('@/views/finance/income/index');
    },
    name: 'income',
    alwaysShow: true,
    meta: {
      title: '收入',
      icon: 'nb_ztcl',
      noCache: true,
      roles: ['商务:线索']
    },
    children: [{
      path: 'individual-driver-income-list',
      component: function component() {
        return import('@/views/finance/income/individual-driver-income-list/individual-driver-income-list');
      },
      name: 'IndividualDriverIncomeList',
      meta: {
        title: '个体司机成本对应收入',
        statisticsName: '收入 / 个体司机成本对应收入',
        icon: 'nb_sr_gtsj'
      }
    }, {
      path: 'sub-income-list',
      component: function component() {
        return import('@/views/finance/income/sub-income-list/sub-income-list');
      },
      name: 'SubIncomeList',
      meta: {
        title: '分包成本对应收入',
        statisticsName: '收入 / 分包成本对应收入',
        icon: 'nb_sr_fbcb'
      }
    }]
  }]
}, {
  path: '/contract/income-contract',
  component: Layout,
  redirect: '/contract/income-contract/income-contract-list',
  alwaysShow: true,
  name: 'Contract',
  meta: {
    title: '合同管理',
    icon: 'nb_htgl',
    roles: ['合同管理', '项目管理:调度:查看', '项目管理:调度:编辑', '项目管理:调度:附加操作']
  },
  children: [{
    path: 'income-contract-list',
    component: function component() {
      return import('@/views/contract/income-contract/income-contract-list');
    },
    name: 'IncomeContractList',
    meta: {
      title: '收入合同',
      statisticsName: '合同管理 / 收入合同',
      icon: 'nb_htgl_srht',
      noCache: true,
      roles: ['合同管理:收入合同']
    }
  }, {
    path: 'cost-contract-list',
    component: function component() {
      return import('@/views/contract/cost-contract/cost-contract-list');
    },
    name: 'CostContractList',
    meta: {
      title: '成本合同',
      icon: 'nb_htgl_cbht',
      noCache: true,
      // 三个调度权限用于控制个体分包合同
      roles: ['合同管理:成本合同', '项目管理:调度:查看', '项目管理:调度:编辑', '项目管理:调度:附加操作']
    }
  }]
}, {
  path: '/project',
  component: Layout,
  redirect: '/project/project-list',
  alwaysShow: true,
  name: 'Project',
  meta: {
    title: '项目管理',
    icon: 'nb_xmgl',
    roles: ['项目管理']
  },
  children: [{
    path: 'project-list',
    component: function component() {
      return import('@/views/project/project/project-list');
    },
    name: 'ProjectList',
    meta: {
      title: '项目',
      statisticsName: '项目管理 / 项目',
      icon: 'nb_xmgl_xm',
      noCache: true,
      roles: ['项目管理:项目']
    }
  }, {
    path: 'insurance-enquiries',
    component: function component() {
      return import('@/views/project/insurance-enquiries/insurance-enquiries');
    },
    name: 'InsuranceEnquiries',
    meta: {
      title: '保险查询',
      statisticsName: '项目管理 / 保险查询',
      icon: 'nb_xtgl_ycxq',
      noCache: true,
      roles: ['项目管理:保险']
    }
  }, {
    path: 'transport-schedule-list',
    component: function component() {
      return import('@/views/project/transport-schedule/transport-schedule-all-list');
    },
    name: 'TransportScheduleAllList',
    meta: {
      title: '调度查询',
      statisticsName: '项目管理 / 调度查询',
      icon: 'nb_xmgl_ddcx',
      noCache: true,
      roles: ['项目管理:调度']
    }
  }, {
    path: 'transport-schedule-process',
    component: function component() {
      return import('@/views/project/transport-schedule/transport-schedule-process-list.vue');
    },
    name: 'TransportScheduleProcessList',
    meta: {
      title: '调度过程',
      statisticsName: '项目管理 / 调度过程',
      icon: 'nb_ztcl_lsgj',
      roles: ['项目管理:调度过程']
    }
  }, {
    path: 'transport-demand-list',
    component: function component() {
      return import('@/views/project/transport-demand/transport-demand-list');
    },
    name: 'TransportDemandList',
    meta: {
      title: '用车需求',
      statisticsName: '项目管理 / 用车需求 / 用车需求',
      icon: 'nb_xtgl_ycxq',
      noCache: true,
      roles: ['项目管理:用车需求']
    }
  }, {
    path: 'whole-process',
    component: function component() {
      return import('@/views/project/whole-process/whole-process');
    },
    name: 'WholeProcess',
    meta: {
      title: '业务全过程',
      statisticsName: '项目管理 / 业务全过程',
      icon: 'nb_xtgl_ycxq',
      noCache: true,
      roles: ['项目管理']
    }
  }, {
    path: 'project-detail/:projectId',
    component: function component() {
      return import('@/views/project/project/project-detail');
    },
    name: 'ProjectDetail',
    hidden: true,
    meta: {
      title: '项目详情',
      noCache: true,
      roles: ['项目管理']
    }
  }, {
    path: 'transport-demand/:projectId/list',
    component: function component() {
      return import('@/views/project/transport-demand/project-transport-demand-list');
    },
    name: 'ProjectTransportDemandList',
    hidden: true,
    meta: {
      title: '用车需求',
      noCache: true,
      roles: ['项目管理']
    }
  }, {
    path: 'transport-schedule/:projectId/list',
    component: function component() {
      return import('@/views/project/transport-schedule/transport-schedule-list');
    },
    name: 'TransportScheduleList',
    hidden: true,
    meta: {
      title: '项目调度',
      noCache: true,
      roles: ['项目管理']
    }
  }, {
    path: 'transport-schedule/:projectId/list-with-process',
    component: function component() {
      return import('@/views/project/transport-schedule/transport-schedule-with-process-list');
    },
    name: 'TransportScheduleWithProcessList',
    hidden: true,
    meta: {
      title: '调度管控流程',
      noCache: true,
      roles: ['项目管理']
    }
  }, {
    path: 'transport-payment/:projectId/list',
    component: function component() {
      return import('@/views/project/settlement/components/transport-payment-list');
    },
    name: 'TransportPaymentList',
    hidden: true,
    meta: {
      title: '付款历史',
      noCache: true,
      roles: ['项目管理']
    }
  }, {
    path: 'project-issue-invoice/:projectId/list',
    component: function component() {
      return import('@/views/project/settlement/components/project-issue-invoice-list');
    },
    name: 'ProjectIssueInvoiceList',
    hidden: true,
    meta: {
      title: '项目开发票列表',
      noCache: true,
      roles: ['项目管理:项目:项目结算:开票记录']
    }
  }, {
    path: 'transport-cost-settle/:projectId/list',
    component: function component() {
      return import('@/views/project/settlement/components/transport-cost-settle-list');
    },
    name: 'TransportCostSettleList',
    hidden: true,
    meta: {
      title: '成本结算历史',
      noCache: true,
      roles: ['项目管理']
    }
  }, {
    path: 'project-report/:projectId',
    component: function component() {
      return import('@/views/project/report/index');
    },
    name: 'SettlementPage',
    hidden: true,
    meta: {
      title: '项目报表',
      noCache: true,
      roles: ['项目管理:项目:项目报表']
    }
  }, {
    path: 'receipt-page/:projectId',
    component: function component() {
      return import('@/views/project/settlement/receipt/receipt-page');
    },
    name: 'ReceiptPage',
    hidden: true,
    meta: {
      title: '项目结算',
      noCache: true,
      roles: ['项目管理']
    }
  }, {
    path: 'transport-bulk-cost-settle',
    component: function component() {
      return import('@/views/project/cost-settle/transport-bulk-cost-settle');
    },
    name: 'TransportBulkCostSettle',
    hidden: true,
    meta: {
      title: '成本核算',
      noCache: true,
      roles: ['项目管理']
    }
  }, {
    path: 'bulk-cost-settle-apply-list',
    component: function component() {
      return import('@/views/project/cost-settle/settle-apply-list');
    },
    name: 'BulkCostSettleApplyList',
    hidden: true,
    meta: {
      title: '结算历史',
      noCache: true,
      roles: ['项目管理']
    }
  }, {
    path: 'transshipment-finance/:projectId',
    component: function component() {
      return import('@/views/project/settlement/components/transshipment-finance');
    },
    name: 'TransshipmentFinance',
    hidden: true,
    meta: {
      title: '倒运收支',
      noCache: true,
      roles: ['项目管理']
    }
  }]
}, {
  path: '/truck-track',
  component: Layout,
  redirect: '/truck-track/location-track',
  alwaysShow: true,
  name: 'TruckTrack',
  meta: {
    title: '在途车辆',
    icon: 'nb_ztcl',
    roles: ['在途车辆', '项目管理:调度']
  },
  children: [{
    path: 'location-track',
    component: function component() {
      return import('@/views/project/truck-track/location-track');
    },
    name: 'LocationTrack',
    meta: {
      title: '车辆定位',
      statisticsName: '在途车辆 / 车辆定位',
      icon: 'nb_ztcl_dwcl',
      roles: ['项目管理:调度']
    }
  }, {
    path: 'travel-track',
    component: function component() {
      return import('@/views/project/truck-track/travel-track');
    },
    name: 'TravelTrack',
    meta: {
      title: '调度车辆轨迹',
      statisticsName: '在途车辆 / 调度车辆轨迹',
      icon: 'nb_ztcl_lsgj',
      roles: ['项目管理:调度']
    }
  }, {
    path: 'travel-track-by-license-date',
    component: function component() {
      return import('@/views/project/truck-track/travel-track-by-license-date');
    },
    name: 'TravelTrackByLicenseDate',
    meta: {
      title: '车辆轨迹查询',
      statisticsName: '在途车辆 / 车辆轨迹查询',
      icon: 'nb_ztcl_lsgj',
      roles: ['在途车辆:车辆轨迹查询']
    }
  }, {
    path: 'truck-dashboard',
    component: function component() {
      return import('@/views/project/truck-track/truck-dashboard-gd');
    },
    name: 'TruckDashboard',
    meta: {
      title: '车辆看板',
      statisticsName: '在途车辆 / 车辆看板',
      icon: 'nb_ztcl_clkb',
      roles: ['在途车辆:车辆看板']
    }
  }]
}, {
  path: '/finance',
  component: Layout,
  redirect: '/finance/matters',
  alwaysShow: true,
  name: 'Finance',
  meta: {
    title: '财务',
    icon: 'nb_cw',
    roles: ['财务']
  },
  children: [{
    path: 'config',
    component: function component() {
      return import('@/views/finance/finance-invoice-tax-config/config-home');
    },
    name: 'FinanceTaxConfig',
    meta: {
      title: '财务科目配置',
      statisticsName: '财务 / 财务科目配置',
      icon: 'nb_cw_cwkmpz',
      roles: ['财务:财务科目配置']
    }
  }, {
    path: 'merge-manage',
    component: function component() {
      return import('@/views/finance/merge-manage/merge-detail-list');
    },
    name: 'MergeDetailList',
    meta: {
      title: '集中处理',
      icon: 'nb_cw_jzcl',
      roles: ['财务:集中处理']
    }
  }, {
    path: 'finance-manage',
    component: function component() {
      return import('@/views/finance/finance-manage/finance-detail-list');
    },
    name: 'FinanceDetailList',
    meta: {
      title: '财务操作',
      statisticsName: '财务 / 财务操作',
      icon: 'nb_cw_cwcl',
      noCache: true,
      roles: ['财务:财务操作']
    }
  }, {
    path: 'finance-settle-bill',
    component: function component() {
      return import('@/views/finance/settle-bill/index');
    },
    name: 'FinanceSettleBill',
    redirect: '/finance/finance-settle-bill/customer-settle-bill',
    alwaysShow: true,
    meta: {
      title: '财务平账',
      icon: 'nb_cw',
      roles: ['财务:平账']
    },
    children: [{
      path: 'customer-settle-bill',
      component: function component() {
        return import('@/views/finance/settle-bill/customer-settle-bill/customer-settle-bill-list');
      },
      name: 'CustomerSettleBillList',
      meta: {
        title: '客户对账',
        icon: 'nb_empty',
        // noCache: true,
        statisticsName: '财务 / 客户对账'
      }
    }, {
      path: 'supplier-settle-bill',
      component: function component() {
        return import('@/views/finance/settle-bill/supplier-settle-bill/supplier-settle-bill-list');
      },
      name: 'SupplierSettleBillList',
      meta: {
        title: '供应商对账',
        icon: 'nb_empty',
        // noCache: true,
        statisticsName: '财务 / 供应商对账'
      }
    }, {
      path: 'project-fee-settle-bill',
      component: function component() {
        return import('@/views/finance/settle-bill/project-fee-settle-bill/project-fee-settle-bill-list');
      },
      name: 'ProjectFeeSettleBillList',
      meta: {
        title: '项目对账',
        icon: 'nb_empty',
        // noCache: true,
        statisticsName: '财务 / 项目对账'
      }
    }, {
      path: 'staff-schedule-settle-bill',
      component: function component() {
        return import('@/views/finance/settle-bill/staff-schedule-settle-bill/staff-schedule-settle-bill-list');
      },
      name: 'StaffScheduleSettleBillList',
      meta: {
        title: '员工对账',
        icon: 'nb_empty',
        // noCache: true,
        statisticsName: '财务 / 员工对账'
      }
    }]
  }, {
    path: 'project-report',
    component: function component() {
      return import('@/views/finance/report/project-income-cost-list');
    },
    name: 'ProjectIncomeCostReport',
    meta: {
      title: '项目收支报表',
      statisticsName: '财务 / 项目收支报表',
      icon: 'nb_cw_xmszbb',
      roles: ['财务:项目收支报表']
    }
  }, // {
  //   path: 'finance-report',
  //   component: () => import('@/views/finance/report/finance-report-list'),
  //   name: 'FinanceReportList',
  //   meta: {
  //     title: '财务报表',
  //     statisticsName: '财务 / 财务报表',
  //     icon: 'nb_cw_cwbb',
  //     roles: ['财务:财务报表']
  //   }
  // },
  {
    path: 'finance-detail-report',
    component: function component() {
      return import('@/views/finance/report/finance-report-v2-list');
    },
    name: 'FinanceDetailReportList',
    meta: {
      title: '财务报表',
      statisticsName: '财务 / 财务报表',
      icon: 'nb_cw_cwbb',
      roles: ['财务:财务报表']
    }
  }, {
    path: 'finance-detail-sum-report',
    component: function component() {
      return import('@/views/finance/report/finance-report-sum-v2-list');
    },
    name: 'FinanceDetailSumReportList',
    meta: {
      title: '财务汇总报表',
      statisticsName: '财务 / 财务汇总报表',
      icon: 'nb_cw_cwbb',
      roles: ['财务:财务报表']
    }
  }, {
    path: 'income-cost-report',
    component: function component() {
      return import('@/views/finance/report/income-cost-list');
    },
    name: 'IncomeCostList',
    meta: {
      title: '收支明细报表',
      statisticsName: '财务 / 收支明细报表',
      icon: 'nb_cw_cwbb',
      roles: ['财务:项目收支报表']
    }
  }]
}, {
  path: '/digital-asset',
  component: Layout,
  alwaysShow: true,
  name: 'DigitalAsset',
  meta: {
    title: '数字资产',
    icon: 'nb_szzc',
    roles: ['数字资产']
  },
  children: [{
    path: 'category-list',
    component: function component() {
      return import('@/views/digital-asset/category-list');
    },
    name: 'DigitalAssetCategoryList',
    meta: {
      title: '资料类别',
      icon: 'nb_szzc_zllb',
      noCache: true,
      roles: ['数字资产:资料类别:超级管理员', '数字资产:资料类别:查看']
    }
  }, {
    path: 'digital-asset-list',
    component: function component() {
      return import('@/views/digital-asset/digital-asset-list');
    },
    name: 'DigitalAssetList',
    meta: {
      title: '资料库',
      icon: 'nb_szzc_zlk',
      noCache: true,
      roles: ['数字资产:资料库:查看']
    }
  }, {
    path: 'digital-asset-borrow-list',
    component: function component() {
      return import('@/views/digital-asset/digital-asset-borrow-list');
    },
    name: 'DigitalAssetBorrowList',
    meta: {
      title: '我的借阅',
      icon: 'nb_szzc_wdjy',
      noCache: true,
      roles: ['数字资产:资料库:查看']
    }
  }, {
    path: 'digital-asset-affair-list',
    component: function component() {
      return import('@/views/digital-asset/digital-asset-affair-list');
    },
    name: 'DigitalAssetAffairList',
    meta: {
      title: '我的待办',
      icon: 'nb_szzc_wddb',
      noCache: true,
      roles: ['数字资产:资料类别:查看']
    }
  }, {
    path: 'digital-asset-manage-list',
    component: function component() {
      return import('@/views/digital-asset/manage-digital-asset-list');
    },
    name: 'ManageDigitalAssetList',
    meta: {
      title: '我的管理',
      icon: 'nb_szzc_wdgl',
      noCache: true,
      roles: ['数字资产:资料类别:查看']
    }
  }, {
    path: 'category-designer',
    component: function component() {
      return import('@/views/digital-asset/category-form-designer');
    },
    name: 'CategoryFormDesigner',
    hidden: true,
    meta: {
      title: '新增类别',
      icon: 'nb_cw_zjsx',
      noCache: true,
      roles: ['数字资产:资料类别:超级管理员']
    }
  }]
}, {
  path: '/stat',
  component: Layout,
  redirect: '/stat/stat-data',
  alwaysShow: true,
  name: 'StatData',
  meta: {
    title: '统计报表',
    icon: 'nb_cw_zjsx',
    roles: ['统计报表']
  },
  children: [{
    path: 'stat-data',
    component: function component() {
      return import('@/views/stat/stat-data-page');
    },
    name: 'StatDataPage',
    meta: {
      title: '报表',
      statisticsName: '统计报表 / 报表',
      icon: 'nb_cw_zjsx',
      roles: ['统计报表:报表']
    }
  }, {
    path: 'stat-transport',
    component: function component() {
      return import('@/views/stat/stat-transport-page');
    },
    name: 'TransportStatList',
    meta: {
      title: '调车统计',
      statisticsName: '统计报表 / 调车统计',
      icon: 'nb_cw_zjsx',
      roles: ['统计报表:报表']
    }
  }, {
    path: 'stat-enquiry',
    component: function component() {
      return import('@/views/stat/stat-enquiry-report-list');
    },
    name: 'EnquiryStatList',
    meta: {
      title: '询报价表',
      statisticsName: '统计报表 / 询报价表',
      icon: 'nb_cw_zjsx',
      roles: ['统计报表:报表']
    }
  }, {
    path: 'stat-staff-settle',
    component: function component() {
      return import('@/views/stat/stat-staff-settle-page');
    },
    name: 'StaffSettleStatList',
    meta: {
      title: '员工记账',
      statisticsName: '统计报表 / 员工记账',
      icon: 'nb_cw_zjsx',
      roles: ['统计报表:报表']
    }
  }, {
    path: 'stat-activity',
    component: function component() {
      return import('@/views/stat/stat-activity');
    },
    name: 'StatActivity',
    meta: {
      title: '活跃度',
      icon: 'nb_cw_zjsx',
      roles: ['统计报表:活跃度']
    }
  }, {
    path: 'matters',
    component: function component() {
      return import('@/views/finance/matters/finance-matters-v2-list');
    },
    name: 'FinanceMattersList',
    hidden: false,
    meta: {
      title: '资金事项',
      icon: 'nb_cw_zjsx',
      noCache: true,
      roles: ['财务:资金事项:收入:查看', '财务:资金事项:成本:查看']
    }
  }, {
    path: '/kanban-portal',
    name: 'KanbanPortal',
    component: function component() {
      return import('@/views/kanban/kanban-portal');
    },
    hidden: false,
    meta: {
      title: '大屏看板',
      icon: 'nb_xtgl_ycxq',
      noCache: true,
      roles: ['大屏看板']
    }
  }]
}, {
  path: '/cost-model',
  component: Layout,
  redirect: '/cost-model/unit-cost',
  alwaysShow: true,
  name: 'CostModel',
  meta: {
    title: '成本模型',
    icon: 'nb_cw_zjsx',
    roles: ['成本模型']
  },
  children: [{
    path: 'unit-cost',
    component: function component() {
      return import('@/views/cost-model/unit-cost');
    },
    name: 'UnitCost',
    meta: {
      title: '成本单价',
      statisticsName: '成本模型 / 成本单价',
      icon: 'nb_cw_zjsx',
      roles: ['成本模型:成本单价']
    }
  }]
}, {
  path: '/project/print-contract-template/:type/:id(\\d+)',
  component: function component() {
    return import('@/views/project/transport-schedule/print/print-contract-template');
  },
  hidden: true,
  name: 'PrintTemplate',
  meta: {
    title: '打印',
    icon: 'print' // roles: ['business:contract:income:detail', 'project:project:detail']

  }
}, {
  path: '/inventory/print-contract-template/:id',
  component: function component() {
    return import('@/views/inventory/stat/print/print-transport-contract');
  },
  hidden: true,
  name: 'PrintTransportTemplate',
  meta: {
    title: '打印运输合同',
    icon: 'print' // roles: ['business:contract:income:detail', 'project:project:detail']

  }
}, {
  path: '/delivery-out-print/:id',
  component: function component() {
    return import('@/views/inventory/stat/print/print-delivery');
  },
  name: 'PrintDelivery',
  hidden: true,
  meta: {
    title: '发货单打印',
    icon: 'print' // roles: ['inventory:part:delivery', 'inventory:query:detail', 'inventory:statistics:delivery']

  }
}, {
  path: '/material-in-print/:id',
  component: function component() {
    return import('@/views/inventory/stat/print/print-material-in');
  },
  name: 'PrintMaterial',
  hidden: true,
  meta: {
    title: '材料入库单打印',
    icon: 'print' // roles: ['inventory:material:storage']

  }
}, {
  path: '/demand-print/:id',
  component: function component() {
    return import('@/views/project/transport-demand/print/print-demand-template');
  },
  name: 'PrintTransportDemand',
  hidden: true,
  meta: {
    title: '用车需求打印',
    icon: 'print' // roles: ['inventory:material:storage']

  }
}, // 404 page must be placed at the end !!!
{
  path: '*',
  redirect: '/404',
  hidden: true
}];

var createRouter = function createRouter() {
  return new Router({
    // mode: 'history', // require service support
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: constantRoutes
  });
};

var router = createRouter();
router.afterEach(function (to, from) {
  if (to.meta && to.meta.statisticsName) {
    createPageVisitStat({
      page: to.meta.statisticsName
    });
  }
}); // Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465

export function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}
export default router;