import request from '@/utils/request';
var basePath = '/api/v1/inventory-base/workshop';
var baseUrl = process.env.VUE_APP_INVENTORY_API;
export function listAllWorkshop(query) {
  return request({
    baseURL: baseUrl,
    url: basePath + '/list-all',
    method: 'get',
    params: query
  });
}
export function listWorkshop(query) {
  return request({
    baseURL: baseUrl,
    url: basePath + '/list',
    method: 'get',
    params: query
  });
}
export function getWorkshop(query) {
  return request({
    baseURL: baseUrl,
    url: basePath + '/{workshopId}',
    method: 'get',
    params: query
  });
}
export function saveWorkshop(data) {
  return request({
    baseURL: baseUrl,
    url: basePath + '/save',
    method: 'post',
    data: {
      data: data
    }
  });
}
export function updateWorkshop(query, data) {
  return request({
    baseURL: baseUrl,
    url: basePath + '/{workshopId}',
    method: 'put',
    data: {
      data: data
    },
    params: query
  });
}
export function deleteWorkshop(query) {
  return request({
    baseURL: baseUrl,
    url: basePath + '/{workshopId}',
    method: 'delete',
    params: query
  });
}