import "core-js/modules/es6.array.find";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { changeId } from "../mixin";
export default {
  name: 'UploadConfig',
  components: {},
  mixins: [changeId],
  // eslint-disable-next-line vue/require-prop-types
  props: ['props'],
  data: function data() {
    return {
      attachmentFileType: 'DigitalAssetFile',
      attachmentFileTypeList: [{
        label: '数字资产附件',
        value: 'DigitalAssetFile'
      }]
    };
  },
  watch: {},
  mounted: function mounted() {},
  methods: {
    handlerChangeLabel: function handlerChangeLabel(val) {
      this.props.labelWidth = val ? '80' : '1';
    },
    handleListTypeChange: function handleListTypeChange(val) {
      console.log(val);

      if (val === 'picture-card') {
        this.props.accept = '.jpg, .jpeg, .png';
      } else {
        this.props.accept = '.jpg, .jpeg, .png, .pdf, .doc, .docx, .xls, .xlsx, .zip, .rar';
      }
    },
    handleAttachmentFileType: function handleAttachmentFileType(val) {
      var type = this.attachmentFileTypeList.find(function (a) {
        return a.value === val;
      });
      this.props.data = {
        prefixName: type.label,
        fileType: type.value
      };
    }
  }
};