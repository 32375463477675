import "core-js/modules/es6.object.keys";
import "core-js/modules/web.dom.iterable";
import "/var/jenkins_home/workspace/ops.dfwl.plus/node_modules/core-js/modules/es6.array.iterator.js";
import "/var/jenkins_home/workspace/ops.dfwl.plus/node_modules/core-js/modules/es6.promise.js";
import "/var/jenkins_home/workspace/ops.dfwl.plus/node_modules/core-js/modules/es6.object.assign.js";
import "/var/jenkins_home/workspace/ops.dfwl.plus/node_modules/core-js/modules/es7.promise.finally.js";
import Vue from 'vue';
import Cookies from 'js-cookie';
import 'normalize.css/normalize.css'; // a modern alternative to CSS resets

import Element from 'element-ui';
import "./styles/element-variables.scss"; // import FnbElementUI from '@autodt/fnb-element-ui'
// import '@autodt/fnb-element-ui/lib/index.css'

import App from "./App";
import store from "./store";
import router from "./router";
import "./icons"; // icon

import "./permission"; // permission control

import "./utils/error-log"; // error log

import PinyinMatch from 'pinyin-match';
import 'xe-utils';
import VXETable from 'vxe-table';
import 'vxe-table/lib/style.css'; // import 'vxe-table/lib/index.css'

import VXETablePluginElement from 'vxe-table-plugin-element';
import 'vxe-table-plugin-element/dist/style.css';
import onlyNumber from '@/utils/only_number';
import fullscreen from 'vue-fullscreen';
import VideoPlayer from 'vue-video-player';
import 'vue-video-player/src/custom-theme.css';
import 'video.js/dist/video-js.css';
Vue.use(VideoPlayer);
import TextEllipsis from '@/components/TextEllipsis/index.vue';
Vue.component('TextEllipsis', TextEllipsis); // form designer

import axios from 'axios';
import "./assets/iconfont/iconfont.js";
import plugins from "./components/FormDesigner/index";
Vue.prototype.$axios = axios;
Vue.use(plugins); // end form designer
// import AmapVue from '@amap/amap-vue'
// AmapVue.config.version = '2.0' // 默认2.0，这里可以不修改
// AmapVue.config.key = 'ded8c6af58f9924448e7c828e1a4162b'
// AmapVue.config.plugins = [
//   'AMap.ToolBar',
//   'AMap.MoveAnimation'
//   // 在此配置你需要预加载的插件，如果不配置，在使用到的时候会自动异步加载
// ]

import VueAMap from 'vue-amap';
Vue.use(VueAMap);
VueAMap.initAMapApiLoader({
  key: '321225672f80464e9d5cfd920fa7f53d',
  plugin: ['AMap.ToolBar', 'AMap.MoveAnimation', 'AMap.GraspRoad', 'AMap.PolyEditor', 'AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Geolocation', 'AMap.Marker', 'AMap.Geocoder'],
  // 默认高德 sdk 版本为 1.4.4
  v: '1.4.20'
});
import '@/styles/index.scss'; // global css

import * as filters from "./filters"; // global filters

/**
 * If you don't want to use mock-server
 * you want to use MockJs for mock api
 * you can execute: mockXHR()
 *
 * Currently MockJs will be used in the production environment,
 * please remove it before going online! ! !
 */
// import { mockXHR } from '../mock'
// if (process.env.NODE_ENV === 'production') {
//   mockXHR()
// }

Vue.use(Element, {
  size: Cookies.get('size') || 'medium' // set element-ui default size

}); // Vue.use(FnbElementUI)

Vue.use(VXETable);
VXETable.use(VXETablePluginElement);
Vue.use(onlyNumber);
Vue.use(fullscreen); // Vue.use(AmapVue)

Vue.component('pinyinMatch', PinyinMatch); // register global utility filters

Object.keys(filters).forEach(function (key) {
  Vue.filter(key, filters[key]);
});
Vue.config.productionTip = false;
new Vue({
  el: '#app',
  router: router,
  store: store,
  render: function render(h) {
    return h(App);
  }
});