//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/**
 * text的配置项
 */
export default {
  name: 'HtmlConfig',
  props: {
    // eslint-disable-next-line vue/require-prop-types,vue/require-default-prop
    props: {}
  },
  data: function data() {
    return {
      editTextVisible: false
    };
  },
  methods: {
    handlerEditMore: function handlerEditMore() {
      this.editTextVisible = true;
    },
    handlerSave: function handlerSave() {
      this.editTextVisible = false;
    }
  }
};