var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.props.compType === "row",
          expression: "props.compType === 'row'"
        }
      ]
    },
    [
      _c(
        "el-form-item",
        { attrs: { label: "栅格间隔" } },
        [
          _c("el-input-number", {
            attrs: { size: "small", min: 0, max: 100 },
            model: {
              value: _vm.props.gutter,
              callback: function($$v) {
                _vm.$set(_vm.props, "gutter", $$v)
              },
              expression: "props.gutter"
            }
          })
        ],
        1
      ),
      _c("el-divider", [_vm._v("列配置项")]),
      _vm._l(_vm.props.columns, function(item, index) {
        return _c(
          "div",
          { key: index, staticClass: "select-item" },
          [
            _vm._m(0, true),
            _c("el-input-number", {
              attrs: { size: "normal", min: 1, max: 24 },
              model: {
                value: item.span,
                callback: function($$v) {
                  _vm.$set(item, "span", $$v)
                },
                expression: "item.span"
              }
            }),
            _c(
              "div",
              {
                staticClass: "close-btn select-line-icon",
                on: {
                  click: function($event) {
                    return _vm.handlerRemoveCol(index)
                  }
                }
              },
              [_c("i", { staticClass: "el-icon-remove-outline" })]
            )
          ],
          1
        )
      }),
      _c(
        "div",
        { staticStyle: { "margin-left": "30px" } },
        [
          _c(
            "el-button",
            {
              staticStyle: { "padding-bottom": "0" },
              attrs: { icon: "el-icon-circle-plus-outline", type: "text" },
              on: { click: _vm.handlerAddCol }
            },
            [_vm._v(" 添加选项 ")]
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "select-line-icon" }, [
      _c("i", { staticClass: "el-icon-s-operation" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }