import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
import draggable from 'vuedraggable';
import render from "./custom/render";
import { getSimpleId } from "./utils/IdGenerate";
import { dynamicTableAllowedItems, tableAllowedItems } from "./custom/formConf";
import dynamicTable from "./dynamic/dynamicTable";
import dynamicTableItem from "./dynamic/dynamicTableItem";
import fancyTable from "./table/fancyTable";
import fancyTableItem from "./table/fancyTableItem";
/**
 * 动态表单允许增加的组件列表
 */

var components = {
  itemBtns: function itemBtns(h, element, parent) {
    var _this$$listeners = this.$listeners,
        copyItem = _this$$listeners.copyItem,
        deleteItem = _this$$listeners.deleteItem;
    return [h("span", {
      "class": 'drawing-item-copy',
      "attrs": {
        "title": '复制'
      },
      "on": {
        "click": function click(event) {
          copyItem(element, parent);
          event.stopPropagation();
        }
      }
    }, [h("i", {
      "class": 'el-icon-copy-document'
    })]), h("span", {
      "class": 'drawing-item-delete',
      "attrs": {
        "title": '删除'
      },
      "on": {
        "click": function click(event) {
          deleteItem(element, parent);
          event.stopPropagation();
        }
      }
    }, [h("i", {
      "class": 'el-icon-delete'
    })])];
  }
};
var layouts = {
  colItem: function colItem(h, element, parent) {
    var _this = this;

    var className = this.activeItem.id === element.id ? 'drawing-item active-from-item' : 'drawing-item';
    var labelWidth = element.labelWidth ? "".concat(element.labelWidth, "px") : null;
    var onActiveItemChange = this.$listeners.onActiveItemChange;
    return h("el-col", {
      "class": className,
      "attrs": {
        "span": element.span
      },
      "nativeOn": {
        "click": function click(event) {
          onActiveItemChange(element);
          event.stopPropagation();
        }
      }
    }, [h("span", {
      "class": 'component-name component-id'
    }, [element.id]), h("el-form-item", {
      "attrs": {
        "label": element.showLabel ? element.label : '',
        "label-width": labelWidth,
        "required": element.required
      }
    }, [h(render, {
      "key": element.id,
      "attrs": {
        "conf": element
      },
      "on": {
        "input": function input(event) {
          _this.$set(element, 'value', event);
        }
      }
    })]), components.itemBtns.apply(this, arguments)]);
  },
  rowItem: function rowItem(h, element) {
    var _this2 = this;

    var onActiveItemChange = this.$listeners.onActiveItemChange;
    var className = this.activeItem.id === element.id ? 'drawing-item drawing-row-item active-from-item' : 'drawing-item drawing-row-item';
    return h("el-col", {
      "class": className
    }, [h("el-row", {
      "attrs": {
        "gutter": element.gutter
      },
      "nativeOn": {
        "click": function click(event) {
          onActiveItemChange(element);
          event.stopPropagation();
        }
      }
    }, [h("span", {
      "class": 'component-name'
    }, [element.id]), h("div", {
      "class": 'drag-wrapper',
      "style": 'padding-left: 7.5px; padding-right: 7.5px;'
    }, [element.columns.map(function (item, index) {
      return h("el-col", {
        "class": 'drag-col-wrapper',
        "attrs": {
          "span": item.span
        }
      }, [h(draggable, {
        "class": 'drag-wrapper row-drag',
        "attrs": {
          "animation": '100',
          "group": 'componentsGroup'
        },
        "on": {
          "add": function add(e) {
            _this2.handlerAdd(e, item, element);
          }
        },
        "model": {
          value: item.list,
          callback: function callback($$v) {
            _this2.$set(item, "list", $$v);
          }
        }
      }, [item.list.map(function (obj, objIndex) {
        return renderChildren.call(_this2, h, obj, element);
      })])]);
    })])]), components.itemBtns.call(this, h, element)]);
  },
  tableItem: function tableItem(h, element) {
    var _this3 = this;

    var className = '';
    className = this.activeItem.id === element.id ? 'drawing-item drawing-row-item active-from-item' : 'drawing-item drawing-row-item';
    var onActiveItemChange = this.$listeners.onActiveItemChange;
    return h("el-col", {
      "class": className,
      "nativeOn": {
        "click": function click(event) {
          onActiveItemChange(element);
          event.stopPropagation();
        }
      }
    }, [h("span", {
      "class": 'component-name',
      "style": 'margin-bottom:15px'
    }, [element.id]), h("fancy-table", {
      "attrs": {
        "layoutArray": element.layoutArray,
        "tdStyle": element.tdStyle,
        "width": element.width,
        "height": element.height
      },
      "on": {
        "selectItem": function selectItem(item) {
          onActiveItemChange(item);
        }
      },
      "scopedSlots": {
        default: function _default(item) {
          return h(draggable, {
            "attrs": {
              "tag": 'div',
              "animation": '100',
              "group": 'componentsGroup'
            },
            "class": 'table__content row-drag',
            "on": {
              "add": function add(e) {
                _this3.handlerTableAdd(e, item.td);

                e.stopPropagation();
              }
            },
            "model": {
              value: item.td.columns,
              callback: function callback($$v) {
                _this3.$set(item.td, "columns", $$v);
              }
            }
          }, [item.td.columns.map(function (obj) {
            return renderChildren.call(_this3, h, obj, item.td);
          })]);
        }
      }
    }), components.itemBtns.call(this, h, element)]);
  },
  dynamicItem: function dynamicItem(h, element) {
    var _this4 = this;

    var className = '';
    className = this.activeItem.id === element.id ? className + 'drawing-item active-from-item' : className + 'drawing-item';
    var onActiveItemChange = this.$listeners.onActiveItemChange;
    return h("el-col", {
      "class": className
    }, [h("dynamic-table", {
      "attrs": {
        "conf": element,
        "activeItem": this.activeItem
      },
      "nativeOn": {
        "click": function click(event) {
          onActiveItemChange(element);
          event.stopPropagation();
        }
      }
    }, [h(draggable, {
      "attrs": {
        "tag": 'div',
        "ghost-class": 'dynamicGhost',
        "animation": '100',
        "group": 'componentsGroup'
      },
      "class": 'dynamic-table__content row-drag',
      "on": {
        "add": function add(e) {
          _this4.handlerDynamicAdd(e, element);
        }
      },
      "model": {
        value: element.columns,
        callback: function callback($$v) {
          _this4.$set(element, "columns", $$v);
        }
      }
    }, [element.columns.map(function (item, index) {
      return h("dynamic-table-item", {
        "attrs": {
          "item": item,
          "activeItem": _this4.activeItem
        },
        "on": {
          "selectItem": function selectItem(evt, item) {
            onActiveItemChange(item);
            evt.stopPropagation();
          },
          "copyItem": function copyItem(evt) {
            _this4.handlerCopyItem(evt, element, index);

            evt.stopPropagation();
          },
          "deleteItem": function deleteItem(evt) {
            _this4.handlerDeleteItem(evt, element, index);

            evt.stopPropagation();
          }
        }
      });
    })])]), components.itemBtns.call(this, h, element)]);
  }
};
/**
 * 生成row的子选项
 */

function renderChildren(h, element, parent) {
  var layout = layouts[element.layout];

  if (layout) {
    return layout.call(this, h, element, parent);
  }

  return layoutIsNotFound.call(this);
}

function layoutIsNotFound() {
  throw new Error("\u6CA1\u6709\u4E0E".concat(this.element.layout, "\u5339\u914D\u7684layout"));
}

export default {
  name: 'DesignItem',
  components: {
    render: render,
    draggable: draggable,
    dynamicTable: dynamicTable,
    dynamicTableItem: dynamicTableItem,
    fancyTable: fancyTable,
    fancyTableItem: fancyTableItem
  },
  props: {
    model: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    activeItem: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      activeComponent: this.activeItem
    };
  },
  methods: {
    handlerAdd: function handlerAdd(evt, item, row) {
      if (evt.pullMode === 'clone') {
        if (!(evt.to.className.indexOf('row-drag') > -1 && this.activeItem.compType === 'row')) {
          item.list.splice(evt.newIndex, 0, this.activeItem);
        }
      } else {
        if (evt.item.className.indexOf('el-row') > -1 && this.activeItem.compType !== 'row') {
          // 防止row嵌套
          var newIndex = evt.newIndex;
          var oldIndex = evt.oldIndex;
          var rowItem = item.list[newIndex];
          item.list.splice(newIndex, 1);
          this.$message.error('布局组件不允许被嵌套！');
          this.$emit('rowItemRollBack', rowItem, oldIndex); // 还原到原先的列表中

          return false;
        }
      }
    },

    /**
     * 动态表单
     */
    handlerDynamicAdd: function handlerDynamicAdd(evt, item) {
      if (evt.pullMode === 'clone') {
        if (dynamicTableAllowedItems.includes(this.activeItem.compType)) {
          item.columns.splice(evt.newIndex, 0, this.activeItem);
        } else {
          this.$message.error('该组件不允许被放入动态表格内！');
        }
      } else {
        if (evt.item.className.indexOf('el-row') > -1) {
          // 防止row嵌套
          var newIndex = evt.newIndex;
          var oldIndex = evt.oldIndex;
          var rowItem = item.columns[newIndex];
          item.columns.splice(newIndex, 1);
          this.$message.error('布局组件不允许放入动态表格！');
          this.$emit('rowItemRollBack', rowItem, oldIndex); // 还原到原先的列表中

          return false;
        }
      }
    },
    handlerTableAdd: function handlerTableAdd(evt, td) {
      if (evt.pullMode === 'clone') {
        if (!tableAllowedItems.includes(this.activeItem.compType)) {
          td.columns.splice(evt.newIndex, 0, this.activeItem);
        } else {
          this.$message.error('该组件不允许被放入表格内！');
        }
      }
    },
    handlerCopyItem: function handlerCopyItem(evt, element, index) {
      var item = element.columns[index];
      var clone = JSON.parse(JSON.stringify(item));
      var uId = 'fd_' + getSimpleId();
      clone.id = uId;
      clone._id = uId;
      element.columns.push(clone);
    },
    handlerDeleteItem: function handlerDeleteItem(evt, element, index) {
      element.columns.splice(index, 1);
    }
  },
  render: function render(h) {
    var layout = layouts[this.model.layout];

    if (layout) {
      return layout.call(this, h, this.model);
    }

    return layoutIsNotFound.call(this);
  }
};