import render from "../custom/previewRender";
import checkRules from "../custom/rule";
var layouts = {
  colItem: function colItem(element, value, parent, index) {
    var _this = this;

    var h = this.$createElement;
    var valChange = this.$listeners.valChange;
    var rules = [];

    if (element && element.rules) {
      rules = rules.concat(checkRules(element));
      return h("el-form-item", {
        "attrs": {
          "label": '',
          "label-width": '0px',
          "prop": parent.id + '.' + index + '.' + element.id,
          "rules": rules
        }
      }, [h(render, {
        "key": element.id,
        "attrs": {
          "conf": element,
          "value": value
        },
        "on": {
          "input": function input(event) {
            _this.eleValue = event;
            valChange(_this.eleParent.id, _this.index, element.id, _this.eleValue);
          }
        }
      }), element.required ? h("span", {
        "style": 'color:#F56C6C'
      }, ["*"]) : '']);
    }
  }
};
export default {
  name: 'FancyDynamicTableItem',
  components: {
    render: render
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['model', 'value', 'parent', 'index'],
  data: function data() {
    return {
      eleConfig: {},
      eleParent: this.parent,
      eleValue: this.value
    };
  },
  watch: {
    value: function value(newVal) {
      this.eleValue = newVal;
    }
  },
  mounted: function mounted() {
    var __eleConfig = {};
    Object.assign(__eleConfig, this.model);
    this.eleConfig = __eleConfig;
  },
  render: function render() {
    return layouts.colItem.call(this, this.eleConfig, this.eleValue, this.eleParent, this.index);
  }
};