import request from '@/utils/request';
var basePath = '/api/v1/user-base/page-visit-statistics';
export function listPageVisitStatByStaff(query) {
  return request({
    url: basePath + '/by-staff',
    method: 'get',
    params: query
  });
}
export function listPageVisitStatByPage(query) {
  return request({
    url: basePath + '/by-page',
    method: 'get',
    params: query
  });
}
export function listPageVisitStatByStaffPage(query) {
  return request({
    url: basePath + '/by-staff-page',
    method: 'get',
    params: query
  });
}
export function createPageVisitStat(data) {
  return request({
    url: basePath + '/save',
    method: 'post',
    data: {
      data: data
    }
  });
}