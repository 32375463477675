import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.number.constructor";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import icon from "../icon";
import fancyTableItem from "./fancyTableItem";
import { jsonClone } from "../utils";
import { getTrItem, getTdItem } from "./table";
import { getSimpleId } from "../utils/IdGenerate";
var tr = getTrItem();
export default {
  name: 'FancyTable',
  components: {
    icon: icon,
    fancyTableItem: fancyTableItem
  },
  props: {
    layoutArray: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    tdStyle: {
      type: String,
      default: ''
    },
    width: {
      type: Number,
      default: 100
    },
    height: {
      type: Number,
      default: 100
    }
  },
  data: function data() {
    return {
      positionX: 0,
      positionY: 0,
      showContextMunu: false,
      currentRowIndex: 0,
      currentColIndex: 0 // columns: this.trs

    };
  },
  computed: {
    showRightColMenu: function showRightColMenu() {
      if (this.showContextMunu) {
        var col = this.layoutArray[this.currentRowIndex][this.currentColIndex].col;
        var td = this.layoutArray[this.currentRowIndex][this.currentColIndex + col];
        return td && td.row < 2 && td.col < 2 && !td.hide;
      } else {
        return false;
      }
    },
    showDownRowMenu: function showDownRowMenu() {
      if (this.showContextMunu) {
        var row = this.layoutArray[this.currentRowIndex][this.currentColIndex].row;
        var td;

        if (typeof this.layoutArray[this.currentRowIndex + row] !== 'undefined') {
          td = this.layoutArray[this.currentRowIndex + row][this.currentColIndex];
        }

        return td && td.row < 2 && td.col < 2 && !td.hide;
      } else {
        return false;
      }
    },
    showResetTableMenu: function showResetTableMenu() {
      if (this.showContextMunu) {
        var td = this.layoutArray[this.currentRowIndex][this.currentColIndex];
        return !(td.row < 2 && td.col < 2 && !td.hide);
      } else {
        return false;
      }
    },
    tableStyle: function tableStyle() {
      return 'width:' + this.width + '%;';
    },
    trHeight: function trHeight() {
      return 'height:' + this.height + 'px';
    }
  },
  mounted: function mounted() {
    // 添加监听取消右键菜单
    document.addEventListener('click', this.hideRightContextMenu, true);
    document.addEventListener('contextmenu', this.hideRightContextMenu, true); // this.handlerAppendCol();
    // this.handlerAppendCol();
  },
  destroyed: function destroyed() {
    // 移除监听
    document.removeEventListener('click', this.hideRightContextMenu, true);
    document.removeEventListener('contextmenu', this.hideRightContextMenu, true);
  },
  methods: {
    rightClick: function rightClick(e, rowIndex, colIndex) {
      this.positionX = e.clientX;
      this.positionY = e.clientY;
      this.showContextMunu = true;
      this.currentRowIndex = rowIndex;
      this.currentColIndex = colIndex;
    },
    hideRightContextMenu: function hideRightContextMenu() {
      this.showContextMunu = false;
    },
    // 向右合并单元格
    handlerRightCol: function handlerRightCol() {
      var col = this.layoutArray[this.currentRowIndex][this.currentColIndex].col;
      var row = this.layoutArray[this.currentRowIndex][this.currentColIndex].row;

      if (row > 1) {
        for (var i = 0; i < row; i++) {
          this.layoutArray[this.currentRowIndex + i][this.currentColIndex + col].hide = true;
          this.layoutArray[this.currentRowIndex][this.currentColIndex].col = col + 1;
        }
      } else {
        this.layoutArray[this.currentRowIndex][this.currentColIndex + col].hide = true;
        this.layoutArray[this.currentRowIndex][this.currentColIndex].col = col + 1;
      }
    },
    // 向下合并单元格
    handlerDownRow: function handlerDownRow() {
      var col = this.layoutArray[this.currentRowIndex][this.currentColIndex].col;
      var row = this.layoutArray[this.currentRowIndex][this.currentColIndex].row;

      if (col > 1) {
        for (var i = 0; i < col; i++) {
          this.layoutArray[this.currentRowIndex + row][this.currentColIndex + i].hide = true;
          this.layoutArray[this.currentRowIndex][this.currentColIndex].row = row + 1;
        }
      } else {
        this.layoutArray[this.currentRowIndex + row][this.currentColIndex].hide = true;
        this.layoutArray[this.currentRowIndex][this.currentColIndex].row = row + 1;
      } // let nextCol = this.columns[this.currentRowIndex+1][this.currentColIndex].col;
      // let nextRow = this.columns[this.currentRowIndex+1][this.currentColIndex].row;
      // if(nextCol<2&nextRow<2){
      // }else{
      //     alert('请先拆分下方单元格！');
      // }

    },
    handlerResetTable: function handlerResetTable() {
      // debugger;
      var col = this.layoutArray[this.currentRowIndex][this.currentColIndex].col;
      var row = this.layoutArray[this.currentRowIndex][this.currentColIndex].row;
      if (col === 1 && row === 1) return;

      for (var i = 0; i < row; i++) {
        for (var j = 0; j < col; j++) {
          this.layoutArray[this.currentRowIndex + i][this.currentColIndex + j].hide = false;
        }
      }

      this.layoutArray[this.currentRowIndex][this.currentColIndex].row = 1;
      this.layoutArray[this.currentRowIndex][this.currentColIndex].col = 1;
    },
    handlerSelectedTd: function handlerSelectedTd(e, td) {
      this.$emit('selectItem', td);
      e.stopPropagation();
    },
    // 追加行
    handlerAppendCol: function handlerAppendCol() {
      var _trItem = jsonClone(tr); // eslint-disable-next-line no-return-assign


      _trItem.map(function (item) {
        return item.id = getSimpleId();
      });

      this.layoutArray.push(_trItem);
    },
    handlerAppendRow: function handlerAppendRow() {
      tr.push(getTdItem());
      this.layoutArray.forEach(function (item) {
        var _td = jsonClone(getTdItem());

        item.push(_td);
      });
    }
  }
};