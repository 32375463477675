//
//
//
//
//
//
//
//
//
export default {
  name: 'FancyHtml',
  props: {
    text: {
      type: String,
      default: ''
    },
    show: {
      type: Boolean,
      default: true
    }
  }
};