import render from "./custom/previewRender";
import checkRules from "./custom/rule";
var layouts = {
  colItem: function colItem(h, element, value) {
    var _this = this;

    var labelWidth = element.labelWidth ? "".concat(element.labelWidth, "px") : null;
    var valChange = this.$listeners.valChange;
    var rules = checkRules(element);
    return h("el-form-item", {
      "attrs": {
        "label": element.showLabel ? element.label : '',
        "label-width": labelWidth,
        "prop": element.id,
        "rules": rules
      }
    }, [h(render, {
      "key": element.id,
      "attrs": {
        "conf": element,
        "value": value
      },
      "on": {
        "input": function input(event) {
          _this.$set(element, 'value', event);

          valChange(element.id, event);
        }
      }
    })]);
  }
};
export default {
  name: 'PreviewItem',
  components: {
    render: render
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['model', 'value'],
  data: function data() {
    return {
      eleConfig: this.model
    };
  },
  render: function render(h) {
    return layouts.colItem.call(this, h, this.eleConfig, this.value);
  }
};