//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/**
 * input的配置项
 */
export default {
  name: 'ButtonConfig',
  components: {},
  props: {
    // eslint-disable-next-line vue/require-prop-types,vue/require-default-prop
    props: {}
  },
  data: function data() {
    return {};
  },
  mounted: function mounted() {},
  methods: {
    handlerShowLabel: function handlerShowLabel(val) {
      if (val) {
        this.props.labelWidth = 80;
      } else {
        this.props.labelWidth = 0;
      }
    },
    handlerChangeBarCode: function handlerChangeBarCode(val) {
      if (val == null) {
        this.props.lineColor = '#000';
      }
    },
    handlerChangeBackground: function handlerChangeBackground(val) {
      if (val == null) {
        this.props.background = '#fff';
      }
    }
  }
};