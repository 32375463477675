import "core-js/modules/es6.number.constructor";
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'FancyEditTableItem',
  components: {},
  props: {
    item: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    tdIndex: {
      type: Number,
      default: 0
    },
    trIndex: {
      type: Number,
      default: 0
    },
    tdStyle: {
      type: String,
      default: ''
    }
  }
};