var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.props.compType === "colorPicker",
          expression: "props.compType === 'colorPicker'"
        }
      ]
    },
    [
      _c(
        "el-form-item",
        { attrs: { label: "ID" } },
        [
          _c(
            "el-tooltip",
            {
              staticClass: "item",
              attrs: {
                effect: "dark",
                content: "请注意,ID的修改可能会导致该组件相关事件失效！",
                placement: "left"
              }
            },
            [
              _c("el-input", {
                staticClass: "input",
                on: { change: _vm.handlerChangeId },
                model: {
                  value: _vm.props.id,
                  callback: function($$v) {
                    _vm.$set(_vm.props, "id", $$v)
                  },
                  expression: "props.id"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "显示标签" } },
        [
          _c("el-switch", {
            on: { change: _vm.handlerShowLabel },
            model: {
              value: _vm.props.showLabel,
              callback: function($$v) {
                _vm.$set(_vm.props, "showLabel", $$v)
              },
              expression: "props.showLabel"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.props.showLabel,
              expression: "props.showLabel"
            }
          ],
          attrs: { label: "标签文字" }
        },
        [
          _c("el-input", {
            staticClass: "input",
            model: {
              value: _vm.props.label,
              callback: function($$v) {
                _vm.$set(_vm.props, "label", $$v)
              },
              expression: "props.label"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.props.showLabel,
              expression: "props.showLabel"
            }
          ],
          attrs: { label: "标签长度" }
        },
        [
          _c("el-input-number", {
            attrs: { min: 1, max: 200 },
            model: {
              value: _vm.props.labelWidth,
              callback: function($$v) {
                _vm.$set(_vm.props, "labelWidth", $$v)
              },
              expression: "props.labelWidth"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "透明" } },
        [
          _c("el-switch", {
            model: {
              value: _vm.props["show-alpha"],
              callback: function($$v) {
                _vm.$set(_vm.props, "show-alpha", $$v)
              },
              expression: "props['show-alpha']"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "默认颜色" } },
        [
          _c("el-color-picker", {
            model: {
              value: _vm.props.value,
              callback: function($$v) {
                _vm.$set(_vm.props, "value", $$v)
              },
              expression: "props.value"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "大小" } },
        [
          _c(
            "el-radio-group",
            {
              model: {
                value: _vm.props.size,
                callback: function($$v) {
                  _vm.$set(_vm.props, "size", $$v)
                },
                expression: "props.size"
              }
            },
            [
              _c("el-radio-button", { attrs: { label: "medium" } }, [
                _vm._v("常规")
              ]),
              _c("el-radio-button", { attrs: { label: "small" } }, [
                _vm._v("略小")
              ]),
              _c("el-radio-button", { attrs: { label: "mini" } }, [
                _vm._v("迷你")
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "预定义颜色" } },
        [
          _vm._l(_vm.props.predefine, function(item, index) {
            return _c("div", { key: item, staticClass: "select-item" }, [
              _c("span", { staticClass: "el-color-picker__color" }, [
                _c("span", {
                  staticClass: "el-color-picker__color-inner",
                  style: { "background-color": item }
                })
              ]),
              _c("div", { staticClass: "close-btn select-line-icon" }, [
                _c("i", {
                  staticClass: "el-icon-remove-outline",
                  on: {
                    click: function($event) {
                      return _vm.handlerRemoveColor(index)
                    }
                  }
                })
              ])
            ])
          }),
          _c("br"),
          _c("el-color-picker", {
            on: { change: _vm.handlerAddPreColor },
            model: {
              value: _vm.color,
              callback: function($$v) {
                _vm.color = $$v
              },
              expression: "color"
            }
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }