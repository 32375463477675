import "core-js/modules/es6.regexp.split";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { changeId } from "../mixin";
import draggable from 'vuedraggable'; // import { isNumberStr } from '../../utils/index'

/**
 * input的配置项
 */

var vm = {
  name: 'inputConfig',
  props: ['props', 'getFormId'],
  components: {
    draggable: draggable
  },
  mixins: [changeId],
  data: function data() {
    return {
      val: 123
    };
  },
  methods: {
    handlerChangeLabel: function handlerChangeLabel(val) {
      this.props.labelWidth = val ? '80' : '1';
    },
    handlerChangeDisStatus: function handlerChangeDisStatus(val) {
      this.props.readOnly = !val;
    },
    handlerChangeReadStatus: function handlerChangeReadStatus(val) {
      this.props.disabled = !val;
    },
    setDefaultValue: function setDefaultValue(val) {
      if (Array.isArray(val)) {
        return val.join(',');
      }

      if (['string', 'number'].indexOf(val) > -1) {
        return val;
      }

      if (typeof val === 'boolean') {
        return "".concat(val);
      }

      return val;
    },
    onDefaultValueInput: function onDefaultValueInput(str) {
      if (Array.isArray(this.props.value)) {
        // 数组
        this.$set(this.props, 'value', // str.split(',').map(val => (isNumberStr(val) ? +val : val))
        str.split(',').map(function (val) {
          return val;
        }));
      } else if (['true', 'false'].indexOf(str) > -1) {
        // 布尔
        this.$set(this.props, 'value', JSON.parse(str));
      } else {
        // 字符串和数字
        this.$set(this.props, 'value', // isNumberStr(str) ? +str : str
        str);
      }
    },
    setOptionValue: function setOptionValue(item, val) {
      // item.value = isNumberStr(val) ? +val : val
      item.value = val;
    },
    addSelectItem: function addSelectItem() {
      this.props.options.push({
        label: '',
        value: ''
      });
    },
    multipleChange: function multipleChange(val) {//   this.$set(this.props, 'value', val ? [] : '')
    },
    handlerChangeDataType: function handlerChangeDataType(value) {
      if (value === 'static') {
        this.props.options = [];
        this.props.options = this.tempOptions;
      } else {
        this.tempOptions = this.props.options;
        this.props.options = [];
      }
    }
  },
  mounted: function mounted() {},
  watch: {}
};
export default vm;