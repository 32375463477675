//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { changeId } from "../mixin";
var dateType = [{
  label: '日(date)',
  value: 'date'
}, {
  label: '周(week)',
  value: 'week'
}, {
  label: '月(month)',
  value: 'month'
}, {
  label: '年(year)',
  value: 'year'
}, {
  label: '日期时间(datetime)',
  value: 'datetime'
} // {
//   label: '月份范围',
//   value: 'monthrange'
// },
// {
//   label: '日期范围',
//   value: 'daterange'
// },
// {
//   label: '日期时间范围',
//   value: 'datetimerange'
// }
];
var dateTimeFormat = {
  date: 'yyyy-MM-dd',
  week: 'yyyy 第 WW 周',
  month: 'yyyy-MM',
  year: 'yyyy',
  datetime: 'yyyy-MM-dd HH:mm:ss',
  daterange: 'yyyy-MM-dd',
  monthrange: 'yyyy-MM',
  datetimerange: 'yyyy-MM-dd HH:mm:ss'
};
export default {
  name: 'InputConfig',
  components: {},
  mixins: [changeId],
  // eslint-disable-next-line vue/require-prop-types
  props: ['props', 'getFormId'],
  data: function data() {
    return {
      dateTypeOptions: dateType
    };
  },
  mounted: function mounted() {},
  methods: {
    handlerFormatChange: function handlerFormatChange(val) {
      this.props.format = dateTimeFormat[val];
      this.props['value-format'] = dateTimeFormat[val];
    },
    handlerChangeValueFormat: function handlerChangeValueFormat(val) {
      this.props['value-format'] = val;
    }
  }
};