import "core-js/modules/es6.regexp.constructor";
import "core-js/modules/web.dom.iterable";
export default function checkRules(element) {
  var r = [];
  var _trigger = ['blur', 'change'];

  if (element.required) {
    r.push({
      required: true,
      message: element.label + '不能为空',
      trigger: _trigger
    });
  } // 判断是否有规则


  if (element.rules) {
    element.rules.forEach(function (obj) {
      r.push({
        pattern: new RegExp(obj.rule),
        message: obj.msg,
        trigger: _trigger
      });
    });
  } // 编辑器最大字数验证


  if (typeof element.validateMaxText !== 'undefined' && element.validateMaxText) {
    var maxRules = {
      max: element.max,
      message: element.label + '超出最大字数限制',
      trigger: _trigger
    };
    r.push(maxRules);
  }

  return r;
}