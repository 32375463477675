import "core-js/modules/es6.regexp.replace";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.regexp.constructor";
import "core-js/modules/es6.regexp.split";
export function makeMap(str, expectsLowerCase) {
  var map = Object.create(null);
  var list = str.split(',');

  for (var i = 0; i < list.length; i++) {
    map[list[i]] = true;
  }

  return expectsLowerCase ? function (val) {
    return map[val.toLowerCase()];
  } : function (val) {
    return map[val];
  };
}
/**
 * num 小于0，左缩进num*2个空格； 大于0，右缩进num*2个空格。
 * @param {string} str 代码
 * @param {number} num 缩进次数
 * @param {number} len 【可选】缩进单位，空格数
 */

export function indent(str, num) {
  var len = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 2;
  if (num === 0) return str;
  var isLeft = num < 0;
  var result = [];
  var reg;
  var spaces = '';

  if (isLeft) {
    num *= -1;
    reg = new RegExp("(^\\s{0,".concat(num * len, "})"), 'g');
  } else {
    for (var i = 0; i < num * len; i++) {
      spaces += ' ';
    }
  }

  str.split('\n').forEach(function (line) {
    line = isLeft ? line.replace(reg, '') : spaces + line;
    result.push(line);
  });
  return result.join('\n');
} // 首字母大小

export function titleCase(str) {
  return str.replace(/( |^)[a-z]/g, function (L) {
    return L.toUpperCase();
  });
} // 下划转驼峰

export function camelCase(str) {
  return str.replace(/-[a-z]/g, function (str1) {
    return str1.substr(-1).toUpperCase();
  });
}
export function isNumberStr(str) {
  return /^[+-]?(0|([1-9]\d*))(\.\d+)?$/g.test(str);
}
export var beautifierConf = {
  html: {
    indent_size: '2',
    indent_char: ' ',
    max_preserve_newlines: '-1',
    preserve_newlines: false,
    keep_array_indentation: false,
    break_chained_methods: false,
    indent_scripts: 'separate',
    brace_style: 'end-expand',
    space_before_conditional: true,
    unescape_strings: false,
    jslint_happy: false,
    end_with_newline: true,
    wrap_line_length: '110',
    indent_inner_html: true,
    comma_first: false,
    e4x: true,
    indent_empty_lines: true
  },
  js: {
    indent_size: '2',
    indent_char: ' ',
    max_preserve_newlines: '-1',
    preserve_newlines: false,
    keep_array_indentation: false,
    break_chained_methods: false,
    indent_scripts: 'normal',
    brace_style: 'end-expand',
    space_before_conditional: true,
    unescape_strings: false,
    jslint_happy: true,
    end_with_newline: true,
    wrap_line_length: '110',
    indent_inner_html: true,
    comma_first: false,
    e4x: true,
    indent_empty_lines: true
  }
};

function stringify(obj) {
  return JSON.stringify(obj, function (key, val) {
    if (typeof val === 'function') {
      return "".concat(val);
    }

    return val;
  });
}

function parse(str) {
  return JSON.parse(str, function (k, v) {
    if (v !== null && v.indexOf && v.indexOf('function') > -1) {
      // eslint-disable-next-line no-eval
      return eval("(".concat(v, ")"));
    }

    return v;
  });
}

export function jsonClone(obj) {
  return parse(stringify(obj));
}
export function isLayout(obj) {
  return obj.compType === 'row';
}
export function isTable(obj) {
  return obj.compType === 'table';
}
export function inTable(obj) {
  return obj.col && obj.row;
}
export var isAttr = makeMap('accept,accept-charset,accesskey,action,align,alt,async,autocomplete,' + 'autofocus,autoplay,autosave,bgcolor,border,buffered,challenge,charset,' + 'checked,cite,class,code,codebase,color,cols,colspan,content,http-equiv,' + 'name,contenteditable,contextmenu,controls,coords,data,datetime,default,' + 'defer,dir,dirname,disabled,download,draggable,dropzone,enctype,method,for,' + 'form,formaction,headers,height,hidden,high,href,hreflang,http-equiv,' + 'icon,id,ismap,itemprop,keytype,kind,label,lang,language,list,loop,low,' + 'manifest,max,maxlength,media,method,GET,POST,min,multiple,email,file,' + 'muted,name,novalidate,open,optimum,pattern,ping,placeholder,poster,' + 'preload,radiogroup,readonly,rel,required,reversed,rows,rowspan,sandbox,' + 'scope,scoped,seamless,selected,shape,size,type,text,password,sizes,span,' + 'spellcheck,src,srcdoc,srclang,srcset,start,step,style,summary,tabindex,' + 'target,title,type,usemap,value,width,wrap');
export function cleanOption(list) {
  if (list) {
    list.forEach(function (field) {
      if (field.dataType === 'store') {
        field.options = [];
      }
    });
  }
}