var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.props.compType === "text",
          expression: "props.compType === 'text'"
        }
      ]
    },
    [
      _c(
        "el-form-item",
        { attrs: { label: "ID", "label-width": "40px" } },
        [
          _c(
            "el-tooltip",
            {
              staticClass: "item",
              attrs: {
                effect: "dark",
                content: "请注意,ID的修改可能会导致该组件相关事件失效！",
                placement: "left"
              }
            },
            [
              _c("el-input", {
                staticClass: "input",
                on: { change: _vm.handlerChangeId },
                model: {
                  value: _vm.props.id,
                  callback: function($$v) {
                    _vm.$set(_vm.props, "id", $$v)
                  },
                  expression: "props.id"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "文本", "label-width": "40px" } },
        [
          _c("el-input", {
            staticClass: "input",
            attrs: { type: "textarea", rows: "12" },
            model: {
              value: _vm.props.text,
              callback: function($$v) {
                _vm.$set(_vm.props, "text", $$v)
              },
              expression: "props.text"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "颜色", "label-width": "40px" } },
        [
          _c("el-color-picker", {
            model: {
              value: _vm.props.color,
              callback: function($$v) {
                _vm.$set(_vm.props, "color", $$v)
              },
              expression: "props.color"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "布局", "label-width": "40px" } },
        [
          _c(
            "el-radio-group",
            {
              model: {
                value: _vm.props.align,
                callback: function($$v) {
                  _vm.$set(_vm.props, "align", $$v)
                },
                expression: "props.align"
              }
            },
            [
              _c("el-radio-button", { attrs: { label: "left" } }, [
                _vm._v("左")
              ]),
              _c("el-radio-button", { attrs: { label: "center" } }, [
                _vm._v("中")
              ]),
              _c("el-radio-button", { attrs: { label: "right" } }, [
                _vm._v("右")
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "大小", "label-width": "40px" } },
        [
          _c("el-input-number", {
            attrs: { min: 10, max: 50 },
            on: { change: _vm.handlerChangeSize },
            model: {
              value: _vm.props.size,
              callback: function($$v) {
                _vm.$set(_vm.props, "size", $$v)
              },
              expression: "props.size"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "加粗", "label-width": "40px" } },
        [
          _c("el-input-number", {
            attrs: { min: 100, max: 800, step: 100 },
            on: { change: _vm.handlerChangeBold },
            model: {
              value: _vm.props.bold,
              callback: function($$v) {
                _vm.$set(_vm.props, "bold", $$v)
              },
              expression: "props.bold"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }