import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/es6.regexp.replace";
import "core-js/modules/es6.number.constructor";
// import parseTime, formatTime and set to filter
export { parseTime, formatTime, formatMoney, isNullOrEmpty, numberLimit, stageName, quoteMethodName, businessTypeName, verifyStatusName, verifyStatusStyle, percentageRate, projectStatusName, urlFileName, stringElision, feeTypeName, numberToCurrencyNo } from '@/utils';
/**
 * Show plural label if time is plural number
 * @param {number} time
 * @param {string} label
 * @return {string}
 */

function pluralize(time, label) {
  if (time === 1) {
    return time + label;
  }

  return time + label + 's';
}
/**
 * @param {number} time
 */


export function timeAgo(time) {
  var between = Date.now() / 1000 - Number(time);

  if (between < 3600) {
    return pluralize(~~(between / 60), ' minute');
  } else if (between < 86400) {
    return pluralize(~~(between / 3600), ' hour');
  } else {
    return pluralize(~~(between / 86400), ' day');
  }
}
/**
 * Number formatting
 * like 10000 => 10k
 * @param {number} num
 * @param {number} digits
 */

export function numberFormatter(num, digits) {
  var si = [{
    value: 1E18,
    symbol: 'E'
  }, {
    value: 1E15,
    symbol: 'P'
  }, {
    value: 1E12,
    symbol: 'T'
  }, {
    value: 1E9,
    symbol: 'G'
  }, {
    value: 1E6,
    symbol: 'M'
  }, {
    value: 1E3,
    symbol: 'k'
  }];

  for (var i = 0; i < si.length; i++) {
    if (num >= si[i].value) {
      return (num / si[i].value + 0.1).toFixed(digits).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, '$1') + si[i].symbol;
    }
  }

  return num.toString();
}
/**
 * 10000 => "10,000"
 * @param {number} num
 */

export function toThousandFilter(num) {
  return (+num || 0).toString().replace(/^-?\d+/g, function (m) {
    return m.replace(/(?=(?!\b)(\d{3})+$)/g, ',');
  });
}
/**
 * Upper case first char
 * @param {String} string
 */

export function uppercaseFirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
export function transportDemandStatusName(value) {
  var tagMap = {
    0: '暂存',
    5: '撤销',
    10: '待征调',
    15: '待装车',
    20: '终止',
    25: '运输中',
    30: '运输异常',
    35: '已回单'
  };
  return tagMap[value] || '';
}
export function moduleName(val) {
  var map = {
    1: '客户',
    2: '供应商',
    3: '运力',
    4: '线索',
    5: '询价',
    6: '合同',
    7: '项目',
    8: '调度',
    9: '财务'
  };
  return map[val];
}
export function recordFilter(val) {
  var map = {
    1: '操作记录',
    2: '异常记录'
  };
  return map[val] || '';
}
export function operationTypeFilter(val) {
  var map = {
    1: '增加',
    2: '修改',
    3: '删除'
  };
  return map[val];
}