import request from '@/utils/request';
var basePath = '/api/v1/project-base/transport-insurance-rate';
var baseUrl = process.env.VUE_APP_PROJECT_API;
export function listInsuranceRate(query) {
  return request({
    baseURL: baseUrl,
    url: basePath + '/root-tree',
    method: 'get',
    params: query
  });
}