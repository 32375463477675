import "core-js/modules/es6.number.constructor";
import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/**
 * 1.0版本
 */
import draggable from 'vuedraggable';
import { formItems, assistFormItems, layoutFormItems } from "./custom/itemList";
import designer from "./designer";
import icon from "./icon";
import { getSimpleId, setTableId } from "./utils/IdGenerate";
import { cleanOption } from "./utils/index";
import formConf from "./custom/formConf";
import { dynamicTableAllowedItems } from "./custom/formConf";
var tempActiveData;
export default {
  name: 'FormDesigner',
  components: {
    draggable: draggable,
    icon: icon,
    designer: designer
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    useFormItemNames: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    enableAssistForm: {
      type: Boolean,
      default: true
    },
    enableLayoutForm: {
      type: Boolean,
      default: true
    }
  },
  data: function data() {
    return {
      title: '表单设计',
      defaultFormItems: formItems,
      defaultAssistFormItems: assistFormItems,
      defaultLayoutFormItems: layoutFormItems,
      designList: [],
      activeData: {},
      formConfig: formConf
    };
  },
  computed: {
    formData: function formData() {
      var list = this.designList;
      var config = this.formConfig;
      var formData = {};
      formData.list = list;
      formData.config = config;
      cleanOption(formData.list); // console.log(formData)

      return JSON.stringify(formData); // this.$emit('input',JSON.stringify(formData));
    },
    dynamicTableExist: function dynamicTableExist() {
      return function (element) {
        return this.formConfig.dynamicTableAllowed && this.designList.filter(function (item) {
          return item.compType === 'dynamicTable';
        }).length > 0 && dynamicTableAllowedItems.includes(element.compType);
      };
    },
    formItems: function formItems() {
      var _this = this;

      // console.log(this.defaultFormItems.map(i => i.compType))
      if (this.useFormItemNames && this.useFormItemNames.length > 0) {
        var useFormItems = this.defaultFormItems.filter(function (i) {
          return _this.useFormItemNames.includes(i.compType);
        });
        return useFormItems;
      }

      return this.defaultFormItems;
    },
    assistFormItems: function assistFormItems() {
      if (this.enableAssistForm) {
        return this.defaultAssistFormItems;
      }

      return [];
    },
    layoutFormItems: function layoutFormItems() {
      if (this.enableLayoutForm) {
        return this.defaultLayoutFormItems;
      }

      return [];
    }
  },
  watch: {
    value: function value(newVal) {
      if (newVal !== '') {
        var formData = JSON.parse(newVal);
        this.designList = formData.list;
        this.formConfig = formData.config;
      }
    }
  },
  mounted: function mounted() {},
  methods: {
    addComponent: function addComponent(element) {},
    cloneComponent: function cloneComponent(origin) {
      var clone = JSON.parse(JSON.stringify(origin));
      if (!clone.layout) clone.layout = 'colItem';

      if (clone.layout === 'colItem' || clone.layout === 'dynamicItem') {
        var uId = 'fd_' + getSimpleId();
        clone.id = uId;
        clone._id = uId;
        var span = sessionStorage.getItem('localSpan');

        if (span) {
          clone.span = Number(span);
        }

        tempActiveData = clone;
      } else if (clone.layout === 'rowItem') {
        var _uId = 'row_' + getSimpleId();

        clone.id = _uId;
        clone._id = _uId;
        tempActiveData = clone;
      } else if (clone.layout === 'tableItem') {
        var _uId2 = 'table_' + getSimpleId();

        clone.id = _uId2;
        clone._id = _uId2; // 增加td默认的id

        setTableId(clone);
        tempActiveData = clone;
      }

      this.$refs.designer.activeItem = tempActiveData;
    },
    onStart: function onStart(obj) {},
    onEnd: function onEnd(obj) {
      if (obj.from !== obj.to) {
        this.activeData = tempActiveData;
        this.$refs.designer.activeItem = this.activeData;

        if (obj.to.className.indexOf('row-drag') < 0) {
          this.designList.splice(obj.newIndex, 0, this.activeData);
        }
      } else {
        this.$refs.designer.activeItem = {};
      }
    },
    clearAll: function clearAll() {
      this.designList = [];
      this.$refs.designer.activeItem = {};
    },
    getFormData: function getFormData() {
      return this.formData;
    },
    handlerUpdateJSON: function handlerUpdateJSON(json) {
      var jsonObject = JSON.parse(json);
      this.designList = [];
      this.designList = this.designList.concat(jsonObject.list);
    }
  }
};