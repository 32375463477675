var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.props.compType === "input",
          expression: "props.compType === 'input'"
        }
      ]
    },
    [
      _c(
        "el-form-item",
        { attrs: { label: "ID" } },
        [
          _c(
            "el-tooltip",
            {
              staticClass: "item",
              attrs: {
                effect: "dark",
                content: "请注意,ID的修改可能会导致该组件相关事件失效！",
                placement: "left"
              }
            },
            [
              _c("el-input", {
                staticClass: "input",
                on: { change: _vm.handlerChangeId },
                model: {
                  value: _vm.props.id,
                  callback: function($$v) {
                    _vm.$set(_vm.props, "id", $$v)
                  },
                  expression: "props.id"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "标题" } },
        [
          _c("el-input", {
            staticClass: "input",
            model: {
              value: _vm.props.label,
              callback: function($$v) {
                _vm.$set(_vm.props, "label", $$v)
              },
              expression: "props.label"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "提示符" } },
        [
          _c("el-input", {
            staticClass: "input",
            attrs: { placeholder: "请输入提示符" },
            model: {
              value: _vm.props.placeholder,
              callback: function($$v) {
                _vm.$set(_vm.props, "placeholder", $$v)
              },
              expression: "props.placeholder"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "表单栅格" } },
        [
          _c("el-slider", {
            staticClass: "input",
            attrs: { max: 24, min: 1, marks: { 12: "" } },
            model: {
              value: _vm.props.span,
              callback: function($$v) {
                _vm.$set(_vm.props, "span", $$v)
              },
              expression: "props.span"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "栅格间隔" } },
        [
          _c("el-input-number", {
            attrs: { min: 0 },
            model: {
              value: _vm.props.gutter,
              callback: function($$v) {
                _vm.$set(_vm.props, "gutter", $$v)
              },
              expression: "props.gutter"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "标签宽度" } },
        [
          _c("el-input-number", {
            attrs: { min: 1, max: 200 },
            model: {
              value: _vm.props.labelWidth,
              callback: function($$v) {
                _vm.$set(_vm.props, "labelWidth", $$v)
              },
              expression: "props.labelWidth"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "显示标签" } },
        [
          _c("el-switch", {
            on: { change: _vm.handlerChangeLabel },
            model: {
              value: _vm.props.showLabel,
              callback: function($$v) {
                _vm.$set(_vm.props, "showLabel", $$v)
              },
              expression: "props.showLabel"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "必填" } },
        [
          _c("el-switch", {
            model: {
              value: _vm.props.required,
              callback: function($$v) {
                _vm.$set(_vm.props, "required", $$v)
              },
              expression: "props.required"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "清除" } },
        [
          _c("el-switch", {
            model: {
              value: _vm.props.clearable,
              callback: function($$v) {
                _vm.$set(_vm.props, "clearable", $$v)
              },
              expression: "props.clearable"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "禁用" } },
        [
          _c("el-switch", {
            model: {
              value: _vm.props.disabled,
              callback: function($$v) {
                _vm.$set(_vm.props, "disabled", $$v)
              },
              expression: "props.disabled"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "只读" } },
        [
          _c("el-switch", {
            model: {
              value: _vm.props.readonly,
              callback: function($$v) {
                _vm.$set(_vm.props, "readonly", $$v)
              },
              expression: "props.readonly"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "前置图标" } },
        [
          _c(
            "el-input",
            {
              attrs: { placeholder: "请选择图标", readonly: "" },
              model: {
                value: _vm.props["prefix-icon"],
                callback: function($$v) {
                  _vm.$set(_vm.props, "prefix-icon", $$v)
                },
                expression: "props['prefix-icon']"
              }
            },
            [
              _c("template", { slot: "append" }, [
                _c("i", {
                  staticClass: "el-icon-picture",
                  staticStyle: { cursor: "pointer" },
                  on: { click: _vm.handlerPrefixSelectIcon }
                })
              ])
            ],
            2
          )
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "后置图标" } },
        [
          _c(
            "el-input",
            {
              attrs: { placeholder: "请选择图标", readonly: "" },
              model: {
                value: _vm.props["suffix-icon"],
                callback: function($$v) {
                  _vm.$set(_vm.props, "suffix-icon", $$v)
                },
                expression: "props['suffix-icon']"
              }
            },
            [
              _c("template", { slot: "append" }, [
                _c("i", {
                  staticClass: "el-icon-picture",
                  staticStyle: { cursor: "pointer" },
                  on: { click: _vm.handlerSuffixSelectIcon }
                })
              ])
            ],
            2
          )
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "前缀" } },
        [
          _c("el-input", {
            model: {
              value: _vm.props.prepend,
              callback: function($$v) {
                _vm.$set(_vm.props, "prepend", $$v)
              },
              expression: "props.prepend"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "后缀" } },
        [
          _c("el-input", {
            model: {
              value: _vm.props.append,
              callback: function($$v) {
                _vm.$set(_vm.props, "append", $$v)
              },
              expression: "props.append"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "默认值" } },
        [
          _c("el-input", {
            staticClass: "input",
            model: {
              value: _vm.props.value,
              callback: function($$v) {
                _vm.$set(_vm.props, "value", $$v)
              },
              expression: "props.value"
            }
          })
        ],
        1
      ),
      _c("el-divider", [_vm._v("校验")]),
      _c(
        "el-form-item",
        { attrs: { label: "验证类型" } },
        [
          _c(
            "el-select",
            {
              on: { change: _vm.handlerChangeRulesType },
              model: {
                value: _vm.props.rulesType,
                callback: function($$v) {
                  _vm.$set(_vm.props, "rulesType", $$v)
                },
                expression: "props.rulesType"
              }
            },
            [
              _c("el-option", { attrs: { label: "无", value: "default" } }),
              _c("el-option", { attrs: { label: "电话", value: "phone" } }),
              _c("el-option", { attrs: { label: "邮箱", value: "email" } }),
              _c("el-option", { attrs: { label: "纯数字", value: "number" } }),
              _c("el-option", { attrs: { label: "身份证", value: "idcard" } })
            ],
            1
          )
        ],
        1
      ),
      _vm._l(_vm.props.rules, function(item, index) {
        return _c(
          "div",
          { key: index, staticClass: "rule-item" },
          [
            _c("el-input", {
              attrs: { placeholder: "正则", size: "small" },
              model: {
                value: item.rule,
                callback: function($$v) {
                  _vm.$set(item, "rule", $$v)
                },
                expression: "item.rule"
              }
            }),
            _c("p"),
            _c("el-input", {
              attrs: { placeholder: "自定义提示", size: "small" },
              model: {
                value: item.msg,
                callback: function($$v) {
                  _vm.$set(item, "msg", $$v)
                },
                expression: "item.msg"
              }
            }),
            _c(
              "div",
              {
                staticClass: "close-btn select-line-icon",
                on: {
                  click: function($event) {
                    return _vm.props.rules.splice(index, 1)
                  }
                }
              },
              [_c("i", { staticClass: "el-icon-remove-outline close-icon" })]
            )
          ],
          1
        )
      }),
      _c(
        "div",
        { staticStyle: { "margin-left": "20px" } },
        [
          _c(
            "el-button",
            {
              staticStyle: { "padding-bottom": "0" },
              attrs: { icon: "el-icon-circle-plus-outline", type: "text" },
              on: { click: _vm.addRuleItem }
            },
            [_vm._v(" 添加选项 ")]
          )
        ],
        1
      ),
      _c("icon-dialog", {
        attrs: { visible: _vm.iconDialogVisible_suffix },
        on: {
          "update:visible": function($event) {
            _vm.iconDialogVisible_suffix = $event
          }
        },
        model: {
          value: _vm.props["suffix-icon"],
          callback: function($$v) {
            _vm.$set(_vm.props, "suffix-icon", $$v)
          },
          expression: "props['suffix-icon']"
        }
      }),
      _c("icon-dialog", {
        attrs: { visible: _vm.iconDialogVisible_prefix },
        on: {
          "update:visible": function($event) {
            _vm.iconDialogVisible_prefix = $event
          }
        },
        model: {
          value: _vm.props["prefix-icon"],
          callback: function($$v) {
            _vm.$set(_vm.props, "prefix-icon", $$v)
          },
          expression: "props['prefix-icon']"
        }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }