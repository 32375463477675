import request from '@/utils/request'; // 后台基地址

var baseStaffPath = '/api/v1/user-base/staff'; // 环境变量配置

var baseUrl = process.env.VUE_APP_USER_API;
export function listLocalOrgStaff() {
  return request({
    baseURL: baseUrl,
    url: baseStaffPath + '/list-local-org-staff',
    method: 'get'
  });
}
export function listPagedOrgStaff(query) {
  return request({
    baseURL: baseUrl,
    url: baseStaffPath + '/list-paged-org-staff',
    method: 'get',
    params: query
  });
}
export function listOrgManager(query) {
  return request({
    baseURL: baseUrl,
    url: baseStaffPath + '/list-org-manager',
    method: 'get',
    params: query
  });
}
export function listCorpStaffLight() {
  return request({
    baseURL: baseUrl,
    url: baseStaffPath + '/list-corp-staff-light',
    method: 'get'
  });
}
export function listLocalOrgStaffLight() {
  return request({
    baseURL: baseUrl,
    url: baseStaffPath + '/list-local-org-staff-light',
    method: 'get'
  });
}
export function listOrgStaffLight(query) {
  return request({
    baseURL: baseUrl,
    url: baseStaffPath + '/org/{orgId}/staff-light',
    method: 'get',
    params: query
  });
}
/**
 * 获取一个部门的员工
 * @param query
 * @returns {AxiosPromise}
 */

export function listDepStaffLight(query) {
  return request({
    baseURL: baseUrl,
    url: baseStaffPath + '/dep/{depId}/staff-light',
    method: 'get',
    params: query
  });
}
/**
 * 获取一批部门的员工
 * @param query
 * @returns {AxiosPromise}
 */

export function listStaffLightOfDeps(query) {
  return request({
    baseURL: baseUrl,
    url: baseStaffPath + '/dep/staff-light',
    method: 'get',
    params: query
  });
}
export function listBusinessStaff(query) {
  return request({
    baseURL: baseUrl,
    url: baseStaffPath + '/list-business-staff',
    method: 'get',
    params: query
  });
}
export function listVerifyStaff(query) {
  return request({
    baseURL: baseUrl,
    url: baseStaffPath + '/list-verify-staff',
    method: 'get',
    params: query
  });
}
export function listTechPlanStaff(query) {
  return request({
    baseURL: baseUrl,
    url: baseStaffPath + '/list-tech-plan-staff',
    method: 'get',
    params: query
  });
}
export function listOrgAllStaff() {
  return request({
    baseURL: baseUrl,
    url: baseStaffPath + '/list-org-all-staff',
    method: 'get'
  });
}
export function listFinanceApplier() {
  return request({
    baseURL: baseUrl,
    url: baseStaffPath + '/list-finance-applier',
    method: 'get'
  });
}
export function listStaffByFilter(query) {
  return request({
    baseURL: baseUrl,
    url: baseStaffPath + '/list-staff-by-filter',
    method: 'get',
    params: query
  });
}
export function listCurrentDepStaff(query) {
  return request({
    baseURL: baseUrl,
    url: baseStaffPath + '/list-org-dep-staff',
    method: 'get',
    params: query
  });
}
export function listMyOrgStaff(query) {
  return request({
    baseURL: baseUrl,
    url: baseStaffPath + '/list-paged-local-org-staff',
    method: 'get',
    params: query
  });
}
export function listCustomerManageStaff(query) {
  return request({
    baseURL: baseUrl,
    url: baseStaffPath + '/customer/{customerId}/list-manage-staff',
    method: 'get',
    params: query
  });
}
export function listPermManageStaff(query) {
  return request({
    baseURL: baseUrl,
    url: baseStaffPath + '/list-perm-staff',
    method: 'get',
    params: query
  });
}
export function leave(data) {
  return request({
    baseURL: baseUrl,
    url: baseStaffPath + '/leave',
    method: 'put',
    data: {
      data: data
    }
  });
}
export function getLeaveStaffRoles() {
  return request({
    baseURL: baseUrl,
    url: baseStaffPath + '/leave',
    method: 'get'
  });
}
export function listStaffsHasPermissions(query) {
  return request({
    baseURL: baseUrl,
    url: baseStaffPath + '/staffs-has-permissions',
    method: 'get',
    params: query
  });
}
export function listStaffsHasPermission(query) {
  return request({
    baseURL: baseUrl,
    url: baseStaffPath + '/staffs-has-permission/{perm}',
    method: 'get',
    params: query
  });
}