var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        { name: "show", rawName: "v-show", value: _vm.show, expression: "show" }
      ],
      staticClass: "test"
    },
    [
      _c("p", {
        staticStyle: { "margin-block-start": "0em", "margin-block-end": "0em" },
        style: {
          "text-align": _vm.align,
          color: _vm.color,
          "font-size": _vm.size + "px",
          "font-weight": _vm.bold
        },
        domProps: { innerHTML: _vm._s(_vm.text) }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }