//
import draggable from 'vuedraggable';
import render from "../custom/render";

var colItem = function colItem(h, element) {
  var _this = this;

  var _this$$listeners = this.$listeners,
      selectItem = _this$$listeners.selectItem,
      copyItem = _this$$listeners.copyItem,
      deleteItem = _this$$listeners.deleteItem;
  return h("div", {
    "class": {
      'dynamic-table__item': true,
      'active': this.isHover || this.isActive
    },
    "style": 'min-width:200px;width: auto;position:relative',
    "on": {
      "click": function click(e) {
        selectItem(e, element);
      },
      "mouseover": function mouseover(e) {
        _this.isHover = true;
      },
      "mouseleave": function mouseleave(e) {
        _this.isHover = false;
      }
    }
  }, [h("div", {
    "class": 'dynamic-table__item_title'
  }, [element.label]), h("div", {
    "class": 'dynamic-table__item_body'
  }, [h(render, {
    "attrs": {
      "conf": element
    }
  }), h("span", {
    "directives": [{
      name: "show",
      value: element.required
    }],
    "style": 'color:#F56C6C'
  }, ["*"])]), h("span", {
    "class": 'dynamic-table-item-copy',
    "attrs": {
      "title": '复制'
    },
    "directives": [{
      name: "show",
      value: this.showbutton
    }],
    "on": {
      "click": function click(e) {
        copyItem(e, element);
      }
    }
  }, [h("i", {
    "class": 'el-icon-copy-document'
  })]), h("span", {
    "class": 'dynamic-table-item-delete',
    "attrs": {
      "title": '删除'
    },
    "directives": [{
      name: "show",
      value: this.showbutton
    }],
    "on": {
      "click": function click(e) {
        deleteItem(e, element);
      }
    }
  }, [h("i", {
    "class": 'el-icon-delete'
  })])]);
};

export default {
  name: 'DynamicTableItem',
  components: {
    draggable: draggable,
    render: render
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['item', 'activeItem'],
  data: function data() {
    return {
      isActive: false,
      isHover: false
    };
  },
  computed: {
    showbutton: function showbutton() {
      return this.isActive || this.isHover;
    }
  },
  watch: {
    activeItem: function activeItem(newvalue) {
      this.isActive = newvalue.id === this.item.id;
    }
  },
  methods: {
    handlerCopy: function handlerCopy(evt) {
      this.$emit('copy', evt, this.item);
    },
    handlerDelete: function handlerDelete(evt) {
      this.$emit('delete', evt, this.item);
    }
  },
  render: function render(h) {
    return colItem.call(this, h, this.item);
  }
};