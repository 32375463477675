import request from '@/utils/request';
var basePath = '/api/v1/inventory-base/delivery';
var baseUrl = process.env.VUE_APP_INVENTORY_API;
export function listDeliveryAddress(query) {
  return request({
    baseURL: baseUrl,
    url: basePath + '/list-delivery-address',
    method: 'get',
    params: query
  });
}
export function getDelivery(query) {
  return request({
    baseURL: baseUrl,
    url: basePath + '/{deliveryId}',
    method: 'get',
    params: query
  });
}
export function getDeliveryByNo(query) {
  return request({
    baseURL: baseUrl,
    url: basePath + '/delivery-no/{deliveryNo}',
    method: 'get',
    params: query
  });
}
export function deliveryOut(data) {
  return request({
    baseURL: baseUrl,
    url: basePath + '/delivery-out',
    method: 'post',
    data: {
      data: data
    }
  });
}
export function additionalDeliveryOut(data) {
  return request({
    baseURL: baseUrl,
    url: basePath + '/additional-delivery-out',
    method: 'post',
    data: {
      data: data
    }
  });
}
export function updateDelivery(data) {
  return request({
    baseURL: baseUrl,
    url: basePath + '/update-delivery-out',
    method: 'post',
    data: {
      data: data
    }
  });
}
export function cancelDelivery(data) {
  return request({
    baseURL: baseUrl,
    url: basePath + '/cancel-delivery-out',
    method: 'post',
    data: {
      data: data
    }
  });
}
export function updateReceive(data) {
  return request({
    baseURL: baseUrl,
    url: basePath + '/update-receive',
    method: 'post',
    data: {
      data: data
    }
  });
}
export function updateDeliveryWorkPostRatio(query, data) {
  return request({
    baseURL: baseUrl,
    url: basePath + '/{deliveryId}/work-post-ratio',
    method: 'put',
    params: query,
    data: {
      data: data
    }
  });
}
export function getDeliveryPrintHtml(query) {
  return request({
    baseURL: baseUrl,
    url: basePath + '/{deliveryId}/print-html',
    method: 'get',
    params: query
  });
}
export function exportDeliveryExcel(query, name) {
  return request({
    baseURL: baseUrl,
    url: basePath + '/{deliveryId}/export-excel',
    method: 'get',
    params: query,
    responseType: 'blob'
  }).then(function (res) {
    downloadExcel(res, name, 'xls');
  });
}
export var downloadExcel = function downloadExcel(url, fileName) {
  var fileType = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'xlsx';
  var blob = new Blob([url], {
    type: url.type
  });
  var downloadElement = document.createElement('a');
  var href = window.URL.createObjectURL(blob); // 创建下载的链接

  var fileSuffix = ".".concat(fileType); // 文件的后缀，默认是Excel的xls

  downloadElement.href = href;
  var date = new Date();
  var yy = date.getFullYear();
  var mm = date.getMonth() + 1;
  var dd = date.getDate();
  var hh = date.getHours();
  var datestr = yy + '年' + mm + '月' + dd + '日 ' + hh + '时';
  downloadElement.download = fileName + datestr + fileSuffix; // 下载后文件名

  document.body.appendChild(downloadElement);
  downloadElement.click(); // 点击下载

  document.body.removeChild(downloadElement); // 下载完成移除元素

  window.URL.revokeObjectURL(href); // 释放掉blob对象
};