import request from '@/utils/request';
var basePath = '/api/v1/user-base/organization';
var baseUrl = process.env.VUE_APP_USER_API;
export function fetchOrg(query) {
  return request({
    baseURL: baseUrl,
    url: basePath + '/list',
    method: 'get',
    params: query
  });
}
export function listSameCorpOrgs(query) {
  return request({
    baseURL: baseUrl,
    url: basePath + '/list-same-corp-orgs',
    method: 'get',
    params: query
  });
}
export function listPermManageOrg(query) {
  return request({
    baseURL: baseUrl,
    url: basePath + '/list-perm-org',
    method: 'get',
    params: query
  });
}
export function listUserOrg(query) {
  return request({
    baseURL: baseUrl,
    url: basePath + '/list-user-org',
    method: 'get',
    params: query
  });
}
export function fetchOrgAnonymous(query) {
  return request({
    baseURL: baseUrl,
    url: basePath + '/allow-anonymous/list',
    method: 'get',
    params: query
  });
}
export function getOrg(query) {
  return request({
    baseURL: baseUrl,
    url: basePath + '/{orgId}',
    method: 'get',
    params: query
  });
}
export function createOrg(data) {
  return request({
    baseURL: baseUrl,
    url: basePath + '/save',
    method: 'post',
    data: {
      data: data
    }
  });
}
export function updateOrg(query, data) {
  return request({
    baseURL: baseUrl,
    url: basePath + '/{orgId}',
    method: 'put',
    params: query,
    data: {
      data: data
    }
  });
}
export function setOrgManager(data) {
  return request({
    baseURL: baseUrl,
    url: basePath + '/set-manager',
    method: 'put',
    data: {
      data: data
    }
  });
}
export function disableOrg(query) {
  return request({
    baseURL: baseUrl,
    url: basePath + '/{orgId}/disable',
    method: 'put',
    params: query
  });
}
export function enableOrg(query) {
  return request({
    baseURL: baseUrl,
    url: basePath + '/{orgId}/enable',
    method: 'put',
    params: query
  });
}
export function deleteImage(data) {
  return request({
    baseURL: baseUrl,
    url: basePath + '/delete-image',
    method: 'delete',
    data: {
      data: data
    }
  });
}
export function getPlatformMatchedNames(query) {
  return request({
    baseURL: baseUrl,
    url: basePath + '/platform/matched-names',
    method: 'get',
    params: query
  });
}
export function getPlatformOrganizationByName(query) {
  return request({
    baseURL: baseUrl,
    url: basePath + '/platform',
    method: 'get',
    params: query
  });
}