import "core-js/modules/es6.number.constructor";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'FancyText',
  props: {
    text: {
      type: String,
      default: ''
    },
    show: {
      type: Boolean,
      default: true
    },
    align: {
      type: String,
      default: 'left'
    },
    color: {
      type: String,
      default: ''
    },
    size: {
      type: Number,
      default: 14
    },
    bold: {
      type: Number,
      default: 400
    }
  },
  data: function data() {
    return {
      htmlText: ''
    };
  }
};