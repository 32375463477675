var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.props.compType === "time",
          expression: "props.compType === 'time'"
        }
      ]
    },
    [
      _c(
        "el-form-item",
        { attrs: { label: "ID" } },
        [
          _c(
            "el-tooltip",
            {
              staticClass: "item",
              attrs: {
                effect: "dark",
                content: "请注意,ID的修改可能会导致该组件相关事件失效！",
                placement: "left"
              }
            },
            [
              _c("el-input", {
                staticClass: "input",
                on: { change: _vm.handlerChangeId },
                model: {
                  value: _vm.props.id,
                  callback: function($$v) {
                    _vm.$set(_vm.props, "id", $$v)
                  },
                  expression: "props.id"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "标题" } },
        [
          _c("el-input", {
            staticClass: "input",
            model: {
              value: _vm.props.label,
              callback: function($$v) {
                _vm.$set(_vm.props, "label", $$v)
              },
              expression: "props.label"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "栅格间隔" } },
        [
          _c("el-input-number", {
            attrs: { min: 0 },
            model: {
              value: _vm.props.gutter,
              callback: function($$v) {
                _vm.$set(_vm.props, "gutter", $$v)
              },
              expression: "props.gutter"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "标签宽度" } },
        [
          _c("el-input-number", {
            attrs: { min: 1, max: 200 },
            model: {
              value: _vm.props.labelWidth,
              callback: function($$v) {
                _vm.$set(_vm.props, "labelWidth", $$v)
              },
              expression: "props.labelWidth"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "显示标签" } },
        [
          _c("el-switch", {
            model: {
              value: _vm.props.showLabel,
              callback: function($$v) {
                _vm.$set(_vm.props, "showLabel", $$v)
              },
              expression: "props.showLabel"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "显示标签" } },
        [
          _c("el-input", {
            staticClass: "input",
            model: {
              value: _vm.props.placeholder,
              callback: function($$v) {
                _vm.$set(_vm.props, "placeholder", $$v)
              },
              expression: "props.placeholder"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "必填" } },
        [
          _c("el-switch", {
            model: {
              value: _vm.props.required,
              callback: function($$v) {
                _vm.$set(_vm.props, "required", $$v)
              },
              expression: "props.required"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "清空" } },
        [
          _c("el-switch", {
            model: {
              value: _vm.props.clearable,
              callback: function($$v) {
                _vm.$set(_vm.props, "clearable", $$v)
              },
              expression: "props.clearable"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "只读" } },
        [
          _c("el-switch", {
            model: {
              value: _vm.props.readonly,
              callback: function($$v) {
                _vm.$set(_vm.props, "readonly", $$v)
              },
              expression: "props.readonly"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "禁用" } },
        [
          _c("el-switch", {
            model: {
              value: _vm.props.disabled,
              callback: function($$v) {
                _vm.$set(_vm.props, "disabled", $$v)
              },
              expression: "props.disabled"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "范围选择" } },
        [
          _c("el-switch", {
            model: {
              value: _vm.props["is-range"],
              callback: function($$v) {
                _vm.$set(_vm.props, "is-range", $$v)
              },
              expression: "props['is-range']"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.props["is-range"],
              expression: "props['is-range']"
            }
          ],
          attrs: { label: "分隔符" }
        },
        [
          _c("el-input", {
            model: {
              value: _vm.props["range-separator"],
              callback: function($$v) {
                _vm.$set(_vm.props, "range-separator", $$v)
              },
              expression: "props['range-separator']"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "默认值" } },
        [
          _c("el-time-picker", {
            staticClass: "input",
            attrs: { placeholder: "选择默认时间" },
            model: {
              value: _vm.props.value,
              callback: function($$v) {
                _vm.$set(_vm.props, "value", $$v)
              },
              expression: "props.value"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }