import request from '@/utils/request';
var basePath = '/api/v1/user-base/gaode';
var baseUrl = process.env.VUE_APP_USER_API;
var gaodeUrl = 'https://restapi.amap.com/v3'; // const gaodeKey = '35e6196dd5af1c10c95e809e0311d5f7'

var gaodeKey = '680930e097714485eea59860fc20e4b3';
export function locateByIpGaode(query) {
  return request({
    baseURL: gaodeUrl,
    url: '/ip?key=' + gaodeKey,
    method: 'get',
    params: query
  });
}
export function getInputTipsGaode(query) {
  return request({
    baseURL: gaodeUrl,
    url: '/assistant/inputtips?key=' + gaodeKey,
    method: 'get',
    params: query
  });
}
export function getDistanceGaode(query) {
  return request({
    baseURL: gaodeUrl,
    url: '/distance?key=' + gaodeKey,
    method: 'get',
    params: query
  });
}
export function getGaodeGeo(query) {
  // 根据地址获取经纬度
  // query : { address, city }
  return request({
    baseURL: gaodeUrl,
    url: '/geocode/geo?key=' + gaodeKey,
    method: 'get',
    params: query
  });
}
export function locateByIp(query) {
  return request({
    baseURL: baseUrl,
    url: basePath + '/locate-by-ip',
    method: 'get',
    params: query
  });
}
export function getInputTips(query) {
  return request({
    baseURL: baseUrl,
    url: basePath + '/input-tips',
    method: 'get',
    params: query
  });
}