import "core-js/modules/es6.number.constructor";
import "core-js/modules/web.dom.iterable";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'FancyDynamicTable',
  components: {},
  // eslint-disable-next-line vue/require-prop-types
  props: ['data', 'conf', 'disabled'],
  data: function data() {
    return {
      tableData: this.data,
      tableColumns: [],
      tableColumnLabels: [],
      multipleSelection: [],
      componentsMount: false
    };
  },
  computed: {
    BachDeleteButtonShow: function BachDeleteButtonShow() {
      return function (id) {
        if (this.componentsMount) {
          return this.conf.multiCheck && this.$refs[id].selection.length > 0;
        }
      };
    }
  },
  mounted: function mounted() {
    var _this = this;

    this.conf.columns.forEach(function (element) {
      _this.tableColumnLabels.push(element.label);

      var tableCol = {};
      Object.assign(tableCol, element);

      _this.tableColumns.push(tableCol);
    });
    this.componentsMount = true;
  },
  methods: {
    handlerAdd: function handlerAdd(origin) {
      this.$emit('addRow', origin);
    },
    handlerDelete: function handlerDelete(scope, element) {
      this.$emit('deleteRow', scope, element);
    },
    handlerBachDelete: function handlerBachDelete() {
      var _this2 = this;

      this.$confirm('确认删除选中的数据?').then(function () {
        var indexs = [];

        _this2.multipleSelection.forEach(function (item) {
          return indexs.push(item.index);
        });

        _this2.$emit('batchDeleteRow', indexs, _this2.conf);
      });
    },
    handlerSelectionChange: function handlerSelectionChange(val) {
      this.multipleSelection = val;
    },
    tableRowClassName: function tableRowClassName(row) {
      row.row.index = row.rowIndex;
    },
    sumTotal: function sumTotal(param) {
      var _this3 = this;

      var columns = param.columns,
          data = param.data;
      var sums = [];
      columns.forEach(function (column, index) {
        if (index === 0) {
          sums[index] = _this3.conf['sum-text'];
          return;
        }

        var values = data.map(function (item) {
          return Number(item[column.property]);
        });

        if (!values.every(function (value) {
          return isNaN(value);
        })) {
          sums[index] = values.reduce(function (prev, curr) {
            var value = Number(curr);

            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          sums[index] += ' ' + _this3.conf['summary-text'];
        } else {
          sums[index] = '';
        }
      });
      return sums;
    }
  }
};