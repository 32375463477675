export default {
  slot: function slot(h, conf) {
    var slotText = [];

    if (conf.prepend !== '') {
      slotText.push(h("template", {
        "slot": 'prepend'
      }, [conf.prepend]));
    }

    if (conf.append !== '') {
      slotText.push(h("template", {
        "slot": 'append'
      }, [conf.append]));
    }

    return slotText;
  }
};