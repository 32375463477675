import _objectSpread from "/var/jenkins_home/workspace/ops.dfwl.plus/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import RightPanel from '@/components/RightPanel';
import { AppMain, Navbar, Settings, Sidebar, TagsView } from "./components";
import ResizeMixin from "./mixin/ResizeHandler";
import NotificationBox from '@/components/NotificationBox'; // import { listNotice, receivedNotice, getNotice } from '@/api/oa-verify-process'

import eventBus from '@/utils/eventBus.js';
import { mapState } from 'vuex';
export default {
  name: 'Layout',
  components: {
    AppMain: AppMain,
    Navbar: Navbar,
    RightPanel: RightPanel,
    Settings: Settings,
    Sidebar: Sidebar,
    TagsView: TagsView,
    NotificationBox: NotificationBox
  },
  mixins: [ResizeMixin],
  computed: _objectSpread(_objectSpread({}, mapState({
    sidebar: function sidebar(state) {
      return state.app.sidebar;
    },
    device: function device(state) {
      return state.app.device;
    },
    showSettings: function showSettings(state) {
      return state.settings.showSettings;
    },
    needTagsView: function needTagsView(state) {
      return state.settings.tagsView;
    },
    fixedHeader: function fixedHeader(state) {
      return state.settings.fixedHeader;
    },
    staffId: function staffId(state) {
      return state.user.staffId;
    },
    allOaMessageList: function allOaMessageList(state) {
      return state.message.allMessageList;
    },
    messageBoxCount: function messageBoxCount(state) {
      return state.message.messageBoxCount;
    }
  })), {}, {
    classObj: function classObj() {
      return {
        hideSidebar: !this.sidebar.opened,
        openSidebar: this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation,
        mobile: this.device === 'mobile'
      };
    }
  }),
  created: function created() {
    this.preLoadData();
    console.log('layout');
    eventBus.$off('message', this.handleMessage);
    eventBus.$on('message', this.handleMessage);
    this.listAllMessage();
  },
  methods: {
    handleClickOutside: function handleClickOutside() {
      this.$store.dispatch('app/closeSideBar', {
        withoutAnimation: false
      });
    },
    preLoadData: function preLoadData() {
      this.$store.dispatch('businessType/getBusinessTypeList');
      this.$store.dispatch('billPlatform/getBillPlatformList');
      this.$store.dispatch('region/getRegionTreeList');
      this.$store.dispatch('user/loadAllOrgList');
      this.$store.dispatch('user/locateByIp');
      this.$store.dispatch('staff/getAllStaff');
      this.$store.dispatch('staff/getCropAllStaff');
      this.$store.dispatch('customer/getCropAllCustomer');
      this.$store.dispatch('supplier/getAllActiveSupplier');
      this.$store.dispatch('message/getDailyMessageCount');
    },
    listAllMessage: function listAllMessage() {
      this.$store.dispatch('message/reloadMessageList');
    },
    handleMessage: function handleMessage(msg) {
      var obj = JSON.parse(msg);

      if (obj.type === 'error') {
        this.$message.error(obj.msg);
      } else if (obj.type === 'daily_message_count_message') {
        this.$store.dispatch('message/getDailyMessageCount');
      } else if (obj.type === 'oa_message' || obj.type === 'event_message') {
        this.$store.dispatch('message/fetchOneNotice', {
          msg: msg
        });
      }
    }
  }
};