import request from '@/utils/request';
var baseSupplierPath = '/api/v1/user-base/supplier';
var baseStaffPath = '/api/v1/user-base/staff';
var baseCustomerPath = '/api/v1/user-base/customer';
var baseUrl = process.env.VUE_APP_USER_API;
export function save(data) {
  return request({
    baseURL: baseUrl,
    url: baseCustomerPath + '/save',
    method: 'post',
    data: {
      data: data
    }
  });
}
export function deleteImage(data) {
  return request({
    baseURL: baseUrl,
    url: baseCustomerPath + '/delete-image',
    method: 'delete',
    data: {
      data: data
    }
  });
}
export function listOrgCustomer() {
  return request({
    baseURL: baseUrl,
    url: baseCustomerPath + '/list-org-customer',
    method: 'get'
  });
}
export function listCropAllCustomer() {
  return request({
    baseURL: baseUrl,
    url: baseCustomerPath + '/list-all-crop-light-customer',
    method: 'get'
  });
}
export function listCustomerTree(query) {
  return request({
    baseURL: baseUrl,
    url: baseCustomerPath + '/list-customer-tree',
    method: 'get',
    params: query
  });
}
export function listByLocalStaff(query) {
  return request({
    baseURL: baseUrl,
    url: baseCustomerPath + '/list-local-customer',
    method: 'get',
    params: query
  });
}
export function listAuthLightCustomer() {
  return request({
    baseURL: baseUrl,
    url: baseCustomerPath + '/list-auth-light-customer',
    method: 'get'
  });
}
export function listByMarketer() {
  return request({
    baseURL: baseUrl,
    url: baseCustomerPath + '/list-marketer-customer',
    method: 'get'
  });
}
export function listTemporaryCustomer() {
  return request({
    baseURL: baseUrl,
    url: baseCustomerPath + '/list-temporary-customer',
    method: 'get'
  });
}
export function listByLocalStaffNoPerm(query) {
  return request({
    baseURL: baseUrl,
    url: baseCustomerPath + '/list-local-customer-no-perm',
    method: 'get',
    params: query
  });
}
export function listSupplier(query) {
  return request({
    baseURL: baseUrl,
    url: baseSupplierPath + '/list-org-supplier',
    method: 'get',
    params: query
  });
}
export function listVerifyStaff(query) {
  return request({
    baseURL: baseUrl,
    url: baseStaffPath + '/list-verify-staff',
    method: 'get',
    params: query
  });
}
export function customInfo(query) {
  return request({
    baseURL: baseUrl,
    url: baseCustomerPath + '/{customerId}',
    method: 'get',
    params: query
  });
}
export function orgCustomInfo(query) {
  return request({
    baseURL: baseUrl,
    url: baseCustomerPath + '/{orgId}/{customerId}',
    method: 'get',
    params: query
  });
}
export function updateCustomer(query, data) {
  return request({
    baseURL: baseUrl,
    url: baseCustomerPath + '/{customerId}',
    method: 'put',
    params: query,
    data: {
      data: data
    }
  });
}
export function customerBidDocFeeApply(data) {
  return request({
    baseURL: baseUrl,
    url: baseCustomerPath + '/bid-doc-fee/apply-verify',
    method: 'post',
    data: {
      data: data
    }
  });
}
export function customerBidDocFeeVerify(data) {
  return request({
    baseURL: baseUrl,
    url: baseCustomerPath + '/bid-doc-fee/verify',
    method: 'post',
    data: {
      data: data
    }
  });
}
export function applyCancelCustomer(data) {
  return request({
    baseURL: baseUrl,
    url: baseCustomerPath + '/apply-cancel',
    method: 'post',
    data: {
      data: data
    }
  });
}
export function listInvitedCustomer(query) {
  return request({
    baseURL: baseUrl,
    url: baseCustomerPath + '/invited/list',
    method: 'get',
    params: query
  });
} // 根据分配的部门获取邀请当前企业的客户的平台OrgId

export function listInvitedCustomerPlatformOrgByDeps() {
  return request({
    baseURL: baseUrl,
    url: baseCustomerPath + '/invited/list-platform-org-by-deps',
    method: 'get'
  });
}
export function getInvitedCustomersProcessStatus(data) {
  return request({
    baseURL: baseUrl,
    url: baseCustomerPath + '/invited/process-status',
    method: 'post',
    data: {
      data: data
    }
  });
}
export function assignOrg(data) {
  return request({
    baseURL: baseUrl,
    url: baseCustomerPath + '/assign-org',
    method: 'put',
    data: {
      data: data
    }
  });
}
export function assignDepartment(data) {
  return request({
    baseURL: baseUrl,
    url: baseCustomerPath + '/assign-department',
    method: 'put',
    data: {
      data: data
    }
  });
}
export function rejectInvite(data) {
  return request({
    baseURL: baseUrl,
    url: baseCustomerPath + '/invited/reject',
    method: 'post',
    data: {
      data: data
    }
  });
}