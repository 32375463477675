import request from '@/utils/request';
var basePath = '/api/v1/finance-base/finance-fee-subject-config';
var baseUrl = process.env.VUE_APP_FINANCE_API;
export function listFinanceFeeSubjectConfig(query) {
  return request({
    baseURL: baseUrl,
    url: basePath + '/list',
    method: 'get',
    params: query
  });
}
export function listProfitLossSubject() {
  return request({
    baseURL: baseUrl,
    url: basePath + '/list-profit-loss-subject',
    method: 'get'
  });
}
export function getFinanceFeeSubjectConfig(query) {
  return request({
    baseURL: baseUrl,
    url: basePath + '/{configId}',
    method: 'get',
    params: query
  });
}
export function saveFinanceFeeSubjectConfig(data) {
  return request({
    baseURL: baseUrl,
    url: basePath + '/save',
    method: 'post',
    data: {
      data: data
    }
  });
}
export function deleteFinanceFeeSubjectConfig(query) {
  return request({
    baseURL: baseUrl,
    url: basePath + '/{configId}',
    method: 'delete',
    params: query
  });
}