//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { changeId } from "../mixin"; // 引入 省市区 数据
// import { areaData } from '../../utils/chinaAreaData'

import { codemirror } from 'vue-codemirror'; // 核心样式

import 'codemirror/lib/codemirror.css'; // 引入主题后还需要在 options 中指定主题才会生效

import 'codemirror/theme/dracula.css';
import 'codemirror/mode/javascript/javascript';
var options = {
  tabSize: 2,
  // 缩进格式
  theme: 'dracula',
  // 主题，对应主题库 JS 需要提前引入
  lineNumbers: true,
  // 显示行号
  line: true,
  styleActiveLine: true,
  // 高亮选中行
  hintOptions: {
    completeSingle: true // 当匹配只有一项的时候是否自动补全

  }
};
/**
 * input的配置项
 */

export default {
  name: 'CascaderConfig',
  components: {
    codemirror: codemirror
  },
  mixins: [changeId],
  // eslint-disable-next-line vue/require-prop-types
  props: ['props'],
  data: function data() {
    return {
      staticDataVisible: false,
      codeMirror: options,
      staticOptions: '',
      tempOptions: []
    };
  },
  watch: {},
  mounted: function mounted() {},
  methods: {
    handlerChangeLabel: function handlerChangeLabel(val) {
      this.props.labelWidth = val ? '80' : '1';
    },
    handlerStaticData: function handlerStaticData() {
      this.staticOptions = JSON.stringify(this.props.options, null, 4);
      this.staticDataVisible = true;
    },
    handlerSave: function handlerSave() {
      this.props.options = JSON.parse(this.staticOptions);
      this.staticDataVisible = false;
    },
    handlerChangeDataType: function handlerChangeDataType(value) {
      if (value === 'static') {
        this.props.options = [];
        this.props.options = this.tempOptions;
      } else {
        this.tempOptions = this.props.options;
        this.props.options = [];
      }
    } // handlerSetAreaData(val) {
    //   if (val) {
    //     const areaoptions = areaData()
    //     this.tempOptions = this.props.options
    //     this.props.options = areaoptions
    //   } else {
    //     this.props.options = this.tempOptions
    //   }
    // }

  }
};