import "core-js/modules/es6.array.find-index";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import draggable from 'vuedraggable';
import configPanel from "./configPanel";
import designItem from "./designItem";
import { getSimpleId } from "./utils/IdGenerate";
import { isLayout, isTable, inTable, jsonClone, cleanOption } from "./utils/index";
import formConf from "./custom/formConf";
import preview from "./preview";
import { codemirror } from 'vue-codemirror'; // 核心样式

import 'codemirror/lib/codemirror.css'; // 引入主题后还需要在 options 中指定主题才会生效

import 'codemirror/theme/dracula.css';
import 'codemirror/mode/javascript/javascript';
export default {
  name: 'Designer',
  components: {
    draggable: draggable,
    configPanel: configPanel,
    designItem: designItem,
    preview: preview,
    codemirror: codemirror
  },
  props: {
    list: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    formConfig: {
      type: Object,
      default: formConf
    }
  },
  provide: function provide() {
    return {
      getContext: this
    };
  },
  data: function data() {
    return {
      formConf: formConf,
      activeItem: {},
      lastActiveItem: {},
      formConfVisible: false,
      previewVisible: false,
      JSONVisible: false,
      itemList: [],
      activeName: 'formConf',
      editorCode: '',
      viewCode: '',
      // 默认配置
      options: {
        tabSize: 2,
        // 缩进格式
        theme: 'dracula',
        // 主题，对应主题库 JS 需要提前引入
        lineNumbers: true,
        // 显示行号
        line: true,
        styleActiveLine: true,
        // 高亮选中行
        hintOptions: {
          completeSingle: true // 当匹配只有一项的时候是否自动补全

        }
      }
    };
  },
  computed: {
    infoShow: function infoShow() {
      return this.list.length < 1;
    },
    code: function code() {
      var json = {};
      json.config = this.formConf;
      json.list = this.list;
      cleanOption(json.list);
      return JSON.stringify(json, null, 4);
    }
  },
  watch: {
    activeItem: function activeItem(newValue, oldValue) {
      this.lastActiveItem = oldValue;
    },
    'activeItem.span': {
      handler: function handler(newVal, oldVal) {
        // console.log('span change')
        if (newVal) {
          sessionStorage.setItem('localSpan', newVal);
        }
      },
      deep: true
    },
    'activeItem.label': {
      handler: function handler(newVal, oldVal) {
        // console.log('label change')
        var compType = this.activeItem.compType;

        if (compType === 'input' || compType === 'inputNumber' || compType === 'textarea') {
          this.activeItem.placeholder = '请输入' + newVal;
        } else {
          this.activeItem.placeholder = '请选择' + newVal;
        }
      },
      deep: true
    }
  },
  mounted: function mounted() {},
  methods: {
    preview: function preview() {
      var clone = JSON.parse(JSON.stringify(this.list));
      this.itemList = clone;
      this.previewVisible = true;
    },
    viewJSON: function viewJSON() {
      this.viewCode = this.code;
      this.JSONVisible = true;
    },
    view: function view() {
      localStorage.setItem('formValue', this.code);
      window.open('#/view');
    },
    setting: function setting() {
      this.formConfVisible = true;
    },
    clear: function clear() {
      var _this = this;

      this.$confirm('此操作将清空整个表单,是否继续?').then(function () {
        _this.$emit('clear');
      });
    },
    help: function help() {
      window.open('https://gitee.com/wurong19870715/formDesigner');
    },
    handlerActiveItemChange: function handlerActiveItemChange(obj) {
      this.lastActiveItem = this.activeItem;
      this.activeItem = obj;
    },
    handlerItemCopy: function handlerItemCopy(origin, parent) {
      var _this2 = this;

      if (isLayout(origin)) {
        // row
        var clone = jsonClone(origin);
        var uId = 'row_' + getSimpleId();
        console.log(uId);
        clone.id = uId;
        clone._id = uId;
        clone.columns.map(function (column) {
          var itemList = [];
          column.list.map(function (item) {
            var cloneitem = jsonClone(item);
            var uId = 'fd_' + getSimpleId();
            cloneitem.id = uId;
            cloneitem._id = uId;
            itemList.push(cloneitem);
          });
          column.list = [];
          column.list = itemList;
        });
        this.list.push(clone);
        this.handlerActiveItemChange(clone);
      } else if (isTable(origin)) {
        // 表格布局
        var _clone = jsonClone(origin);

        var _uId = 'table_' + getSimpleId();

        _clone.id = _uId;
        _clone._id = _uId;

        _clone.layoutArray.map(function (tr) {
          tr.map(function (td) {
            var itemList = [];
            td.id = getSimpleId();
            td.columns.map(function (item, i) {
              var cloneitem = jsonClone(item);
              var uId = 'fd_' + getSimpleId();
              cloneitem.id = uId;
              cloneitem._id = uId;
              itemList.push(cloneitem);
            });
            td.columns = [];
            td.columns = itemList;
          });
        });

        this.list.push(_clone);
        this.handlerActiveItemChange(_clone);
      } else {
        // 如果是普通组件，需要判断他是否再布局组件下。
        if (parent) {
          if (inTable(parent)) {
            // 增加表格组件的支持
            if (parent.columns.some(function (item) {
              return item.id === origin.id;
            })) {
              var _clone2 = jsonClone(origin);

              var _uId2 = 'fd_' + getSimpleId();

              _clone2.id = _uId2;
              _clone2._id = _uId2;
              parent.columns.push(_clone2);
              this.handlerActiveItemChange(_clone2);
            }
          } else {
            parent.columns.map(function (column) {
              if (column.list.some(function (item) {
                return item.id === origin.id;
              })) {
                var _clone3 = jsonClone(origin);

                var _uId3 = 'fd_' + getSimpleId();

                _clone3.id = _uId3;
                _clone3._id = _uId3;
                column.list.push(_clone3);

                _this2.handlerActiveItemChange(_clone3);
              }
            });
          }
        } else {
          var _clone4 = jsonClone(origin);

          var _uId4 = 'fd_' + getSimpleId();

          _clone4.id = _uId4;
          _clone4._id = _uId4;
          this.list.push(_clone4);
          this.handlerActiveItemChange(_clone4);
        }
      }
    },
    handlerItemDelete: function handlerItemDelete(origin, parent) {
      if (isLayout(origin) || isTable(origin)) {
        // 如果是布局组件,则直接删除
        var index = this.list.findIndex(function (item) {
          return item.id === origin.id;
        });
        this.list.splice(index, 1);
      } else {
        // 如果不是布局组件，则先判断是不是再布局内部，如果不是，则直接删除就可以，如果是，则要在布局内部删除
        if (parent) {
          if (inTable(parent)) {
            // 增加表格组件的支持
            var colIndex = parent.columns.findIndex(function (item) {
              return item.id === origin.id;
            });

            if (colIndex > -1) {
              parent.columns.splice(colIndex, 1);
            }
          } else {
            parent.columns.map(function (column) {
              var colIndex = column.list.findIndex(function (item) {
                return item.id === origin.id;
              });

              if (colIndex > -1) {
                column.list.splice(colIndex, 1);
              }
            });
          }
        } else {
          var _index = this.list.findIndex(function (item) {
            return item.id === origin.id;
          });

          if (_index >= 0) {
            this.list.splice(_index, 1); // 如果当前属性配置显示的是删除项, 则清除属性配置

            if (this.activeItem && this.activeItem.id === origin.id) {
              this.activeItem = {};
            }
          }
        }
      }
    },
    handlerSaveFormConf: function handlerSaveFormConf() {
      this.formConfVisible = false;
    },
    handlerRollBack: function handlerRollBack(rowItem, oldIndex) {
      // 还原
      this.list.splice(oldIndex, 0, rowItem);
    },
    handlerSetJson: function handlerSetJson() {
      this.$emit('updateJSON', this.viewCode);
      this.JSONVisible = false;
    }
  }
};