var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "notification-box" },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showBox,
              expression: "showBox"
            }
          ],
          staticClass: "my-message-box"
        },
        [
          _c("div", { staticClass: "title" }, [
            _c("span", [
              _vm._v("未读消息(" + _vm._s(_vm.messageTotal || "0") + ")")
            ]),
            _c("i", {
              staticClass: "el-icon-minus",
              on: { click: _vm.onHideBox }
            })
          ]),
          _c(
            "div",
            { staticClass: "content-box" },
            _vm._l(_vm.showMessageList, function(item) {
              return _c(
                "div",
                {
                  key: item.id,
                  staticClass: "message",
                  on: {
                    click: function($event) {
                      return _vm.onClickMessage(item)
                    }
                  }
                },
                [
                  _c("div", { staticClass: "left" }, [
                    _c("div", { staticClass: "icon" }, [
                      _vm._v(_vm._s(_vm.getLastTwoWord(item.name || "系统")))
                    ])
                  ]),
                  _c("div", { staticClass: "right" }, [
                    _c("div", { staticClass: "top" }, [
                      _c("div", { staticClass: "text" }, [
                        _vm._v(_vm._s(item.message))
                      ]),
                      _c("div", { staticClass: "remove" }, [
                        _c("i", {
                          staticClass: "el-icon-circle-close",
                          on: {
                            click: function($event) {
                              return _vm.onRemoveMessage(item.type, item.id)
                            }
                          }
                        })
                      ])
                    ]),
                    _c("div", { staticClass: "sign" }, [
                      _c("div", [_vm._v(_vm._s(item.name || "系统"))]),
                      _c("div", [
                        _vm._v(
                          _vm._s(
                            _vm._f("parseTime")(
                              item.messageDate,
                              "{m}-{d} {h}:{i}"
                            )
                          )
                        )
                      ])
                    ])
                  ])
                ]
              )
            }),
            0
          ),
          _c("div", { staticClass: "bottom-box" }, [
            _c(
              "span",
              {
                staticStyle: { "margin-right": "10px" },
                on: { click: _vm.onViewAll }
              },
              [_vm._v("查看全部")]
            ),
            _c("span", { on: { click: _vm.onIgnoreAll } }, [_vm._v("忽略全部")])
          ])
        ]
      ),
      _c("el-button", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.showBox,
            expression: "!showBox"
          }
        ],
        staticClass: "btn-show",
        attrs: { type: "primary", icon: "el-icon-message-solid", circle: "" },
        on: { click: _vm.onShowBox }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }