//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import previewItem from "./previewItem";
import previewRowItem from "./previewRowItem";
import fancyDynamicTable from "./dynamic/fancyDynamicTable";
import fancyDynamicTableItem from "./dynamic/fancyDynamicTableItem";
import fancyEditTable from "./table/fancyEditTable";
import { datas, addRow, batchDeleteRow, deleteRow } from "./custom/formDraw";
export default {
  name: 'Preview',
  components: {
    previewItem: previewItem,
    previewRowItem: previewRowItem,
    fancyDynamicTable: fancyDynamicTable,
    fancyDynamicTableItem: fancyDynamicTableItem,
    fancyEditTable: fancyEditTable
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['itemList', 'formConf'],
  data: function data() {
    return {
      list: this.itemList,
      form: {},
      rules: {},
      currentIndex: -1
    };
  },
  computed: {},
  created: function created() {
    this.handlerInitDatas(); // 初始化表单
  },
  mounted: function mounted() {
    this.$nextTick(function () {});
  },
  beforeCreate: function beforeCreate() {},
  methods: {
    handlerValChange: function handlerValChange(key, origin) {
      console.log(key);
      this.$set(this.form, key, origin);
    },
    handlerDynamicValChange: function handlerDynamicValChange(parentId, index, key, origin) {
      this.$set(this.form[parentId][index], key, origin);
      this.currentIndex = index;
    },
    handlerSubForm: function handlerSubForm() {
      var _this = this;

      this.$refs[this.formConf.formModel].validate(function (valid) {
        if (valid) {
          console.log(_this.form);

          _this.$message.success('success');
        }
      });
    },
    handlerAddRow: addRow,
    handlerDeleteRow: deleteRow,
    handlerBatchDeleteRow: batchDeleteRow,
    handlerInitDatas: datas
  }
};