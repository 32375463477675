var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "right-board" },
    [
      _c(
        "el-tabs",
        {
          staticClass: "center-tabs",
          model: {
            value: _vm.currentTab,
            callback: function($$v) {
              _vm.currentTab = $$v
            },
            expression: "currentTab"
          }
        },
        [_c("el-tab-pane", { attrs: { label: "属性配置", name: "field" } })],
        1
      ),
      _c(
        "div",
        { staticClass: "field-box" },
        [
          _c(
            "el-scrollbar",
            { staticClass: "right-scrollbar" },
            [
              _c(
                "el-form",
                { attrs: { size: "small", "label-width": "100px" } },
                _vm._l(_vm.cmps, function(item, index) {
                  return _c(
                    "div",
                    { key: index },
                    [
                      item.name === _vm.activeItem.compType
                        ? _c(item.content, {
                            tag: "component",
                            attrs: {
                              "get-form-id": _vm.getFormId,
                              props: _vm.activeItem
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                }),
                0
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }