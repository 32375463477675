var state = {
  currentSettleBillDate: undefined
};
var mutations = {
  SET_SETTLE_BILL_DATE: function SET_SETTLE_BILL_DATE(state, settleBillDate) {
    state.currentSettleBillDate = settleBillDate;
  }
};
var actions = {
  setSettleBillDate: function setSettleBillDate(_ref, date) {
    var commit = _ref.commit;
    commit('SET_SETTLE_BILL_DATE', date);
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};