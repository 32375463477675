//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'SidebarLogo',
  props: {
    collapse: {
      type: Boolean,
      required: true
    }
  },
  data: function data() {
    return {
      title: '东方物流管理系统',
      logo: 'https://dfwl-ops-prod.oss-cn-zhangjiakou.aliyuncs.com/dfwl-logo.png'
    };
  }
};