var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "td",
    {
      class: { cellHide: _vm.item.hide },
      style: {
        tdStyle: _vm.tdStyle,
        "background-color": _vm.item.style.background,
        width: _vm.item.style.width + "%"
      },
      attrs: { colspan: _vm.item.col, rowspan: _vm.item.row },
      on: {
        contextmenu: function($event) {
          $event.preventDefault()
          return _vm.rightClick($event, _vm.trIndex, _vm.tdIndex)
        }
      }
    },
    [_vm._t("default", null, { td: _vm.item })],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }