//
//
//
//
//
//
//
//
export default {
  name: 'Icon',
  // eslint-disable-next-line vue/require-prop-types
  props: ['code', 'text'],
  computed: {
    iconFont: function iconFont() {
      return '#icon-' + this.code;
    }
  }
};