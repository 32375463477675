export default {
  slot: function slot(h, conf) {
    var list = [];

    if (conf['list-type'] === 'picture-card') {
      list.push(h("i", {
        "class": 'el-icon-plus'
      }));
    } else {
      list.push(h("el-button", {
        "attrs": {
          "size": 'small',
          "type": 'primary',
          "icon": 'el-icon-upload'
        }
      }, [conf.buttonText]));
    }

    if (conf.showTip) {
      list.push(h("div", {
        "slot": 'tip',
        "class": 'el-upload__tip'
      }, [conf.tips]));
    }

    return list;
  }
};