import request from '@/utils/request';
var basePath = '/api/v1/inventory-base/material-item';
var baseUrl = process.env.VUE_APP_INVENTORY_API;
export function listAllMaterial(query) {
  return request({
    baseURL: baseUrl,
    url: basePath + '/list-all',
    method: 'get',
    params: query
  });
}