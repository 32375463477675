export function getSimpleId() {
  sleep(10); // 主要为了延时工作

  return new Date().getTime();
}

var sleep = function sleep(time) {
  var startTime = new Date().getTime() + parseInt(time, 10); // eslint-disable-next-line no-empty

  while (new Date().getTime() < startTime) {}
};

export function setTableId(table) {
  table.layoutArray.map(function (tr, trIndex) {
    tr[0].id = getSimpleId();
    tr[1].id = getSimpleId();
  });
  return table;
}