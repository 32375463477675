var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "navbar" },
    [
      _c("hamburger", {
        staticClass: "hamburger-container",
        attrs: { id: "hamburger-container", "is-active": _vm.sidebar.opened },
        on: { toggleClick: _vm.toggleSideBar }
      }),
      _c("breadcrumb", {
        staticClass: "breadcrumb-container",
        attrs: { id: "breadcrumb-container" }
      }),
      _c(
        "div",
        {
          staticClass: "right-menu",
          staticStyle: { display: "flex", "justify-content": "space-between" }
        },
        [
          _vm.device !== "mobile"
            ? [
                _vm.isTestEnvironment
                  ? _c(
                      "span",
                      { staticClass: "right-menu-item test-env-wrapper" },
                      [_vm._v("测试环境")]
                    )
                  : _vm._e(),
                _c(
                  "el-tooltip",
                  {
                    attrs: {
                      content: "管理系统",
                      effect: "dark",
                      placement: "bottom"
                    }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "right-menu-item" },
                      [
                        _c(
                          "el-link",
                          {
                            attrs: {
                              href: _vm.sassRedirectUrl,
                              underline: false,
                              target: "_blank"
                            }
                          },
                          [_vm._v("管理系统")]
                        )
                      ],
                      1
                    )
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "right-menu-item chat",
                    class: _vm.isTwinkle ? "twinkle" : ""
                  },
                  [
                    _c("i", {
                      staticClass: "el-icon-s-comment",
                      on: { click: _vm.onChatClick }
                    }),
                    _c("el-badge", {
                      staticClass: "mark",
                      attrs: {
                        value: _vm.enquirySeqQuoteMessageCount,
                        hidden: _vm.enquirySeqQuoteMessageCount <= 0
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-tooltip",
                  {
                    attrs: {
                      content: "操作流程图",
                      effect: "dark",
                      placement: "bottom"
                    }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "right-menu-item" },
                      [
                        _c(
                          "router-link",
                          { attrs: { to: "/guide" } },
                          [
                            _c("svg-icon", { attrs: { "icon-class": "guide" } })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                ),
                _c("search", {
                  staticClass: "right-menu-item",
                  attrs: { id: "header-search" }
                }),
                _c("error-log", {
                  staticClass: "errLog-container right-menu-item hover-effect"
                }),
                _c("screenfull", {
                  staticClass: "right-menu-item hover-effect",
                  attrs: { id: "screenfull" }
                }),
                _c(
                  "el-tooltip",
                  {
                    attrs: {
                      content: "全局大小",
                      effect: "dark",
                      placement: "bottom"
                    }
                  },
                  [
                    _c("size-select", {
                      staticClass: "right-menu-item hover-effect",
                      attrs: { id: "size-select" }
                    })
                  ],
                  1
                )
              ]
            : _vm._e(),
          _c(
            "el-dropdown",
            {
              staticClass: "dropdown",
              attrs: { trigger: "click" },
              on: {
                command: _vm.handleCommand,
                "visible-change": _vm.onDropdownVisibleChange
              }
            },
            [
              _c("span", { staticStyle: { display: "flex" } }, [
                _c("img", {
                  staticClass: "user-avatar",
                  attrs: { src: _vm.avatar + "?imageView2/1/w/80/h/80" }
                }),
                _c("div", [
                  _c("div", { staticClass: "user-name" }, [
                    _vm._v(_vm._s(_vm.name))
                  ]),
                  _c("div", { staticClass: "org-name" }, [
                    _vm._v(_vm._s(_vm.userInfo.orgName))
                  ])
                ]),
                _c("i", {
                  staticClass: "el-icon-arrow-down el-icon--right arrow-center"
                })
              ]),
              _c(
                "el-dropdown-menu",
                { attrs: { slot: "dropdown" }, slot: "dropdown" },
                [
                  _c("el-dropdown-item", { attrs: { command: "switchOrg" } }, [
                    _c("span", [_vm._v("切换单位")])
                  ]),
                  _c(
                    "router-link",
                    { attrs: { to: "/profile/password-change" } },
                    [_c("el-dropdown-item", [_vm._v("密码修改")])],
                    1
                  ),
                  _c(
                    "router-link",
                    { attrs: { to: "/profile/handover" } },
                    [_c("el-dropdown-item", [_vm._v("离职交接")])],
                    1
                  ),
                  _c(
                    "router-link",
                    { attrs: { to: "/profile/profile-finance" } },
                    [
                      _c(
                        "el-dropdown-item",
                        [
                          _vm.needConfirmIncomeCount > 0
                            ? _c("el-badge", { attrs: { "is-dot": "" } }, [
                                _vm._v("个人财务管理")
                              ])
                            : _c("span", [_vm._v("个人财务管理")])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("el-dropdown-item", { attrs: { divided: "" } }, [
                    _c(
                      "span",
                      {
                        staticStyle: { display: "block" },
                        on: { click: _vm.logout }
                      },
                      [_vm._v("退出")]
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        2
      ),
      [_c("switch-org-dialog", { ref: "switchOrgDialog" })]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }