/**
 * 省市区级联选择器
 */
export var cascaderRegion = {
  id: '',
  _id: '',
  compType: 'cascaderRegion',
  ele: 'el-cascader',
  // 控件名称(左侧显示)
  compName: '省市区',
  // 图标
  compIcon: 'cascader',
  // 展示表单的模式
  viewType: 'component',
  // 是否可配置
  // 暂时默认为true，无法更改，后期考虑某些时候无法配置
  config: true,
  // 控件左侧label内容
  showLabel: true,
  label: '省市区',
  labelWidth: '80',
  placeholder: '请选择省市区',
  // 栅格间隔
  gutter: 15,
  // 默认栅格
  span: 24,
  value: [],
  // 大小
  size: 'medium',
  // 禁用
  disabled: false,
  // 数据类型 static，dynamic
  dataType: 'store',
  storeDispatchName: 'region/getRegionTreeList',
  'china-area-data': false,
  // 请求地址
  action: '',
  props: {
    // 清空
    clearable: true,
    // 分隔符
    separator: '/',
    // 显示完整路径
    'show-all-levels': true,
    filterable: false,
    props: {
      // 触发方式,click, hover
      expandTrigger: 'hover',
      // 多选
      multiple: false,
      // 可选任一级
      checkStrictly: false,
      label: 'name',
      value: 'regionId',
      children: 'children'
    }
  },
  options: []
};