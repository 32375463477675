//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { changeId } from "../mixin";
export default {
  name: 'ButtonConfig',
  mixins: [changeId],
  props: {
    // eslint-disable-next-line vue/require-prop-types,vue/require-default-prop
    props: {}
  },
  data: function data() {
    return {
      color: '#409EFF'
    };
  },
  mounted: function mounted() {},
  methods: {
    handlerShowLabel: function handlerShowLabel(val) {
      if (val) {
        this.props.labelWidth = 80;
      } else {
        this.props.labelWidth = 0;
      }
    },
    handlerAddPreColor: function handlerAddPreColor(val) {
      console.log(val);
      this.props.predefine.push(val);
      console.log(this.props.predefine);
    },
    handlerRemoveColor: function handlerRemoveColor(index) {
      this.props.predefine.splice(index, 1);
    }
  }
};