var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.props.compType === "dynamicTable",
          expression: "props.compType === 'dynamicTable'"
        }
      ]
    },
    [
      _c(
        "el-form-item",
        { attrs: { label: "ID" } },
        [
          _c(
            "el-tooltip",
            {
              staticClass: "item",
              attrs: {
                effect: "dark",
                content: "请注意,ID的修改可能会导致该组件相关事件失效！",
                placement: "left"
              }
            },
            [
              _c("el-input", {
                staticClass: "input",
                on: { change: _vm.handlerChangeId },
                model: {
                  value: _vm.props.id,
                  callback: function($$v) {
                    _vm.$set(_vm.props, "id", $$v)
                  },
                  expression: "props.id"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "显示新增按钮", "label-width": "120px" } },
        [
          _c("el-switch", {
            model: {
              value: _vm.props.buttonAdd,
              callback: function($$v) {
                _vm.$set(_vm.props, "buttonAdd", $$v)
              },
              expression: "props.buttonAdd"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "显示删除按钮", "label-width": "120px" } },
        [
          _c("el-switch", {
            model: {
              value: _vm.props.buttonDel,
              callback: function($$v) {
                _vm.$set(_vm.props, "buttonDel", $$v)
              },
              expression: "props.buttonDel"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "显示行号", "label-width": "120px" } },
        [
          _c("el-switch", {
            model: {
              value: _vm.props.showIndex,
              callback: function($$v) {
                _vm.$set(_vm.props, "showIndex", $$v)
              },
              expression: "props.showIndex"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "多选", "label-width": "120px" } },
        [
          _c("el-switch", {
            model: {
              value: _vm.props.multiCheck,
              callback: function($$v) {
                _vm.$set(_vm.props, "multiCheck", $$v)
              },
              expression: "props.multiCheck"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "合计行", "label-width": "120px" } },
        [
          _c("el-switch", {
            model: {
              value: _vm.props["show-summary"],
              callback: function($$v) {
                _vm.$set(_vm.props, "show-summary", $$v)
              },
              expression: "props['show-summary']"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "合计文本", "label-width": "120px" } },
        [
          _c("el-input", {
            model: {
              value: _vm.props["sum-text"],
              callback: function($$v) {
                _vm.$set(_vm.props, "sum-text", $$v)
              },
              expression: "props['sum-text']"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "单位文本", "label-width": "120px" } },
        [
          _c("el-input", {
            model: {
              value: _vm.props["summary-text"],
              callback: function($$v) {
                _vm.$set(_vm.props, "summary-text", $$v)
              },
              expression: "props['summary-text']"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }