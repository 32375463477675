//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { changeId } from "../mixin";
export default {
  name: 'TextConfig',
  mixins: [changeId],
  // eslint-disable-next-line vue/require-prop-types
  props: ['props'],
  data: function data() {
    return {};
  },
  methods: {
    handlerChangeSize: function handlerChangeSize(val) {
      this.props.size = val + '';
    },
    handlerChangeBold: function handlerChangeBold(val) {
      this.props.bold = val + '';
    }
  }
};