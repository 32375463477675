import "core-js/modules/es6.number.constructor";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
import { quillEditor } from 'vue-quill-editor';
export default {
  components: {
    quillEditor: quillEditor
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    max: {
      type: Number,
      default: 0
    },
    validateMaxText: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      content: this.value,
      currentLength: 0,
      defaultMaxLength: 2000,
      editorOption: {}
    };
  },
  computed: {
    editor: function editor() {
      return this.$refs.myQuillEditor.quill;
    },
    maxTextLength: function maxTextLength() {
      var len = this.defaultMaxLength;

      if (this.max >= 1) {
        len = this.max;
      }

      return len;
    },
    warnTextLength: function warnTextLength() {
      var warn = this.validateMaxText && this.currentLength > this.maxTextLength;
      return warn;
    }
  },
  watch: {
    value: function value(newVal) {
      this.content = newVal;
    }
  },
  methods: {
    onEditorBlur: function onEditorBlur(editor) {},
    // 失去焦点事件
    onEditorFocus: function onEditorFocus(v) {},
    // 获得焦点事件
    onEditorChange: function onEditorChange(v) {
      this.currentLength = v.text.length - 1;
      this.$emit('input', this.content);
    } // 内容改变事件

  }
};