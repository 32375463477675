import "core-js/modules/web.dom.iterable";
export default {
  slot: function slot(h, conf) {
    var list = [];
    var vertical = conf.vertical ? 'display:block;' : '';
    conf.options.forEach(function (item) {
      if (conf.optionType === 'button') list.push(h("el-checkbox-button", {
        "attrs": {
          "label": item.value
        }
      }, [item.label]));else list.push(h("el-checkbox", {
        "attrs": {
          "label": item.value,
          "border": conf.border
        },
        "style": vertical
      }, [item.label]));
    });
    return list;
  }
};