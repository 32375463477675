import request from '@/utils/request';
var basePath = '/api/v1/project-base/transport-bulk-cost-settle';
var baseUrl = process.env.VUE_APP_PROJECT_API;
export function getBulkConfig() {
  return request({
    baseURL: baseUrl,
    url: basePath + '/config',
    method: 'get'
  });
}
export function getBulkCostSettle(query) {
  return request({
    baseURL: baseUrl,
    url: basePath + '/{projectId}/cost-settle',
    method: 'get',
    params: query
  });
}
export function getClueBulkCostSettle(query) {
  return request({
    baseURL: baseUrl,
    url: basePath + '/clue/{clueId}/cost-settle',
    method: 'get',
    params: query
  });
}
export function listStaffCostFeeByProject(query) {
  return request({
    baseURL: baseUrl,
    url: basePath + '/{projectId}/list-staff-cost-settle',
    method: 'get',
    params: query
  });
}
export function listStaffCostFeeByClue(query) {
  return request({
    baseURL: baseUrl,
    url: basePath + '/clue/{clueId}/list-staff-cost-settle',
    method: 'get',
    params: query
  });
}
export function listContractCostFeeByProject(query) {
  return request({
    baseURL: baseUrl,
    url: basePath + '/{projectId}/list-contract-cost-settle',
    method: 'get',
    params: query
  });
}
export function listContractCostFeeByClue(query) {
  return request({
    baseURL: baseUrl,
    url: basePath + '/clue/{clueId}/list-contract-cost-settle',
    method: 'get',
    params: query
  });
}
export function getContractCostFee(query) {
  return request({
    baseURL: baseUrl,
    url: basePath + '/contract-cost-settle/{contractSettleId}',
    method: 'get',
    params: query
  });
}
export function getStaffCostFee(query) {
  return request({
    baseURL: baseUrl,
    url: basePath + '/staff-cost-settle/{staffSettleId}',
    method: 'get',
    params: query
  });
}
export function getTruckCostFee(query) {
  return request({
    baseURL: baseUrl,
    url: basePath + '/staff-cost-settle/{truckSettleId}',
    method: 'get',
    params: query
  });
}
export function getTruckCostFeeBySchedule(query) {
  return request({
    baseURL: baseUrl,
    url: basePath + '/truck-cost-settle/schedule/{scheduleId}/{truckLicensePlate}',
    method: 'get',
    params: query
  });
}
export function getTruckCostFeeByClue(query) {
  return request({
    baseURL: baseUrl,
    url: basePath + '/truck-cost-settle/clue/{clueId}/{truckLicensePlate}',
    method: 'get',
    params: query
  });
}
export function listTruckCostFeeByProject(query) {
  return request({
    baseURL: baseUrl,
    url: basePath + '/truck-cost-settle/project/{projectId}',
    method: 'get',
    params: query
  });
}
export function listTruckCostFeeByClue(query) {
  return request({
    baseURL: baseUrl,
    url: basePath + '/clue/{clueId}/list-truck-cost-settle',
    method: 'get',
    params: query
  });
}
export function saveStaffCostFee(data) {
  return request({
    baseURL: baseUrl,
    url: basePath + '/staff-cost-settle/save',
    method: 'post',
    data: {
      data: data
    }
  });
}
export function saveTruckCostFee(data) {
  return request({
    baseURL: baseUrl,
    url: basePath + '/truck-cost-settle/save',
    method: 'post',
    data: {
      data: data
    }
  });
}
export function saveContractCostFee(data) {
  return request({
    baseURL: baseUrl,
    url: basePath + '/contract-cost-settle/save',
    method: 'post',
    data: {
      data: data
    }
  });
}
export function deleteStaffCostFee(query) {
  return request({
    baseURL: baseUrl,
    url: basePath + '/staff-cost-settle/{staffSettleId}',
    method: 'delete',
    params: query
  });
}
export function deleteTruckCostFee(query) {
  return request({
    baseURL: baseUrl,
    url: basePath + '/truck-cost-settle/{truckSettleId}',
    method: 'delete',
    params: query
  });
}
export function deleteContractCostFee(query) {
  return request({
    baseURL: baseUrl,
    url: basePath + '/contract-cost-settle/{contractSettleId}',
    method: 'delete',
    params: query
  });
}
export function listStaffExpenseProject(query) {
  return request({
    baseURL: baseUrl,
    url: basePath + '/{staffId}/list-staff-expense-project',
    method: 'get',
    params: query
  });
}