var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.props.compType === "upload",
          expression: "props.compType === 'upload'"
        }
      ]
    },
    [
      _c(
        "el-form-item",
        { attrs: { label: "ID" } },
        [
          _c(
            "el-tooltip",
            {
              staticClass: "item",
              attrs: {
                effect: "dark",
                content: "请注意,ID的修改可能会导致该组件相关事件失效！",
                placement: "left"
              }
            },
            [
              _c("el-input", {
                staticClass: "input",
                on: { change: _vm.handlerChangeId },
                model: {
                  value: _vm.props.id,
                  callback: function($$v) {
                    _vm.$set(_vm.props, "id", $$v)
                  },
                  expression: "props.id"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "标题" } },
        [
          _c("el-input", {
            staticClass: "input",
            model: {
              value: _vm.props.label,
              callback: function($$v) {
                _vm.$set(_vm.props, "label", $$v)
              },
              expression: "props.label"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "宽度%" } },
        [
          _c("el-input-number", {
            attrs: { min: 1, max: 100 },
            model: {
              value: _vm.props.width,
              callback: function($$v) {
                _vm.$set(_vm.props, "width", $$v)
              },
              expression: "props.width"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "表单栅格" } },
        [
          _c("el-slider", {
            staticClass: "input",
            attrs: { max: 24, min: 1, marks: { 12: "" } },
            model: {
              value: _vm.props.span,
              callback: function($$v) {
                _vm.$set(_vm.props, "span", $$v)
              },
              expression: "props.span"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "栅格间隔" } },
        [
          _c("el-input-number", {
            attrs: { min: 0 },
            model: {
              value: _vm.props.gutter,
              callback: function($$v) {
                _vm.$set(_vm.props, "gutter", $$v)
              },
              expression: "props.gutter"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "标签宽度" } },
        [
          _c("el-input-number", {
            attrs: { min: 1, max: 200 },
            model: {
              value: _vm.props.labelWidth,
              callback: function($$v) {
                _vm.$set(_vm.props, "labelWidth", $$v)
              },
              expression: "props.labelWidth"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "显示标签" } },
        [
          _c("el-switch", {
            on: { change: _vm.handlerChangeLabel },
            model: {
              value: _vm.props.showLabel,
              callback: function($$v) {
                _vm.$set(_vm.props, "showLabel", $$v)
              },
              expression: "props.showLabel"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "大小(MB)" } },
        [
          _c("el-input-number", {
            attrs: { min: 1, max: 10000, step: 10 },
            model: {
              value: _vm.props.fileSize,
              callback: function($$v) {
                _vm.$set(_vm.props, "fileSize", $$v)
              },
              expression: "props.fileSize"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "允许文件类型" } },
        [
          _c("el-input", {
            model: {
              value: _vm.props.accept,
              callback: function($$v) {
                _vm.$set(_vm.props, "accept", $$v)
              },
              expression: "props.accept"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "必填" } },
        [
          _c("el-switch", {
            model: {
              value: _vm.props.required,
              callback: function($$v) {
                _vm.$set(_vm.props, "required", $$v)
              },
              expression: "props.required"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "多文件上传" } },
        [
          _c("el-switch", {
            model: {
              value: _vm.props.multiple,
              callback: function($$v) {
                _vm.$set(_vm.props, "multiple", $$v)
              },
              expression: "props.multiple"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "显示文件列表" } },
        [
          _c("el-switch", {
            model: {
              value: _vm.props["show-file-list"],
              callback: function($$v) {
                _vm.$set(_vm.props, "show-file-list", $$v)
              },
              expression: "props['show-file-list']"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "显示类型" } },
        [
          _c(
            "el-radio-group",
            {
              on: { input: _vm.handleListTypeChange },
              model: {
                value: _vm.props["list-type"],
                callback: function($$v) {
                  _vm.$set(_vm.props, "list-type", $$v)
                },
                expression: "props['list-type']"
              }
            },
            [
              _c("el-radio-button", { attrs: { label: "text" } }, [
                _vm._v("普通")
              ]),
              _c("el-radio-button", { attrs: { label: "picture-card" } }, [
                _vm._v("图片")
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "按钮文字" } },
        [
          _c("el-input", {
            model: {
              value: _vm.props.buttonText,
              callback: function($$v) {
                _vm.$set(_vm.props, "buttonText", $$v)
              },
              expression: "props.buttonText"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "显示提示" } },
        [
          _c("el-switch", {
            model: {
              value: _vm.props.showTip,
              callback: function($$v) {
                _vm.$set(_vm.props, "showTip", $$v)
              },
              expression: "props.showTip"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.props.showTip,
              expression: "props.showTip"
            }
          ],
          attrs: { label: "提示内容" }
        },
        [
          _c("el-input", {
            model: {
              value: _vm.props.tips,
              callback: function($$v) {
                _vm.$set(_vm.props, "tips", $$v)
              },
              expression: "props.tips"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "附件类型" } },
        [
          _c(
            "el-select",
            {
              attrs: { placeholder: "请选择" },
              on: { change: _vm.handleAttachmentFileType },
              model: {
                value: _vm.attachmentFileType,
                callback: function($$v) {
                  _vm.attachmentFileType = $$v
                },
                expression: "attachmentFileType"
              }
            },
            _vm._l(_vm.attachmentFileTypeList, function(item) {
              return _c("el-option", {
                key: item.value,
                attrs: { label: item.label, value: item.value }
              })
            }),
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }