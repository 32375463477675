import { render, staticRenderFns } from "./select.vue?vue&type=template&id=38a4eda8&scoped=true&"
import script from "./select.vue?vue&type=script&lang=js&"
export * from "./select.vue?vue&type=script&lang=js&"
import style0 from "./select.vue?vue&type=style&index=0&id=38a4eda8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38a4eda8",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/jenkins_home/workspace/ops.dfwl.plus/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('38a4eda8')) {
      api.createRecord('38a4eda8', component.options)
    } else {
      api.reload('38a4eda8', component.options)
    }
    module.hot.accept("./select.vue?vue&type=template&id=38a4eda8&scoped=true&", function () {
      api.rerender('38a4eda8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/FormDesigner/custom/configs/select.vue"
export default component.exports