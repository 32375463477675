import { getSimpleId } from "../utils/IdGenerate";
import constant from "../utils/constants";
import { jsonClone } from "../utils";
var tdItemObj = {
  id: '',
  col: 1,
  row: 1,
  hide: false,
  compType: 'tdItem',
  style: {
    background: constant.defaultTdBackgroundColor,
    width: 35
  },
  columns: []
};
export function getTdItem() {
  var tdItem = cloneObj(tdItemObj);
  tdItem.id = getSimpleId();
  return tdItem;
}
export function getTitleTdItem() {
  var tdItem = cloneObj(tdItemObj);
  tdItem.style.background = constant.defaultTitleTdBackgroundColor;
  tdItem.style.width = 15;
  tdItem.id = getSimpleId();
  return tdItem;
}

function cloneObj(source) {
  var target = jsonClone(source);
  target.id = getSimpleId();
  return target;
}

export function getTrItem() {
  var trItem = [getTitleTdItem(), getTdItem(), getTitleTdItem(), getTdItem()];
  return trItem;
}
export function getDefaultTrs() {
  var trs = [getTrItem(), getTrItem()];
  return trs;
}