import "core-js/modules/es6.array.find-index";
import "core-js/modules/es6.regexp.split";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.number.constructor";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var splitKey = ';';
export default {
  name: 'FancyDialogList',
  props: {
    value: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: '请选择'
    },
    multi: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    showIndex: {
      // 显示序号
      type: Boolean,
      default: false
    },
    action: {
      type: String,
      default: ''
    },
    height: {
      type: Number,
      default: 600
    },
    colConf: {
      type: String,
      default: ''
    },
    dval: {
      type: String,
      default: 'id'
    },
    dlabel: {
      type: String,
      default: 'name'
    }
  },
  data: function data() {
    return {
      currentRow: null,
      dialogValue: '',
      dialogVisible: false,
      gridData: []
    };
  },
  computed: {
    jsonColConf: function jsonColConf() {
      return JSON.parse(this.colConf);
    },
    selectName: function selectName() {
      var _this = this;

      if (this.currentRow == null) {
        return '';
      }

      if (this.multi) {
        var names = '';
        this.currentRow.forEach(function (element) {
          names = names + splitKey + element[_this.dlabel];
        });

        if (names.length > 0) {
          names = names.substring(1);
        }

        return names;
      } else {
        return this.currentRow[this.dlabel];
      }
    },
    selectId: function selectId() {
      var _this2 = this;

      if (this.currentRow == null) {
        return '';
      }

      if (this.multi) {
        var ids = '';
        this.currentRow.forEach(function (element) {
          ids = ids + splitKey + element[_this2.dval];
        });

        if (ids.length > 0) {
          ids = ids.substring(1);
        }

        return ids;
      } else {
        return this.currentRow[this.dval];
      }
    },
    filterGridData: function filterGridData() {
      return this.gridData;
    }
  },
  mounted: function mounted() {
    var _this3 = this;

    this.$nextTick(function () {
      _this3.$axios.get(_this3.action).then(function (res) {
        _this3.gridData = [];
        _this3.gridData = _this3.gridData.concat(res.data.list);

        if (_this3.value !== '' && _this3.dialogValue === '') {
          if (_this3.multi) {
            var ids = _this3.value.split(splitKey);

            _this3.currentRow = [];
            ids.forEach(function (e) {
              var index = _this3.gridData.findIndex(function (element) {
                return element[_this3.dval] + '' === e;
              });

              if (index > 0) {
                var row = _this3.gridData[index];

                _this3.currentRow.push(row);
              }
            });
            _this3.dialogValue = _this3.selectName;
          } else {
            var index = _this3.gridData.findIndex(function (element) {
              return element[_this3.dval] === _this3.value;
            });

            if (index > 0) {
              var row = _this3.gridData[index];
              _this3.dialogValue = row[_this3.dlabel];
            }
          }
        }
      });
    });
  },
  methods: {
    handlerShowDialog: function handlerShowDialog() {
      if (this.disabled) return;
      this.dialogVisible = true;
    },
    handleClose: function handleClose() {},
    tableRowClassName: function tableRowClassName(v) {
      if (v.rowIndex % 2 === 1) {
        return 'odd-row';
      }

      return '';
    },
    handleCurrentChange: function handleCurrentChange(val) {
      if (!this.multi) {
        this.currentRow = val;
      }
    },
    handlerRowDoubleClick: function handlerRowDoubleClick(val) {
      if (!this.multi) {
        this.currentRow = val;
        this.handlerSelect();
      }
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.currentRow = val;
    },
    handlerSelect: function handlerSelect() {
      this.dialogVisible = false;
      var dialogVal = '';
      var dialogId = '';
      dialogVal = this.selectName;
      dialogId = this.selectId;
      this.dialogValue = dialogVal;
      this.$emit('input', dialogId + '');
    },
    handlerHideDialog: function handlerHideDialog() {
      this.dialogVisible = false;
      this.dialogValue = '';
      this.$emit('input', '');
    },
    setDialogValue: function setDialogValue() {
      var _this4 = this;

      if (this.multi) {
        var ids = this.value.split(splitKey);
        this.currentRow = [];
        ids.forEach(function (e) {
          var index = _this4.gridData.findIndex(function (element) {
            return element[_this4.dval] === e;
          });

          if (index > 0) {
            var row = _this4.gridData[index];

            _this4.$refs.dataTable.toggleRowSelection(row);
          }
        });
      } else {
        var index = this.gridData.findIndex(function (element) {
          return element[_this4.dval] === _this4.value;
        });
        var row = this.gridData[index];
        this.$refs.dataTable.setCurrentRow(row);
      }
    },
    show: function show() {
      var _this5 = this;

      this.$nextTick(function () {
        _this5.setDialogValue();
      });
    }
  }
};