import { input } from "./items/input";
import { select } from "./items/select";
import { selectDepartment } from "./items/selectDepartment";
import { selectStaff } from "./items/selectStaff";
import { radio } from "./items/radio";
import { checkbox } from "./items/checkbox";
import { Switch } from "./items/Switch";
import { inputNumber } from "./items/inputNumber";
import { textarea } from "./items/textarea";
import { slider } from "./items/slider";
import { rate } from "./items/rate";
import { date } from "./items/date";
import { time } from "./items/time";
import { editor } from "./items/editor";
import { colorPicker } from "./items/colorPicker";
import { cascader } from "./items/cascader";
import { cascaderRegion } from "./items/cascaderRegion";
import { upload } from "./items/upload";
import { dialogList } from "./items/dialogList";
import { dynamicTable } from "./items/dynamicTable";
import { row } from "./items/row"; // import { table } from './items/table'
// import { button } from './items/button'

import { divider } from "./items/divider"; // import { alert } from './items/alert'

import { link } from "./items/link";
import { text } from "./items/text";
import { barCode } from "./items/barCode";
export var formItems = [input, inputNumber, textarea, radio, checkbox, select, selectDepartment, selectStaff, cascader, cascaderRegion, Switch, slider, rate, date, time, editor, colorPicker, upload, barCode, dialogList, dynamicTable]; // export const assistFormItems = [button, divider, alert, link, text]
// export const layoutFormItems = [row, table]

export var assistFormItems = [divider, link, text];
export var layoutFormItems = [row];