var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "center-board" },
    [
      _c(
        "div",
        { staticClass: "action-bar" },
        [
          _c(
            "el-button",
            {
              attrs: { icon: "el-icon-view", type: "text" },
              on: { click: _vm.preview }
            },
            [_vm._v(" 预览 ")]
          ),
          _c(
            "el-button",
            {
              attrs: { icon: "el-icon-tickets", type: "text" },
              on: { click: _vm.viewJSON }
            },
            [_vm._v(" JSON ")]
          ),
          _c(
            "el-button",
            {
              attrs: { icon: "el-icon-s-tools", type: "text" },
              on: { click: _vm.setting }
            },
            [_vm._v(" 表单设置 ")]
          ),
          _c(
            "el-button",
            {
              staticClass: "delete-btn",
              attrs: { icon: "el-icon-delete-solid", type: "text" },
              on: { click: _vm.clear }
            },
            [_vm._v(" 清空 ")]
          )
        ],
        1
      ),
      _c(
        "el-scrollbar",
        { staticClass: "center-scrollbar" },
        [
          _c(
            "el-row",
            {
              staticClass: "center-board-row",
              attrs: { gutter: _vm.formConf.gutter }
            },
            [
              _c(
                "el-form",
                {
                  attrs: {
                    size: _vm.formConf.size,
                    "label-position": _vm.formConf.labelPosition,
                    disabled: _vm.formConf.disabled,
                    "label-width": _vm.formConf.labelWidth + "px"
                  }
                },
                [
                  _c(
                    "draggable",
                    {
                      staticClass: "drawing-board",
                      attrs: {
                        list: _vm.list,
                        animation: 100,
                        group: "componentsGroup",
                        draggable: ".drawing-item"
                      }
                    },
                    _vm._l(_vm.list, function(element, index) {
                      return _c("design-item", {
                        key: index,
                        attrs: {
                          model: element,
                          "active-item": _vm.activeItem
                        },
                        on: {
                          rowItemRollBack: _vm.handlerRollBack,
                          onActiveItemChange: _vm.handlerActiveItemChange,
                          copyItem: _vm.handlerItemCopy,
                          deleteItem: _vm.handlerItemDelete
                        }
                      })
                    }),
                    1
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.infoShow,
                          expression: "infoShow"
                        }
                      ],
                      staticClass: "empty-info"
                    },
                    [
                      _c("el-empty", {
                        attrs: { description: "从左侧拖拽添加控件" }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("config-panel", {
        attrs: { "active-item": _vm.activeItem, "item-list": _vm.list }
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.formConfVisible,
            width: "50%",
            top: "30px",
            center: true
          },
          on: {
            "update:visible": function($event) {
              _vm.formConfVisible = $event
            }
          }
        },
        [
          _c(
            "el-tabs",
            {
              model: {
                value: _vm.activeName,
                callback: function($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName"
              }
            },
            [
              _c(
                "el-tab-pane",
                { attrs: { label: "表单配置", name: "formConf" } },
                [
                  _c(
                    "el-form",
                    {
                      ref: "formConf",
                      attrs: { model: _vm.formConf, "label-width": "100px" }
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "表单名" } },
                        [
                          _c("el-input", {
                            staticClass: "input",
                            model: {
                              value: _vm.formConf.formRef,
                              callback: function($$v) {
                                _vm.$set(_vm.formConf, "formRef", $$v)
                              },
                              expression: "formConf.formRef"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "表单模型" } },
                        [
                          _c("el-input", {
                            staticClass: "input",
                            model: {
                              value: _vm.formConf.formModel,
                              callback: function($$v) {
                                _vm.$set(_vm.formConf, "formModel", $$v)
                              },
                              expression: "formConf.formModel"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "校验模型" } },
                        [
                          _c("el-input", {
                            staticClass: "input",
                            model: {
                              value: _vm.formConf.formRules,
                              callback: function($$v) {
                                _vm.$set(_vm.formConf, "formRules", $$v)
                              },
                              expression: "formConf.formRules"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "表单尺寸" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.formConf.size,
                                callback: function($$v) {
                                  _vm.$set(_vm.formConf, "size", $$v)
                                },
                                expression: "formConf.size"
                              }
                            },
                            [
                              _c(
                                "el-radio-button",
                                { attrs: { label: "medium" } },
                                [_vm._v("中等")]
                              ),
                              _c(
                                "el-radio-button",
                                { attrs: { label: "small" } },
                                [_vm._v("较小")]
                              ),
                              _c(
                                "el-radio-button",
                                { attrs: { label: "mini" } },
                                [_vm._v("迷你")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "标签对齐" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.formConf.labelPosition,
                                callback: function($$v) {
                                  _vm.$set(_vm.formConf, "labelPosition", $$v)
                                },
                                expression: "formConf.labelPosition"
                              }
                            },
                            [
                              _c(
                                "el-radio-button",
                                { attrs: { label: "right" } },
                                [_vm._v("右对齐")]
                              ),
                              _c(
                                "el-radio-button",
                                { attrs: { label: "left" } },
                                [_vm._v("左对齐")]
                              ),
                              _c(
                                "el-radio-button",
                                { attrs: { label: "top" } },
                                [_vm._v("顶部对齐")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "标签宽度" } },
                        [
                          _c("el-input-number", {
                            attrs: { min: 60, max: 140 },
                            model: {
                              value: _vm.formConf.labelWidth,
                              callback: function($$v) {
                                _vm.$set(_vm.formConf, "labelWidth", $$v)
                              },
                              expression: "formConf.labelWidth"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "栅格间隔" } },
                        [
                          _c("el-input-number", {
                            attrs: { min: 0, max: 30 },
                            model: {
                              value: _vm.formConf.gutter,
                              callback: function($$v) {
                                _vm.$set(_vm.formConf, "gutter", $$v)
                              },
                              expression: "formConf.gutter"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "动态表格支持组件高亮显示" } },
                        [
                          _c("el-switch", {
                            model: {
                              value: _vm.formConfig.dynamicTableAllowed,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.formConfig,
                                  "dynamicTableAllowed",
                                  $$v
                                )
                              },
                              expression: "formConfig.dynamicTableAllowed"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "禁用表单" } },
                        [
                          _c("el-switch", {
                            model: {
                              value: _vm.formConf.disabled,
                              callback: function($$v) {
                                _vm.$set(_vm.formConf, "disabled", $$v)
                              },
                              expression: "formConf.disabled"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handlerSaveFormConf }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.previewVisible, width: "70%", title: "预览" },
          on: {
            "update:visible": function($event) {
              _vm.previewVisible = $event
            }
          }
        },
        [
          _vm.previewVisible
            ? _c("preview", {
                attrs: { "item-list": _vm.itemList, "form-conf": _vm.formConf }
              })
            : _vm._e()
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.JSONVisible,
            width: "70%",
            title: "JSON",
            center: "",
            "close-on-click-modal": false
          },
          on: {
            "update:visible": function($event) {
              _vm.JSONVisible = $event
            }
          }
        },
        [
          _c("codemirror", {
            attrs: { options: _vm.options },
            model: {
              value: _vm.viewCode,
              callback: function($$v) {
                _vm.viewCode = $$v
              },
              expression: "viewCode"
            }
          }),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.handlerSetJson()
                    }
                  }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }