import "core-js/modules/es6.object.keys";
import "core-js/modules/web.dom.iterable";
// 初始化data里面的数据（1、默认为空，2、之前存在的数据。。）
var itemDatas = {};
export function datas() {
  var _this = this;

  itemDatas = {}; // const self = this

  this.itemList.forEach(function (val) {
    if (val.layout === 'rowItem') {
      // row布局
      dataResolveRowItem(val); // 解析row布局
    } else if (val.layout === 'dynamicItem') {
      // 动态表单布局
      var obj = dataResolveDynamicItem(val);
      var array = [];
      array.push(obj);
      itemDatas[val.id] = array;
    } else if (val.layout === 'tableItem') {
      // 表格布局
      dataResolveTableItem(val);
    } else {
      // 表单
      dataResolveColItem(val);
    }
  });
  Object.keys(itemDatas).forEach(function (key) {
    _this.$set(_this.form, key, itemDatas[key]);
  });
}

function dataResolveRowItem(val) {
  var columns = val.columns;
  columns.forEach(function (v) {
    v.list.forEach(function (item) {
      if (item.layout === 'dynamicItem') {
        var obj = dataResolveDynamicItem(item);
        var array = [];
        array.push(obj);
        itemDatas[item.id] = array;
      } else {
        dataResolveColItem(item);
      }
    });
  });
}

function dataResolveDynamicItem(val) {
  var columns = val.columns;
  var obj = {};
  columns.forEach(function (v) {
    var key = v.id;
    obj[key] = v.value;
  });
  return obj;
}
/*
遍历表格实现组件渲染
*/


function dataResolveTableItem(val) {
  val.layoutArray.map(function (tr) {
    tr.map(function (td) {
      td.columns.map(function (item) {
        return dataResolveColItem(item);
      });
    });
  });
}

function dataResolveColItem(val) {
  itemDatas[val.id] = val.value;
}

export function fillDatas(jsonValue) {
  var self = this;
  Object.keys(jsonValue).forEach(function (key) {
    if (typeof self.form[key] === 'string' || typeof self.form[key] === 'number') {
      self.$set(self.form, key, jsonValue[key]);
    } else if (self.form[key] instanceof Array) {
      // 有可能是多选或者是明细表
      // 判断是否数组中为对象
      if (jsonValue[key].length > 0) {
        var firsValue = jsonValue[key][0]; // 获取第一个对象判断是什么类型

        if (typeof firsValue === 'string' || typeof firsValue === 'number') {
          self.$set(self.form, key, jsonValue[key]);
        } else {
          // 明细表数组
          var arrayData = jsonValue[key]; // self.form[key] = [];

          arrayData.forEach(function (v, index) {
            var newV = JSON.parse(JSON.stringify(v));
            self.$set(self.form[key], index, newV);
          });
        }
      }
    }
  }); // console.log(this.form);
}
export function addRow(element) {
  var obj = dataResolveDynamicItem(element);
  this.form[element.id].push(obj);
}
export function deleteRow(scope, element) {
  this.form[element.id].splice(scope.$index, 1);
}
export function batchDeleteRow(indexs, element) {
  for (var i = 0; i < indexs.length; i++) {
    var index = indexs[i];
    this.form[element.id].splice(index, 1);
  }
}