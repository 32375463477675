var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "edit_container",
      class: { warn_edit_container: _vm.warnTextLength }
    },
    [
      _c("quill-editor", {
        ref: "myQuillEditor",
        attrs: { options: _vm.editorOption },
        on: {
          blur: function($event) {
            return _vm.onEditorBlur($event)
          },
          focus: function($event) {
            return _vm.onEditorFocus($event)
          },
          change: function($event) {
            return _vm.onEditorChange($event)
          }
        },
        model: {
          value: _vm.content,
          callback: function($$v) {
            _vm.content = $$v
          },
          expression: "content"
        }
      }),
      _c(
        "div",
        {
          staticClass: "text_number_tips",
          class: { warn_text_number_tips: _vm.warnTextLength }
        },
        [_vm._v(_vm._s(_vm.currentLength) + "/" + _vm._s(_vm.maxTextLength))]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }