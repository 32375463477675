import request from '@/utils/request';
var basePath = '/api/v1/user-base/digital-asset-category';
var baseUrl = process.env.VUE_APP_USER_API;
export function listCategory(query) {
  return request({
    baseURL: baseUrl,
    url: basePath + '/list',
    method: 'get',
    params: query
  });
}
export function listCategoryTree(query) {
  return request({
    baseURL: baseUrl,
    url: basePath + '/list-tree',
    method: 'get',
    params: query
  });
}
export function listCategoryLevel1() {
  return request({
    baseURL: baseUrl,
    url: basePath + '/list-all-level1',
    method: 'get'
  });
}
export function getCategory(query) {
  return request({
    baseURL: baseUrl,
    url: basePath + '/{categoryId}',
    method: 'get',
    params: query
  });
}
export function saveCategory(data) {
  return request({
    baseURL: baseUrl,
    url: basePath + '/save',
    method: 'post',
    data: {
      data: data
    }
  });
}
export function publishCategory(data) {
  return request({
    baseURL: baseUrl,
    url: basePath + '/publish',
    method: 'post',
    data: {
      data: data
    }
  });
}
export function batchDisableCategory(data) {
  return request({
    baseURL: baseUrl,
    url: basePath + '/batch-disable',
    method: 'post',
    data: {
      data: data
    }
  });
}
export function enableCategory(data) {
  return request({
    baseURL: baseUrl,
    url: basePath + '/enable',
    method: 'post',
    data: {
      data: data
    }
  });
}
export function deleteCategory(query) {
  return request({
    baseURL: baseUrl,
    url: basePath + '/{categoryId}',
    method: 'delete',
    params: query
  });
}
export function getCategoryPermission(query) {
  return request({
    baseURL: baseUrl,
    url: basePath + '/permission/{categoryId}',
    method: 'get',
    params: query
  });
}
export function getStaffCategoryPermission(query) {
  return request({
    baseURL: baseUrl,
    url: basePath + '/staff-permission/{staffId}',
    method: 'get',
    params: query
  });
}
export function updateCategoryPermission(data) {
  return request({
    baseURL: baseUrl,
    url: basePath + '/permission',
    method: 'post',
    data: {
      data: data
    }
  });
}
export function transferCategory(data) {
  return request({
    baseURL: baseUrl,
    url: basePath + '/transfer',
    method: 'post',
    data: {
      data: data
    }
  });
}
export function getExampleOptions() {
  return request({
    baseURL: baseUrl,
    url: basePath + '/example/options',
    method: 'get'
  });
}
export function getExampleCascaderOptions() {
  return request({
    baseURL: baseUrl,
    url: basePath + '/example/cascader-options',
    method: 'get'
  });
}