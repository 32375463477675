//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { changeId } from "../mixin";
import iconDialog from "../../iconDialog";
var defaultRules = {
  'phone': {
    'rule': '0?(13|14|15|17|18|19)[0-9]{9}',
    'msg': '您输入的电话号码不符合规则'
  },
  'email': {
    'rule': '\\w[-\\w.+]*@([A-Za-z0-9][-A-Za-z0-9]+\\.)+[A-Za-z]{2,14}',
    'msg': '您输入的邮件地址不符合规则'
  },
  'number': {
    'rule': '^[0-9]*$',
    'msg': '您输入的内容不符合纯数字规则'
  },
  'idcard': {
    'rule': '\\d{17}[\\d|x]|\\d{15}',
    'msg': '您输入的身份证号码不符合规则'
  }
};
/**
 * input的配置项
 */

export default {
  name: 'InputConfig',
  components: {
    iconDialog: iconDialog
  },
  mixins: [changeId],
  // eslint-disable-next-line vue/require-prop-types
  props: ['props', 'getFormId'],
  data: function data() {
    return {
      val: '',
      iconDialogVisible_suffix: false,
      iconDialogVisible_prefix: false
    };
  },
  watch: {},
  mounted: function mounted() {},
  methods: {
    handlerChangeLabel: function handlerChangeLabel(val) {
      this.props.labelWidth = val ? '80' : '1';
    },
    handlerSuffixSelectIcon: function handlerSuffixSelectIcon() {
      this.iconDialogVisible_suffix = true;
    },
    handlerPrefixSelectIcon: function handlerPrefixSelectIcon() {
      this.iconDialogVisible_prefix = true;
    },
    addRuleItem: function addRuleItem() {
      this.props.rules.push({
        rule: '',
        msg: ''
      });
    },
    handlerChangeRulesType: function handlerChangeRulesType(val) {
      var obj = defaultRules[val];
      this.props.rules.push({
        rule: obj.rule,
        msg: obj.msg
      });
    }
  }
};