import "core-js/modules/web.dom.iterable";
export default {
  slot: function slot(h, conf) {
    var list = [];
    conf.options.forEach(function (item) {
      list.push(h("el-option", {
        "attrs": {
          "label": item.label,
          "value": item.value,
          "disabled": item.disabled
        }
      }));
    });
    return list;
  }
};