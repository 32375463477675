var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        "close-on-click-modal": false,
        visible: _vm.visible,
        width: "600px"
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        }
      }
    },
    [
      _c("div", [_vm._v("当前公司: " + _vm._s(_vm.userInfo.orgName))]),
      _c(
        "div",
        { staticClass: "switch-panel" },
        [
          _c(
            "span",
            { staticStyle: { "margin-left": "20px", "margin-right": "10px" } },
            [_vm._v("切换单位")]
          ),
          _c(
            "el-select",
            {
              attrs: { placeholder: "请选择要切换的单位" },
              model: {
                value: _vm.selectedOrgId,
                callback: function($$v) {
                  _vm.selectedOrgId = $$v
                },
                expression: "selectedOrgId"
              }
            },
            _vm._l(_vm.orgList, function(item) {
              return _c("el-option", {
                key: item.orgId,
                attrs: {
                  label: item.name,
                  value: item.orgId,
                  disabled: item.disabled
                }
              })
            }),
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "btn-panel" },
        [
          _c(
            "el-button",
            {
              staticClass: "btn",
              attrs: {
                type: "success",
                round: "",
                disabled: !_vm.selectedOrgId
              },
              on: { click: _vm.switchOrgAsync }
            },
            [_vm._v("切换")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }