import "core-js/modules/es6.number.constructor";
export default {
  name: 'MenuItem',
  functional: true,
  props: {
    icon: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    messageTotal: {
      type: Number,
      default: 0
    }
  },
  render: function render(h, context) {
    var _context$props = context.props,
        icon = _context$props.icon,
        title = _context$props.title,
        messageTotal = _context$props.messageTotal;
    var vnodes = [];

    if (icon) {
      vnodes.push(h("svg-icon", {
        "attrs": {
          "icon-class": icon
        }
      }));
    }

    if (title) {
      if (title === '日常事项') {
        vnodes.push(h("div", {
          "slot": 'title',
          "style": 'display: inline-block'
        }, [h("span", [title]), h("div", {
          "class": 'el-badge mark'
        }, [h("sup", {
          "class": 'el-badge__content'
        }, [messageTotal])])]));
      } else {
        vnodes.push(h("span", {
          "slot": 'title'
        }, [title]));
      }
    }

    return vnodes;
  }
};