var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.props.compType === "barCode",
          expression: "props.compType === 'barCode'"
        }
      ]
    },
    [
      _c(
        "el-form-item",
        { attrs: { label: "ID" } },
        [
          _c(
            "el-tooltip",
            {
              staticClass: "item",
              attrs: {
                effect: "dark",
                content: "请注意,ID的修改可能会导致该组件相关事件失效！",
                placement: "left"
              }
            },
            [
              _c("el-input", {
                staticClass: "input",
                model: {
                  value: _vm.props.id,
                  callback: function($$v) {
                    _vm.$set(_vm.props, "id", $$v)
                  },
                  expression: "props.id"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "显示标签" } },
        [
          _c("el-switch", {
            on: { change: _vm.handlerShowLabel },
            model: {
              value: _vm.props.showLabel,
              callback: function($$v) {
                _vm.$set(_vm.props, "showLabel", $$v)
              },
              expression: "props.showLabel"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.props.showLabel,
              expression: "props.showLabel"
            }
          ],
          attrs: { label: "标签文字" }
        },
        [
          _c("el-input", {
            staticClass: "input",
            model: {
              value: _vm.props.label,
              callback: function($$v) {
                _vm.$set(_vm.props, "label", $$v)
              },
              expression: "props.label"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.props.showLabel,
              expression: "props.showLabel"
            }
          ],
          attrs: { label: "标签长度" }
        },
        [
          _c("el-input-number", {
            attrs: { min: 1, max: 200 },
            model: {
              value: _vm.props.labelWidth,
              callback: function($$v) {
                _vm.$set(_vm.props, "labelWidth", $$v)
              },
              expression: "props.labelWidth"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.props.span,
              expression: "props.span"
            }
          ],
          attrs: { label: "占用列数" }
        },
        [
          _c("el-input-number", {
            attrs: { min: 1, max: 24 },
            model: {
              value: _vm.props.span,
              callback: function($$v) {
                _vm.$set(_vm.props, "span", $$v)
              },
              expression: "props.span"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "条码值" } },
        [
          _c("el-input", {
            model: {
              value: _vm.props.value,
              callback: function($$v) {
                _vm.$set(_vm.props, "value", $$v)
              },
              expression: "props.value"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.props.lineColor,
              expression: "props.lineColor"
            }
          ],
          attrs: { label: "条码颜色" }
        },
        [
          _c("el-color-picker", {
            on: { change: _vm.handlerChangeBarCode },
            model: {
              value: _vm.props.lineColor,
              callback: function($$v) {
                _vm.$set(_vm.props, "lineColor", $$v)
              },
              expression: "props.lineColor"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.props.background,
              expression: "props.background"
            }
          ],
          attrs: { label: "背景颜色" }
        },
        [
          _c("el-color-picker", {
            on: { change: _vm.handlerChangeBackground },
            model: {
              value: _vm.props.background,
              callback: function($$v) {
                _vm.$set(_vm.props, "background", $$v)
              },
              expression: "props.background"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "条码高度" } },
        [
          _c("el-input-number", {
            attrs: { min: 10, max: 100, step: 1 },
            model: {
              value: _vm.props.height,
              callback: function($$v) {
                _vm.$set(_vm.props, "height", $$v)
              },
              expression: "props.height"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "显示值" } },
        [
          _c("el-switch", {
            model: {
              value: _vm.props.displayValue,
              callback: function($$v) {
                _vm.$set(_vm.props, "displayValue", $$v)
              },
              expression: "props.displayValue"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }