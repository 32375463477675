//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { changeId } from "../mixin";
export default {
  name: 'EditorConfig',
  mixins: [changeId],
  props: {
    // eslint-disable-next-line vue/require-prop-types,vue/require-default-prop
    props: {}
  },
  methods: {
    handlerShowLabel: function handlerShowLabel(val) {
      if (val) {
        this.props.labelWidth = 80;
      } else {
        this.props.labelWidth = 0;
      }
    }
  }
};