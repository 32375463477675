//
//
//
//
//
//
//
//
//
//

/**
 * input的配置项
 */
export default {
  name: 'TdItemConfig',
  // eslint-disable-next-line vue/require-prop-types
  props: ['props'],
  data: function data() {
    return {
      predefineColors: ['#F6F6F6']
    };
  },
  methods: {}
};