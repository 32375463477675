import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.regexp.replace";
import "core-js/modules/web.dom.iterable";

var slots = require.context('./', false, /\.js$/);

var componentChild = {};
slots.keys().forEach(function (obj) {
  var tag = obj.replace('./', '').replace('.js', '');

  if (tag !== 'index') {
    componentChild[tag] = slots(obj).default;
  }
});
export default function childrenItem(h, confClone) {
  var children = [];
  var childObjs = componentChild[confClone.ele];

  if (childObjs && childObjs.slot) {
    Object.keys(childObjs).forEach(function (key) {
      var childFunc = childObjs[key];
      children.push(childFunc(h, confClone));
    });
  }

  return children;
}