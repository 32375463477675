import request from '@/utils/request';
var basePath = '/api/v1/finance-base/finance-invoice-tax-config';
var baseUrl = process.env.VUE_APP_FINANCE_API;
export function listFinanceInvoiceTaxConfig(query) {
  return request({
    baseURL: baseUrl,
    url: basePath + '/list',
    method: 'get',
    params: query
  });
}
export function getFinanceInvoiceTaxConfig(query) {
  return request({
    baseURL: baseUrl,
    url: basePath + '/{configId}',
    method: 'get',
    params: query
  });
}
export function saveFinanceInvoiceTaxConfig(data) {
  return request({
    baseURL: baseUrl,
    url: basePath + '/save',
    method: 'post',
    data: {
      data: data
    }
  });
}
export function deleteFinanceInvoiceTaxConfig(query) {
  return request({
    baseURL: baseUrl,
    url: basePath + '/{configId}',
    method: 'delete',
    params: query
  });
}