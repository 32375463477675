//
//
//
//
//
//
//
//
export default {
  name: 'DynamicTable',
  components: {},
  // eslint-disable-next-line vue/require-prop-types
  props: ['conf', 'activeItem'],
  data: function data() {
    return {
      test1: '',
      list: []
    };
  },
  methods: {}
};