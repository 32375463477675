import _typeof from "/var/jenkins_home/workspace/ops.dfwl.plus/node_modules/@babel/runtime-corejs2/helpers/esm/typeof";
import store from "../store";
import eventBus from "../utils/eventBus.js";
import SockJS from 'sockjs-client';
import Stomp from 'stompjs';
/**
 * 这里只管 socket 自己的，不要去关心业务逻辑。
 * 只管接受服务端数据发送给外边和接受外边数据发送给服务端，至于是否连上了，外边无需关心，至于外边是否侦听了这里也无需关心
 */

var client = {
  socketUrl: process.env.VUE_APP_MESSAGE_API + '/websocket',
  connected: false,
  jumpTimer: null,
  heartJumping: true,
  jumpInterval: 5000,
  reconnectTimes: 0,
  wss: null,
  msgQueue: [],
  socket: null,
  stompClient: null,
  reconInv: undefined,
  trace: function trace(value) {
    var cmd = value.cmd,
        msg = value.msg,
        data = value.data;
    var msgData = !data ? '' : _typeof(data) === 'object' ? JSON.stringify(data) : data;
    console.log(cmd, msg, msgData);
  },
  // 接收到消息并对消息做处理
  onMessageReceived: function onMessageReceived(payload) {
    console.info('Message', payload.body);
    var message = payload.body; // JSON.parse(payload.body)
    // this.trace({ cmd: 'onmessage', msg: `onmessage`, data: message })

    eventBus.$emit('message', message);
  },
  onError: function onError(payload) {
    console.info('Message', payload.body);
    var msg = {
      type: 'error',
      msg: payload.body
    };
    eventBus.$emit('message', JSON.stringify(msg));
  },
  // 连接成功
  successCallback: function successCallback() {
    this.connected = true;
    var staffId = store.getters.staffId;
    console.info('onConnected');
    store.commit('user/SET_SOCKET', this);
    this.stompClient.subscribe('/user/' + staffId + '/topic/getResponse', this.onMessageReceived);
    this.stompClient.subscribe('/user/queue/error', this.onError);
    this.unReadMsgCount(staffId); // store.getters.socket.sendMsg(staffId, 'hello')
  },
  initWebSocket: function initWebSocket() {
    this.connected = false;
    this.connect();
  },
  connect: function connect() {
    if (this.connected) {
      console.info('已经连接成功!');
      return;
    }

    this.socket = new SockJS(this.socketUrl, null, {
      timeout: 15000
    }); // 连接服务端

    this.stompClient = Stomp.over(this.socket);
    var token = store.getters.token;
    var headers = [];
    var that = this;
    headers['access-token'] = token;
    this.stompClient.connect(headers, function (frame) {
      if (that.reconInv !== undefined) {
        clearInterval(that.reconInv);
        that.reconInv = undefined;
      }

      that.successCallback();
    }, function () {
      console.info('连接失败');
      that.reconnect();
    });
  },
  // 断开重连使用定时器定时连接服务器
  reconnect: function reconnect() {
    console.info('5秒后重新连接!'); // this.disconnect()

    var that = this;
    this.connected = false; // this.reconInv = setInterval(() => {
    //   console.info('in interval ' + Math.random())
    //   that.connect()
    // }, 5000)

    setTimeout(function () {
      that.connect();
    }, 5000);
  },
  disconnect: function disconnect() {
    if (this.stompClient) {
      this.stompClient.disconnect();
    }
  },
  sendMsg: function sendMsg(message) {
    this.stompClient.send('/app/send-message', {}, JSON.stringify(message));
  },
  readMsg: function readMsg(message) {
    this.stompClient.send('/app/read-message', {}, JSON.stringify(message));
  },
  unReadMsgCount: function unReadMsgCount(staffId) {
    this.stompClient.send('/app/unread-message-count', {}, JSON.stringify({
      sendStaffId: staffId
    }));
  }
};
export default client;