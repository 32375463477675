//
//
//
//
//
//
//
//
//
export default {
  name: 'PreviewRowItem',
  components: {},
  // eslint-disable-next-line vue/require-prop-types
  props: ['model', 'value'],
  data: function data() {
    return {};
  },
  computed: {}
};