//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/**
 * input的配置项
 */
export default {
  name: 'DividerConfig',
  props: {
    // eslint-disable-next-line vue/require-prop-types,vue/require-default-prop
    props: {}
  },
  data: function data() {
    return {};
  },
  mounted: function mounted() {},
  methods: {}
};