import "core-js/modules/es6.number.constructor";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import fancyEditTableItem from "./fancyEditTableItem";
export default {
  name: 'FancyEditTable',
  components: {
    fancyEditTableItem: fancyEditTableItem
  },
  props: {
    layoutArray: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    tdStyle: {
      type: String,
      default: ''
    },
    width: {
      type: Number,
      default: 100
    },
    height: {
      type: Number,
      default: 100
    }
  },
  data: function data() {
    return {};
  },
  computed: {
    tableWidth: function tableWidth() {
      return 'width:' + this.width + '%';
    },
    trHeight: function trHeight() {
      return 'height:' + this.height + 'px';
    }
  },
  mounted: function mounted() {},
  destroyed: function destroyed() {},
  methods: {}
};