var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticStyle: { padding: "5px", "margin-top": "10px" } }, [
      _c("table", { staticClass: "table-layout", style: _vm.tableWidth }, [
        _c(
          "tbody",
          _vm._l(_vm.layoutArray, function(tr, trIndex) {
            return _c(
              "tr",
              { key: trIndex, style: _vm.trHeight },
              _vm._l(tr, function(td, tdIndex) {
                return _c(
                  "fancy-edit-table-item",
                  {
                    key: tdIndex,
                    attrs: {
                      item: td,
                      "td-index": tdIndex,
                      "tr-index": trIndex,
                      "td-style": _vm.tdStyle
                    }
                  },
                  [_vm._t("default", null, { td: td })],
                  2
                )
              }),
              1
            )
          }),
          0
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }