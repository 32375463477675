import request from '@/utils/request';
var basePath = '/api/v1/inventory-base/config';
var baseUrl = process.env.VUE_APP_INVENTORY_API;
export function listAllWorkPostRatio(query) {
  return request({
    baseURL: baseUrl,
    url: basePath + '/work-post-ratio/list-all',
    method: 'get',
    params: query
  });
}
export function saveWorkPostRatio(data) {
  return request({
    baseURL: baseUrl,
    url: basePath + '/work-post-ratio/save',
    method: 'post',
    data: {
      data: data
    }
  });
}
export function listAllWorkRatio(query) {
  return request({
    baseURL: baseUrl,
    url: basePath + '/work-ratio/list-all',
    method: 'get',
    params: query
  });
}
export function listStaffPost(query) {
  return request({
    baseURL: baseUrl,
    url: basePath + '/staff-post/list',
    method: 'get',
    params: query
  });
}
export function listAllStaffNoDuplicate(query) {
  return request({
    baseURL: baseUrl,
    url: basePath + '/staff-post/list-all-no-duplicate',
    method: 'get',
    params: query
  });
}
export function getAllStaffPost() {
  return request({
    baseURL: baseUrl,
    url: basePath + '/staff-post/data-all',
    method: 'get'
  });
}
export function saveStaffPost(data) {
  return request({
    baseURL: baseUrl,
    url: basePath + '/staff-post/save',
    method: 'post',
    data: {
      data: data
    }
  });
}
export function deleteStaffPost(query) {
  return request({
    baseURL: baseUrl,
    url: basePath + '/staff-post/{recordId}',
    method: 'delete',
    params: query
  });
}