import request from '@/utils/request';
var baseSupplierPath = '/api/v1/user-base/supplier';
var baseCustomerPath = '/api/v1/user-base/customer';
var baseContactPath = '/api/v1/user-base/contact';
var baseUrl = process.env.VUE_APP_USER_API;
export function listOrgSynchronizedSupplier(query) {
  return request({
    baseURL: baseUrl,
    url: baseSupplierPath + '/list-third-party-supplier',
    method: 'get',
    params: query
  });
}
export function listThirdPartySupplier(query) {
  return request({
    baseURL: baseUrl,
    url: baseSupplierPath + '/list-third-party-supplier',
    method: 'get',
    params: query
  });
}
export function save(data) {
  return request({
    baseURL: baseUrl,
    url: baseSupplierPath + '/save',
    method: 'post',
    data: {
      data: data
    }
  });
}
export function submit(data) {
  return request({
    baseURL: baseUrl,
    url: baseSupplierPath + '/submit',
    method: 'post',
    data: {
      data: data
    }
  });
}
export function applyCancelSupplier(data) {
  return request({
    baseURL: baseUrl,
    url: baseSupplierPath + '/apply-cancel',
    method: 'post',
    data: {
      data: data
    }
  });
}
export function updateSupplier(query, data) {
  return request({
    baseURL: baseUrl,
    url: baseSupplierPath + '/{supplierId}',
    method: 'put',
    params: query,
    data: {
      data: data
    }
  });
}
export function deleteContact(query) {
  return request({
    baseURL: baseUrl,
    url: baseContactPath + '/{contactId}',
    method: 'delete',
    params: query
  });
}
export function supplierInfo(query) {
  return request({
    baseURL: baseUrl,
    url: baseSupplierPath + '/{supplierId}',
    method: 'get',
    params: query
  });
}
export function supplierWithManagerInfo(query) {
  return request({
    baseURL: baseUrl,
    url: baseSupplierPath + '/supplier-with-org/{supplierId}',
    method: 'get',
    params: query
  });
}
export function deleteImage(data) {
  return request({
    baseURL: baseUrl,
    url: baseSupplierPath + '/delete-image',
    method: 'delete',
    data: {
      data: data
    }
  });
}
export function listSupplierTree(query) {
  return request({
    baseURL: baseUrl,
    url: baseSupplierPath + '/list-org-supplier',
    method: 'get',
    params: query
  });
}
export function listStaffsRelatedToSupplier(query) {
  return request({
    baseURL: baseUrl,
    url: baseSupplierPath + '/staffs-related/{supplierId}',
    method: 'get',
    params: query
  });
}
export function listSupplier(query) {
  return request({
    baseURL: baseUrl,
    url: baseSupplierPath + '/list',
    method: 'get',
    params: query
  });
}
export function listSupplierCanDoEvaluate(query) {
  return request({
    baseURL: baseUrl,
    url: baseSupplierPath + '/can-evaluate/list',
    method: 'get',
    params: query
  });
}
export function listBindCustomer(query) {
  return request({
    baseURL: baseUrl,
    url: baseCustomerPath + '/list-org-customer',
    method: 'get',
    params: query
  });
}
export function listOrgActiveSupplier() {
  return request({
    baseURL: baseUrl,
    url: baseSupplierPath + '/list-org-active-supplier',
    method: 'get'
  });
}
export function listAllActiveSupplier() {
  return request({
    baseURL: baseUrl,
    url: baseSupplierPath + '/list-all-active-supplier',
    method: 'get'
  });
}
export function listOrgStaffSupplier() {
  return request({
    baseURL: baseUrl,
    url: baseSupplierPath + '/list-org-staff-supplier',
    method: 'get'
  });
}
export function inviteRegisterSupplier(data) {
  return request({
    baseURL: baseUrl,
    url: baseSupplierPath + '/invite-register',
    method: 'post',
    data: {
      data: data
    }
  });
}
export function getInviteRegisterSupplierSmsStatus(query) {
  return request({
    baseURL: baseUrl,
    url: baseSupplierPath + '/invite-register-sms-status/{platformSupplierId}',
    method: 'get',
    params: query
  });
}
export function handover(query) {
  return request({
    baseURL: baseUrl,
    url: baseSupplierPath + '/{supplierId}/handover/{staffId}',
    method: 'put',
    params: query
  });
}