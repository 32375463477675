//
//
//
//
//
//
//
//
//
//
//
//
//
//
import iconList from "./utils/icon.json";
export default {
  props: {
    visible: {
      type: Boolean,
      default: true
    },
    value: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      icons: iconList
    };
  },
  computed: {
    dialogVisible: {
      // getter
      get: function get() {
        return this.visible;
      },
      // setter
      set: function set(newValue) {
        this.$emit('update:visible', false);
      }
    }
  },
  methods: {
    handlerSelectIcon: function handlerSelectIcon(iconName) {
      this.$emit('input', iconName);
      this.$emit('update:visible', false);
    }
  }
};