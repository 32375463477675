var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.props.compType === "dialogList",
          expression: "props.compType === 'dialogList'"
        }
      ],
      staticClass: "dialogList"
    },
    [
      _c(
        "el-collapse",
        {
          attrs: { accordion: "" },
          model: {
            value: _vm.activePanel,
            callback: function($$v) {
              _vm.activePanel = $$v
            },
            expression: "activePanel"
          }
        },
        [
          _c(
            "el-collapse-item",
            { attrs: { title: "基础设置", name: "1" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "ID" } },
                [
                  _c(
                    "el-tooltip",
                    {
                      staticClass: "item",
                      attrs: {
                        effect: "dark",
                        content:
                          "请注意,ID的修改可能会导致该组件相关事件失效！",
                        placement: "left"
                      }
                    },
                    [
                      _c("el-input", {
                        staticClass: "input",
                        model: {
                          value: _vm.props.id,
                          callback: function($$v) {
                            _vm.$set(_vm.props, "id", $$v)
                          },
                          expression: "props.id"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "标题" } },
                [
                  _c("el-input", {
                    staticClass: "input",
                    model: {
                      value: _vm.props.label,
                      callback: function($$v) {
                        _vm.$set(_vm.props, "label", $$v)
                      },
                      expression: "props.label"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "栅格" } },
                [
                  _c("el-input-number", {
                    attrs: { min: 1, max: 24 },
                    model: {
                      value: _vm.props.span,
                      callback: function($$v) {
                        _vm.$set(_vm.props, "span", $$v)
                      },
                      expression: "props.span"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "标签宽度" } },
                [
                  _c("el-input-number", {
                    attrs: { min: 1, max: 200 },
                    model: {
                      value: _vm.props.labelWidth,
                      callback: function($$v) {
                        _vm.$set(_vm.props, "labelWidth", $$v)
                      },
                      expression: "props.labelWidth"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "显示标签" } },
                [
                  _c("el-switch", {
                    model: {
                      value: _vm.props.showLabel,
                      callback: function($$v) {
                        _vm.$set(_vm.props, "showLabel", $$v)
                      },
                      expression: "props.showLabel"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "必填" } },
                [
                  _c("el-switch", {
                    model: {
                      value: _vm.props.required,
                      callback: function($$v) {
                        _vm.$set(_vm.props, "required", $$v)
                      },
                      expression: "props.required"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "禁用" } },
                [
                  _c("el-switch", {
                    model: {
                      value: _vm.props.disabled,
                      callback: function($$v) {
                        _vm.$set(_vm.props, "disabled", $$v)
                      },
                      expression: "props.disabled"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "默认值" } },
                [
                  _c("el-input", {
                    staticClass: "input",
                    model: {
                      value: _vm.props.value,
                      callback: function($$v) {
                        _vm.$set(_vm.props, "value", $$v)
                      },
                      expression: "props.value"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "请求地址" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.props.action,
                      callback: function($$v) {
                        _vm.$set(_vm.props, "action", $$v)
                      },
                      expression: "props.action"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "标题" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.props.title,
                      callback: function($$v) {
                        _vm.$set(_vm.props, "title", $$v)
                      },
                      expression: "props.title"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "多选" } },
                [
                  _c("el-switch", {
                    model: {
                      value: _vm.props.multi,
                      callback: function($$v) {
                        _vm.$set(_vm.props, "multi", $$v)
                      },
                      expression: "props.multi"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "显示序号" } },
                [
                  _c("el-switch", {
                    model: {
                      value: _vm.props.showIndex,
                      callback: function($$v) {
                        _vm.$set(_vm.props, "showIndex", $$v)
                      },
                      expression: "props.showIndex"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "表格高度" } },
                [
                  _c("el-input-number", {
                    attrs: { step: 10, max: 1500, min: 100 },
                    model: {
                      value: _vm.props.height,
                      callback: function($$v) {
                        _vm.$set(_vm.props, "height", $$v)
                      },
                      expression: "props.height"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "字段值" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.props.dval,
                      callback: function($$v) {
                        _vm.$set(_vm.props, "dval", $$v)
                      },
                      expression: "props.dval"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "字段名称" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.props.dlabel,
                      callback: function($$v) {
                        _vm.$set(_vm.props, "dlabel", $$v)
                      },
                      expression: "props.dlabel"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-collapse-item",
            { attrs: { title: "字段配置", name: "2" } },
            [
              _c(
                "el-table",
                {
                  attrs: { data: _vm.colOptions },
                  on: { "row-dblclick": _vm.handlerDeleteRow }
                },
                [
                  _c("el-table-column", {
                    attrs: { property: "label", label: "字段", align: "center" }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      property: "property",
                      label: "属性",
                      align: "center"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      property: "width",
                      label: "宽度",
                      align: "center",
                      width: "70"
                    }
                  }),
                  _c("el-table-column", { attrs: { label: "显示" } })
                ],
                1
              ),
              _c("br"),
              _c("el-alert", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.alertShow,
                    expression: "alertShow"
                  }
                ],
                attrs: {
                  title: "字段和属性不能为空,请检查",
                  type: "error",
                  closable: false
                }
              }),
              _c("el-alert", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.propertyExistShow,
                    expression: "propertyExistShow"
                  }
                ],
                attrs: {
                  title: "属性已存在请检查",
                  type: "error",
                  closable: false
                }
              }),
              _c("br"),
              _c(
                "el-form-item",
                { attrs: { label: "字段", "label-width": "60px" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.dLabel,
                      callback: function($$v) {
                        _vm.dLabel = $$v
                      },
                      expression: "dLabel"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "属性", "label-width": "60px" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.dProperty,
                      callback: function($$v) {
                        _vm.dProperty = $$v
                      },
                      expression: "dProperty"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "宽度", "label-width": "60px" } },
                [
                  _c("el-input-number", {
                    model: {
                      value: _vm.dWidth,
                      callback: function($$v) {
                        _vm.dWidth = $$v
                      },
                      expression: "dWidth"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "显示", "label-width": "60px" } },
                [
                  _c("el-switch", {
                    model: {
                      value: _vm.dShow,
                      callback: function($$v) {
                        _vm.dShow = $$v
                      },
                      expression: "dShow"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticStyle: { "margin-left": "20px" } },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { "padding-bottom": "0" },
                      attrs: {
                        icon: "el-icon-circle-plus-outline",
                        type: "text"
                      },
                      on: { click: _vm.addColItem }
                    },
                    [_vm._v(" 添加选项 ")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("el-collapse-item", { attrs: { title: "数值联动", name: "3" } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }