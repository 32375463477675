import "core-js/modules/es6.array.find-index";
import "core-js/modules/es6.array.find";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/**
 * text的配置项
 */
export default {
  name: 'TextConfig',
  props: {
    // eslint-disable-next-line vue/require-prop-types,vue/require-default-prop
    props: {}
  },
  data: function data() {
    return {
      activePanel: '1',
      colOptions: [],
      dLabel: '',
      dProperty: '',
      dWidth: 150,
      dShow: true,
      alertShow: false,
      propertyExistShow: false
    };
  },
  watch: {
    'colOptions': function colOptions(newVal) {
      this.props.colConf = JSON.stringify(newVal);
    }
  },
  mounted: function mounted() {
    var _this = this;

    this.$nextTick(function () {
      _this.colOptions = _this.colOptions.concat(JSON.parse(_this.props.colConf));
    });
  },
  methods: {
    addColItem: function addColItem() {
      var _this2 = this;

      if (this.dLabel !== '' && this.dProperty !== '') {
        var existOptions = this.colOptions.find(function (item) {
          return item.property === _this2.dProperty;
        });

        if (typeof existOptions === 'undefined') {
          this.alertShow = false;
          this.propertyExistShow = false;
          var obj = {};
          obj.index = this.colOptions.length;
          obj.show = this.dShow;
          obj.label = this.dLabel;
          obj.property = this.dProperty;
          obj.width = this.dWidth;
          this.colOptions.push(obj);
          this.resetFields();
        } else {
          this.propertyExistShow = true;
        }
      } else {
        this.alertShow = true;
      }
    },
    resetFields: function resetFields() {
      this.dLabel = '';
      this.dProperty = '';
      this.dWidth = 150;
      this.dShow = true;
    },
    handlerDeleteRow: function handlerDeleteRow(row) {
      var index = this.colOptions.findIndex(function (item) {
        return item.property === row.property;
      });
      this.colOptions.splice(index, 1);
    }
  }
};