import request from '@/utils/request';
var basePath = '/api/v1/message-base/message';
var basePath1 = '/api/v1/message-base';
var messageUrl = process.env.VUE_APP_MESSAGE_API;
export function listMessage(query) {
  return request({
    baseURL: messageUrl,
    url: basePath + '/list',
    method: 'get',
    params: query
  });
}
export function listUnHandleMessageForManager(query) {
  return request({
    baseURL: messageUrl,
    url: basePath + '/list-un-handle-message',
    method: 'get',
    params: query
  });
}
export function countDailyMessage() {
  return request({
    baseURL: messageUrl,
    url: basePath + '/count-daily-message',
    method: 'get'
  });
}
export function closeMessage(data) {
  return request({
    baseURL: messageUrl,
    url: basePath + '/close-message',
    method: 'post',
    data: {
      data: data
    }
  });
}
export function getWxMessage(data) {
  return request({
    baseURL: messageUrl,
    url: basePath1 + '/wechat-bot-message/preview-wechat-bot-insurance-message',
    method: 'post',
    data: {
      data: data
    }
  });
}