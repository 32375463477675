var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.props.compType === "html",
          expression: "props.compType === 'html'"
        }
      ]
    },
    [
      _c(
        "el-form-item",
        { attrs: { label: "ID", "label-width": "40px" } },
        [
          _c(
            "el-tooltip",
            {
              staticClass: "item",
              attrs: {
                effect: "dark",
                content: "请注意,ID的修改可能会导致该组件相关事件失效！",
                placement: "left"
              }
            },
            [
              _c("el-input", {
                staticClass: "input",
                model: {
                  value: _vm.props.id,
                  callback: function($$v) {
                    _vm.$set(_vm.props, "id", $$v)
                  },
                  expression: "props.id"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "文本", "label-width": "40px" } },
        [
          _c("el-button", {
            attrs: { icon: "el-icon-edit-outline", circle: "" },
            on: { click: _vm.handlerEditMore }
          })
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.editTextVisible,
            width: "70%",
            "close-on-press-escape": false,
            "close-on-click-modal": false,
            "show-close": false,
            center: true,
            top: "20px"
          },
          on: {
            "update:visible": function($event) {
              _vm.editTextVisible = $event
            }
          }
        },
        [
          _c("fancy-editor", {
            model: {
              value: _vm.props.text,
              callback: function($$v) {
                _vm.$set(_vm.props, "text", $$v)
              },
              expression: "props.text"
            }
          }),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handlerSave } },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }