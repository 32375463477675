import request from '@/utils/request';
var basePath = '/api/v1/message-base/notice';
var messageUrl = process.env.VUE_APP_MESSAGE_API;
export function listNotice(query) {
  return request({
    baseURL: messageUrl,
    url: basePath + '/list-all',
    method: 'get',
    params: query
  });
}
export function getNotice(query) {
  return request({
    baseURL: messageUrl,
    url: basePath + '/{type}/{id}',
    method: 'get',
    params: query
  });
}
export function receivedNotice(query) {
  return request({
    baseURL: messageUrl,
    url: basePath + '/received/{type}/{id}',
    method: 'put',
    params: query
  });
}
export function ignoreAllNotice() {
  return request({
    baseURL: messageUrl,
    url: basePath + '/ignore-all',
    method: 'post'
  });
}