import "core-js/modules/es6.function.name";
var getters = {
  sidebar: function sidebar(state) {
    return state.app.sidebar;
  },
  size: function size(state) {
    return state.app.size;
  },
  device: function device(state) {
    return state.app.device;
  },
  visitedViews: function visitedViews(state) {
    return state.tagsView.visitedViews;
  },
  cachedViews: function cachedViews(state) {
    return state.tagsView.cachedViews;
  },
  token: function token(state) {
    return state.user.token;
  },
  avatar: function avatar(state) {
    return state.user.avatar;
  },
  name: function name(state) {
    return state.user.name;
  },
  introduction: function introduction(state) {
    return state.user.introduction;
  },
  roles: function roles(state) {
    return state.user.roles;
  },
  socket: function socket(state) {
    return state.user.socket;
  },
  permission_routes: function permission_routes(state) {
    return state.permission.routes;
  },
  regionTreeList: function regionTreeList(state) {
    return state.region.regionTreeList;
  },
  costTypeList: function costTypeList(state) {
    return state.incomeCostType.costTypeList;
  },
  costTypeForCustomerList: function costTypeForCustomerList(state) {
    return state.incomeCostType.costTypeForCustomerList;
  },
  costTypeForClueList: function costTypeForClueList(state) {
    return state.incomeCostType.costTypeForClueList;
  },
  costTypeForProjectList: function costTypeForProjectList(state) {
    return state.incomeCostType.costTypeForProjectList;
  },
  incomeTypeList: function incomeTypeList(state) {
    return state.incomeCostType.incomeTypeList;
  },
  financeInvoiceTaxList: function financeInvoiceTaxList(state) {
    return state.financeInvoiceTax.financeInvoiceTaxList;
  },
  financeFeeSubjectList: function financeFeeSubjectList(state) {
    return state.financeFeeSubject.financeFeeSubjectList;
  },
  profitLossSubjectList: function profitLossSubjectList(state) {
    return state.financeFeeSubject.profitLossSubjectList;
  },
  insuranceRateList: function insuranceRateList(state) {
    return state.insuranceRate.insuranceRateList;
  },
  railwayInsuranceRateList: function railwayInsuranceRateList(state) {
    return state.insuranceRate.railwayInsuranceRateList;
  },
  errorLogs: function errorLogs(state) {
    return state.errorLog.logs;
  },
  user: function user(state) {
    return state.user;
  },
  location: function location(state) {
    return state.user.location;
  },
  userInfo: function userInfo(state) {
    return state.user.userInfo;
  },
  corpId: function corpId(state) {
    return state.user.corpId;
  },
  orgId: function orgId(state) {
    return state.user.orgId;
  },
  depId: function depId(state) {
    return state.user.depId;
  },
  staffId: function staffId(state) {
    return state.user.staffId;
  },
  localStaff: function localStaff(state) {
    return state.staff.localStaff;
  },
  config: function config(state) {
    return state.user.config;
  },
  currentSettleBillDate: function currentSettleBillDate(state) {
    return state.config.currentSettleBillDate;
  },
  allOrgList: function allOrgList(state) {
    return state.user.allOrgList;
  },
  bizDepartmentList: function bizDepartmentList(state) {
    return state.department.bizDepartmentList;
  },
  corpBizDepartmentList: function corpBizDepartmentList(state) {
    return state.department.corpBizDepartmentList;
  },
  cropAllStaffList: function cropAllStaffList(state) {
    return state.staff.cropAllStaffList;
  },
  allStaffList: function allStaffList(state) {
    return state.staff.allStaffList;
  },
  depStaffList: function depStaffList(state) {
    return state.staff.depStaffList;
  },
  allWorkshopList: function allWorkshopList(state) {
    return state.workshop.workshopList;
  },
  allWarehouseList: function allWarehouseList(state) {
    return state.warehouse.warehouseList;
  },
  activeSupplierList: function activeSupplierList(state) {
    return state.supplier.activeSupplierList;
  },
  allActiveSupplierList: function allActiveSupplierList(state) {
    return state.supplier.allActiveSupplierList;
  },
  orgSynchronizedSupplierList: function orgSynchronizedSupplierList(state) {
    return state.supplier.orgSynchronizedSupplierList;
  },
  noPermActiveCustomerList: function noPermActiveCustomerList(state) {
    return state.customer.noPermActiveCustomerList;
  },
  activeCustomerList: function activeCustomerList(state) {
    return state.customer.activeCustomerList;
  },
  localCustomerList: function localCustomerList(state) {
    return state.customer.localCustomerList;
  },
  filterCustomerList: function filterCustomerList(state) {
    return state.customer.filterCustomerList;
  },
  cropAllCustomerList: function cropAllCustomerList(state) {
    return state.customer.cropAllCustomerList;
  },
  allLevel1CategoryList: function allLevel1CategoryList(state) {
    return state.digitalAssetCategory.level1CategoryList;
  },
  businessTypeList: function businessTypeList(state) {
    return state.businessType.businessTypeList;
  },
  billPlatformList: function billPlatformList(state) {
    return state.billPlatform.billPlatformList;
  },
  bulkConfig: function bulkConfig(state) {
    return state.bulkConfig.bulkConfig;
  },
  allWorkPostRatioList: function allWorkPostRatioList(state) {
    return state.workPostRatio.allWorkPostRatioList;
  },
  allWorkRatioList: function allWorkRatioList(state) {
    return state.workRatio.allWorkRatioList;
  },
  allMaterialList: function allMaterialList(state) {
    return state.material.allMaterialList;
  },
  allMaterialSupplierList: function allMaterialSupplierList(state) {
    return state.material.allMaterialSupplierList;
  },
  allDeliveryAddressList: function allDeliveryAddressList(state) {
    return state.delivery.allDeliveryAddressList;
  },
  allScheduleStaffList: function allScheduleStaffList(state) {
    return state.staffPost.allScheduleStaffList;
  },
  allStorageStaffList: function allStorageStaffList(state) {
    return state.staffPost.allStorageStaffList;
  },
  allLeaderStaffList: function allLeaderStaffList(state) {
    return state.staffPost.allLeaderStaffList;
  },
  allCraneStaffList: function allCraneStaffList(state) {
    return state.staffPost.allCraneStaffList;
  },
  allOperatorStaffList: function allOperatorStaffList(state) {
    return state.staffPost.allOperatorStaffList;
  },
  allOaMessageList: function allOaMessageList(state) {
    return state.message.allMessageList;
  },
  dailyMessageCount: function dailyMessageCount(state) {
    return state.message.dailyMessageCount;
  },
  triggerTypeList: function triggerTypeList(state) {
    return state.message.triggerTypeList;
  }
};
export default getters;