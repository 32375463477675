import "core-js/modules/es6.string.starts-with";
import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
import { asyncRoutes, constantRoutes } from '@/router';
/**
 * Use meta.role to determine if the current user has permission
 * @param roles
 * @param route
 */

function hasPermission(roles, route) {
  if (route.meta && route.meta.roles) {
    // return roles.some(role => route.meta.roles.includes(role))
    return roles.some(function (role) {
      return route.meta.roles.includes(role) || route.meta.roles.some(function (metaRole) {
        return role.startsWith(metaRole + ':');
      });
    });
  } else {
    return true;
  }
}
/**
 * Filter asynchronous routing tables by recursion
 * @param routes asyncRoutes
 * @param roles
 */


export function filterAsyncRoutes(routes, roles) {
  // const res = []
  //
  // routes.forEach(route => {
  //   const tmp = { ...route }
  //   if (hasPermission(roles, tmp)) {
  //     if (tmp.children) {
  //       tmp.children = filterAsyncRoutes(tmp.children, roles)
  //     }
  //     res.push(tmp)
  //   }
  // })
  // return res
  var res = routes.map(function (route) {
    if (hasPermission(roles, route)) {
      if (route.children && route.children.length) {
        route.children = filterAsyncRoutes(route.children, roles);
      }

      return route;
    } else {
      if (route.children && route.children.length) {
        route.children = filterAsyncRoutes(route.children, roles);
      }

      Object.assign(route, {
        hidden: true
      });
      return route;
    }
  });
  return res;
}
var state = {
  routes: [],
  addRoutes: []
};
var mutations = {
  SET_ROUTES: function SET_ROUTES(state, routes) {
    state.addRoutes = routes;
    state.routes = constantRoutes.concat(routes);
  }
};
var actions = {
  generateRoutes: function generateRoutes(_ref, roles) {
    var commit = _ref.commit;
    return new Promise(function (resolve) {
      // let accessedRoutes
      // if (roles.includes('admin')) {
      //   accessedRoutes = asyncRoutes || []
      // } else {
      //   accessedRoutes = filterAsyncRoutes(asyncRoutes, roles)
      // }
      // 无论是否超级管理员，如果区分权限 路由上自己配置，这样更加灵活
      // 例如有些普通用户的权限，管理员很有可能不需要，全部把动态路由赋值给管理员也不够灵活
      var accessedRoutes = filterAsyncRoutes(asyncRoutes, roles);
      commit('SET_ROUTES', accessedRoutes);
      resolve(accessedRoutes);
    });
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};