/**
 * 文字链接
 */
export var link = {
  id: '',
  _id: '',
  compType: 'link',
  ele: 'el-link',
  compName: '文字链接',
  compIcon: 'link',
  // 展示表单的模式
  viewType: 'component',
  config: true,
  form: false,
  // 是否表单组件
  // 控件左侧label内容
  showLabel: false,
  labelWidth: '0',
  span: 24,
  // 主题
  type: 'primary',
  // 链接地址
  href: 'http://www.baidu.com',
  // 是否下划线
  underline: true,
  // 是否禁用
  disabled: false,
  text: '链接',
  target: '_blank'
};