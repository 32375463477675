var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticStyle: { padding: "5px", "margin-top": "10px" } }, [
      _c("table", { staticClass: "table-layout", style: _vm.tableStyle }, [
        _c(
          "tbody",
          _vm._l(_vm.layoutArray, function(tr, trIndex) {
            return _c(
              "tr",
              { key: trIndex, style: _vm.trHeight },
              _vm._l(tr, function(td, tdIndex) {
                return _c(
                  "fancy-table-item",
                  {
                    key: tdIndex,
                    attrs: {
                      item: td,
                      "td-index": tdIndex,
                      "tr-index": trIndex,
                      "td-style": _vm.tdStyle
                    },
                    on: { rightClick: _vm.rightClick },
                    nativeOn: {
                      click: function($event) {
                        return _vm.handlerSelectedTd($event, td)
                      }
                    }
                  },
                  [_vm._t("default", null, { td: td })],
                  2
                )
              }),
              1
            )
          }),
          0
        )
      ])
    ]),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showContextMunu,
            expression: "showContextMunu"
          }
        ],
        staticClass: "right-menu",
        style: { top: +_vm.positionY + "px", left: +_vm.positionX + "px" }
      },
      [
        _c("ul", { staticStyle: { "list-style-type": "none" } }, [
          _vm.showRightColMenu
            ? _c(
                "li",
                { on: { click: _vm.handlerRightCol } },
                [
                  _c("icon", {
                    attrs: { code: "zuoyouhebing", text: "向右合并单元格" }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.showDownRowMenu
            ? _c(
                "li",
                { on: { click: _vm.handlerDownRow } },
                [
                  _c("icon", {
                    attrs: { code: "shangxiahebing", text: "向下合并单元格" }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.showResetTableMenu
            ? _c(
                "li",
                { on: { click: _vm.handlerResetTable } },
                [
                  _c("icon", { attrs: { code: "chaifen", text: "拆分单元格" } })
                ],
                1
              )
            : _vm._e(),
          _c(
            "li",
            { on: { click: _vm.handlerAppendCol } },
            [_c("icon", { attrs: { code: "zhuijiahang", text: "追加行" } })],
            1
          ),
          _c(
            "li",
            { on: { click: _vm.handlerAppendRow } },
            [_c("icon", { attrs: { code: "zhuijialie", text: "追加列" } })],
            1
          )
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }