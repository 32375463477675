//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { changeId } from "../mixin";
export default {
  name: 'ButtonConfig',
  components: {},
  mixins: [changeId],
  props: {
    // eslint-disable-next-line vue/require-default-prop,vue/require-prop-types
    props: {}
  },
  data: function data() {
    return {
      isNewWindow: true
    };
  },
  mounted: function mounted() {},
  methods: {
    handlerShowLabel: function handlerShowLabel(val) {
      if (val) {
        this.props.labelWidth = 80;
      } else {
        this.props.labelWidth = 0;
      }
    },
    handlerChangeTargetValue: function handlerChangeTargetValue(val) {
      if (val) {
        this.props.target = '_blank';
      } else {
        this.props.target = '_self';
      }
    }
  }
};