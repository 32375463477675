import "core-js/modules/es6.number.constructor";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    lineClamp: Number
  },
  data: function data() {
    return {
      tooltipVisible: false,
      disabledTooltip: true
    };
  },
  computed: {
    lineClampStyle: function lineClampStyle() {
      return this.lineClamp ? {
        display: '-webkit-inline-box',
        '-webkit-box-orient': 'vertical',
        '-webkit-line-clamp': this.lineClamp
      } : {};
    }
  },
  methods: {
    setTooltipDisabled: function setTooltipDisabled() {
      var $trigger = this.$refs.triggerRef;

      if ($trigger) {
        if (this.lineClamp) {
          this.disabledTooltip = $trigger.scrollHeight <= $trigger.offsetHeight;
        } else {
          var $triggerInner = this.$refs.triggerInnerRef;

          if ($triggerInner) {
            this.disabledTooltip = $triggerInner.getBoundingClientRect().width <= $trigger.getBoundingClientRect().width;
          }
        }
      }
    }
  }
};