import "core-js/modules/es6.object.keys";
import "core-js/modules/web.dom.iterable";
import { isAttr, jsonClone } from "../utils/index";
import childrenItem from "./slot/index";
import { remoteData } from "./mixin";

function vModel(self, dataObject) {
  dataObject.on.input = function (val) {
    self.$emit('input', val);
  };
}

export default {
  render: function render(h) {
    var dataObject = {
      attrs: {},
      props: {},
      on: {},
      style: {}
    }; // 远程获取数据

    console.log('render');
    this.getRemoteData();
    var confClone = jsonClone(this.conf);
    var children = childrenItem(h, confClone);
    Object.keys(confClone).forEach(function (key) {
      var val = confClone[key];

      if (dataObject[key]) {
        dataObject[key] = val;
      } else if (!isAttr(key)) {
        dataObject.props[key] = val;
      } else if (key === 'width') {
        dataObject.style = 'width:' + val;
      } else {
        dataObject.attrs[key] = val;
      }
    });
    /* 调整赋值模式，规避cascader组件赋值props会出现覆盖预制参数的bug */
    // console.log(JSON.stringify(dataObject))

    vModel(this, dataObject);
    return h(confClone.ele, dataObject, children);
  },
  props: ['conf'],
  mixins: [remoteData]
};