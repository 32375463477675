import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
import "core-js/modules/es6.array.find";
import "core-js/modules/es6.array.find-index";
import "core-js/modules/es6.function.name";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import eventBus from '@/utils/eventBus.js';
export default {
  name: 'NotificationBox',
  props: {
    messageList: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      showBox: false,
      maxMessageToShow: 3,
      showMessageList: [],
      lazyLoadFun: undefined
    };
  },
  computed: {
    messageTotal: function messageTotal() {
      return this.messageList.length;
    }
  },
  watch: {
    showMessageList: {
      handler: function handler(newVal, oldVal) {
        if (!this.showBox) {
          this.showBox = true;
        }
      },
      immediate: true
    }
  },
  created: function created() {
    this.showBox = false;
  },
  mounted: function mounted() {
    var _this = this;

    this.$nextTick(function () {
      _this.lazyLoadMessage();
    });
  },
  beforeDestroy: function beforeDestroy() {
    console.log('lazyLoadMessage off');

    if (this.lazyLoadFun !== undefined) {
      clearInterval(this.lazyLoadFun);
    }
  },
  methods: {
    getLastTwoWord: function getLastTwoWord(val) {
      if (val.length > 2) {
        return val.substring(val.length - 2);
      } else {
        return val;
      }
    },
    onShowBox: function onShowBox() {
      this.showBox = true;
    },
    onHideBox: function onHideBox() {
      this.showBox = false;
    },
    onViewAll: function onViewAll() {
      if (this.$router.currentRoute.name !== 'DailyRoutinePage') {
        this.$router.push({
          name: 'DailyRoutinePage',
          params: {
            tabPosition: 'remind'
          }
        });
      } else {
        eventBus.$emit('remindDailyRoutinePage');
      }
    },
    onIgnoreAll: function onIgnoreAll() {
      var _this2 = this;

      this.$store.dispatch('message/ignoreAllNotice').then(function () {
        _this2.showMessageList = [];
      }).then(function () {
        _this2.showBox = false;
      });
    },
    onClickMessage: function onClickMessage(item) {
      if (item.eventType === 7) {
        if (this.$router.currentRoute.name !== 'EnquiryList') {
          this.$router.push({
            name: 'EnquiryList',
            params: {
              eventSubject: {
                eventSubjectId: item.eventSubjectId
              }
            }
          });
        } else {
          eventBus.$emit('showEnquiry', item.eventSubjectId);
        }
      }
    },
    onRemoveMessage: function onRemoveMessage(type, id) {
      var _this3 = this;

      this.$store.dispatch('message/receivedNotice', {
        type: type,
        id: id
      }).then(function () {
        var idx = _this3.showMessageList.findIndex(function (m) {
          return m.type === type && m.id === id;
        });

        if (idx >= 0) {
          _this3.showMessageList.splice(idx, 1);
        } // console.log(this.messageList.length)

      });
    },
    lazyLoadMessage: function lazyLoadMessage() {
      // debugger
      // console.log('lazyLoadMessage on')
      var that = this;
      this.lazyLoadFun = setInterval(function () {
        // debugger
        if (that.messageList.length > 0 && that.showMessageList.length < that.maxMessageToShow) {
          var showProcessIds = that.showMessageList.map(function (m) {
            return m.id;
          });
          var addMessageList = that.messageList.find(function (m) {
            return !showProcessIds.includes(m.id);
          });

          if (addMessageList) {
            that.showMessageList.push(addMessageList);
          }
        }
      }, 1000);
    }
  }
};