import request from '@/utils/request';
var basePath = '/api/v1/project-base/business-type';
var baseUrl = process.env.VUE_APP_PROJECT_API;
export function listBusinessType(query) {
  return request({
    baseURL: baseUrl,
    url: basePath + '/list',
    method: 'get',
    params: query
  });
}