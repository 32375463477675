import "core-js/modules/es6.string.starts-with";
import _typeof from "/var/jenkins_home/workspace/ops.dfwl.plus/node_modules/@babel/runtime-corejs2/helpers/esm/typeof";
import _slicedToArray from "/var/jenkins_home/workspace/ops.dfwl.plus/node_modules/@babel/runtime-corejs2/helpers/esm/slicedToArray";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es7.object.entries";
import "core-js/modules/es6.regexp.replace";
import "core-js/modules/es6.regexp.constructor";
import "core-js/modules/es6.regexp.match";
import axios from 'axios';
import { Message } from 'element-ui';
import store from '@/store';
import { getToken } from '@/utils/auth'; // create an axios instance

var service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 60000 // request timeout 60秒

}); // request interceptor

service.interceptors.request.use(function (config) {
  // do something before request is sent
  // console.log(config)
  var url = config.url;
  var params = config.params;

  if (url.match(new RegExp('{.*}', 'i'))) {
    if (typeof params === 'string' || typeof params === 'number') {
      url = url.replace(new RegExp('{.*}', 'i'), params);
      config.params = undefined;
    } else {
      for (var _i = 0, _Object$entries = Object.entries(params); _i < _Object$entries.length; _i++) {
        var _Object$entries$_i = _slicedToArray(_Object$entries[_i], 2),
            key = _Object$entries$_i[0],
            value = _Object$entries$_i[1];

        url = url.replace(new RegExp('{' + key + '}', 'i'), value);
      }
    }

    config.url = url;
  }

  if (_typeof(params) === 'object') {
    for (var _i2 = 0, _Object$entries2 = Object.entries(params); _i2 < _Object$entries2.length; _i2++) {
      var _Object$entries2$_i = _slicedToArray(_Object$entries2[_i2], 2),
          _key = _Object$entries2$_i[0],
          _value = _Object$entries2$_i[1];

      if (Array.isArray(_value)) {
        params[_key] = _value.join(',');
      }
    }
  }

  if (store.getters.token && !config.baseURL.startsWith('https://restapi.amap.com')) {
    // let each request carry token
    // ['X-Token'] is a custom headers key
    // please modify it according to the actual situation
    config.headers['access-token'] = getToken(); // if (config.params) {
    //   config.params.access_token = getToken()
    // } else {
    //   config.params = { access_token: getToken() }
    // }
  }

  return config;
}, function (error) {
  // do something with request error
  console.log(error); // for debug

  return Promise.reject(error);
}); // response interceptor

service.interceptors.response.use(
/**
 * If you want to get http information such as headers or status
 * Please return  response => response
*/

/**
 * Determine the request status by custom code
 * Here is just an example
 * You can also judge the status by HTTP Status Code
 */
function (response) {
  var res = response.data; // console.log(res)
  // if the custom code is not 0, it is judged as an error.

  if (res.code !== undefined && res.code !== 0) {
    if (res.code === 401) {
      store.dispatch('user/resetToken').then(function () {
        location.reload();
      });
    }

    Message({
      message: res.msg || 'Error',
      type: 'error',
      duration: 3 * 1000
    }); // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
    // if (res.code === 50008 || res.code === 50012 || res.code === 50014) {
    //   // to re-login
    //   MessageBox.confirm('You have been logged out, you can cancel to stay on this page, or log in again', 'Confirm logout', {
    //     confirmButtonText: 'Re-Login',
    //     cancelButtonText: 'Cancel',
    //     type: 'warning'
    //   }).then(() => {
    //     store.dispatch('user/resetToken').then(() => {
    //       location.reload()
    //     })
    //   })
    // }

    return Promise.reject(new Error(res.msg || 'Error'));
  } else {
    return res;
  }
}, function (error) {
  if (error.response) {
    console.log('err:' + error); // for debug

    if (error.response.status === 401) {
      store.dispatch('user/resetToken').then(function () {
        location.reload();
      });
    } else if (error.response.status >= 500) {
      var res = error.response.data;
      Message({
        message: res.msg || 'Error',
        type: 'error',
        duration: 3 * 1000
      });
    }

    return Promise.reject(error);
  } else {
    Message({
      message: '连接服务器失败!',
      type: 'error'
    });
  }
});
export default service;