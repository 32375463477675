import request from '@/utils/request';
export function login(data) {
  return request({
    url: '/auth/login',
    method: 'post',
    data: {
      data: data
    }
  });
}
export function ssoLogin(query) {
  return request({
    url: '/auth/sso/{routerId}',
    method: 'get',
    params: query
  });
}
export function ssoSaasLogin(query) {
  return request({
    url: '/auth/sso-saas',
    method: 'get',
    params: query
  });
}
export function getInfo() {
  return request({
    url: '/auth/me',
    method: 'get'
  });
}
export function logout() {
  return request({
    url: '/auth/logout',
    method: 'post'
  });
}
export function switchOrg(data) {
  return request({
    url: '/auth/switch-org',
    method: 'post',
    data: {
      data: data
    }
  });
} // export function switchOrg(query) {
//   return request({
//     url: '/auth/switch-org/{orgId}',
//     method: 'get',
//     params: query
//   })
// }