import "core-js/modules/es6.number.constructor";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'FancyTableItem',
  components: {},
  props: {
    item: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    tdIndex: {
      type: Number,
      default: 0
    },
    trIndex: {
      type: Number,
      default: 0
    },
    tdStyle: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {};
  },
  inject: ['getContext'],
  computed: {
    isActiveItem: function isActiveItem() {
      return this.getContext.activeItem.id === this.item.id; // this.designerActiveItem
    }
  },
  methods: {
    rightClick: function rightClick(e, rowIndex, colIndex) {
      this.$emit('rightClick', e, rowIndex, colIndex);
    }
  }
};