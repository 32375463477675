import "core-js/modules/es6.map";
import "core-js/modules/es6.string.ends-with";
import "core-js/modules/es6.math.trunc";
import "core-js/modules/es6.number.is-nan";
import "core-js/modules/es6.number.constructor";
import "core-js/modules/es6.number.is-finite";
import "core-js/modules/es6.regexp.constructor";
import "core-js/modules/es6.regexp.match";
import "core-js/modules/es6.set";
import "core-js/modules/es6.string.iterator";
import "core-js/modules/es6.array.from";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.regexp.split";
import "core-js/modules/es6.regexp.replace";
import "core-js/modules/es6.regexp.to-string";
import _typeof from "/var/jenkins_home/workspace/ops.dfwl.plus/node_modules/@babel/runtime-corejs2/helpers/esm/typeof";

/**
 * Created by PanJiaChen on 16/11/18.
 */

/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string}
 */
export function parseTime(time, cFormat) {
  if (arguments.length === 0 || time === null || time === undefined) {
    return null;
  }

  var format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}';
  var date;

  if (_typeof(time) === 'object') {
    date = time;
  } else {
    if (typeof time === 'string' && /^[0-9]+$/.test(time)) {
      time = parseInt(time);
    }

    if (typeof time === 'number' && time.toString().length === 10) {
      time = time * 1000;
    }

    date = new Date(time);
  }

  var formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  };
  var time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, function (result, key) {
    var value = formatObj[key]; // Note: getDay() returns 0 on Sunday

    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value];
    }

    if (result.length > 0 && value < 10) {
      value = '0' + value;
    }

    return value || 0;
  });
  return time_str;
}
/**
 * @param {number} time
 * @param {string} option
 * @returns {string}
 */

export function formatTime(time, option) {
  if (('' + time).length === 10) {
    time = parseInt(time) * 1000;
  } else {
    time = +time;
  }

  var d = new Date(time);
  var now = Date.now();
  var diff = (now - d) / 1000;

  if (diff < 30) {
    return '刚刚';
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + '分钟前';
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + '小时前';
  } else if (diff < 3600 * 24 * 2) {
    return '1天前';
  }

  if (option) {
    return parseTime(time, option);
  } else {
    return d.getMonth() + 1 + '月' + d.getDate() + '日' + d.getHours() + '时' + d.getMinutes() + '分';
  }
}
/**
 * 计算2个日期之间的天数 输入格式：yyyy-MM-DD
 * @param sDate1
 * @param sDate2
 * @returns {number}
 */

export function daysBetween(sDate1, sDate2) {
  // Date.parse() 解析一个日期时间字符串，并返回1970/1/1 午夜距离该日期时间的毫秒数
  var date1 = _typeof(sDate1) === 'object' ? sDate1 : new Date(sDate1);
  var date2 = _typeof(sDate2) === 'object' ? sDate2 : new Date(sDate2);
  var time1 = Date.parse(date1);
  var time2 = Date.parse(date2);
  var nDays = Math.abs(parseInt((time2 - time1) / 1000 / 3600 / 24));
  return nDays;
}
/**
 * @param {string} url
 * @returns {Object}
 */

export function getQueryObject(url) {
  url = url == null ? window.location.href : url;
  var search = url.substring(url.lastIndexOf('?') + 1);
  var obj = {};
  var reg = /([^?&=]+)=([^?&=]*)/g;
  search.replace(reg, function (rs, $1, $2) {
    var name = decodeURIComponent($1);
    var val = decodeURIComponent($2);
    val = String(val);
    obj[name] = val;
    return rs;
  });
  return obj;
}
/**
 * 数字千分位逗号处理
 * @param number
 * @param decimals
 * @param decPoint
 * @param thousandsSep
 * @returns {string}
 */

export function formatMoney(number) {
  var decPoint = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '.';
  var thousandsSep = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : ',';
  var decimals = 0;
  var valueArray = (number + '').split('.');

  if (valueArray.length === 2) {
    decimals = valueArray[1].length;
  }

  var numberStr = (number + '').replace(/[^0-9+-Ee.]/g, '');
  var n = !isFinite(+numberStr) ? 0 : +numberStr;
  var prec = !isFinite(+decimals) ? 0 : Math.abs(decimals);
  var sep = typeof thousandsSep === 'undefined' ? ',' : thousandsSep;
  var dec = typeof decPoint === 'undefined' ? '.' : decPoint;
  var s = '';

  var toFixedFix = function toFixedFix(n, prec) {
    var k = Math.pow(10, prec);
    return '' + Math.ceil(n * k) / k;
  };

  s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
  var re = /(-?\d+)(\d{3})/;

  while (re.test(s[0])) {
    s[0] = s[0].replace(re, '$1' + sep + '$2');
  }

  if ((s[1] || '').length < prec) {
    s[1] = s[1] || '';
    s[1] += new Array(prec - s[1].length + 1).join('0');
  }

  return s.join(dec);
}
/**
 * @param {string} input value
 * @returns {number} output value
 */

export function byteLength(str) {
  // returns the byte length of an utf8 string
  var s = str.length;

  for (var i = str.length - 1; i >= 0; i--) {
    var code = str.charCodeAt(i);
    if (code > 0x7f && code <= 0x7ff) s++;else if (code > 0x7ff && code <= 0xffff) s += 2;
    if (code >= 0xDC00 && code <= 0xDFFF) i--;
  }

  return s;
}
/**
 * @param {Array} actual
 * @returns {Array}
 */

export function cleanArray(actual) {
  var newArray = [];

  for (var i = 0; i < actual.length; i++) {
    if (actual[i]) {
      newArray.push(actual[i]);
    }
  }

  return newArray;
}
/**
 * @param {Object} json
 * @returns {Array}
 */

export function param(json) {
  if (!json) return '';
  return cleanArray(Object.keys(json).map(function (key) {
    if (json[key] === undefined) return '';
    return encodeURIComponent(key) + '=' + encodeURIComponent(json[key]);
  })).join('&');
}
/**
 * @param {string} url
 * @returns {Object}
 */

export function param2Obj(url) {
  var search = url.split('?')[1];

  if (!search) {
    return {};
  }

  return JSON.parse('{"' + decodeURIComponent(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"').replace(/\+/g, ' ') + '"}');
}
/**
 * @param {string} val
 * @returns {string}
 */

export function html2Text(val) {
  var div = document.createElement('div');
  div.innerHTML = val;
  return div.textContent || div.innerText;
}
/**
 * Merges two objects, giving the last one precedence
 * @param {Object} target
 * @param {(Object|Array)} source
 * @returns {Object}
 */

export function objectMerge(target, source) {
  if (_typeof(target) !== 'object') {
    target = {};
  }

  if (Array.isArray(source)) {
    return source.slice();
  }

  Object.keys(source).forEach(function (property) {
    var sourceProperty = source[property];

    if (_typeof(sourceProperty) === 'object') {
      target[property] = objectMerge(target[property], sourceProperty);
    } else {
      target[property] = sourceProperty;
    }
  });
  return target;
}
/**
 * @param {HTMLElement} element
 * @param {string} className
 */

export function toggleClass(element, className) {
  if (!element || !className) {
    return;
  }

  var classString = element.className;
  var nameIndex = classString.indexOf(className);

  if (nameIndex === -1) {
    classString += '' + className;
  } else {
    classString = classString.substr(0, nameIndex) + classString.substr(nameIndex + className.length);
  }

  element.className = classString;
}
/**
 * @param {string} type
 * @returns {Date}
 */

export function getTime(type) {
  if (type === 'start') {
    return new Date().getTime() - 3600 * 1000 * 24 * 90;
  } else {
    return new Date(new Date().toDateString());
  }
}
/**
 * @param {Function} func
 * @param {number} wait
 * @param {boolean} immediate
 * @return {*}
 */

export function debounce(func, wait, immediate) {
  var timeout, args, context, timestamp, result;

  var later = function later() {
    // 据上一次触发时间间隔
    var last = +new Date() - timestamp; // 上次被包装函数被调用时间间隔 last 小于设定时间间隔 wait

    if (last < wait && last > 0) {
      timeout = setTimeout(later, wait - last);
    } else {
      timeout = null; // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用

      if (!immediate) {
        result = func.apply(context, args);
        if (!timeout) context = args = null;
      }
    }
  };

  return function () {
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    context = this;
    timestamp = +new Date();
    var callNow = immediate && !timeout; // 如果延时不存在，重新设定延时

    if (!timeout) timeout = setTimeout(later, wait);

    if (callNow) {
      result = func.apply(context, args);
      context = args = null;
    }

    return result;
  };
}
/**
 * This is just a simple version of deep copy
 * Has a lot of edge cases bug
 * If you want to use a perfect deep copy, use lodash's _.cloneDeep
 * @param {Object} source
 * @returns {Object}
 */

export function deepClone(source) {
  if (!source && _typeof(source) !== 'object') {
    throw new Error('error arguments', 'deepClone');
  }

  var targetObj = source.constructor === Array ? [] : {};
  Object.keys(source).forEach(function (keys) {
    if (source[keys] && _typeof(source[keys]) === 'object') {
      targetObj[keys] = deepClone(source[keys]);
    } else {
      targetObj[keys] = source[keys];
    }
  });
  return targetObj;
}
/**
 * @param {Array} arr
 * @returns {Array}
 */

export function uniqueArr(arr) {
  return Array.from(new Set(arr));
}
/**
 * @returns {string}
 */

export function createUniqueString() {
  var timestamp = +new Date() + '';
  var randomNum = parseInt((1 + Math.random()) * 65536) + '';
  return (+(randomNum + timestamp)).toString(32);
}
/**
 * Check if an element has a class
 * @param {HTMLElement} elm
 * @param {string} cls
 * @returns {boolean}
 */

export function hasClass(ele, cls) {
  return !!ele.className.match(new RegExp('(\\s|^)' + cls + '(\\s|$)'));
}
/**
 * Add class to element
 * @param {HTMLElement} elm
 * @param {string} cls
 */

export function addClass(ele, cls) {
  if (!hasClass(ele, cls)) ele.className += ' ' + cls;
}
/**
 * Remove class from element
 * @param {HTMLElement} elm
 * @param {string} cls
 */

export function removeClass(ele, cls) {
  if (hasClass(ele, cls)) {
    var reg = new RegExp('(\\s|^)' + cls + '(\\s|$)');
    ele.className = ele.className.replace(reg, ' ');
  }
}
export function isNullOrEmpty(obj) {
  if (typeof obj === 'string') {
    return obj.trim() === '';
  } else if (typeof obj === 'number') {
    return isNaN(obj);
  } else if (Array.isArray(obj)) {
    return obj === undefined || obj === null || obj.length <= 0;
  } else {
    return obj === undefined || obj === null;
  }
}
export function numberLimit(value, limit) {
  value = value.replace(/[^0-9.]/g, '');

  if (value.indexOf('.') > 0) {
    value = value.slice(0, value.indexOf('.') + limit);
  }

  return value;
}
/**
 * 阿拉伯数字转中文数字,
 * 如果传入数字时则最多处理到21位，超过21位js会自动将数字表示成科学计数法，导致精度丢失和处理出错
 * 传入数字字符串则没有限制
 * @param {number|string} digit
 */

export function toZhDigit(digit) {
  digit = typeof digit === 'number' ? String(digit) : digit;
  var zh = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九'];
  var unit = ['千', '百', '十', ''];
  var quot = ['万', '亿', '兆', '京', '垓', '秭', '穰', '沟', '涧', '正', '载', '极', '恒河沙', '阿僧祗', '那由他', '不可思议', '无量', '大数'];
  var breakLen = Math.ceil(digit.length / 4);
  var notBreakSegment = digit.length % 4 || 4;
  var segment;
  var zeroFlag = [];
  var allZeroFlag = [];
  var result = '';

  while (breakLen > 0) {
    if (!result) {
      // 第一次执行
      segment = digit.slice(0, notBreakSegment);
      var segmentLen = segment.length;

      for (var i = 0; i < segmentLen; i++) {
        if (segment[i] !== 0) {
          if (zeroFlag.length > 0) {
            result += '零' + zh[segment[i]] + unit[4 - segmentLen + i]; // 判断是否需要加上 quot 单位

            if (i === segmentLen - 1 && breakLen > 1) {
              result += quot[breakLen - 2];
            }

            zeroFlag.length = 0;
          } else {
            result += zh[segment[i]] + unit[4 - segmentLen + i];

            if (i === segmentLen - 1 && breakLen > 1) {
              result += quot[breakLen - 2];
            }
          }
        } else {
          // 处理为 0 的情形
          if (segmentLen === 1) {
            result += zh[segment[i]];
            break;
          }

          zeroFlag.push(segment[i]);
          continue;
        }
      }
    } else {
      segment = digit.slice(notBreakSegment, notBreakSegment + 4);
      notBreakSegment += 4;

      for (var j = 0; j < segment.length; j++) {
        if (segment[j] !== 0) {
          if (zeroFlag.length > 0) {
            // 第一次执行zeroFlag长度不为0，说明上一个分区最后有0待处理
            if (j === 0) {
              result += quot[breakLen - 1] + zh[segment[j]] + unit[j];
            } else {
              result += '零' + zh[segment[j]] + unit[j];
            }

            zeroFlag.length = 0;
          } else {
            result += zh[segment[j]] + unit[j];
          } // 判断是否需要加上 quot 单位


          if (j === segment.length - 1 && breakLen > 1) {
            result += quot[breakLen - 2];
          }
        } else {
          // 第一次执行如果zeroFlag长度不为0, 且上一划分不全为0
          if (j === 0 && zeroFlag.length > 0 && allZeroFlag.length === 0) {
            result += quot[breakLen - 1];
            zeroFlag.length = 0;
            zeroFlag.push(segment[j]);
          } else if (allZeroFlag.length > 0) {
            // 执行到最后
            if (breakLen === 1) {
              result += '';
            } else {
              zeroFlag.length = 0;
            }
          } else {
            zeroFlag.push(segment[j]);
          }

          if (j === segment.length - 1 && zeroFlag.length === 4 && breakLen !== 1) {
            // 如果执行到末尾
            if (breakLen === 1) {
              allZeroFlag.length = 0;
              zeroFlag.length = 0;
              result += quot[breakLen - 1];
            } else {
              allZeroFlag.push(segment[j]);
            }
          }

          continue;
        }
      }

      --breakLen;
    }

    return result;
  }
}
export function stageName(value) {
  var tagMap = {
    5: '计划',
    10: '询价',
    15: '招标',
    20: '未中标',
    25: '中标',
    30: '合同'
  };
  return tagMap[value] || '计划';
}
export function quoteMethodName(value) {
  var tagMap = {
    1: '单件报价',
    2: '吨报价',
    3: '吨公里价',
    4: '单车价',
    5: '综合'
  };
  return tagMap[value];
}
export function businessTypeName(value) {
  var tagMap = {
    1: '运输',
    2: '装卸',
    3: '仓储',
    4: '综合',
    5: '其他'
  };
  return tagMap[value];
}
export function verifyStatusName(value) {
  var tagMap = {
    0: '待提交',
    5: '待审核',
    10: '未通过',
    15: '已通过'
  };
  return tagMap[value] || '待提交';
}
export function projectStatusName(value) {
  var tagMap = {
    0: '未启运',
    5: '已暂停',
    10: '已启运',
    15: '已终止',
    20: '已完工',
    25: '已完成',
    30: '已关账'
  };
  return tagMap[value];
}
export function verifyStatusStyle(value) {
  var tagMap = {
    0: 'color: #1890ff;',
    5: 'color: #1890ff;',
    10: 'color: #F56C6C;',
    15: 'color: #67C23A;'
  };
  return tagMap[value] || '';
}
export function feeTypeName(value) {
  var tagMap = {
    1: '客户直接费',
    2: '线索直接费',
    3: '线索直接费',
    4: '路勘方案费',
    5: '路勘方案费',
    6: '项目直接费',
    7: '调度直接费'
  };
  return tagMap[value] || '';
}
export function percentageRate(value) {
  if (value !== undefined && value !== null) {
    var v = (value * 100).toFixed(2);
    return v + '%';
  }

  return '';
}
export function defaultPickerOptions() {
  var pickerOptions = {
    shortcuts: [{
      text: '今天',
      onClick: function onClick(picker) {
        var end = new Date();
        var start = new Date();
        picker.$emit('pick', [start, end]);
      }
    }, //   {
    //   text: '明天',
    //   onClick(picker) {
    //     const end = new Date()
    //     const start = new Date()
    //     start.setTime(start.getTime() + 3600 * 1000 * 24)
    //     end.setTime(end.getTime() + 3600 * 1000 * 24)
    //     picker.$emit('pick', [start, end])
    //   }
    // },
    {
      text: '昨天',
      onClick: function onClick(picker) {
        var end = new Date();
        var start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24);
        end.setTime(end.getTime() - 3600 * 1000 * 24);
        picker.$emit('pick', [start, end]);
      }
    }, {
      text: '最近一周',
      onClick: function onClick(picker) {
        var end = new Date();
        var start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
        picker.$emit('pick', [start, end]);
      }
    }, {
      text: '最近一个月',
      onClick: function onClick(picker) {
        var end = new Date();
        var start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
        picker.$emit('pick', [start, end]);
      }
    }, {
      text: '最近三个月',
      onClick: function onClick(picker) {
        var end = new Date();
        var start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
        picker.$emit('pick', [start, end]);
      }
    }]
  };
  return pickerOptions;
}
/**
 * 为el-date-picker提供当月、上月、当年、上年的快捷选择
 * @returns {{shortcuts: [{onClick(*): void, text: string}, {onClick(*): void, text: string}, {onClick(*): void, text: string}, {onClick(*): void, text: string}]}}
 */

export function datePickerOptions() {
  var pickerOptions = {
    shortcuts: [{
      text: '当月',
      onClick: function onClick(picker) {
        var end = new Date();
        var start = new Date(end.getFullYear(), end.getMonth(), 1);
        picker.$emit('pick', [start, end]);
      }
    }, {
      text: '上月',
      onClick: function onClick(picker) {
        var now = new Date();
        var monthStart = new Date(now.getFullYear(), now.getMonth(), 1);
        var start = new Date(monthStart.getTime());
        start.setMonth(start.getMonth() - 1);
        var end = new Date(monthStart.getTime());
        end.setDate(end.getDate() - 1);
        picker.$emit('pick', [start, end]);
      }
    }, {
      text: '当年',
      onClick: function onClick(picker) {
        var end = new Date();
        var start = new Date(end.getFullYear(), 0, 1);
        picker.$emit('pick', [start, end]);
      }
    }, {
      text: '去年',
      onClick: function onClick(picker) {
        var now = new Date();
        var yearStart = new Date(now.getFullYear(), 0, 1);
        var start = new Date(yearStart.getTime());
        start.setFullYear(start.getFullYear() - 1);
        var end = new Date(yearStart.getTime());
        end.setDate(end.getDate() - 1);
        picker.$emit('pick', [start, end]);
      }
    }]
  };
  return pickerOptions;
}
export function urlFileName(url, limit) {
  var out = '附件';

  if (url) {
    var arr = url.split('/');
    var lastStr = arr[arr.length - 1];

    if (lastStr.indexOf('_') !== -1) {
      out = lastStr.split('_')[0];
    } else {
      out = lastStr;
    }
  }

  out = decodeURIComponent(out);

  if (limit) {
    if (limit !== 0) {
      out = stringElision(out, limit);
    }
  } else {
    out = stringElision(out, 8);
  }

  return out;
}
export function stringElision(val, limit) {
  if (!limit) {
    limit = 8;
  }

  if (val && val.length > limit) {
    var str = val.substr(0, limit);
    return str + '...';
  }

  return val;
}
export function roundDecimal(val, digit) {
  if (!digit) {
    digit = 2;
  }

  var divisor = 1;

  for (var i = 0; i < digit; i++) {
    divisor = divisor * 10;
  }

  return Math.round(val * divisor) / divisor;
}
export function toNumber(val) {
  if (Number.isFinite(val)) {
    return val;
  } else {
    var number = Number(val);

    if (Number.isNaN(number)) {
      return 0;
    } else {
      return number;
    }
  }
}
export function toTenThousand(val, digit) {
  if (!digit) {
    digit = 2;
  }

  var divisor = 1;

  for (var i = 0; i < digit; i++) {
    divisor = divisor * 10;
  }

  var divisor2 = 1;

  for (var _i = 0; _i < 4 - digit; _i++) {
    divisor2 = divisor2 * 10;
  }

  return Math.round(val / divisor2) / divisor;
}
export function numberToCurrencyNo(value) {
  if (!value) return 0; // 获取整数部分

  var intPart = Math.trunc(value);

  if (isNaN(intPart)) {
    return value;
  } // 整数部分处理，增加,


  var intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,'); // 预定义小数部分

  var floatPart = ''; // 将数值截取为小数部分和整数部分

  var valueArray = value.toString().split('.');

  if (valueArray.length === 2) {
    // 有小数部分
    floatPart = valueArray[1].toString(); // 取得小数部分

    return intPartFormat + '.' + floatPart;
  }

  return intPartFormat + floatPart;
}
export function isMp4(val) {
  if (!val) {
    return false;
  }

  return val.endsWith('.mp4');
}
export function defPlayerOptions(val) {
  var playerOptions = {
    playbackRates: [0.5, 1.0, 1.5, 2.0],
    // 可选的播放速度
    autoplay: false,
    // 如果为true,浏览器准备好时开始回放。
    muted: false,
    // 默认情况下将会消除任何音频。
    loop: false,
    // 是否视频一结束就重新开始。
    preload: 'auto',
    // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
    language: 'zh-CN',
    aspectRatio: '16:9',
    // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
    fluid: true,
    // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
    sources: [{
      type: 'video/mp4',
      // 类型
      src: 'https://dfwl-ops-qa.oss-cn-zhangjiakou.aliyuncs.com/TransportProcess/1/1655692272310820_202206201100538501538718503351300097.mp4' // url地址

    }],
    poster: '',
    // 封面地址
    notSupportedMessage: '此视频暂无法播放，请稍后再试',
    // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
    controlBar: {
      timeDivider: true,
      // 当前时间和持续时间的分隔符
      durationDisplay: true,
      // 显示持续时间
      remainingTimeDisplay: false,
      // 是否显示剩余时间功能
      fullscreenToggle: true // 是否显示全屏按钮

    }
  };
  return playerOptions;
}
/**
 * 对象的深度拷贝
 * 克隆所有JSON类型：Object Array Number String null
 * 附加支持：Date (复制) undefined (复制)
 * @param {Object|Array} o 待深度复制的对象
 */

export function clone(o) {
  if (_typeof(o) !== 'object' || o === null) {
    return o;
  }

  if (o instanceof Date) {
    return new Date(o);
  }

  if (Array.isArray(o)) {
    var keys = Object.keys(o);
    var a2 = new Array(keys.length);

    for (var i = 0; i < keys.length; i++) {
      var k = keys[i];
      a2[k] = clone(o[k]);
    }

    return a2;
  }

  var o2 = {};

  for (var _k in o) {
    if (Object.hasOwnProperty.call(o, _k) === false) {
      continue;
    }

    o2[_k] = clone(o[_k]);
  }

  return o2;
}
/**
 * 车牌号校验
 * @param {string} plateNo 车牌号
 * @returns {boolean} 是否符合车牌号规则 true:符合 false:不符合
*/

export function checkPlateNumberFormat(plateNo) {
  if (!plateNo) return false;
  var len = plateNo.length; // 位数校验

  if (len !== 7 && len !== 8) return false; // 车牌号规则校验

  var PLATE_NO_PATTERN = /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼]{1}[A-HJ-NP-Z]{1}(([A-HJ-NP-Z0-9]{5})|([0-9]{6}|[A-HJ-NP-Z]{1}[0-9]{5}|[0-9]{5}[A-HJ-NP-Z]{1}|[A-HJ-NP-Z]{2}[0-9]{4}))$/;

  if (PLATE_NO_PATTERN.test(plateNo)) {
    // 新能源车
    if (len === 8) return true; // 7位时,后5位最多包含2位字母校验

    var arr = plateNo.match(/[A-HJ-NP-Z]/g);
    if (arr.length <= 3) return true;
  }

  return false;
}
export function fetchCostTypeList(costTypeList, busType) {
  if (busType === undefined || busType === null) {
    return costTypeList;
  }

  if (costTypeList === null || costTypeList === undefined) {
    return null;
  }

  var outCostTypeList = [];
  costTypeList.forEach(function (costType) {
    if (costType[busType + 'Fee']) {
      costType.children = fetchCostTypeList(costType.children, busType);
      outCostTypeList.push(costType);
    }
  });
  return outCostTypeList;
}
export function uniqueFunc(arr, uniId) {
  var res = new Map();
  return arr.filter(function (item) {
    return !res.has(item[uniId]) && res.set(item[uniId], 1);
  });
}
export function alertMsg(msg, msgBox) {
  if (_typeof(msg) === 'object') {
    var key = Object.keys(msg).pop();
    var m = msg[key][0].message;
    msgBox.warning(m);
  }
}
export function sleep(ms) {
  return new Promise(function (resolve) {
    return setTimeout(resolve, ms);
  });
}