var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.props.compType === "table",
          expression: "props.compType === 'table'"
        }
      ]
    },
    [
      _c(
        "el-form-item",
        { attrs: { label: "ID" } },
        [
          _c(
            "el-tooltip",
            {
              staticClass: "item",
              attrs: {
                effect: "dark",
                content: "请注意,ID的修改可能会导致该组件相关事件失效！",
                placement: "left"
              }
            },
            [
              _c("el-input", {
                staticClass: "input",
                on: { change: _vm.handlerChangeId },
                model: {
                  value: _vm.props.id,
                  callback: function($$v) {
                    _vm.$set(_vm.props, "id", $$v)
                  },
                  expression: "props.id"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "单元格样式" } },
        [
          _c("el-input", {
            staticClass: "input",
            model: {
              value: _vm.props.tdStyle,
              callback: function($$v) {
                _vm.$set(_vm.props, "tdStyle", $$v)
              },
              expression: "props.tdStyle"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "表格宽度" } },
        [
          _c("el-inputNumber", {
            staticClass: "input",
            attrs: { min: 0, max: 500 },
            model: {
              value: _vm.props.width,
              callback: function($$v) {
                _vm.$set(_vm.props, "width", $$v)
              },
              expression: "props.width"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "行高" } },
        [
          _c("el-inputNumber", {
            staticClass: "input",
            attrs: { min: 0, max: 1500, step: 5 },
            model: {
              value: _vm.props.height,
              callback: function($$v) {
                _vm.$set(_vm.props, "height", $$v)
              },
              expression: "props.height"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }