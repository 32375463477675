var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.props.compType === "divider",
          expression: "props.compType === 'divider'"
        }
      ]
    },
    [
      _c(
        "el-form-item",
        { attrs: { label: "ID" } },
        [
          _c(
            "el-tooltip",
            {
              staticClass: "item",
              attrs: {
                effect: "dark",
                content: "请注意,ID的修改可能会导致该组件相关事件失效！",
                placement: "left"
              }
            },
            [
              _c("el-input", {
                staticClass: "input",
                model: {
                  value: _vm.props.id,
                  callback: function($$v) {
                    _vm.$set(_vm.props, "id", $$v)
                  },
                  expression: "props.id"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "文字" } },
        [
          _c("el-input", {
            staticClass: "input",
            model: {
              value: _vm.props.text,
              callback: function($$v) {
                _vm.$set(_vm.props, "text", $$v)
              },
              expression: "props.text"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "文字大小" } },
        [
          _c(
            "el-radio-group",
            {
              model: {
                value: _vm.props["content-position"],
                callback: function($$v) {
                  _vm.$set(_vm.props, "content-position", $$v)
                },
                expression: "props['content-position']"
              }
            },
            [
              _c("el-radio-button", { attrs: { label: "left" } }, [
                _vm._v("左")
              ]),
              _c("el-radio-button", { attrs: { label: "center" } }, [
                _vm._v("中")
              ]),
              _c("el-radio-button", { attrs: { label: "right" } }, [
                _vm._v("右")
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }