var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-tooltip",
    _vm._b(
      {
        attrs: { disabled: _vm.disabledTooltip },
        scopedSlots: _vm._u(
          [
            {
              key: "content",
              fn: function() {
                return [_vm._t(_vm.$slots.content ? "content" : "default")]
              },
              proxy: true
            }
          ],
          null,
          true
        ),
        model: {
          value: _vm.tooltipVisible,
          callback: function($$v) {
            _vm.tooltipVisible = $$v
          },
          expression: "tooltipVisible"
        }
      },
      "el-tooltip",
      _vm.$attrs,
      false
    ),
    [
      _c(
        "span",
        {
          ref: "triggerRef",
          class: {
            "text-ellipsis": true,
            "text-ellipsis-line-clamp": _vm.lineClamp
          },
          on: { mouseenter: _vm.setTooltipDisabled }
        },
        [
          _c(
            "span",
            {
              ref: "triggerInnerRef",
              staticClass: "text-ellipsis-inner",
              style: _vm.lineClampStyle
            },
            [_vm._t("default")],
            2
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }