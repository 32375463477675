var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.props.compType === "link",
          expression: "props.compType === 'link'"
        }
      ]
    },
    [
      _c(
        "el-form-item",
        { attrs: { label: "ID" } },
        [
          _c(
            "el-tooltip",
            {
              staticClass: "item",
              attrs: {
                effect: "dark",
                content: "请注意,ID的修改可能会导致该组件相关事件失效！",
                placement: "left"
              }
            },
            [
              _c("el-input", {
                staticClass: "input",
                on: { change: _vm.handlerChangeId },
                model: {
                  value: _vm.props.id,
                  callback: function($$v) {
                    _vm.$set(_vm.props, "id", $$v)
                  },
                  expression: "props.id"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "链接文字" } },
        [
          _c("el-input", {
            staticClass: "input",
            model: {
              value: _vm.props.text,
              callback: function($$v) {
                _vm.$set(_vm.props, "text", $$v)
              },
              expression: "props.text"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "类型" } },
        [
          _c(
            "el-select",
            {
              attrs: { placeholder: "请选择" },
              model: {
                value: _vm.props.type,
                callback: function($$v) {
                  _vm.$set(_vm.props, "type", $$v)
                },
                expression: "props.type"
              }
            },
            [
              _c("el-option", {
                attrs: { label: "primary", value: "primary" }
              }),
              _c("el-option", {
                attrs: { label: "success", value: "success" }
              }),
              _c("el-option", {
                attrs: { label: "warning", value: "warning" }
              }),
              _c("el-option", { attrs: { label: "danger", value: "danger" } }),
              _c("el-option", { attrs: { label: "info", value: "info" } })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "url" } },
        [
          _c("el-input", {
            staticClass: "input",
            model: {
              value: _vm.props.href,
              callback: function($$v) {
                _vm.$set(_vm.props, "href", $$v)
              },
              expression: "props.href"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "禁用" } },
        [
          _c("el-switch", {
            model: {
              value: _vm.props.disabled,
              callback: function($$v) {
                _vm.$set(_vm.props, "disabled", $$v)
              },
              expression: "props.disabled"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "新窗口" } },
        [
          _c("el-switch", {
            on: { change: _vm.handlerChangeTargetValue },
            model: {
              value: _vm.isNewWindow,
              callback: function($$v) {
                _vm.isNewWindow = $$v
              },
              expression: "isNewWindow"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }