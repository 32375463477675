var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.props.compType === "checkbox",
          expression: "props.compType === 'checkbox'"
        }
      ]
    },
    [
      _c(
        "el-form-item",
        { attrs: { label: "ID" } },
        [
          _c(
            "el-tooltip",
            {
              staticClass: "item",
              attrs: {
                effect: "dark",
                content: "请注意,ID的修改可能会导致该组件相关事件失效！",
                placement: "left"
              }
            },
            [
              _c("el-input", {
                staticClass: "input",
                on: { change: _vm.handlerChangeId },
                model: {
                  value: _vm.props.id,
                  callback: function($$v) {
                    _vm.$set(_vm.props, "id", $$v)
                  },
                  expression: "props.id"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "标题" } },
        [
          _c("el-input", {
            staticClass: "input",
            model: {
              value: _vm.props.label,
              callback: function($$v) {
                _vm.$set(_vm.props, "label", $$v)
              },
              expression: "props.label"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "表单栅格" } },
        [
          _c("el-slider", {
            staticClass: "input",
            attrs: { max: 24, min: 1, marks: { 12: "" } },
            model: {
              value: _vm.props.span,
              callback: function($$v) {
                _vm.$set(_vm.props, "span", $$v)
              },
              expression: "props.span"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "栅格间隔" } },
        [
          _c("el-input-number", {
            attrs: { min: 0 },
            model: {
              value: _vm.props.gutter,
              callback: function($$v) {
                _vm.$set(_vm.props, "gutter", $$v)
              },
              expression: "props.gutter"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "标签宽度" } },
        [
          _c("el-input-number", {
            attrs: { min: 1, max: 200 },
            model: {
              value: _vm.props.labelWidth,
              callback: function($$v) {
                _vm.$set(_vm.props, "labelWidth", $$v)
              },
              expression: "props.labelWidth"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "显示标签" } },
        [
          _c("el-switch", {
            on: { change: _vm.handlerChangeLabel },
            model: {
              value: _vm.props.showLabel,
              callback: function($$v) {
                _vm.$set(_vm.props, "showLabel", $$v)
              },
              expression: "props.showLabel"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "必填" } },
        [
          _c("el-switch", {
            model: {
              value: _vm.props.required,
              callback: function($$v) {
                _vm.$set(_vm.props, "required", $$v)
              },
              expression: "props.required"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "垂直" } },
        [
          _c("el-switch", {
            model: {
              value: _vm.props.vertical,
              callback: function($$v) {
                _vm.$set(_vm.props, "vertical", $$v)
              },
              expression: "props.vertical"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "最小数量" } },
        [
          _c("el-input-number", {
            attrs: { min: 1 },
            model: {
              value: _vm.props.min,
              callback: function($$v) {
                _vm.$set(_vm.props, "min", $$v)
              },
              expression: "props.min"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "最大数量" } },
        [
          _c("el-input-number", {
            attrs: { min: 1 },
            model: {
              value: _vm.props.max,
              callback: function($$v) {
                _vm.$set(_vm.props, "max", $$v)
              },
              expression: "props.max"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "选项样式" } },
        [
          _c(
            "el-radio-group",
            {
              model: {
                value: _vm.props.optionType,
                callback: function($$v) {
                  _vm.$set(_vm.props, "optionType", $$v)
                },
                expression: "props.optionType"
              }
            },
            [
              _c("el-radio-button", { attrs: { label: "default" } }, [
                _vm._v("默认")
              ]),
              _c("el-radio-button", { attrs: { label: "button" } }, [
                _vm._v("按钮")
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "显示边框" } },
        [
          _c("el-switch", {
            model: {
              value: _vm.props.border,
              callback: function($$v) {
                _vm.$set(_vm.props, "border", $$v)
              },
              expression: "props.border"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.props.border || _vm.props.optionType === "button",
              expression: "props.border||props.optionType ==='button'"
            }
          ],
          attrs: { label: "选项尺寸" }
        },
        [
          _c(
            "el-radio-group",
            {
              model: {
                value: _vm.props.size,
                callback: function($$v) {
                  _vm.$set(_vm.props, "size", $$v)
                },
                expression: "props.size"
              }
            },
            [
              _c("el-radio-button", { attrs: { label: "medium" } }, [
                _vm._v("正常")
              ]),
              _c("el-radio-button", { attrs: { label: "small" } }, [
                _vm._v("略小")
              ]),
              _c("el-radio-button", { attrs: { label: "mini" } }, [
                _vm._v("迷你")
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "禁用" } },
        [
          _c("el-switch", {
            on: {
              change: function($event) {
                return _vm.handlerChangeDisStatus("disabled")
              }
            },
            model: {
              value: _vm.props.disabled,
              callback: function($$v) {
                _vm.$set(_vm.props, "disabled", $$v)
              },
              expression: "props.disabled"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "默认值" } },
        [
          _c("el-input", {
            staticClass: "input",
            attrs: {
              value: _vm.setDefaultValue(_vm.props.value),
              placeholder: "请输入默认值"
            },
            on: { input: _vm.onDefaultValueInput }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "数据类型" } },
        [
          _c(
            "el-radio-group",
            {
              on: { change: _vm.handlerChangeDataType },
              model: {
                value: _vm.props.dataType,
                callback: function($$v) {
                  _vm.$set(_vm.props, "dataType", $$v)
                },
                expression: "props.dataType"
              }
            },
            [
              _c("el-radio-button", { attrs: { label: "static" } }, [
                _vm._v("静态数据")
              ]),
              _c("el-radio-button", { attrs: { label: "dynamic" } }, [
                _vm._v("动态数据")
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.props.dataType === "static",
              expression: "props.dataType ==='static'"
            }
          ]
        },
        [
          _c("el-divider", [_vm._v("选项")]),
          _c(
            "draggable",
            { attrs: { list: _vm.props.options, handle: ".option-drag" } },
            _vm._l(_vm.props.options, function(item, index) {
              return _c(
                "div",
                { key: index, staticClass: "select-item" },
                [
                  _c("div", { staticClass: "select-line-icon option-drag" }, [
                    _c("i", { staticClass: "el-icon-s-operation" })
                  ]),
                  _c("el-input", {
                    attrs: { placeholder: "选项名", size: "small" },
                    model: {
                      value: item.label,
                      callback: function($$v) {
                        _vm.$set(item, "label", $$v)
                      },
                      expression: "item.label"
                    }
                  }),
                  _c("el-input", {
                    attrs: {
                      placeholder: "选项值",
                      size: "small",
                      value: item.value
                    },
                    on: {
                      input: function($event) {
                        return _vm.setOptionValue(item, $event)
                      }
                    }
                  }),
                  _c(
                    "div",
                    {
                      staticClass: "close-btn select-line-icon",
                      on: {
                        click: function($event) {
                          return _vm.props.options.splice(index, 1)
                        }
                      }
                    },
                    [_c("i", { staticClass: "el-icon-remove-outline" })]
                  )
                ],
                1
              )
            }),
            0
          ),
          _c(
            "div",
            { staticStyle: { "margin-left": "20px" } },
            [
              _c(
                "el-button",
                {
                  staticStyle: { "padding-bottom": "0" },
                  attrs: { icon: "el-icon-circle-plus-outline", type: "text" },
                  on: { click: _vm.addSelectItem }
                },
                [_vm._v(" 添加选项 ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.props.dataType === "dynamic",
              expression: "props.dataType ==='dynamic'"
            }
          ]
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "地址" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.props.action,
                  callback: function($$v) {
                    _vm.$set(_vm.props, "action", $$v)
                  },
                  expression: "props.action"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }