import request from '@/utils/request';
var basePath = '/api/v1/finance-base/finance-matters-merge';
var baseUrl = process.env.VUE_APP_FINANCE_API;
export function statProfileMatter() {
  return request({
    baseURL: baseUrl,
    url: basePath + '/stat-profile-matter',
    method: 'get'
  });
}
export function listProfileMatters(query) {
  return request({
    baseURL: baseUrl,
    url: basePath + '/list-profile-finance-matters',
    method: 'get',
    params: query
  });
}
export function listSettleStaff(query) {
  return request({
    baseURL: baseUrl,
    url: basePath + '/list-settle-staff',
    method: 'get',
    params: query
  });
}
export function listCustomerBySettleStaff(query) {
  return request({
    baseURL: baseUrl,
    url: basePath + '/list-settle-staff-customer',
    method: 'get',
    params: query
  });
}
export function getProfileFinManageData(query) {
  return request({
    baseURL: baseUrl,
    url: basePath + '/profile-finance-manage-data',
    method: 'get',
    params: query
  });
}
export function listTargetMatters(query) {
  return request({
    baseURL: baseUrl,
    url: basePath + '/list-target-matters',
    method: 'get',
    params: query
  });
}
export function listBatchReview(query) {
  return request({
    baseURL: baseUrl,
    url: basePath + '/list-review',
    method: 'get',
    params: query
  });
}
export function countConfirmIncomeReview(query) {
  return request({
    baseURL: baseUrl,
    url: basePath + '/count-confirm-income-review',
    method: 'get',
    params: query
  });
}
export function listConfirmIncomeReview(query) {
  return request({
    baseURL: baseUrl,
    url: basePath + '/list-confirm-income-review',
    method: 'get',
    params: query
  });
}
export function deleteFeeApplyMatter(data) {
  return request({
    baseURL: baseUrl,
    url: basePath + '/delete-fee-apply-matter',
    method: 'post',
    data: {
      data: data
    }
  });
}
export function listPettyCashFee(query) {
  return request({
    baseURL: baseUrl,
    url: basePath + '/list-petty-cash-fee',
    method: 'get',
    params: query
  });
}
export function batchPayment(data) {
  return request({
    baseURL: baseUrl,
    url: basePath + '/batch-payment',
    method: 'post',
    data: {
      data: data
    }
  });
}
export function getBatchPayment(query) {
  return request({
    baseURL: baseUrl,
    url: basePath + '/batch-payment/{reviewId}',
    method: 'get',
    params: query
  });
}
export function getPaymentConfirmIncome(query) {
  return request({
    baseURL: baseUrl,
    url: basePath + '/batch-payment-confirm-income/{reviewId}',
    method: 'get',
    params: query
  });
}
export function SupplierPaymentConfirmIncome(data) {
  return request({
    baseURL: baseUrl,
    url: basePath + '/supplier-payment-confirm-income',
    method: 'post',
    data: {
      data: data
    }
  });
}
export function batchReceipt(data) {
  return request({
    baseURL: baseUrl,
    url: basePath + '/batch-receipt',
    method: 'post',
    data: {
      data: data
    }
  });
}
export function getBatchReceipt(query) {
  return request({
    baseURL: baseUrl,
    url: basePath + '/batch-receipt/{reviewId}',
    method: 'get',
    params: query
  });
}
export function batchReceiveInvoice(data) {
  return request({
    baseURL: baseUrl,
    url: basePath + '/batch-receive-invoice',
    method: 'post',
    data: {
      data: data
    }
  });
}
export function getBatchReceiveInvoice(query) {
  return request({
    baseURL: baseUrl,
    url: basePath + '/batch-receive-invoice/{reviewId}',
    method: 'get',
    params: query
  });
}
export function applyIssueInvoice(data) {
  return request({
    baseURL: baseUrl,
    url: basePath + '/apply-issue-invoice',
    method: 'post',
    data: {
      data: data
    }
  });
}
export function getIssueInvoice(query) {
  return request({
    baseURL: baseUrl,
    url: basePath + '/issue-invoice/{reviewId}',
    method: 'get',
    params: query
  });
}
export function batchProjectFee(data) {
  return request({
    baseURL: baseUrl,
    url: basePath + '/batch-project-fee',
    method: 'post',
    data: {
      data: data
    }
  });
}
export function getBatchProjectFee(query) {
  return request({
    baseURL: baseUrl,
    url: basePath + '/batch-project-fee/{reviewId}',
    method: 'get',
    params: query
  });
}
export function applyCancelBatch(data) {
  return request({
    baseURL: baseUrl,
    url: basePath + '/apply-cancel',
    method: 'post',
    data: {
      data: data
    }
  });
}
export function deletePendingReview(query) {
  return request({
    baseURL: baseUrl,
    url: basePath + '/delete-pending-review/{reviewId}',
    method: 'delete',
    params: query
  });
}