var data = [{
  'regionId': '110000',
  'parentId': '0',
  'name': '北京市',
  'children': [{
    'regionId': '110100',
    'parentId': '110000',
    'name': '北京城区',
    'children': [{
      'regionId': '110101',
      'parentId': '110100',
      'name': '东城区',
      'children': null
    }, {
      'regionId': '110102',
      'parentId': '110100',
      'name': '西城区',
      'children': null
    }, {
      'regionId': '110105',
      'parentId': '110100',
      'name': '朝阳区',
      'children': null
    }, {
      'regionId': '110106',
      'parentId': '110100',
      'name': '丰台区',
      'children': null
    }, {
      'regionId': '110107',
      'parentId': '110100',
      'name': '石景山区',
      'children': null
    }, {
      'regionId': '110108',
      'parentId': '110100',
      'name': '海淀区',
      'children': null
    }, {
      'regionId': '110109',
      'parentId': '110100',
      'name': '门头沟区',
      'children': null
    }, {
      'regionId': '110111',
      'parentId': '110100',
      'name': '房山区',
      'children': null
    }, {
      'regionId': '110112',
      'parentId': '110100',
      'name': '通州区',
      'children': null
    }, {
      'regionId': '110113',
      'parentId': '110100',
      'name': '顺义区',
      'children': null
    }, {
      'regionId': '110114',
      'parentId': '110100',
      'name': '昌平区',
      'children': null
    }, {
      'regionId': '110115',
      'parentId': '110100',
      'name': '大兴区',
      'children': null
    }, {
      'regionId': '110116',
      'parentId': '110100',
      'name': '怀柔区',
      'children': null
    }, {
      'regionId': '110117',
      'parentId': '110100',
      'name': '平谷区',
      'children': null
    }, {
      'regionId': '110118',
      'parentId': '110100',
      'name': '密云区',
      'children': null
    }, {
      'regionId': '110119',
      'parentId': '110100',
      'name': '延庆区',
      'children': null
    }]
  }]
}, {
  'regionId': '120000',
  'parentId': '0',
  'name': '天津市',
  'children': [{
    'regionId': '120100',
    'parentId': '120000',
    'name': '天津城区',
    'children': [{
      'regionId': '120101',
      'parentId': '120100',
      'name': '和平区',
      'children': null
    }, {
      'regionId': '120102',
      'parentId': '120100',
      'name': '河东区',
      'children': null
    }, {
      'regionId': '120103',
      'parentId': '120100',
      'name': '河西区',
      'children': null
    }, {
      'regionId': '120104',
      'parentId': '120100',
      'name': '南开区',
      'children': null
    }, {
      'regionId': '120105',
      'parentId': '120100',
      'name': '河北区',
      'children': null
    }, {
      'regionId': '120106',
      'parentId': '120100',
      'name': '红桥区',
      'children': null
    }, {
      'regionId': '120110',
      'parentId': '120100',
      'name': '东丽区',
      'children': null
    }, {
      'regionId': '120111',
      'parentId': '120100',
      'name': '西青区',
      'children': null
    }, {
      'regionId': '120112',
      'parentId': '120100',
      'name': '津南区',
      'children': null
    }, {
      'regionId': '120113',
      'parentId': '120100',
      'name': '北辰区',
      'children': null
    }, {
      'regionId': '120114',
      'parentId': '120100',
      'name': '武清区',
      'children': null
    }, {
      'regionId': '120115',
      'parentId': '120100',
      'name': '宝坻区',
      'children': null
    }, {
      'regionId': '120116',
      'parentId': '120100',
      'name': '滨海新区',
      'children': null
    }, {
      'regionId': '120117',
      'parentId': '120100',
      'name': '宁河区',
      'children': null
    }, {
      'regionId': '120118',
      'parentId': '120100',
      'name': '静海区',
      'children': null
    }, {
      'regionId': '120119',
      'parentId': '120100',
      'name': '蓟州区',
      'children': null
    }]
  }]
}, {
  'regionId': '130000',
  'parentId': '0',
  'name': '河北省',
  'children': [{
    'regionId': '130100',
    'parentId': '130000',
    'name': '石家庄市',
    'children': [{
      'regionId': '130102',
      'parentId': '130100',
      'name': '长安区',
      'children': null
    }, {
      'regionId': '130104',
      'parentId': '130100',
      'name': '桥西区',
      'children': null
    }, {
      'regionId': '130105',
      'parentId': '130100',
      'name': '新华区',
      'children': null
    }, {
      'regionId': '130107',
      'parentId': '130100',
      'name': '井陉矿区',
      'children': null
    }, {
      'regionId': '130108',
      'parentId': '130100',
      'name': '裕华区',
      'children': null
    }, {
      'regionId': '130109',
      'parentId': '130100',
      'name': '藁城区',
      'children': null
    }, {
      'regionId': '130110',
      'parentId': '130100',
      'name': '鹿泉区',
      'children': null
    }, {
      'regionId': '130111',
      'parentId': '130100',
      'name': '栾城区',
      'children': null
    }, {
      'regionId': '130121',
      'parentId': '130100',
      'name': '井陉县',
      'children': null
    }, {
      'regionId': '130123',
      'parentId': '130100',
      'name': '正定县',
      'children': null
    }, {
      'regionId': '130125',
      'parentId': '130100',
      'name': '行唐县',
      'children': null
    }, {
      'regionId': '130126',
      'parentId': '130100',
      'name': '灵寿县',
      'children': null
    }, {
      'regionId': '130127',
      'parentId': '130100',
      'name': '高邑县',
      'children': null
    }, {
      'regionId': '130128',
      'parentId': '130100',
      'name': '深泽县',
      'children': null
    }, {
      'regionId': '130129',
      'parentId': '130100',
      'name': '赞皇县',
      'children': null
    }, {
      'regionId': '130130',
      'parentId': '130100',
      'name': '无极县',
      'children': null
    }, {
      'regionId': '130131',
      'parentId': '130100',
      'name': '平山县',
      'children': null
    }, {
      'regionId': '130132',
      'parentId': '130100',
      'name': '元氏县',
      'children': null
    }, {
      'regionId': '130133',
      'parentId': '130100',
      'name': '赵县',
      'children': null
    }, {
      'regionId': '130181',
      'parentId': '130100',
      'name': '辛集市',
      'children': null
    }, {
      'regionId': '130183',
      'parentId': '130100',
      'name': '晋州市',
      'children': null
    }, {
      'regionId': '130184',
      'parentId': '130100',
      'name': '新乐市',
      'children': null
    }]
  }, {
    'regionId': '130200',
    'parentId': '130000',
    'name': '唐山市',
    'children': [{
      'regionId': '130202',
      'parentId': '130200',
      'name': '路南区',
      'children': null
    }, {
      'regionId': '130203',
      'parentId': '130200',
      'name': '路北区',
      'children': null
    }, {
      'regionId': '130204',
      'parentId': '130200',
      'name': '古冶区',
      'children': null
    }, {
      'regionId': '130205',
      'parentId': '130200',
      'name': '开平区',
      'children': null
    }, {
      'regionId': '130207',
      'parentId': '130200',
      'name': '丰南区',
      'children': null
    }, {
      'regionId': '130208',
      'parentId': '130200',
      'name': '丰润区',
      'children': null
    }, {
      'regionId': '130209',
      'parentId': '130200',
      'name': '曹妃甸区',
      'children': null
    }, {
      'regionId': '130224',
      'parentId': '130200',
      'name': '滦南县',
      'children': null
    }, {
      'regionId': '130225',
      'parentId': '130200',
      'name': '乐亭县',
      'children': null
    }, {
      'regionId': '130227',
      'parentId': '130200',
      'name': '迁西县',
      'children': null
    }, {
      'regionId': '130229',
      'parentId': '130200',
      'name': '玉田县',
      'children': null
    }, {
      'regionId': '130281',
      'parentId': '130200',
      'name': '遵化市',
      'children': null
    }, {
      'regionId': '130283',
      'parentId': '130200',
      'name': '迁安市',
      'children': null
    }, {
      'regionId': '130284',
      'parentId': '130200',
      'name': '滦州市',
      'children': null
    }]
  }, {
    'regionId': '130300',
    'parentId': '130000',
    'name': '秦皇岛市',
    'children': [{
      'regionId': '130302',
      'parentId': '130300',
      'name': '海港区',
      'children': null
    }, {
      'regionId': '130303',
      'parentId': '130300',
      'name': '山海关区',
      'children': null
    }, {
      'regionId': '130304',
      'parentId': '130300',
      'name': '北戴河区',
      'children': null
    }, {
      'regionId': '130306',
      'parentId': '130300',
      'name': '抚宁区',
      'children': null
    }, {
      'regionId': '130321',
      'parentId': '130300',
      'name': '青龙满族自治县',
      'children': null
    }, {
      'regionId': '130322',
      'parentId': '130300',
      'name': '昌黎县',
      'children': null
    }, {
      'regionId': '130324',
      'parentId': '130300',
      'name': '卢龙县',
      'children': null
    }]
  }, {
    'regionId': '130400',
    'parentId': '130000',
    'name': '邯郸市',
    'children': [{
      'regionId': '130402',
      'parentId': '130400',
      'name': '邯山区',
      'children': null
    }, {
      'regionId': '130403',
      'parentId': '130400',
      'name': '丛台区',
      'children': null
    }, {
      'regionId': '130404',
      'parentId': '130400',
      'name': '复兴区',
      'children': null
    }, {
      'regionId': '130406',
      'parentId': '130400',
      'name': '峰峰矿区',
      'children': null
    }, {
      'regionId': '130407',
      'parentId': '130400',
      'name': '肥乡区',
      'children': null
    }, {
      'regionId': '130408',
      'parentId': '130400',
      'name': '永年区',
      'children': null
    }, {
      'regionId': '130423',
      'parentId': '130400',
      'name': '临漳县',
      'children': null
    }, {
      'regionId': '130424',
      'parentId': '130400',
      'name': '成安县',
      'children': null
    }, {
      'regionId': '130425',
      'parentId': '130400',
      'name': '大名县',
      'children': null
    }, {
      'regionId': '130426',
      'parentId': '130400',
      'name': '涉县',
      'children': null
    }, {
      'regionId': '130427',
      'parentId': '130400',
      'name': '磁县',
      'children': null
    }, {
      'regionId': '130430',
      'parentId': '130400',
      'name': '邱县',
      'children': null
    }, {
      'regionId': '130431',
      'parentId': '130400',
      'name': '鸡泽县',
      'children': null
    }, {
      'regionId': '130432',
      'parentId': '130400',
      'name': '广平县',
      'children': null
    }, {
      'regionId': '130433',
      'parentId': '130400',
      'name': '馆陶县',
      'children': null
    }, {
      'regionId': '130434',
      'parentId': '130400',
      'name': '魏县',
      'children': null
    }, {
      'regionId': '130435',
      'parentId': '130400',
      'name': '曲周县',
      'children': null
    }, {
      'regionId': '130481',
      'parentId': '130400',
      'name': '武安市',
      'children': null
    }]
  }, {
    'regionId': '130500',
    'parentId': '130000',
    'name': '邢台市',
    'children': [{
      'regionId': '130502',
      'parentId': '130500',
      'name': '襄都区',
      'children': null
    }, {
      'regionId': '130503',
      'parentId': '130500',
      'name': '信都区',
      'children': null
    }, {
      'regionId': '130505',
      'parentId': '130500',
      'name': '任泽区',
      'children': null
    }, {
      'regionId': '130506',
      'parentId': '130500',
      'name': '南和区',
      'children': null
    }, {
      'regionId': '130522',
      'parentId': '130500',
      'name': '临城县',
      'children': null
    }, {
      'regionId': '130523',
      'parentId': '130500',
      'name': '内丘县',
      'children': null
    }, {
      'regionId': '130524',
      'parentId': '130500',
      'name': '柏乡县',
      'children': null
    }, {
      'regionId': '130525',
      'parentId': '130500',
      'name': '隆尧县',
      'children': null
    }, {
      'regionId': '130528',
      'parentId': '130500',
      'name': '宁晋县',
      'children': null
    }, {
      'regionId': '130529',
      'parentId': '130500',
      'name': '巨鹿县',
      'children': null
    }, {
      'regionId': '130530',
      'parentId': '130500',
      'name': '新河县',
      'children': null
    }, {
      'regionId': '130531',
      'parentId': '130500',
      'name': '广宗县',
      'children': null
    }, {
      'regionId': '130532',
      'parentId': '130500',
      'name': '平乡县',
      'children': null
    }, {
      'regionId': '130533',
      'parentId': '130500',
      'name': '威县',
      'children': null
    }, {
      'regionId': '130534',
      'parentId': '130500',
      'name': '清河县',
      'children': null
    }, {
      'regionId': '130535',
      'parentId': '130500',
      'name': '临西县',
      'children': null
    }, {
      'regionId': '130581',
      'parentId': '130500',
      'name': '南宫市',
      'children': null
    }, {
      'regionId': '130582',
      'parentId': '130500',
      'name': '沙河市',
      'children': null
    }]
  }, {
    'regionId': '130600',
    'parentId': '130000',
    'name': '保定市',
    'children': [{
      'regionId': '130602',
      'parentId': '130600',
      'name': '竞秀区',
      'children': null
    }, {
      'regionId': '130606',
      'parentId': '130600',
      'name': '莲池区',
      'children': null
    }, {
      'regionId': '130607',
      'parentId': '130600',
      'name': '满城区',
      'children': null
    }, {
      'regionId': '130608',
      'parentId': '130600',
      'name': '清苑区',
      'children': null
    }, {
      'regionId': '130609',
      'parentId': '130600',
      'name': '徐水区',
      'children': null
    }, {
      'regionId': '130623',
      'parentId': '130600',
      'name': '涞水县',
      'children': null
    }, {
      'regionId': '130624',
      'parentId': '130600',
      'name': '阜平县',
      'children': null
    }, {
      'regionId': '130626',
      'parentId': '130600',
      'name': '定兴县',
      'children': null
    }, {
      'regionId': '130627',
      'parentId': '130600',
      'name': '唐县',
      'children': null
    }, {
      'regionId': '130628',
      'parentId': '130600',
      'name': '高阳县',
      'children': null
    }, {
      'regionId': '130629',
      'parentId': '130600',
      'name': '容城县',
      'children': null
    }, {
      'regionId': '130630',
      'parentId': '130600',
      'name': '涞源县',
      'children': null
    }, {
      'regionId': '130631',
      'parentId': '130600',
      'name': '望都县',
      'children': null
    }, {
      'regionId': '130632',
      'parentId': '130600',
      'name': '安新县',
      'children': null
    }, {
      'regionId': '130633',
      'parentId': '130600',
      'name': '易县',
      'children': null
    }, {
      'regionId': '130634',
      'parentId': '130600',
      'name': '曲阳县',
      'children': null
    }, {
      'regionId': '130635',
      'parentId': '130600',
      'name': '蠡县',
      'children': null
    }, {
      'regionId': '130636',
      'parentId': '130600',
      'name': '顺平县',
      'children': null
    }, {
      'regionId': '130637',
      'parentId': '130600',
      'name': '博野县',
      'children': null
    }, {
      'regionId': '130638',
      'parentId': '130600',
      'name': '雄县',
      'children': null
    }, {
      'regionId': '130681',
      'parentId': '130600',
      'name': '涿州市',
      'children': null
    }, {
      'regionId': '130682',
      'parentId': '130600',
      'name': '定州市',
      'children': null
    }, {
      'regionId': '130683',
      'parentId': '130600',
      'name': '安国市',
      'children': null
    }, {
      'regionId': '130684',
      'parentId': '130600',
      'name': '高碑店市',
      'children': null
    }]
  }, {
    'regionId': '130700',
    'parentId': '130000',
    'name': '张家口市',
    'children': [{
      'regionId': '130702',
      'parentId': '130700',
      'name': '桥东区',
      'children': null
    }, {
      'regionId': '130703',
      'parentId': '130700',
      'name': '桥西区',
      'children': null
    }, {
      'regionId': '130705',
      'parentId': '130700',
      'name': '宣化区',
      'children': null
    }, {
      'regionId': '130706',
      'parentId': '130700',
      'name': '下花园区',
      'children': null
    }, {
      'regionId': '130708',
      'parentId': '130700',
      'name': '万全区',
      'children': null
    }, {
      'regionId': '130709',
      'parentId': '130700',
      'name': '崇礼区',
      'children': null
    }, {
      'regionId': '130722',
      'parentId': '130700',
      'name': '张北县',
      'children': null
    }, {
      'regionId': '130723',
      'parentId': '130700',
      'name': '康保县',
      'children': null
    }, {
      'regionId': '130724',
      'parentId': '130700',
      'name': '沽源县',
      'children': null
    }, {
      'regionId': '130725',
      'parentId': '130700',
      'name': '尚义县',
      'children': null
    }, {
      'regionId': '130726',
      'parentId': '130700',
      'name': '蔚县',
      'children': null
    }, {
      'regionId': '130727',
      'parentId': '130700',
      'name': '阳原县',
      'children': null
    }, {
      'regionId': '130728',
      'parentId': '130700',
      'name': '怀安县',
      'children': null
    }, {
      'regionId': '130730',
      'parentId': '130700',
      'name': '怀来县',
      'children': null
    }, {
      'regionId': '130731',
      'parentId': '130700',
      'name': '涿鹿县',
      'children': null
    }, {
      'regionId': '130732',
      'parentId': '130700',
      'name': '赤城县',
      'children': null
    }]
  }, {
    'regionId': '130800',
    'parentId': '130000',
    'name': '承德市',
    'children': [{
      'regionId': '130802',
      'parentId': '130800',
      'name': '双桥区',
      'children': null
    }, {
      'regionId': '130803',
      'parentId': '130800',
      'name': '双滦区',
      'children': null
    }, {
      'regionId': '130804',
      'parentId': '130800',
      'name': '鹰手营子矿区',
      'children': null
    }, {
      'regionId': '130821',
      'parentId': '130800',
      'name': '承德县',
      'children': null
    }, {
      'regionId': '130822',
      'parentId': '130800',
      'name': '兴隆县',
      'children': null
    }, {
      'regionId': '130824',
      'parentId': '130800',
      'name': '滦平县',
      'children': null
    }, {
      'regionId': '130825',
      'parentId': '130800',
      'name': '隆化县',
      'children': null
    }, {
      'regionId': '130826',
      'parentId': '130800',
      'name': '丰宁满族自治县',
      'children': null
    }, {
      'regionId': '130827',
      'parentId': '130800',
      'name': '宽城满族自治县',
      'children': null
    }, {
      'regionId': '130828',
      'parentId': '130800',
      'name': '围场满族蒙古族自治县',
      'children': null
    }, {
      'regionId': '130881',
      'parentId': '130800',
      'name': '平泉市',
      'children': null
    }]
  }, {
    'regionId': '130900',
    'parentId': '130000',
    'name': '沧州市',
    'children': [{
      'regionId': '130902',
      'parentId': '130900',
      'name': '新华区',
      'children': null
    }, {
      'regionId': '130903',
      'parentId': '130900',
      'name': '运河区',
      'children': null
    }, {
      'regionId': '130921',
      'parentId': '130900',
      'name': '沧县',
      'children': null
    }, {
      'regionId': '130922',
      'parentId': '130900',
      'name': '青县',
      'children': null
    }, {
      'regionId': '130923',
      'parentId': '130900',
      'name': '东光县',
      'children': null
    }, {
      'regionId': '130924',
      'parentId': '130900',
      'name': '海兴县',
      'children': null
    }, {
      'regionId': '130925',
      'parentId': '130900',
      'name': '盐山县',
      'children': null
    }, {
      'regionId': '130926',
      'parentId': '130900',
      'name': '肃宁县',
      'children': null
    }, {
      'regionId': '130927',
      'parentId': '130900',
      'name': '南皮县',
      'children': null
    }, {
      'regionId': '130928',
      'parentId': '130900',
      'name': '吴桥县',
      'children': null
    }, {
      'regionId': '130929',
      'parentId': '130900',
      'name': '献县',
      'children': null
    }, {
      'regionId': '130930',
      'parentId': '130900',
      'name': '孟村回族自治县',
      'children': null
    }, {
      'regionId': '130981',
      'parentId': '130900',
      'name': '泊头市',
      'children': null
    }, {
      'regionId': '130982',
      'parentId': '130900',
      'name': '任丘市',
      'children': null
    }, {
      'regionId': '130983',
      'parentId': '130900',
      'name': '黄骅市',
      'children': null
    }, {
      'regionId': '130984',
      'parentId': '130900',
      'name': '河间市',
      'children': null
    }]
  }, {
    'regionId': '131000',
    'parentId': '130000',
    'name': '廊坊市',
    'children': [{
      'regionId': '131002',
      'parentId': '131000',
      'name': '安次区',
      'children': null
    }, {
      'regionId': '131003',
      'parentId': '131000',
      'name': '广阳区',
      'children': null
    }, {
      'regionId': '131022',
      'parentId': '131000',
      'name': '固安县',
      'children': null
    }, {
      'regionId': '131023',
      'parentId': '131000',
      'name': '永清县',
      'children': null
    }, {
      'regionId': '131024',
      'parentId': '131000',
      'name': '香河县',
      'children': null
    }, {
      'regionId': '131025',
      'parentId': '131000',
      'name': '大城县',
      'children': null
    }, {
      'regionId': '131026',
      'parentId': '131000',
      'name': '文安县',
      'children': null
    }, {
      'regionId': '131028',
      'parentId': '131000',
      'name': '大厂回族自治县',
      'children': null
    }, {
      'regionId': '131081',
      'parentId': '131000',
      'name': '霸州市',
      'children': null
    }, {
      'regionId': '131082',
      'parentId': '131000',
      'name': '三河市',
      'children': null
    }]
  }, {
    'regionId': '131100',
    'parentId': '130000',
    'name': '衡水市',
    'children': [{
      'regionId': '131102',
      'parentId': '131100',
      'name': '桃城区',
      'children': null
    }, {
      'regionId': '131103',
      'parentId': '131100',
      'name': '冀州区',
      'children': null
    }, {
      'regionId': '131121',
      'parentId': '131100',
      'name': '枣强县',
      'children': null
    }, {
      'regionId': '131122',
      'parentId': '131100',
      'name': '武邑县',
      'children': null
    }, {
      'regionId': '131123',
      'parentId': '131100',
      'name': '武强县',
      'children': null
    }, {
      'regionId': '131124',
      'parentId': '131100',
      'name': '饶阳县',
      'children': null
    }, {
      'regionId': '131125',
      'parentId': '131100',
      'name': '安平县',
      'children': null
    }, {
      'regionId': '131126',
      'parentId': '131100',
      'name': '故城县',
      'children': null
    }, {
      'regionId': '131127',
      'parentId': '131100',
      'name': '景县',
      'children': null
    }, {
      'regionId': '131128',
      'parentId': '131100',
      'name': '阜城县',
      'children': null
    }, {
      'regionId': '131182',
      'parentId': '131100',
      'name': '深州市',
      'children': null
    }]
  }]
}, {
  'regionId': '140000',
  'parentId': '0',
  'name': '山西省',
  'children': [{
    'regionId': '140100',
    'parentId': '140000',
    'name': '太原市',
    'children': [{
      'regionId': '140105',
      'parentId': '140100',
      'name': '小店区',
      'children': null
    }, {
      'regionId': '140106',
      'parentId': '140100',
      'name': '迎泽区',
      'children': null
    }, {
      'regionId': '140107',
      'parentId': '140100',
      'name': '杏花岭区',
      'children': null
    }, {
      'regionId': '140108',
      'parentId': '140100',
      'name': '尖草坪区',
      'children': null
    }, {
      'regionId': '140109',
      'parentId': '140100',
      'name': '万柏林区',
      'children': null
    }, {
      'regionId': '140110',
      'parentId': '140100',
      'name': '晋源区',
      'children': null
    }, {
      'regionId': '140121',
      'parentId': '140100',
      'name': '清徐县',
      'children': null
    }, {
      'regionId': '140122',
      'parentId': '140100',
      'name': '阳曲县',
      'children': null
    }, {
      'regionId': '140123',
      'parentId': '140100',
      'name': '娄烦县',
      'children': null
    }, {
      'regionId': '140181',
      'parentId': '140100',
      'name': '古交市',
      'children': null
    }]
  }, {
    'regionId': '140200',
    'parentId': '140000',
    'name': '大同市',
    'children': [{
      'regionId': '140212',
      'parentId': '140200',
      'name': '新荣区',
      'children': null
    }, {
      'regionId': '140213',
      'parentId': '140200',
      'name': '平城区',
      'children': null
    }, {
      'regionId': '140214',
      'parentId': '140200',
      'name': '云冈区',
      'children': null
    }, {
      'regionId': '140215',
      'parentId': '140200',
      'name': '云州区',
      'children': null
    }, {
      'regionId': '140221',
      'parentId': '140200',
      'name': '阳高县',
      'children': null
    }, {
      'regionId': '140222',
      'parentId': '140200',
      'name': '天镇县',
      'children': null
    }, {
      'regionId': '140223',
      'parentId': '140200',
      'name': '广灵县',
      'children': null
    }, {
      'regionId': '140224',
      'parentId': '140200',
      'name': '灵丘县',
      'children': null
    }, {
      'regionId': '140225',
      'parentId': '140200',
      'name': '浑源县',
      'children': null
    }, {
      'regionId': '140226',
      'parentId': '140200',
      'name': '左云县',
      'children': null
    }]
  }, {
    'regionId': '140300',
    'parentId': '140000',
    'name': '阳泉市',
    'children': [{
      'regionId': '140302',
      'parentId': '140300',
      'name': '城区',
      'children': null
    }, {
      'regionId': '140303',
      'parentId': '140300',
      'name': '矿区',
      'children': null
    }, {
      'regionId': '140311',
      'parentId': '140300',
      'name': '郊区',
      'children': null
    }, {
      'regionId': '140321',
      'parentId': '140300',
      'name': '平定县',
      'children': null
    }, {
      'regionId': '140322',
      'parentId': '140300',
      'name': '盂县',
      'children': null
    }]
  }, {
    'regionId': '140400',
    'parentId': '140000',
    'name': '长治市',
    'children': [{
      'regionId': '140403',
      'parentId': '140400',
      'name': '潞州区',
      'children': null
    }, {
      'regionId': '140404',
      'parentId': '140400',
      'name': '上党区',
      'children': null
    }, {
      'regionId': '140405',
      'parentId': '140400',
      'name': '屯留区',
      'children': null
    }, {
      'regionId': '140406',
      'parentId': '140400',
      'name': '潞城区',
      'children': null
    }, {
      'regionId': '140423',
      'parentId': '140400',
      'name': '襄垣县',
      'children': null
    }, {
      'regionId': '140425',
      'parentId': '140400',
      'name': '平顺县',
      'children': null
    }, {
      'regionId': '140426',
      'parentId': '140400',
      'name': '黎城县',
      'children': null
    }, {
      'regionId': '140427',
      'parentId': '140400',
      'name': '壶关县',
      'children': null
    }, {
      'regionId': '140428',
      'parentId': '140400',
      'name': '长子县',
      'children': null
    }, {
      'regionId': '140429',
      'parentId': '140400',
      'name': '武乡县',
      'children': null
    }, {
      'regionId': '140430',
      'parentId': '140400',
      'name': '沁县',
      'children': null
    }, {
      'regionId': '140431',
      'parentId': '140400',
      'name': '沁源县',
      'children': null
    }]
  }, {
    'regionId': '140500',
    'parentId': '140000',
    'name': '晋城市',
    'children': [{
      'regionId': '140502',
      'parentId': '140500',
      'name': '城区',
      'children': null
    }, {
      'regionId': '140521',
      'parentId': '140500',
      'name': '沁水县',
      'children': null
    }, {
      'regionId': '140522',
      'parentId': '140500',
      'name': '阳城县',
      'children': null
    }, {
      'regionId': '140524',
      'parentId': '140500',
      'name': '陵川县',
      'children': null
    }, {
      'regionId': '140525',
      'parentId': '140500',
      'name': '泽州县',
      'children': null
    }, {
      'regionId': '140581',
      'parentId': '140500',
      'name': '高平市',
      'children': null
    }]
  }, {
    'regionId': '140600',
    'parentId': '140000',
    'name': '朔州市',
    'children': [{
      'regionId': '140602',
      'parentId': '140600',
      'name': '朔城区',
      'children': null
    }, {
      'regionId': '140603',
      'parentId': '140600',
      'name': '平鲁区',
      'children': null
    }, {
      'regionId': '140621',
      'parentId': '140600',
      'name': '山阴县',
      'children': null
    }, {
      'regionId': '140622',
      'parentId': '140600',
      'name': '应县',
      'children': null
    }, {
      'regionId': '140623',
      'parentId': '140600',
      'name': '右玉县',
      'children': null
    }, {
      'regionId': '140681',
      'parentId': '140600',
      'name': '怀仁市',
      'children': null
    }]
  }, {
    'regionId': '140700',
    'parentId': '140000',
    'name': '晋中市',
    'children': [{
      'regionId': '140702',
      'parentId': '140700',
      'name': '榆次区',
      'children': null
    }, {
      'regionId': '140703',
      'parentId': '140700',
      'name': '太谷区',
      'children': null
    }, {
      'regionId': '140721',
      'parentId': '140700',
      'name': '榆社县',
      'children': null
    }, {
      'regionId': '140722',
      'parentId': '140700',
      'name': '左权县',
      'children': null
    }, {
      'regionId': '140723',
      'parentId': '140700',
      'name': '和顺县',
      'children': null
    }, {
      'regionId': '140724',
      'parentId': '140700',
      'name': '昔阳县',
      'children': null
    }, {
      'regionId': '140725',
      'parentId': '140700',
      'name': '寿阳县',
      'children': null
    }, {
      'regionId': '140727',
      'parentId': '140700',
      'name': '祁县',
      'children': null
    }, {
      'regionId': '140728',
      'parentId': '140700',
      'name': '平遥县',
      'children': null
    }, {
      'regionId': '140729',
      'parentId': '140700',
      'name': '灵石县',
      'children': null
    }, {
      'regionId': '140781',
      'parentId': '140700',
      'name': '介休市',
      'children': null
    }]
  }, {
    'regionId': '140800',
    'parentId': '140000',
    'name': '运城市',
    'children': [{
      'regionId': '140802',
      'parentId': '140800',
      'name': '盐湖区',
      'children': null
    }, {
      'regionId': '140821',
      'parentId': '140800',
      'name': '临猗县',
      'children': null
    }, {
      'regionId': '140822',
      'parentId': '140800',
      'name': '万荣县',
      'children': null
    }, {
      'regionId': '140823',
      'parentId': '140800',
      'name': '闻喜县',
      'children': null
    }, {
      'regionId': '140824',
      'parentId': '140800',
      'name': '稷山县',
      'children': null
    }, {
      'regionId': '140825',
      'parentId': '140800',
      'name': '新绛县',
      'children': null
    }, {
      'regionId': '140826',
      'parentId': '140800',
      'name': '绛县',
      'children': null
    }, {
      'regionId': '140827',
      'parentId': '140800',
      'name': '垣曲县',
      'children': null
    }, {
      'regionId': '140828',
      'parentId': '140800',
      'name': '夏县',
      'children': null
    }, {
      'regionId': '140829',
      'parentId': '140800',
      'name': '平陆县',
      'children': null
    }, {
      'regionId': '140830',
      'parentId': '140800',
      'name': '芮城县',
      'children': null
    }, {
      'regionId': '140881',
      'parentId': '140800',
      'name': '永济市',
      'children': null
    }, {
      'regionId': '140882',
      'parentId': '140800',
      'name': '河津市',
      'children': null
    }]
  }, {
    'regionId': '140900',
    'parentId': '140000',
    'name': '忻州市',
    'children': [{
      'regionId': '140902',
      'parentId': '140900',
      'name': '忻府区',
      'children': null
    }, {
      'regionId': '140921',
      'parentId': '140900',
      'name': '定襄县',
      'children': null
    }, {
      'regionId': '140922',
      'parentId': '140900',
      'name': '五台县',
      'children': null
    }, {
      'regionId': '140923',
      'parentId': '140900',
      'name': '代县',
      'children': null
    }, {
      'regionId': '140924',
      'parentId': '140900',
      'name': '繁峙县',
      'children': null
    }, {
      'regionId': '140925',
      'parentId': '140900',
      'name': '宁武县',
      'children': null
    }, {
      'regionId': '140926',
      'parentId': '140900',
      'name': '静乐县',
      'children': null
    }, {
      'regionId': '140927',
      'parentId': '140900',
      'name': '神池县',
      'children': null
    }, {
      'regionId': '140928',
      'parentId': '140900',
      'name': '五寨县',
      'children': null
    }, {
      'regionId': '140929',
      'parentId': '140900',
      'name': '岢岚县',
      'children': null
    }, {
      'regionId': '140930',
      'parentId': '140900',
      'name': '河曲县',
      'children': null
    }, {
      'regionId': '140931',
      'parentId': '140900',
      'name': '保德县',
      'children': null
    }, {
      'regionId': '140932',
      'parentId': '140900',
      'name': '偏关县',
      'children': null
    }, {
      'regionId': '140981',
      'parentId': '140900',
      'name': '原平市',
      'children': null
    }]
  }, {
    'regionId': '141000',
    'parentId': '140000',
    'name': '临汾市',
    'children': [{
      'regionId': '141002',
      'parentId': '141000',
      'name': '尧都区',
      'children': null
    }, {
      'regionId': '141021',
      'parentId': '141000',
      'name': '曲沃县',
      'children': null
    }, {
      'regionId': '141022',
      'parentId': '141000',
      'name': '翼城县',
      'children': null
    }, {
      'regionId': '141023',
      'parentId': '141000',
      'name': '襄汾县',
      'children': null
    }, {
      'regionId': '141024',
      'parentId': '141000',
      'name': '洪洞县',
      'children': null
    }, {
      'regionId': '141025',
      'parentId': '141000',
      'name': '古县',
      'children': null
    }, {
      'regionId': '141026',
      'parentId': '141000',
      'name': '安泽县',
      'children': null
    }, {
      'regionId': '141027',
      'parentId': '141000',
      'name': '浮山县',
      'children': null
    }, {
      'regionId': '141028',
      'parentId': '141000',
      'name': '吉县',
      'children': null
    }, {
      'regionId': '141029',
      'parentId': '141000',
      'name': '乡宁县',
      'children': null
    }, {
      'regionId': '141030',
      'parentId': '141000',
      'name': '大宁县',
      'children': null
    }, {
      'regionId': '141031',
      'parentId': '141000',
      'name': '隰县',
      'children': null
    }, {
      'regionId': '141032',
      'parentId': '141000',
      'name': '永和县',
      'children': null
    }, {
      'regionId': '141033',
      'parentId': '141000',
      'name': '蒲县',
      'children': null
    }, {
      'regionId': '141034',
      'parentId': '141000',
      'name': '汾西县',
      'children': null
    }, {
      'regionId': '141081',
      'parentId': '141000',
      'name': '侯马市',
      'children': null
    }, {
      'regionId': '141082',
      'parentId': '141000',
      'name': '霍州市',
      'children': null
    }]
  }, {
    'regionId': '141100',
    'parentId': '140000',
    'name': '吕梁市',
    'children': [{
      'regionId': '141102',
      'parentId': '141100',
      'name': '离石区',
      'children': null
    }, {
      'regionId': '141121',
      'parentId': '141100',
      'name': '文水县',
      'children': null
    }, {
      'regionId': '141122',
      'parentId': '141100',
      'name': '交城县',
      'children': null
    }, {
      'regionId': '141123',
      'parentId': '141100',
      'name': '兴县',
      'children': null
    }, {
      'regionId': '141124',
      'parentId': '141100',
      'name': '临县',
      'children': null
    }, {
      'regionId': '141125',
      'parentId': '141100',
      'name': '柳林县',
      'children': null
    }, {
      'regionId': '141126',
      'parentId': '141100',
      'name': '石楼县',
      'children': null
    }, {
      'regionId': '141127',
      'parentId': '141100',
      'name': '岚县',
      'children': null
    }, {
      'regionId': '141128',
      'parentId': '141100',
      'name': '方山县',
      'children': null
    }, {
      'regionId': '141129',
      'parentId': '141100',
      'name': '中阳县',
      'children': null
    }, {
      'regionId': '141130',
      'parentId': '141100',
      'name': '交口县',
      'children': null
    }, {
      'regionId': '141181',
      'parentId': '141100',
      'name': '孝义市',
      'children': null
    }, {
      'regionId': '141182',
      'parentId': '141100',
      'name': '汾阳市',
      'children': null
    }]
  }]
}, {
  'regionId': '150000',
  'parentId': '0',
  'name': '内蒙古自治区',
  'children': [{
    'regionId': '150100',
    'parentId': '150000',
    'name': '呼和浩特市',
    'children': [{
      'regionId': '150102',
      'parentId': '150100',
      'name': '新城区',
      'children': null
    }, {
      'regionId': '150103',
      'parentId': '150100',
      'name': '回民区',
      'children': null
    }, {
      'regionId': '150104',
      'parentId': '150100',
      'name': '玉泉区',
      'children': null
    }, {
      'regionId': '150105',
      'parentId': '150100',
      'name': '赛罕区',
      'children': null
    }, {
      'regionId': '150121',
      'parentId': '150100',
      'name': '土默特左旗',
      'children': null
    }, {
      'regionId': '150122',
      'parentId': '150100',
      'name': '托克托县',
      'children': null
    }, {
      'regionId': '150123',
      'parentId': '150100',
      'name': '和林格尔县',
      'children': null
    }, {
      'regionId': '150124',
      'parentId': '150100',
      'name': '清水河县',
      'children': null
    }, {
      'regionId': '150125',
      'parentId': '150100',
      'name': '武川县',
      'children': null
    }]
  }, {
    'regionId': '150200',
    'parentId': '150000',
    'name': '包头市',
    'children': [{
      'regionId': '150202',
      'parentId': '150200',
      'name': '东河区',
      'children': null
    }, {
      'regionId': '150203',
      'parentId': '150200',
      'name': '昆都仑区',
      'children': null
    }, {
      'regionId': '150204',
      'parentId': '150200',
      'name': '青山区',
      'children': null
    }, {
      'regionId': '150205',
      'parentId': '150200',
      'name': '石拐区',
      'children': null
    }, {
      'regionId': '150206',
      'parentId': '150200',
      'name': '白云鄂博矿区',
      'children': null
    }, {
      'regionId': '150207',
      'parentId': '150200',
      'name': '九原区',
      'children': null
    }, {
      'regionId': '150221',
      'parentId': '150200',
      'name': '土默特右旗',
      'children': null
    }, {
      'regionId': '150222',
      'parentId': '150200',
      'name': '固阳县',
      'children': null
    }, {
      'regionId': '150223',
      'parentId': '150200',
      'name': '达尔罕茂明安联合旗',
      'children': null
    }]
  }, {
    'regionId': '150300',
    'parentId': '150000',
    'name': '乌海市',
    'children': [{
      'regionId': '150302',
      'parentId': '150300',
      'name': '海勃湾区',
      'children': null
    }, {
      'regionId': '150303',
      'parentId': '150300',
      'name': '海南区',
      'children': null
    }, {
      'regionId': '150304',
      'parentId': '150300',
      'name': '乌达区',
      'children': null
    }]
  }, {
    'regionId': '150400',
    'parentId': '150000',
    'name': '赤峰市',
    'children': [{
      'regionId': '150402',
      'parentId': '150400',
      'name': '红山区',
      'children': null
    }, {
      'regionId': '150403',
      'parentId': '150400',
      'name': '元宝山区',
      'children': null
    }, {
      'regionId': '150404',
      'parentId': '150400',
      'name': '松山区',
      'children': null
    }, {
      'regionId': '150421',
      'parentId': '150400',
      'name': '阿鲁科尔沁旗',
      'children': null
    }, {
      'regionId': '150422',
      'parentId': '150400',
      'name': '巴林左旗',
      'children': null
    }, {
      'regionId': '150423',
      'parentId': '150400',
      'name': '巴林右旗',
      'children': null
    }, {
      'regionId': '150424',
      'parentId': '150400',
      'name': '林西县',
      'children': null
    }, {
      'regionId': '150425',
      'parentId': '150400',
      'name': '克什克腾旗',
      'children': null
    }, {
      'regionId': '150426',
      'parentId': '150400',
      'name': '翁牛特旗',
      'children': null
    }, {
      'regionId': '150428',
      'parentId': '150400',
      'name': '喀喇沁旗',
      'children': null
    }, {
      'regionId': '150429',
      'parentId': '150400',
      'name': '宁城县',
      'children': null
    }, {
      'regionId': '150430',
      'parentId': '150400',
      'name': '敖汉旗',
      'children': null
    }]
  }, {
    'regionId': '150500',
    'parentId': '150000',
    'name': '通辽市',
    'children': [{
      'regionId': '150502',
      'parentId': '150500',
      'name': '科尔沁区',
      'children': null
    }, {
      'regionId': '150521',
      'parentId': '150500',
      'name': '科尔沁左翼中旗',
      'children': null
    }, {
      'regionId': '150522',
      'parentId': '150500',
      'name': '科尔沁左翼后旗',
      'children': null
    }, {
      'regionId': '150523',
      'parentId': '150500',
      'name': '开鲁县',
      'children': null
    }, {
      'regionId': '150524',
      'parentId': '150500',
      'name': '库伦旗',
      'children': null
    }, {
      'regionId': '150525',
      'parentId': '150500',
      'name': '奈曼旗',
      'children': null
    }, {
      'regionId': '150526',
      'parentId': '150500',
      'name': '扎鲁特旗',
      'children': null
    }, {
      'regionId': '150581',
      'parentId': '150500',
      'name': '霍林郭勒市',
      'children': null
    }]
  }, {
    'regionId': '150600',
    'parentId': '150000',
    'name': '鄂尔多斯市',
    'children': [{
      'regionId': '150602',
      'parentId': '150600',
      'name': '东胜区',
      'children': null
    }, {
      'regionId': '150603',
      'parentId': '150600',
      'name': '康巴什区',
      'children': null
    }, {
      'regionId': '150621',
      'parentId': '150600',
      'name': '达拉特旗',
      'children': null
    }, {
      'regionId': '150622',
      'parentId': '150600',
      'name': '准格尔旗',
      'children': null
    }, {
      'regionId': '150623',
      'parentId': '150600',
      'name': '鄂托克前旗',
      'children': null
    }, {
      'regionId': '150624',
      'parentId': '150600',
      'name': '鄂托克旗',
      'children': null
    }, {
      'regionId': '150625',
      'parentId': '150600',
      'name': '杭锦旗',
      'children': null
    }, {
      'regionId': '150626',
      'parentId': '150600',
      'name': '乌审旗',
      'children': null
    }, {
      'regionId': '150627',
      'parentId': '150600',
      'name': '伊金霍洛旗',
      'children': null
    }]
  }, {
    'regionId': '150700',
    'parentId': '150000',
    'name': '呼伦贝尔市',
    'children': [{
      'regionId': '150702',
      'parentId': '150700',
      'name': '海拉尔区',
      'children': null
    }, {
      'regionId': '150703',
      'parentId': '150700',
      'name': '扎赉诺尔区',
      'children': null
    }, {
      'regionId': '150721',
      'parentId': '150700',
      'name': '阿荣旗',
      'children': null
    }, {
      'regionId': '150722',
      'parentId': '150700',
      'name': '莫力达瓦达斡尔族自治旗',
      'children': null
    }, {
      'regionId': '150723',
      'parentId': '150700',
      'name': '鄂伦春自治旗',
      'children': null
    }, {
      'regionId': '150724',
      'parentId': '150700',
      'name': '鄂温克族自治旗',
      'children': null
    }, {
      'regionId': '150725',
      'parentId': '150700',
      'name': '陈巴尔虎旗',
      'children': null
    }, {
      'regionId': '150726',
      'parentId': '150700',
      'name': '新巴尔虎左旗',
      'children': null
    }, {
      'regionId': '150727',
      'parentId': '150700',
      'name': '新巴尔虎右旗',
      'children': null
    }, {
      'regionId': '150781',
      'parentId': '150700',
      'name': '满洲里市',
      'children': null
    }, {
      'regionId': '150782',
      'parentId': '150700',
      'name': '牙克石市',
      'children': null
    }, {
      'regionId': '150783',
      'parentId': '150700',
      'name': '扎兰屯市',
      'children': null
    }, {
      'regionId': '150784',
      'parentId': '150700',
      'name': '额尔古纳市',
      'children': null
    }, {
      'regionId': '150785',
      'parentId': '150700',
      'name': '根河市',
      'children': null
    }]
  }, {
    'regionId': '150800',
    'parentId': '150000',
    'name': '巴彦淖尔市',
    'children': [{
      'regionId': '150802',
      'parentId': '150800',
      'name': '临河区',
      'children': null
    }, {
      'regionId': '150821',
      'parentId': '150800',
      'name': '五原县',
      'children': null
    }, {
      'regionId': '150822',
      'parentId': '150800',
      'name': '磴口县',
      'children': null
    }, {
      'regionId': '150823',
      'parentId': '150800',
      'name': '乌拉特前旗',
      'children': null
    }, {
      'regionId': '150824',
      'parentId': '150800',
      'name': '乌拉特中旗',
      'children': null
    }, {
      'regionId': '150825',
      'parentId': '150800',
      'name': '乌拉特后旗',
      'children': null
    }, {
      'regionId': '150826',
      'parentId': '150800',
      'name': '杭锦后旗',
      'children': null
    }]
  }, {
    'regionId': '150900',
    'parentId': '150000',
    'name': '乌兰察布市',
    'children': [{
      'regionId': '150902',
      'parentId': '150900',
      'name': '集宁区',
      'children': null
    }, {
      'regionId': '150921',
      'parentId': '150900',
      'name': '卓资县',
      'children': null
    }, {
      'regionId': '150922',
      'parentId': '150900',
      'name': '化德县',
      'children': null
    }, {
      'regionId': '150923',
      'parentId': '150900',
      'name': '商都县',
      'children': null
    }, {
      'regionId': '150924',
      'parentId': '150900',
      'name': '兴和县',
      'children': null
    }, {
      'regionId': '150925',
      'parentId': '150900',
      'name': '凉城县',
      'children': null
    }, {
      'regionId': '150926',
      'parentId': '150900',
      'name': '察哈尔右翼前旗',
      'children': null
    }, {
      'regionId': '150927',
      'parentId': '150900',
      'name': '察哈尔右翼中旗',
      'children': null
    }, {
      'regionId': '150928',
      'parentId': '150900',
      'name': '察哈尔右翼后旗',
      'children': null
    }, {
      'regionId': '150929',
      'parentId': '150900',
      'name': '四子王旗',
      'children': null
    }, {
      'regionId': '150981',
      'parentId': '150900',
      'name': '丰镇市',
      'children': null
    }]
  }, {
    'regionId': '152200',
    'parentId': '150000',
    'name': '兴安盟',
    'children': [{
      'regionId': '152201',
      'parentId': '152200',
      'name': '乌兰浩特市',
      'children': null
    }, {
      'regionId': '152202',
      'parentId': '152200',
      'name': '阿尔山市',
      'children': null
    }, {
      'regionId': '152221',
      'parentId': '152200',
      'name': '科尔沁右翼前旗',
      'children': null
    }, {
      'regionId': '152222',
      'parentId': '152200',
      'name': '科尔沁右翼中旗',
      'children': null
    }, {
      'regionId': '152223',
      'parentId': '152200',
      'name': '扎赉特旗',
      'children': null
    }, {
      'regionId': '152224',
      'parentId': '152200',
      'name': '突泉县',
      'children': null
    }]
  }, {
    'regionId': '152500',
    'parentId': '150000',
    'name': '锡林郭勒盟',
    'children': [{
      'regionId': '152501',
      'parentId': '152500',
      'name': '二连浩特市',
      'children': null
    }, {
      'regionId': '152502',
      'parentId': '152500',
      'name': '锡林浩特市',
      'children': null
    }, {
      'regionId': '152522',
      'parentId': '152500',
      'name': '阿巴嘎旗',
      'children': null
    }, {
      'regionId': '152523',
      'parentId': '152500',
      'name': '苏尼特左旗',
      'children': null
    }, {
      'regionId': '152524',
      'parentId': '152500',
      'name': '苏尼特右旗',
      'children': null
    }, {
      'regionId': '152525',
      'parentId': '152500',
      'name': '东乌珠穆沁旗',
      'children': null
    }, {
      'regionId': '152526',
      'parentId': '152500',
      'name': '西乌珠穆沁旗',
      'children': null
    }, {
      'regionId': '152527',
      'parentId': '152500',
      'name': '太仆寺旗',
      'children': null
    }, {
      'regionId': '152528',
      'parentId': '152500',
      'name': '镶黄旗',
      'children': null
    }, {
      'regionId': '152529',
      'parentId': '152500',
      'name': '正镶白旗',
      'children': null
    }, {
      'regionId': '152530',
      'parentId': '152500',
      'name': '正蓝旗',
      'children': null
    }, {
      'regionId': '152531',
      'parentId': '152500',
      'name': '多伦县',
      'children': null
    }]
  }, {
    'regionId': '152900',
    'parentId': '150000',
    'name': '阿拉善盟',
    'children': [{
      'regionId': '152921',
      'parentId': '152900',
      'name': '阿拉善左旗',
      'children': null
    }, {
      'regionId': '152922',
      'parentId': '152900',
      'name': '阿拉善右旗',
      'children': null
    }, {
      'regionId': '152923',
      'parentId': '152900',
      'name': '额济纳旗',
      'children': null
    }]
  }]
}, {
  'regionId': '210000',
  'parentId': '0',
  'name': '辽宁省',
  'children': [{
    'regionId': '210100',
    'parentId': '210000',
    'name': '沈阳市',
    'children': [{
      'regionId': '210102',
      'parentId': '210100',
      'name': '和平区',
      'children': null
    }, {
      'regionId': '210103',
      'parentId': '210100',
      'name': '沈河区',
      'children': null
    }, {
      'regionId': '210104',
      'parentId': '210100',
      'name': '大东区',
      'children': null
    }, {
      'regionId': '210105',
      'parentId': '210100',
      'name': '皇姑区',
      'children': null
    }, {
      'regionId': '210106',
      'parentId': '210100',
      'name': '铁西区',
      'children': null
    }, {
      'regionId': '210111',
      'parentId': '210100',
      'name': '苏家屯区',
      'children': null
    }, {
      'regionId': '210112',
      'parentId': '210100',
      'name': '浑南区',
      'children': null
    }, {
      'regionId': '210113',
      'parentId': '210100',
      'name': '沈北新区',
      'children': null
    }, {
      'regionId': '210114',
      'parentId': '210100',
      'name': '于洪区',
      'children': null
    }, {
      'regionId': '210115',
      'parentId': '210100',
      'name': '辽中区',
      'children': null
    }, {
      'regionId': '210123',
      'parentId': '210100',
      'name': '康平县',
      'children': null
    }, {
      'regionId': '210124',
      'parentId': '210100',
      'name': '法库县',
      'children': null
    }, {
      'regionId': '210181',
      'parentId': '210100',
      'name': '新民市',
      'children': null
    }]
  }, {
    'regionId': '210200',
    'parentId': '210000',
    'name': '大连市',
    'children': [{
      'regionId': '210202',
      'parentId': '210200',
      'name': '中山区',
      'children': null
    }, {
      'regionId': '210203',
      'parentId': '210200',
      'name': '西岗区',
      'children': null
    }, {
      'regionId': '210204',
      'parentId': '210200',
      'name': '沙河口区',
      'children': null
    }, {
      'regionId': '210211',
      'parentId': '210200',
      'name': '甘井子区',
      'children': null
    }, {
      'regionId': '210212',
      'parentId': '210200',
      'name': '旅顺口区',
      'children': null
    }, {
      'regionId': '210213',
      'parentId': '210200',
      'name': '金州区',
      'children': null
    }, {
      'regionId': '210214',
      'parentId': '210200',
      'name': '普兰店区',
      'children': null
    }, {
      'regionId': '210224',
      'parentId': '210200',
      'name': '长海县',
      'children': null
    }, {
      'regionId': '210281',
      'parentId': '210200',
      'name': '瓦房店市',
      'children': null
    }, {
      'regionId': '210283',
      'parentId': '210200',
      'name': '庄河市',
      'children': null
    }]
  }, {
    'regionId': '210300',
    'parentId': '210000',
    'name': '鞍山市',
    'children': [{
      'regionId': '210302',
      'parentId': '210300',
      'name': '铁东区',
      'children': null
    }, {
      'regionId': '210303',
      'parentId': '210300',
      'name': '铁西区',
      'children': null
    }, {
      'regionId': '210304',
      'parentId': '210300',
      'name': '立山区',
      'children': null
    }, {
      'regionId': '210311',
      'parentId': '210300',
      'name': '千山区',
      'children': null
    }, {
      'regionId': '210321',
      'parentId': '210300',
      'name': '台安县',
      'children': null
    }, {
      'regionId': '210323',
      'parentId': '210300',
      'name': '岫岩满族自治县',
      'children': null
    }, {
      'regionId': '210381',
      'parentId': '210300',
      'name': '海城市',
      'children': null
    }]
  }, {
    'regionId': '210400',
    'parentId': '210000',
    'name': '抚顺市',
    'children': [{
      'regionId': '210402',
      'parentId': '210400',
      'name': '新抚区',
      'children': null
    }, {
      'regionId': '210403',
      'parentId': '210400',
      'name': '东洲区',
      'children': null
    }, {
      'regionId': '210404',
      'parentId': '210400',
      'name': '望花区',
      'children': null
    }, {
      'regionId': '210411',
      'parentId': '210400',
      'name': '顺城区',
      'children': null
    }, {
      'regionId': '210421',
      'parentId': '210400',
      'name': '抚顺县',
      'children': null
    }, {
      'regionId': '210422',
      'parentId': '210400',
      'name': '新宾满族自治县',
      'children': null
    }, {
      'regionId': '210423',
      'parentId': '210400',
      'name': '清原满族自治县',
      'children': null
    }]
  }, {
    'regionId': '210500',
    'parentId': '210000',
    'name': '本溪市',
    'children': [{
      'regionId': '210502',
      'parentId': '210500',
      'name': '平山区',
      'children': null
    }, {
      'regionId': '210503',
      'parentId': '210500',
      'name': '溪湖区',
      'children': null
    }, {
      'regionId': '210504',
      'parentId': '210500',
      'name': '明山区',
      'children': null
    }, {
      'regionId': '210505',
      'parentId': '210500',
      'name': '南芬区',
      'children': null
    }, {
      'regionId': '210521',
      'parentId': '210500',
      'name': '本溪满族自治县',
      'children': null
    }, {
      'regionId': '210522',
      'parentId': '210500',
      'name': '桓仁满族自治县',
      'children': null
    }]
  }, {
    'regionId': '210600',
    'parentId': '210000',
    'name': '丹东市',
    'children': [{
      'regionId': '210602',
      'parentId': '210600',
      'name': '元宝区',
      'children': null
    }, {
      'regionId': '210603',
      'parentId': '210600',
      'name': '振兴区',
      'children': null
    }, {
      'regionId': '210604',
      'parentId': '210600',
      'name': '振安区',
      'children': null
    }, {
      'regionId': '210624',
      'parentId': '210600',
      'name': '宽甸满族自治县',
      'children': null
    }, {
      'regionId': '210681',
      'parentId': '210600',
      'name': '东港市',
      'children': null
    }, {
      'regionId': '210682',
      'parentId': '210600',
      'name': '凤城市',
      'children': null
    }]
  }, {
    'regionId': '210700',
    'parentId': '210000',
    'name': '锦州市',
    'children': [{
      'regionId': '210702',
      'parentId': '210700',
      'name': '古塔区',
      'children': null
    }, {
      'regionId': '210703',
      'parentId': '210700',
      'name': '凌河区',
      'children': null
    }, {
      'regionId': '210711',
      'parentId': '210700',
      'name': '太和区',
      'children': null
    }, {
      'regionId': '210726',
      'parentId': '210700',
      'name': '黑山县',
      'children': null
    }, {
      'regionId': '210727',
      'parentId': '210700',
      'name': '义县',
      'children': null
    }, {
      'regionId': '210781',
      'parentId': '210700',
      'name': '凌海市',
      'children': null
    }, {
      'regionId': '210782',
      'parentId': '210700',
      'name': '北镇市',
      'children': null
    }]
  }, {
    'regionId': '210800',
    'parentId': '210000',
    'name': '营口市',
    'children': [{
      'regionId': '210802',
      'parentId': '210800',
      'name': '站前区',
      'children': null
    }, {
      'regionId': '210803',
      'parentId': '210800',
      'name': '西市区',
      'children': null
    }, {
      'regionId': '210804',
      'parentId': '210800',
      'name': '鲅鱼圈区',
      'children': null
    }, {
      'regionId': '210811',
      'parentId': '210800',
      'name': '老边区',
      'children': null
    }, {
      'regionId': '210881',
      'parentId': '210800',
      'name': '盖州市',
      'children': null
    }, {
      'regionId': '210882',
      'parentId': '210800',
      'name': '大石桥市',
      'children': null
    }]
  }, {
    'regionId': '210900',
    'parentId': '210000',
    'name': '阜新市',
    'children': [{
      'regionId': '210902',
      'parentId': '210900',
      'name': '海州区',
      'children': null
    }, {
      'regionId': '210903',
      'parentId': '210900',
      'name': '新邱区',
      'children': null
    }, {
      'regionId': '210904',
      'parentId': '210900',
      'name': '太平区',
      'children': null
    }, {
      'regionId': '210905',
      'parentId': '210900',
      'name': '清河门区',
      'children': null
    }, {
      'regionId': '210911',
      'parentId': '210900',
      'name': '细河区',
      'children': null
    }, {
      'regionId': '210921',
      'parentId': '210900',
      'name': '阜新蒙古族自治县',
      'children': null
    }, {
      'regionId': '210922',
      'parentId': '210900',
      'name': '彰武县',
      'children': null
    }]
  }, {
    'regionId': '211000',
    'parentId': '210000',
    'name': '辽阳市',
    'children': [{
      'regionId': '211002',
      'parentId': '211000',
      'name': '白塔区',
      'children': null
    }, {
      'regionId': '211003',
      'parentId': '211000',
      'name': '文圣区',
      'children': null
    }, {
      'regionId': '211004',
      'parentId': '211000',
      'name': '宏伟区',
      'children': null
    }, {
      'regionId': '211005',
      'parentId': '211000',
      'name': '弓长岭区',
      'children': null
    }, {
      'regionId': '211011',
      'parentId': '211000',
      'name': '太子河区',
      'children': null
    }, {
      'regionId': '211021',
      'parentId': '211000',
      'name': '辽阳县',
      'children': null
    }, {
      'regionId': '211081',
      'parentId': '211000',
      'name': '灯塔市',
      'children': null
    }]
  }, {
    'regionId': '211100',
    'parentId': '210000',
    'name': '盘锦市',
    'children': [{
      'regionId': '211102',
      'parentId': '211100',
      'name': '双台子区',
      'children': null
    }, {
      'regionId': '211103',
      'parentId': '211100',
      'name': '兴隆台区',
      'children': null
    }, {
      'regionId': '211104',
      'parentId': '211100',
      'name': '大洼区',
      'children': null
    }, {
      'regionId': '211122',
      'parentId': '211100',
      'name': '盘山县',
      'children': null
    }]
  }, {
    'regionId': '211200',
    'parentId': '210000',
    'name': '铁岭市',
    'children': [{
      'regionId': '211202',
      'parentId': '211200',
      'name': '银州区',
      'children': null
    }, {
      'regionId': '211204',
      'parentId': '211200',
      'name': '清河区',
      'children': null
    }, {
      'regionId': '211221',
      'parentId': '211200',
      'name': '铁岭县',
      'children': null
    }, {
      'regionId': '211223',
      'parentId': '211200',
      'name': '西丰县',
      'children': null
    }, {
      'regionId': '211224',
      'parentId': '211200',
      'name': '昌图县',
      'children': null
    }, {
      'regionId': '211281',
      'parentId': '211200',
      'name': '调兵山市',
      'children': null
    }, {
      'regionId': '211282',
      'parentId': '211200',
      'name': '开原市',
      'children': null
    }]
  }, {
    'regionId': '211300',
    'parentId': '210000',
    'name': '朝阳市',
    'children': [{
      'regionId': '211302',
      'parentId': '211300',
      'name': '双塔区',
      'children': null
    }, {
      'regionId': '211303',
      'parentId': '211300',
      'name': '龙城区',
      'children': null
    }, {
      'regionId': '211321',
      'parentId': '211300',
      'name': '朝阳县',
      'children': null
    }, {
      'regionId': '211322',
      'parentId': '211300',
      'name': '建平县',
      'children': null
    }, {
      'regionId': '211324',
      'parentId': '211300',
      'name': '喀喇沁左翼蒙古族自治县',
      'children': null
    }, {
      'regionId': '211381',
      'parentId': '211300',
      'name': '北票市',
      'children': null
    }, {
      'regionId': '211382',
      'parentId': '211300',
      'name': '凌源市',
      'children': null
    }]
  }, {
    'regionId': '211400',
    'parentId': '210000',
    'name': '葫芦岛市',
    'children': [{
      'regionId': '211402',
      'parentId': '211400',
      'name': '连山区',
      'children': null
    }, {
      'regionId': '211403',
      'parentId': '211400',
      'name': '龙港区',
      'children': null
    }, {
      'regionId': '211404',
      'parentId': '211400',
      'name': '南票区',
      'children': null
    }, {
      'regionId': '211421',
      'parentId': '211400',
      'name': '绥中县',
      'children': null
    }, {
      'regionId': '211422',
      'parentId': '211400',
      'name': '建昌县',
      'children': null
    }, {
      'regionId': '211481',
      'parentId': '211400',
      'name': '兴城市',
      'children': null
    }]
  }]
}, {
  'regionId': '220000',
  'parentId': '0',
  'name': '吉林省',
  'children': [{
    'regionId': '220100',
    'parentId': '220000',
    'name': '长春市',
    'children': [{
      'regionId': '220102',
      'parentId': '220100',
      'name': '南关区',
      'children': null
    }, {
      'regionId': '220103',
      'parentId': '220100',
      'name': '宽城区',
      'children': null
    }, {
      'regionId': '220104',
      'parentId': '220100',
      'name': '朝阳区',
      'children': null
    }, {
      'regionId': '220105',
      'parentId': '220100',
      'name': '二道区',
      'children': null
    }, {
      'regionId': '220106',
      'parentId': '220100',
      'name': '绿园区',
      'children': null
    }, {
      'regionId': '220112',
      'parentId': '220100',
      'name': '双阳区',
      'children': null
    }, {
      'regionId': '220113',
      'parentId': '220100',
      'name': '九台区',
      'children': null
    }, {
      'regionId': '220122',
      'parentId': '220100',
      'name': '农安县',
      'children': null
    }, {
      'regionId': '220182',
      'parentId': '220100',
      'name': '榆树市',
      'children': null
    }, {
      'regionId': '220183',
      'parentId': '220100',
      'name': '德惠市',
      'children': null
    }, {
      'regionId': '220184',
      'parentId': '220100',
      'name': '公主岭市',
      'children': null
    }]
  }, {
    'regionId': '220200',
    'parentId': '220000',
    'name': '吉林市',
    'children': [{
      'regionId': '220202',
      'parentId': '220200',
      'name': '昌邑区',
      'children': null
    }, {
      'regionId': '220203',
      'parentId': '220200',
      'name': '龙潭区',
      'children': null
    }, {
      'regionId': '220204',
      'parentId': '220200',
      'name': '船营区',
      'children': null
    }, {
      'regionId': '220211',
      'parentId': '220200',
      'name': '丰满区',
      'children': null
    }, {
      'regionId': '220221',
      'parentId': '220200',
      'name': '永吉县',
      'children': null
    }, {
      'regionId': '220281',
      'parentId': '220200',
      'name': '蛟河市',
      'children': null
    }, {
      'regionId': '220282',
      'parentId': '220200',
      'name': '桦甸市',
      'children': null
    }, {
      'regionId': '220283',
      'parentId': '220200',
      'name': '舒兰市',
      'children': null
    }, {
      'regionId': '220284',
      'parentId': '220200',
      'name': '磐石市',
      'children': null
    }]
  }, {
    'regionId': '220300',
    'parentId': '220000',
    'name': '四平市',
    'children': [{
      'regionId': '220302',
      'parentId': '220300',
      'name': '铁西区',
      'children': null
    }, {
      'regionId': '220303',
      'parentId': '220300',
      'name': '铁东区',
      'children': null
    }, {
      'regionId': '220322',
      'parentId': '220300',
      'name': '梨树县',
      'children': null
    }, {
      'regionId': '220323',
      'parentId': '220300',
      'name': '伊通满族自治县',
      'children': null
    }, {
      'regionId': '220382',
      'parentId': '220300',
      'name': '双辽市',
      'children': null
    }]
  }, {
    'regionId': '220400',
    'parentId': '220000',
    'name': '辽源市',
    'children': [{
      'regionId': '220402',
      'parentId': '220400',
      'name': '龙山区',
      'children': null
    }, {
      'regionId': '220403',
      'parentId': '220400',
      'name': '西安区',
      'children': null
    }, {
      'regionId': '220421',
      'parentId': '220400',
      'name': '东丰县',
      'children': null
    }, {
      'regionId': '220422',
      'parentId': '220400',
      'name': '东辽县',
      'children': null
    }]
  }, {
    'regionId': '220500',
    'parentId': '220000',
    'name': '通化市',
    'children': [{
      'regionId': '220502',
      'parentId': '220500',
      'name': '东昌区',
      'children': null
    }, {
      'regionId': '220503',
      'parentId': '220500',
      'name': '二道江区',
      'children': null
    }, {
      'regionId': '220521',
      'parentId': '220500',
      'name': '通化县',
      'children': null
    }, {
      'regionId': '220523',
      'parentId': '220500',
      'name': '辉南县',
      'children': null
    }, {
      'regionId': '220524',
      'parentId': '220500',
      'name': '柳河县',
      'children': null
    }, {
      'regionId': '220581',
      'parentId': '220500',
      'name': '梅河口市',
      'children': null
    }, {
      'regionId': '220582',
      'parentId': '220500',
      'name': '集安市',
      'children': null
    }]
  }, {
    'regionId': '220600',
    'parentId': '220000',
    'name': '白山市',
    'children': [{
      'regionId': '220602',
      'parentId': '220600',
      'name': '浑江区',
      'children': null
    }, {
      'regionId': '220605',
      'parentId': '220600',
      'name': '江源区',
      'children': null
    }, {
      'regionId': '220621',
      'parentId': '220600',
      'name': '抚松县',
      'children': null
    }, {
      'regionId': '220622',
      'parentId': '220600',
      'name': '靖宇县',
      'children': null
    }, {
      'regionId': '220623',
      'parentId': '220600',
      'name': '长白朝鲜族自治县',
      'children': null
    }, {
      'regionId': '220681',
      'parentId': '220600',
      'name': '临江市',
      'children': null
    }]
  }, {
    'regionId': '220700',
    'parentId': '220000',
    'name': '松原市',
    'children': [{
      'regionId': '220702',
      'parentId': '220700',
      'name': '宁江区',
      'children': null
    }, {
      'regionId': '220721',
      'parentId': '220700',
      'name': '前郭尔罗斯蒙古族自治县',
      'children': null
    }, {
      'regionId': '220722',
      'parentId': '220700',
      'name': '长岭县',
      'children': null
    }, {
      'regionId': '220723',
      'parentId': '220700',
      'name': '乾安县',
      'children': null
    }, {
      'regionId': '220781',
      'parentId': '220700',
      'name': '扶余市',
      'children': null
    }]
  }, {
    'regionId': '220800',
    'parentId': '220000',
    'name': '白城市',
    'children': [{
      'regionId': '220802',
      'parentId': '220800',
      'name': '洮北区',
      'children': null
    }, {
      'regionId': '220821',
      'parentId': '220800',
      'name': '镇赉县',
      'children': null
    }, {
      'regionId': '220822',
      'parentId': '220800',
      'name': '通榆县',
      'children': null
    }, {
      'regionId': '220881',
      'parentId': '220800',
      'name': '洮南市',
      'children': null
    }, {
      'regionId': '220882',
      'parentId': '220800',
      'name': '大安市',
      'children': null
    }]
  }, {
    'regionId': '222400',
    'parentId': '220000',
    'name': '延边朝鲜族自治州',
    'children': [{
      'regionId': '222401',
      'parentId': '222400',
      'name': '延吉市',
      'children': null
    }, {
      'regionId': '222402',
      'parentId': '222400',
      'name': '图们市',
      'children': null
    }, {
      'regionId': '222403',
      'parentId': '222400',
      'name': '敦化市',
      'children': null
    }, {
      'regionId': '222404',
      'parentId': '222400',
      'name': '珲春市',
      'children': null
    }, {
      'regionId': '222405',
      'parentId': '222400',
      'name': '龙井市',
      'children': null
    }, {
      'regionId': '222406',
      'parentId': '222400',
      'name': '和龙市',
      'children': null
    }, {
      'regionId': '222424',
      'parentId': '222400',
      'name': '汪清县',
      'children': null
    }, {
      'regionId': '222426',
      'parentId': '222400',
      'name': '安图县',
      'children': null
    }]
  }]
}, {
  'regionId': '230000',
  'parentId': '0',
  'name': '黑龙江省',
  'children': [{
    'regionId': '230100',
    'parentId': '230000',
    'name': '哈尔滨市',
    'children': [{
      'regionId': '230102',
      'parentId': '230100',
      'name': '道里区',
      'children': null
    }, {
      'regionId': '230103',
      'parentId': '230100',
      'name': '南岗区',
      'children': null
    }, {
      'regionId': '230104',
      'parentId': '230100',
      'name': '道外区',
      'children': null
    }, {
      'regionId': '230108',
      'parentId': '230100',
      'name': '平房区',
      'children': null
    }, {
      'regionId': '230109',
      'parentId': '230100',
      'name': '松北区',
      'children': null
    }, {
      'regionId': '230110',
      'parentId': '230100',
      'name': '香坊区',
      'children': null
    }, {
      'regionId': '230111',
      'parentId': '230100',
      'name': '呼兰区',
      'children': null
    }, {
      'regionId': '230112',
      'parentId': '230100',
      'name': '阿城区',
      'children': null
    }, {
      'regionId': '230113',
      'parentId': '230100',
      'name': '双城区',
      'children': null
    }, {
      'regionId': '230123',
      'parentId': '230100',
      'name': '依兰县',
      'children': null
    }, {
      'regionId': '230124',
      'parentId': '230100',
      'name': '方正县',
      'children': null
    }, {
      'regionId': '230125',
      'parentId': '230100',
      'name': '宾县',
      'children': null
    }, {
      'regionId': '230126',
      'parentId': '230100',
      'name': '巴彦县',
      'children': null
    }, {
      'regionId': '230127',
      'parentId': '230100',
      'name': '木兰县',
      'children': null
    }, {
      'regionId': '230128',
      'parentId': '230100',
      'name': '通河县',
      'children': null
    }, {
      'regionId': '230129',
      'parentId': '230100',
      'name': '延寿县',
      'children': null
    }, {
      'regionId': '230183',
      'parentId': '230100',
      'name': '尚志市',
      'children': null
    }, {
      'regionId': '230184',
      'parentId': '230100',
      'name': '五常市',
      'children': null
    }]
  }, {
    'regionId': '230200',
    'parentId': '230000',
    'name': '齐齐哈尔市',
    'children': [{
      'regionId': '230202',
      'parentId': '230200',
      'name': '龙沙区',
      'children': null
    }, {
      'regionId': '230203',
      'parentId': '230200',
      'name': '建华区',
      'children': null
    }, {
      'regionId': '230204',
      'parentId': '230200',
      'name': '铁锋区',
      'children': null
    }, {
      'regionId': '230205',
      'parentId': '230200',
      'name': '昂昂溪区',
      'children': null
    }, {
      'regionId': '230206',
      'parentId': '230200',
      'name': '富拉尔基区',
      'children': null
    }, {
      'regionId': '230207',
      'parentId': '230200',
      'name': '碾子山区',
      'children': null
    }, {
      'regionId': '230208',
      'parentId': '230200',
      'name': '梅里斯达斡尔族区',
      'children': null
    }, {
      'regionId': '230221',
      'parentId': '230200',
      'name': '龙江县',
      'children': null
    }, {
      'regionId': '230223',
      'parentId': '230200',
      'name': '依安县',
      'children': null
    }, {
      'regionId': '230224',
      'parentId': '230200',
      'name': '泰来县',
      'children': null
    }, {
      'regionId': '230225',
      'parentId': '230200',
      'name': '甘南县',
      'children': null
    }, {
      'regionId': '230227',
      'parentId': '230200',
      'name': '富裕县',
      'children': null
    }, {
      'regionId': '230229',
      'parentId': '230200',
      'name': '克山县',
      'children': null
    }, {
      'regionId': '230230',
      'parentId': '230200',
      'name': '克东县',
      'children': null
    }, {
      'regionId': '230231',
      'parentId': '230200',
      'name': '拜泉县',
      'children': null
    }, {
      'regionId': '230281',
      'parentId': '230200',
      'name': '讷河市',
      'children': null
    }]
  }, {
    'regionId': '230300',
    'parentId': '230000',
    'name': '鸡西市',
    'children': [{
      'regionId': '230302',
      'parentId': '230300',
      'name': '鸡冠区',
      'children': null
    }, {
      'regionId': '230303',
      'parentId': '230300',
      'name': '恒山区',
      'children': null
    }, {
      'regionId': '230304',
      'parentId': '230300',
      'name': '滴道区',
      'children': null
    }, {
      'regionId': '230305',
      'parentId': '230300',
      'name': '梨树区',
      'children': null
    }, {
      'regionId': '230306',
      'parentId': '230300',
      'name': '城子河区',
      'children': null
    }, {
      'regionId': '230307',
      'parentId': '230300',
      'name': '麻山区',
      'children': null
    }, {
      'regionId': '230321',
      'parentId': '230300',
      'name': '鸡东县',
      'children': null
    }, {
      'regionId': '230381',
      'parentId': '230300',
      'name': '虎林市',
      'children': null
    }, {
      'regionId': '230382',
      'parentId': '230300',
      'name': '密山市',
      'children': null
    }]
  }, {
    'regionId': '230400',
    'parentId': '230000',
    'name': '鹤岗市',
    'children': [{
      'regionId': '230402',
      'parentId': '230400',
      'name': '向阳区',
      'children': null
    }, {
      'regionId': '230403',
      'parentId': '230400',
      'name': '工农区',
      'children': null
    }, {
      'regionId': '230404',
      'parentId': '230400',
      'name': '南山区',
      'children': null
    }, {
      'regionId': '230405',
      'parentId': '230400',
      'name': '兴安区',
      'children': null
    }, {
      'regionId': '230406',
      'parentId': '230400',
      'name': '东山区',
      'children': null
    }, {
      'regionId': '230407',
      'parentId': '230400',
      'name': '兴山区',
      'children': null
    }, {
      'regionId': '230421',
      'parentId': '230400',
      'name': '萝北县',
      'children': null
    }, {
      'regionId': '230422',
      'parentId': '230400',
      'name': '绥滨县',
      'children': null
    }]
  }, {
    'regionId': '230500',
    'parentId': '230000',
    'name': '双鸭山市',
    'children': [{
      'regionId': '230502',
      'parentId': '230500',
      'name': '尖山区',
      'children': null
    }, {
      'regionId': '230503',
      'parentId': '230500',
      'name': '岭东区',
      'children': null
    }, {
      'regionId': '230505',
      'parentId': '230500',
      'name': '四方台区',
      'children': null
    }, {
      'regionId': '230506',
      'parentId': '230500',
      'name': '宝山区',
      'children': null
    }, {
      'regionId': '230521',
      'parentId': '230500',
      'name': '集贤县',
      'children': null
    }, {
      'regionId': '230522',
      'parentId': '230500',
      'name': '友谊县',
      'children': null
    }, {
      'regionId': '230523',
      'parentId': '230500',
      'name': '宝清县',
      'children': null
    }, {
      'regionId': '230524',
      'parentId': '230500',
      'name': '饶河县',
      'children': null
    }]
  }, {
    'regionId': '230600',
    'parentId': '230000',
    'name': '大庆市',
    'children': [{
      'regionId': '230602',
      'parentId': '230600',
      'name': '萨尔图区',
      'children': null
    }, {
      'regionId': '230603',
      'parentId': '230600',
      'name': '龙凤区',
      'children': null
    }, {
      'regionId': '230604',
      'parentId': '230600',
      'name': '让胡路区',
      'children': null
    }, {
      'regionId': '230605',
      'parentId': '230600',
      'name': '红岗区',
      'children': null
    }, {
      'regionId': '230606',
      'parentId': '230600',
      'name': '大同区',
      'children': null
    }, {
      'regionId': '230621',
      'parentId': '230600',
      'name': '肇州县',
      'children': null
    }, {
      'regionId': '230622',
      'parentId': '230600',
      'name': '肇源县',
      'children': null
    }, {
      'regionId': '230623',
      'parentId': '230600',
      'name': '林甸县',
      'children': null
    }, {
      'regionId': '230624',
      'parentId': '230600',
      'name': '杜尔伯特蒙古族自治县',
      'children': null
    }]
  }, {
    'regionId': '230700',
    'parentId': '230000',
    'name': '伊春市',
    'children': [{
      'regionId': '230717',
      'parentId': '230700',
      'name': '伊美区',
      'children': null
    }, {
      'regionId': '230718',
      'parentId': '230700',
      'name': '乌翠区',
      'children': null
    }, {
      'regionId': '230719',
      'parentId': '230700',
      'name': '友好区',
      'children': null
    }, {
      'regionId': '230722',
      'parentId': '230700',
      'name': '嘉荫县',
      'children': null
    }, {
      'regionId': '230723',
      'parentId': '230700',
      'name': '汤旺县',
      'children': null
    }, {
      'regionId': '230724',
      'parentId': '230700',
      'name': '丰林县',
      'children': null
    }, {
      'regionId': '230725',
      'parentId': '230700',
      'name': '大箐山县',
      'children': null
    }, {
      'regionId': '230726',
      'parentId': '230700',
      'name': '南岔县',
      'children': null
    }, {
      'regionId': '230751',
      'parentId': '230700',
      'name': '金林区',
      'children': null
    }, {
      'regionId': '230781',
      'parentId': '230700',
      'name': '铁力市',
      'children': null
    }]
  }, {
    'regionId': '230800',
    'parentId': '230000',
    'name': '佳木斯市',
    'children': [{
      'regionId': '230803',
      'parentId': '230800',
      'name': '向阳区',
      'children': null
    }, {
      'regionId': '230804',
      'parentId': '230800',
      'name': '前进区',
      'children': null
    }, {
      'regionId': '230805',
      'parentId': '230800',
      'name': '东风区',
      'children': null
    }, {
      'regionId': '230811',
      'parentId': '230800',
      'name': '郊区',
      'children': null
    }, {
      'regionId': '230822',
      'parentId': '230800',
      'name': '桦南县',
      'children': null
    }, {
      'regionId': '230826',
      'parentId': '230800',
      'name': '桦川县',
      'children': null
    }, {
      'regionId': '230828',
      'parentId': '230800',
      'name': '汤原县',
      'children': null
    }, {
      'regionId': '230881',
      'parentId': '230800',
      'name': '同江市',
      'children': null
    }, {
      'regionId': '230882',
      'parentId': '230800',
      'name': '富锦市',
      'children': null
    }, {
      'regionId': '230883',
      'parentId': '230800',
      'name': '抚远市',
      'children': null
    }]
  }, {
    'regionId': '230900',
    'parentId': '230000',
    'name': '七台河市',
    'children': [{
      'regionId': '230902',
      'parentId': '230900',
      'name': '新兴区',
      'children': null
    }, {
      'regionId': '230903',
      'parentId': '230900',
      'name': '桃山区',
      'children': null
    }, {
      'regionId': '230904',
      'parentId': '230900',
      'name': '茄子河区',
      'children': null
    }, {
      'regionId': '230921',
      'parentId': '230900',
      'name': '勃利县',
      'children': null
    }]
  }, {
    'regionId': '231000',
    'parentId': '230000',
    'name': '牡丹江市',
    'children': [{
      'regionId': '231002',
      'parentId': '231000',
      'name': '东安区',
      'children': null
    }, {
      'regionId': '231003',
      'parentId': '231000',
      'name': '阳明区',
      'children': null
    }, {
      'regionId': '231004',
      'parentId': '231000',
      'name': '爱民区',
      'children': null
    }, {
      'regionId': '231005',
      'parentId': '231000',
      'name': '西安区',
      'children': null
    }, {
      'regionId': '231025',
      'parentId': '231000',
      'name': '林口县',
      'children': null
    }, {
      'regionId': '231081',
      'parentId': '231000',
      'name': '绥芬河市',
      'children': null
    }, {
      'regionId': '231083',
      'parentId': '231000',
      'name': '海林市',
      'children': null
    }, {
      'regionId': '231084',
      'parentId': '231000',
      'name': '宁安市',
      'children': null
    }, {
      'regionId': '231085',
      'parentId': '231000',
      'name': '穆棱市',
      'children': null
    }, {
      'regionId': '231086',
      'parentId': '231000',
      'name': '东宁市',
      'children': null
    }]
  }, {
    'regionId': '231100',
    'parentId': '230000',
    'name': '黑河市',
    'children': [{
      'regionId': '231102',
      'parentId': '231100',
      'name': '爱辉区',
      'children': null
    }, {
      'regionId': '231123',
      'parentId': '231100',
      'name': '逊克县',
      'children': null
    }, {
      'regionId': '231124',
      'parentId': '231100',
      'name': '孙吴县',
      'children': null
    }, {
      'regionId': '231181',
      'parentId': '231100',
      'name': '北安市',
      'children': null
    }, {
      'regionId': '231182',
      'parentId': '231100',
      'name': '五大连池市',
      'children': null
    }, {
      'regionId': '231183',
      'parentId': '231100',
      'name': '嫩江市',
      'children': null
    }]
  }, {
    'regionId': '231200',
    'parentId': '230000',
    'name': '绥化市',
    'children': [{
      'regionId': '231202',
      'parentId': '231200',
      'name': '北林区',
      'children': null
    }, {
      'regionId': '231221',
      'parentId': '231200',
      'name': '望奎县',
      'children': null
    }, {
      'regionId': '231222',
      'parentId': '231200',
      'name': '兰西县',
      'children': null
    }, {
      'regionId': '231223',
      'parentId': '231200',
      'name': '青冈县',
      'children': null
    }, {
      'regionId': '231224',
      'parentId': '231200',
      'name': '庆安县',
      'children': null
    }, {
      'regionId': '231225',
      'parentId': '231200',
      'name': '明水县',
      'children': null
    }, {
      'regionId': '231226',
      'parentId': '231200',
      'name': '绥棱县',
      'children': null
    }, {
      'regionId': '231281',
      'parentId': '231200',
      'name': '安达市',
      'children': null
    }, {
      'regionId': '231282',
      'parentId': '231200',
      'name': '肇东市',
      'children': null
    }, {
      'regionId': '231283',
      'parentId': '231200',
      'name': '海伦市',
      'children': null
    }]
  }, {
    'regionId': '232700',
    'parentId': '230000',
    'name': '大兴安岭地区',
    'children': [{
      'regionId': '232701',
      'parentId': '232700',
      'name': '漠河市',
      'children': null
    }, {
      'regionId': '232718',
      'parentId': '232700',
      'name': '加格达奇区',
      'children': null
    }, {
      'regionId': '232721',
      'parentId': '232700',
      'name': '呼玛县',
      'children': null
    }, {
      'regionId': '232722',
      'parentId': '232700',
      'name': '塔河县',
      'children': null
    }]
  }]
}, {
  'regionId': '310000',
  'parentId': '0',
  'name': '上海市',
  'children': [{
    'regionId': '310100',
    'parentId': '310000',
    'name': '上海城区',
    'children': [{
      'regionId': '310101',
      'parentId': '310100',
      'name': '黄浦区',
      'children': null
    }, {
      'regionId': '310104',
      'parentId': '310100',
      'name': '徐汇区',
      'children': null
    }, {
      'regionId': '310105',
      'parentId': '310100',
      'name': '长宁区',
      'children': null
    }, {
      'regionId': '310106',
      'parentId': '310100',
      'name': '静安区',
      'children': null
    }, {
      'regionId': '310107',
      'parentId': '310100',
      'name': '普陀区',
      'children': null
    }, {
      'regionId': '310109',
      'parentId': '310100',
      'name': '虹口区',
      'children': null
    }, {
      'regionId': '310110',
      'parentId': '310100',
      'name': '杨浦区',
      'children': null
    }, {
      'regionId': '310112',
      'parentId': '310100',
      'name': '闵行区',
      'children': null
    }, {
      'regionId': '310113',
      'parentId': '310100',
      'name': '宝山区',
      'children': null
    }, {
      'regionId': '310114',
      'parentId': '310100',
      'name': '嘉定区',
      'children': null
    }, {
      'regionId': '310115',
      'parentId': '310100',
      'name': '浦东新区',
      'children': null
    }, {
      'regionId': '310116',
      'parentId': '310100',
      'name': '金山区',
      'children': null
    }, {
      'regionId': '310117',
      'parentId': '310100',
      'name': '松江区',
      'children': null
    }, {
      'regionId': '310118',
      'parentId': '310100',
      'name': '青浦区',
      'children': null
    }, {
      'regionId': '310120',
      'parentId': '310100',
      'name': '奉贤区',
      'children': null
    }, {
      'regionId': '310151',
      'parentId': '310100',
      'name': '崇明区',
      'children': null
    }]
  }]
}, {
  'regionId': '320000',
  'parentId': '0',
  'name': '江苏省',
  'children': [{
    'regionId': '320100',
    'parentId': '320000',
    'name': '南京市',
    'children': [{
      'regionId': '320102',
      'parentId': '320100',
      'name': '玄武区',
      'children': null
    }, {
      'regionId': '320104',
      'parentId': '320100',
      'name': '秦淮区',
      'children': null
    }, {
      'regionId': '320105',
      'parentId': '320100',
      'name': '建邺区',
      'children': null
    }, {
      'regionId': '320106',
      'parentId': '320100',
      'name': '鼓楼区',
      'children': null
    }, {
      'regionId': '320111',
      'parentId': '320100',
      'name': '浦口区',
      'children': null
    }, {
      'regionId': '320113',
      'parentId': '320100',
      'name': '栖霞区',
      'children': null
    }, {
      'regionId': '320114',
      'parentId': '320100',
      'name': '雨花台区',
      'children': null
    }, {
      'regionId': '320115',
      'parentId': '320100',
      'name': '江宁区',
      'children': null
    }, {
      'regionId': '320116',
      'parentId': '320100',
      'name': '六合区',
      'children': null
    }, {
      'regionId': '320117',
      'parentId': '320100',
      'name': '溧水区',
      'children': null
    }, {
      'regionId': '320118',
      'parentId': '320100',
      'name': '高淳区',
      'children': null
    }]
  }, {
    'regionId': '320200',
    'parentId': '320000',
    'name': '无锡市',
    'children': [{
      'regionId': '320205',
      'parentId': '320200',
      'name': '锡山区',
      'children': null
    }, {
      'regionId': '320206',
      'parentId': '320200',
      'name': '惠山区',
      'children': null
    }, {
      'regionId': '320211',
      'parentId': '320200',
      'name': '滨湖区',
      'children': null
    }, {
      'regionId': '320213',
      'parentId': '320200',
      'name': '梁溪区',
      'children': null
    }, {
      'regionId': '320214',
      'parentId': '320200',
      'name': '新吴区',
      'children': null
    }, {
      'regionId': '320281',
      'parentId': '320200',
      'name': '江阴市',
      'children': null
    }, {
      'regionId': '320282',
      'parentId': '320200',
      'name': '宜兴市',
      'children': null
    }]
  }, {
    'regionId': '320300',
    'parentId': '320000',
    'name': '徐州市',
    'children': [{
      'regionId': '320302',
      'parentId': '320300',
      'name': '鼓楼区',
      'children': null
    }, {
      'regionId': '320303',
      'parentId': '320300',
      'name': '云龙区',
      'children': null
    }, {
      'regionId': '320305',
      'parentId': '320300',
      'name': '贾汪区',
      'children': null
    }, {
      'regionId': '320311',
      'parentId': '320300',
      'name': '泉山区',
      'children': null
    }, {
      'regionId': '320312',
      'parentId': '320300',
      'name': '铜山区',
      'children': null
    }, {
      'regionId': '320321',
      'parentId': '320300',
      'name': '丰县',
      'children': null
    }, {
      'regionId': '320322',
      'parentId': '320300',
      'name': '沛县',
      'children': null
    }, {
      'regionId': '320324',
      'parentId': '320300',
      'name': '睢宁县',
      'children': null
    }, {
      'regionId': '320381',
      'parentId': '320300',
      'name': '新沂市',
      'children': null
    }, {
      'regionId': '320382',
      'parentId': '320300',
      'name': '邳州市',
      'children': null
    }]
  }, {
    'regionId': '320400',
    'parentId': '320000',
    'name': '常州市',
    'children': [{
      'regionId': '320402',
      'parentId': '320400',
      'name': '天宁区',
      'children': null
    }, {
      'regionId': '320404',
      'parentId': '320400',
      'name': '钟楼区',
      'children': null
    }, {
      'regionId': '320411',
      'parentId': '320400',
      'name': '新北区',
      'children': null
    }, {
      'regionId': '320412',
      'parentId': '320400',
      'name': '武进区',
      'children': null
    }, {
      'regionId': '320413',
      'parentId': '320400',
      'name': '金坛区',
      'children': null
    }, {
      'regionId': '320481',
      'parentId': '320400',
      'name': '溧阳市',
      'children': null
    }]
  }, {
    'regionId': '320500',
    'parentId': '320000',
    'name': '苏州市',
    'children': [{
      'regionId': '320505',
      'parentId': '320500',
      'name': '虎丘区',
      'children': null
    }, {
      'regionId': '320506',
      'parentId': '320500',
      'name': '吴中区',
      'children': null
    }, {
      'regionId': '320507',
      'parentId': '320500',
      'name': '相城区',
      'children': null
    }, {
      'regionId': '320508',
      'parentId': '320500',
      'name': '姑苏区',
      'children': null
    }, {
      'regionId': '320509',
      'parentId': '320500',
      'name': '吴江区',
      'children': null
    }, {
      'regionId': '320581',
      'parentId': '320500',
      'name': '常熟市',
      'children': null
    }, {
      'regionId': '320582',
      'parentId': '320500',
      'name': '张家港市',
      'children': null
    }, {
      'regionId': '320583',
      'parentId': '320500',
      'name': '昆山市',
      'children': null
    }, {
      'regionId': '320585',
      'parentId': '320500',
      'name': '太仓市',
      'children': null
    }]
  }, {
    'regionId': '320600',
    'parentId': '320000',
    'name': '南通市',
    'children': [{
      'regionId': '320612',
      'parentId': '320600',
      'name': '通州区',
      'children': null
    }, {
      'regionId': '320613',
      'parentId': '320600',
      'name': '崇川区',
      'children': null
    }, {
      'regionId': '320614',
      'parentId': '320600',
      'name': '海门区',
      'children': null
    }, {
      'regionId': '320623',
      'parentId': '320600',
      'name': '如东县',
      'children': null
    }, {
      'regionId': '320681',
      'parentId': '320600',
      'name': '启东市',
      'children': null
    }, {
      'regionId': '320682',
      'parentId': '320600',
      'name': '如皋市',
      'children': null
    }, {
      'regionId': '320685',
      'parentId': '320600',
      'name': '海安市',
      'children': null
    }]
  }, {
    'regionId': '320700',
    'parentId': '320000',
    'name': '连云港市',
    'children': [{
      'regionId': '320703',
      'parentId': '320700',
      'name': '连云区',
      'children': null
    }, {
      'regionId': '320706',
      'parentId': '320700',
      'name': '海州区',
      'children': null
    }, {
      'regionId': '320707',
      'parentId': '320700',
      'name': '赣榆区',
      'children': null
    }, {
      'regionId': '320722',
      'parentId': '320700',
      'name': '东海县',
      'children': null
    }, {
      'regionId': '320723',
      'parentId': '320700',
      'name': '灌云县',
      'children': null
    }, {
      'regionId': '320724',
      'parentId': '320700',
      'name': '灌南县',
      'children': null
    }]
  }, {
    'regionId': '320800',
    'parentId': '320000',
    'name': '淮安市',
    'children': [{
      'regionId': '320803',
      'parentId': '320800',
      'name': '淮安区',
      'children': null
    }, {
      'regionId': '320804',
      'parentId': '320800',
      'name': '淮阴区',
      'children': null
    }, {
      'regionId': '320812',
      'parentId': '320800',
      'name': '清江浦区',
      'children': null
    }, {
      'regionId': '320813',
      'parentId': '320800',
      'name': '洪泽区',
      'children': null
    }, {
      'regionId': '320826',
      'parentId': '320800',
      'name': '涟水县',
      'children': null
    }, {
      'regionId': '320830',
      'parentId': '320800',
      'name': '盱眙县',
      'children': null
    }, {
      'regionId': '320831',
      'parentId': '320800',
      'name': '金湖县',
      'children': null
    }]
  }, {
    'regionId': '320900',
    'parentId': '320000',
    'name': '盐城市',
    'children': [{
      'regionId': '320902',
      'parentId': '320900',
      'name': '亭湖区',
      'children': null
    }, {
      'regionId': '320903',
      'parentId': '320900',
      'name': '盐都区',
      'children': null
    }, {
      'regionId': '320904',
      'parentId': '320900',
      'name': '大丰区',
      'children': null
    }, {
      'regionId': '320921',
      'parentId': '320900',
      'name': '响水县',
      'children': null
    }, {
      'regionId': '320922',
      'parentId': '320900',
      'name': '滨海县',
      'children': null
    }, {
      'regionId': '320923',
      'parentId': '320900',
      'name': '阜宁县',
      'children': null
    }, {
      'regionId': '320924',
      'parentId': '320900',
      'name': '射阳县',
      'children': null
    }, {
      'regionId': '320925',
      'parentId': '320900',
      'name': '建湖县',
      'children': null
    }, {
      'regionId': '320981',
      'parentId': '320900',
      'name': '东台市',
      'children': null
    }]
  }, {
    'regionId': '321000',
    'parentId': '320000',
    'name': '扬州市',
    'children': [{
      'regionId': '321002',
      'parentId': '321000',
      'name': '广陵区',
      'children': null
    }, {
      'regionId': '321003',
      'parentId': '321000',
      'name': '邗江区',
      'children': null
    }, {
      'regionId': '321012',
      'parentId': '321000',
      'name': '江都区',
      'children': null
    }, {
      'regionId': '321023',
      'parentId': '321000',
      'name': '宝应县',
      'children': null
    }, {
      'regionId': '321081',
      'parentId': '321000',
      'name': '仪征市',
      'children': null
    }, {
      'regionId': '321084',
      'parentId': '321000',
      'name': '高邮市',
      'children': null
    }]
  }, {
    'regionId': '321100',
    'parentId': '320000',
    'name': '镇江市',
    'children': [{
      'regionId': '321102',
      'parentId': '321100',
      'name': '京口区',
      'children': null
    }, {
      'regionId': '321111',
      'parentId': '321100',
      'name': '润州区',
      'children': null
    }, {
      'regionId': '321112',
      'parentId': '321100',
      'name': '丹徒区',
      'children': null
    }, {
      'regionId': '321181',
      'parentId': '321100',
      'name': '丹阳市',
      'children': null
    }, {
      'regionId': '321182',
      'parentId': '321100',
      'name': '扬中市',
      'children': null
    }, {
      'regionId': '321183',
      'parentId': '321100',
      'name': '句容市',
      'children': null
    }]
  }, {
    'regionId': '321200',
    'parentId': '320000',
    'name': '泰州市',
    'children': [{
      'regionId': '321202',
      'parentId': '321200',
      'name': '海陵区',
      'children': null
    }, {
      'regionId': '321203',
      'parentId': '321200',
      'name': '高港区',
      'children': null
    }, {
      'regionId': '321204',
      'parentId': '321200',
      'name': '姜堰区',
      'children': null
    }, {
      'regionId': '321281',
      'parentId': '321200',
      'name': '兴化市',
      'children': null
    }, {
      'regionId': '321282',
      'parentId': '321200',
      'name': '靖江市',
      'children': null
    }, {
      'regionId': '321283',
      'parentId': '321200',
      'name': '泰兴市',
      'children': null
    }]
  }, {
    'regionId': '321300',
    'parentId': '320000',
    'name': '宿迁市',
    'children': [{
      'regionId': '321302',
      'parentId': '321300',
      'name': '宿城区',
      'children': null
    }, {
      'regionId': '321311',
      'parentId': '321300',
      'name': '宿豫区',
      'children': null
    }, {
      'regionId': '321322',
      'parentId': '321300',
      'name': '沭阳县',
      'children': null
    }, {
      'regionId': '321323',
      'parentId': '321300',
      'name': '泗阳县',
      'children': null
    }, {
      'regionId': '321324',
      'parentId': '321300',
      'name': '泗洪县',
      'children': null
    }]
  }]
}, {
  'regionId': '330000',
  'parentId': '0',
  'name': '浙江省',
  'children': [{
    'regionId': '330100',
    'parentId': '330000',
    'name': '杭州市',
    'children': [{
      'regionId': '330102',
      'parentId': '330100',
      'name': '上城区',
      'children': null
    }, {
      'regionId': '330105',
      'parentId': '330100',
      'name': '拱墅区',
      'children': null
    }, {
      'regionId': '330106',
      'parentId': '330100',
      'name': '西湖区',
      'children': null
    }, {
      'regionId': '330108',
      'parentId': '330100',
      'name': '滨江区',
      'children': null
    }, {
      'regionId': '330109',
      'parentId': '330100',
      'name': '萧山区',
      'children': null
    }, {
      'regionId': '330110',
      'parentId': '330100',
      'name': '余杭区',
      'children': null
    }, {
      'regionId': '330111',
      'parentId': '330100',
      'name': '富阳区',
      'children': null
    }, {
      'regionId': '330112',
      'parentId': '330100',
      'name': '临安区',
      'children': null
    }, {
      'regionId': '330113',
      'parentId': '330100',
      'name': '临平区',
      'children': null
    }, {
      'regionId': '330114',
      'parentId': '330100',
      'name': '钱塘区',
      'children': null
    }, {
      'regionId': '330122',
      'parentId': '330100',
      'name': '桐庐县',
      'children': null
    }, {
      'regionId': '330127',
      'parentId': '330100',
      'name': '淳安县',
      'children': null
    }, {
      'regionId': '330182',
      'parentId': '330100',
      'name': '建德市',
      'children': null
    }]
  }, {
    'regionId': '330200',
    'parentId': '330000',
    'name': '宁波市',
    'children': [{
      'regionId': '330203',
      'parentId': '330200',
      'name': '海曙区',
      'children': null
    }, {
      'regionId': '330205',
      'parentId': '330200',
      'name': '江北区',
      'children': null
    }, {
      'regionId': '330206',
      'parentId': '330200',
      'name': '北仑区',
      'children': null
    }, {
      'regionId': '330211',
      'parentId': '330200',
      'name': '镇海区',
      'children': null
    }, {
      'regionId': '330212',
      'parentId': '330200',
      'name': '鄞州区',
      'children': null
    }, {
      'regionId': '330213',
      'parentId': '330200',
      'name': '奉化区',
      'children': null
    }, {
      'regionId': '330225',
      'parentId': '330200',
      'name': '象山县',
      'children': null
    }, {
      'regionId': '330226',
      'parentId': '330200',
      'name': '宁海县',
      'children': null
    }, {
      'regionId': '330281',
      'parentId': '330200',
      'name': '余姚市',
      'children': null
    }, {
      'regionId': '330282',
      'parentId': '330200',
      'name': '慈溪市',
      'children': null
    }]
  }, {
    'regionId': '330300',
    'parentId': '330000',
    'name': '温州市',
    'children': [{
      'regionId': '330302',
      'parentId': '330300',
      'name': '鹿城区',
      'children': null
    }, {
      'regionId': '330303',
      'parentId': '330300',
      'name': '龙湾区',
      'children': null
    }, {
      'regionId': '330304',
      'parentId': '330300',
      'name': '瓯海区',
      'children': null
    }, {
      'regionId': '330305',
      'parentId': '330300',
      'name': '洞头区',
      'children': null
    }, {
      'regionId': '330324',
      'parentId': '330300',
      'name': '永嘉县',
      'children': null
    }, {
      'regionId': '330326',
      'parentId': '330300',
      'name': '平阳县',
      'children': null
    }, {
      'regionId': '330327',
      'parentId': '330300',
      'name': '苍南县',
      'children': null
    }, {
      'regionId': '330328',
      'parentId': '330300',
      'name': '文成县',
      'children': null
    }, {
      'regionId': '330329',
      'parentId': '330300',
      'name': '泰顺县',
      'children': null
    }, {
      'regionId': '330381',
      'parentId': '330300',
      'name': '瑞安市',
      'children': null
    }, {
      'regionId': '330382',
      'parentId': '330300',
      'name': '乐清市',
      'children': null
    }, {
      'regionId': '330383',
      'parentId': '330300',
      'name': '龙港市',
      'children': null
    }]
  }, {
    'regionId': '330400',
    'parentId': '330000',
    'name': '嘉兴市',
    'children': [{
      'regionId': '330402',
      'parentId': '330400',
      'name': '南湖区',
      'children': null
    }, {
      'regionId': '330411',
      'parentId': '330400',
      'name': '秀洲区',
      'children': null
    }, {
      'regionId': '330421',
      'parentId': '330400',
      'name': '嘉善县',
      'children': null
    }, {
      'regionId': '330424',
      'parentId': '330400',
      'name': '海盐县',
      'children': null
    }, {
      'regionId': '330481',
      'parentId': '330400',
      'name': '海宁市',
      'children': null
    }, {
      'regionId': '330482',
      'parentId': '330400',
      'name': '平湖市',
      'children': null
    }, {
      'regionId': '330483',
      'parentId': '330400',
      'name': '桐乡市',
      'children': null
    }]
  }, {
    'regionId': '330500',
    'parentId': '330000',
    'name': '湖州市',
    'children': [{
      'regionId': '330502',
      'parentId': '330500',
      'name': '吴兴区',
      'children': null
    }, {
      'regionId': '330503',
      'parentId': '330500',
      'name': '南浔区',
      'children': null
    }, {
      'regionId': '330521',
      'parentId': '330500',
      'name': '德清县',
      'children': null
    }, {
      'regionId': '330522',
      'parentId': '330500',
      'name': '长兴县',
      'children': null
    }, {
      'regionId': '330523',
      'parentId': '330500',
      'name': '安吉县',
      'children': null
    }]
  }, {
    'regionId': '330600',
    'parentId': '330000',
    'name': '绍兴市',
    'children': [{
      'regionId': '330602',
      'parentId': '330600',
      'name': '越城区',
      'children': null
    }, {
      'regionId': '330603',
      'parentId': '330600',
      'name': '柯桥区',
      'children': null
    }, {
      'regionId': '330604',
      'parentId': '330600',
      'name': '上虞区',
      'children': null
    }, {
      'regionId': '330624',
      'parentId': '330600',
      'name': '新昌县',
      'children': null
    }, {
      'regionId': '330681',
      'parentId': '330600',
      'name': '诸暨市',
      'children': null
    }, {
      'regionId': '330683',
      'parentId': '330600',
      'name': '嵊州市',
      'children': null
    }]
  }, {
    'regionId': '330700',
    'parentId': '330000',
    'name': '金华市',
    'children': [{
      'regionId': '330702',
      'parentId': '330700',
      'name': '婺城区',
      'children': null
    }, {
      'regionId': '330703',
      'parentId': '330700',
      'name': '金东区',
      'children': null
    }, {
      'regionId': '330723',
      'parentId': '330700',
      'name': '武义县',
      'children': null
    }, {
      'regionId': '330726',
      'parentId': '330700',
      'name': '浦江县',
      'children': null
    }, {
      'regionId': '330727',
      'parentId': '330700',
      'name': '磐安县',
      'children': null
    }, {
      'regionId': '330781',
      'parentId': '330700',
      'name': '兰溪市',
      'children': null
    }, {
      'regionId': '330782',
      'parentId': '330700',
      'name': '义乌市',
      'children': null
    }, {
      'regionId': '330783',
      'parentId': '330700',
      'name': '东阳市',
      'children': null
    }, {
      'regionId': '330784',
      'parentId': '330700',
      'name': '永康市',
      'children': null
    }]
  }, {
    'regionId': '330800',
    'parentId': '330000',
    'name': '衢州市',
    'children': [{
      'regionId': '330802',
      'parentId': '330800',
      'name': '柯城区',
      'children': null
    }, {
      'regionId': '330803',
      'parentId': '330800',
      'name': '衢江区',
      'children': null
    }, {
      'regionId': '330822',
      'parentId': '330800',
      'name': '常山县',
      'children': null
    }, {
      'regionId': '330824',
      'parentId': '330800',
      'name': '开化县',
      'children': null
    }, {
      'regionId': '330825',
      'parentId': '330800',
      'name': '龙游县',
      'children': null
    }, {
      'regionId': '330881',
      'parentId': '330800',
      'name': '江山市',
      'children': null
    }]
  }, {
    'regionId': '330900',
    'parentId': '330000',
    'name': '舟山市',
    'children': [{
      'regionId': '330902',
      'parentId': '330900',
      'name': '定海区',
      'children': null
    }, {
      'regionId': '330903',
      'parentId': '330900',
      'name': '普陀区',
      'children': null
    }, {
      'regionId': '330921',
      'parentId': '330900',
      'name': '岱山县',
      'children': null
    }, {
      'regionId': '330922',
      'parentId': '330900',
      'name': '嵊泗县',
      'children': null
    }]
  }, {
    'regionId': '331000',
    'parentId': '330000',
    'name': '台州市',
    'children': [{
      'regionId': '331002',
      'parentId': '331000',
      'name': '椒江区',
      'children': null
    }, {
      'regionId': '331003',
      'parentId': '331000',
      'name': '黄岩区',
      'children': null
    }, {
      'regionId': '331004',
      'parentId': '331000',
      'name': '路桥区',
      'children': null
    }, {
      'regionId': '331022',
      'parentId': '331000',
      'name': '三门县',
      'children': null
    }, {
      'regionId': '331023',
      'parentId': '331000',
      'name': '天台县',
      'children': null
    }, {
      'regionId': '331024',
      'parentId': '331000',
      'name': '仙居县',
      'children': null
    }, {
      'regionId': '331081',
      'parentId': '331000',
      'name': '温岭市',
      'children': null
    }, {
      'regionId': '331082',
      'parentId': '331000',
      'name': '临海市',
      'children': null
    }, {
      'regionId': '331083',
      'parentId': '331000',
      'name': '龙港市',
      'children': null
    }]
  }, {
    'regionId': '331100',
    'parentId': '330000',
    'name': '丽水市',
    'children': [{
      'regionId': '331102',
      'parentId': '331100',
      'name': '莲都区',
      'children': null
    }, {
      'regionId': '331121',
      'parentId': '331100',
      'name': '青田县',
      'children': null
    }, {
      'regionId': '331122',
      'parentId': '331100',
      'name': '缙云县',
      'children': null
    }, {
      'regionId': '331123',
      'parentId': '331100',
      'name': '遂昌县',
      'children': null
    }, {
      'regionId': '331124',
      'parentId': '331100',
      'name': '松阳县',
      'children': null
    }, {
      'regionId': '331125',
      'parentId': '331100',
      'name': '云和县',
      'children': null
    }, {
      'regionId': '331126',
      'parentId': '331100',
      'name': '庆元县',
      'children': null
    }, {
      'regionId': '331127',
      'parentId': '331100',
      'name': '景宁畲族自治县',
      'children': null
    }, {
      'regionId': '331181',
      'parentId': '331100',
      'name': '龙泉市',
      'children': null
    }]
  }]
}, {
  'regionId': '340000',
  'parentId': '0',
  'name': '安徽省',
  'children': [{
    'regionId': '340100',
    'parentId': '340000',
    'name': '合肥市',
    'children': [{
      'regionId': '340102',
      'parentId': '340100',
      'name': '瑶海区',
      'children': null
    }, {
      'regionId': '340103',
      'parentId': '340100',
      'name': '庐阳区',
      'children': null
    }, {
      'regionId': '340104',
      'parentId': '340100',
      'name': '蜀山区',
      'children': null
    }, {
      'regionId': '340111',
      'parentId': '340100',
      'name': '包河区',
      'children': null
    }, {
      'regionId': '340121',
      'parentId': '340100',
      'name': '长丰县',
      'children': null
    }, {
      'regionId': '340122',
      'parentId': '340100',
      'name': '肥东县',
      'children': null
    }, {
      'regionId': '340123',
      'parentId': '340100',
      'name': '肥西县',
      'children': null
    }, {
      'regionId': '340124',
      'parentId': '340100',
      'name': '庐江县',
      'children': null
    }, {
      'regionId': '340181',
      'parentId': '340100',
      'name': '巢湖市',
      'children': null
    }]
  }, {
    'regionId': '340200',
    'parentId': '340000',
    'name': '芜湖市',
    'children': [{
      'regionId': '340202',
      'parentId': '340200',
      'name': '镜湖区',
      'children': null
    }, {
      'regionId': '340207',
      'parentId': '340200',
      'name': '鸠江区',
      'children': null
    }, {
      'regionId': '340209',
      'parentId': '340200',
      'name': '弋江区',
      'children': null
    }, {
      'regionId': '340210',
      'parentId': '340200',
      'name': '湾沚区',
      'children': null
    }, {
      'regionId': '340223',
      'parentId': '340200',
      'name': '南陵县',
      'children': null
    }, {
      'regionId': '340281',
      'parentId': '340200',
      'name': '无为市',
      'children': null
    }]
  }, {
    'regionId': '340300',
    'parentId': '340000',
    'name': '蚌埠市',
    'children': [{
      'regionId': '340302',
      'parentId': '340300',
      'name': '龙子湖区',
      'children': null
    }, {
      'regionId': '340303',
      'parentId': '340300',
      'name': '蚌山区',
      'children': null
    }, {
      'regionId': '340304',
      'parentId': '340300',
      'name': '禹会区',
      'children': null
    }, {
      'regionId': '340311',
      'parentId': '340300',
      'name': '淮上区',
      'children': null
    }, {
      'regionId': '340321',
      'parentId': '340300',
      'name': '怀远县',
      'children': null
    }, {
      'regionId': '340322',
      'parentId': '340300',
      'name': '五河县',
      'children': null
    }, {
      'regionId': '340323',
      'parentId': '340300',
      'name': '固镇县',
      'children': null
    }]
  }, {
    'regionId': '340400',
    'parentId': '340000',
    'name': '淮南市',
    'children': [{
      'regionId': '340402',
      'parentId': '340400',
      'name': '大通区',
      'children': null
    }, {
      'regionId': '340403',
      'parentId': '340400',
      'name': '田家庵区',
      'children': null
    }, {
      'regionId': '340404',
      'parentId': '340400',
      'name': '谢家集区',
      'children': null
    }, {
      'regionId': '340405',
      'parentId': '340400',
      'name': '八公山区',
      'children': null
    }, {
      'regionId': '340406',
      'parentId': '340400',
      'name': '潘集区',
      'children': null
    }, {
      'regionId': '340421',
      'parentId': '340400',
      'name': '凤台县',
      'children': null
    }, {
      'regionId': '340422',
      'parentId': '340400',
      'name': '寿县',
      'children': null
    }]
  }, {
    'regionId': '340500',
    'parentId': '340000',
    'name': '马鞍山市',
    'children': [{
      'regionId': '340503',
      'parentId': '340500',
      'name': '花山区',
      'children': null
    }, {
      'regionId': '340504',
      'parentId': '340500',
      'name': '雨山区',
      'children': null
    }, {
      'regionId': '340506',
      'parentId': '340500',
      'name': '博望区',
      'children': null
    }, {
      'regionId': '340521',
      'parentId': '340500',
      'name': '当涂县',
      'children': null
    }, {
      'regionId': '340522',
      'parentId': '340500',
      'name': '含山县',
      'children': null
    }, {
      'regionId': '340523',
      'parentId': '340500',
      'name': '和县',
      'children': null
    }]
  }, {
    'regionId': '340600',
    'parentId': '340000',
    'name': '淮北市',
    'children': [{
      'regionId': '340602',
      'parentId': '340600',
      'name': '杜集区',
      'children': null
    }, {
      'regionId': '340603',
      'parentId': '340600',
      'name': '相山区',
      'children': null
    }, {
      'regionId': '340604',
      'parentId': '340600',
      'name': '烈山区',
      'children': null
    }, {
      'regionId': '340621',
      'parentId': '340600',
      'name': '濉溪县',
      'children': null
    }]
  }, {
    'regionId': '340700',
    'parentId': '340000',
    'name': '铜陵市',
    'children': [{
      'regionId': '340705',
      'parentId': '340700',
      'name': '铜官区',
      'children': null
    }, {
      'regionId': '340706',
      'parentId': '340700',
      'name': '义安区',
      'children': null
    }, {
      'regionId': '340711',
      'parentId': '340700',
      'name': '郊区',
      'children': null
    }, {
      'regionId': '340722',
      'parentId': '340700',
      'name': '枞阳县',
      'children': null
    }]
  }, {
    'regionId': '340800',
    'parentId': '340000',
    'name': '安庆市',
    'children': [{
      'regionId': '340802',
      'parentId': '340800',
      'name': '迎江区',
      'children': null
    }, {
      'regionId': '340803',
      'parentId': '340800',
      'name': '大观区',
      'children': null
    }, {
      'regionId': '340811',
      'parentId': '340800',
      'name': '宜秀区',
      'children': null
    }, {
      'regionId': '340822',
      'parentId': '340800',
      'name': '怀宁县',
      'children': null
    }, {
      'regionId': '340825',
      'parentId': '340800',
      'name': '太湖县',
      'children': null
    }, {
      'regionId': '340826',
      'parentId': '340800',
      'name': '宿松县',
      'children': null
    }, {
      'regionId': '340827',
      'parentId': '340800',
      'name': '望江县',
      'children': null
    }, {
      'regionId': '340828',
      'parentId': '340800',
      'name': '岳西县',
      'children': null
    }, {
      'regionId': '340881',
      'parentId': '340800',
      'name': '桐城市',
      'children': null
    }, {
      'regionId': '340882',
      'parentId': '340800',
      'name': '潜山市',
      'children': null
    }]
  }, {
    'regionId': '341000',
    'parentId': '340000',
    'name': '黄山市',
    'children': [{
      'regionId': '341002',
      'parentId': '341000',
      'name': '屯溪区',
      'children': null
    }, {
      'regionId': '341003',
      'parentId': '341000',
      'name': '黄山区',
      'children': null
    }, {
      'regionId': '341004',
      'parentId': '341000',
      'name': '徽州区',
      'children': null
    }, {
      'regionId': '341021',
      'parentId': '341000',
      'name': '歙县',
      'children': null
    }, {
      'regionId': '341022',
      'parentId': '341000',
      'name': '休宁县',
      'children': null
    }, {
      'regionId': '341023',
      'parentId': '341000',
      'name': '黟县',
      'children': null
    }, {
      'regionId': '341024',
      'parentId': '341000',
      'name': '祁门县',
      'children': null
    }]
  }, {
    'regionId': '341100',
    'parentId': '340000',
    'name': '滁州市',
    'children': [{
      'regionId': '341102',
      'parentId': '341100',
      'name': '琅琊区',
      'children': null
    }, {
      'regionId': '341103',
      'parentId': '341100',
      'name': '南谯区',
      'children': null
    }, {
      'regionId': '341122',
      'parentId': '341100',
      'name': '来安县',
      'children': null
    }, {
      'regionId': '341124',
      'parentId': '341100',
      'name': '全椒县',
      'children': null
    }, {
      'regionId': '341125',
      'parentId': '341100',
      'name': '定远县',
      'children': null
    }, {
      'regionId': '341126',
      'parentId': '341100',
      'name': '凤阳县',
      'children': null
    }, {
      'regionId': '341181',
      'parentId': '341100',
      'name': '天长市',
      'children': null
    }, {
      'regionId': '341182',
      'parentId': '341100',
      'name': '明光市',
      'children': null
    }]
  }, {
    'regionId': '341200',
    'parentId': '340000',
    'name': '阜阳市',
    'children': [{
      'regionId': '341202',
      'parentId': '341200',
      'name': '颍州区',
      'children': null
    }, {
      'regionId': '341203',
      'parentId': '341200',
      'name': '颍东区',
      'children': null
    }, {
      'regionId': '341204',
      'parentId': '341200',
      'name': '颍泉区',
      'children': null
    }, {
      'regionId': '341221',
      'parentId': '341200',
      'name': '临泉县',
      'children': null
    }, {
      'regionId': '341222',
      'parentId': '341200',
      'name': '太和县',
      'children': null
    }, {
      'regionId': '341225',
      'parentId': '341200',
      'name': '阜南县',
      'children': null
    }, {
      'regionId': '341226',
      'parentId': '341200',
      'name': '颍上县',
      'children': null
    }, {
      'regionId': '341282',
      'parentId': '341200',
      'name': '界首市',
      'children': null
    }]
  }, {
    'regionId': '341300',
    'parentId': '340000',
    'name': '宿州市',
    'children': [{
      'regionId': '341302',
      'parentId': '341300',
      'name': '埇桥区',
      'children': null
    }, {
      'regionId': '341321',
      'parentId': '341300',
      'name': '砀山县',
      'children': null
    }, {
      'regionId': '341322',
      'parentId': '341300',
      'name': '萧县',
      'children': null
    }, {
      'regionId': '341323',
      'parentId': '341300',
      'name': '灵璧县',
      'children': null
    }, {
      'regionId': '341324',
      'parentId': '341300',
      'name': '泗县',
      'children': null
    }]
  }, {
    'regionId': '341500',
    'parentId': '340000',
    'name': '六安市',
    'children': [{
      'regionId': '341502',
      'parentId': '341500',
      'name': '金安区',
      'children': null
    }, {
      'regionId': '341503',
      'parentId': '341500',
      'name': '裕安区',
      'children': null
    }, {
      'regionId': '341504',
      'parentId': '341500',
      'name': '叶集区',
      'children': null
    }, {
      'regionId': '341522',
      'parentId': '341500',
      'name': '霍邱县',
      'children': null
    }, {
      'regionId': '341523',
      'parentId': '341500',
      'name': '舒城县',
      'children': null
    }, {
      'regionId': '341524',
      'parentId': '341500',
      'name': '金寨县',
      'children': null
    }, {
      'regionId': '341525',
      'parentId': '341500',
      'name': '霍山县',
      'children': null
    }]
  }, {
    'regionId': '341600',
    'parentId': '340000',
    'name': '亳州市',
    'children': [{
      'regionId': '341602',
      'parentId': '341600',
      'name': '谯城区',
      'children': null
    }, {
      'regionId': '341621',
      'parentId': '341600',
      'name': '涡阳县',
      'children': null
    }, {
      'regionId': '341622',
      'parentId': '341600',
      'name': '蒙城县',
      'children': null
    }, {
      'regionId': '341623',
      'parentId': '341600',
      'name': '利辛县',
      'children': null
    }]
  }, {
    'regionId': '341700',
    'parentId': '340000',
    'name': '池州市',
    'children': [{
      'regionId': '341702',
      'parentId': '341700',
      'name': '贵池区',
      'children': null
    }, {
      'regionId': '341721',
      'parentId': '341700',
      'name': '东至县',
      'children': null
    }, {
      'regionId': '341722',
      'parentId': '341700',
      'name': '石台县',
      'children': null
    }, {
      'regionId': '341723',
      'parentId': '341700',
      'name': '青阳县',
      'children': null
    }]
  }, {
    'regionId': '341800',
    'parentId': '340000',
    'name': '宣城市',
    'children': [{
      'regionId': '341802',
      'parentId': '341800',
      'name': '宣州区',
      'children': null
    }, {
      'regionId': '341821',
      'parentId': '341800',
      'name': '郎溪县',
      'children': null
    }, {
      'regionId': '341823',
      'parentId': '341800',
      'name': '泾县',
      'children': null
    }, {
      'regionId': '341824',
      'parentId': '341800',
      'name': '绩溪县',
      'children': null
    }, {
      'regionId': '341825',
      'parentId': '341800',
      'name': '旌德县',
      'children': null
    }, {
      'regionId': '341881',
      'parentId': '341800',
      'name': '宁国市',
      'children': null
    }, {
      'regionId': '341882',
      'parentId': '341800',
      'name': '广德市',
      'children': null
    }]
  }]
}, {
  'regionId': '350000',
  'parentId': '0',
  'name': '福建省',
  'children': [{
    'regionId': '350100',
    'parentId': '350000',
    'name': '福州市',
    'children': [{
      'regionId': '350102',
      'parentId': '350100',
      'name': '鼓楼区',
      'children': null
    }, {
      'regionId': '350103',
      'parentId': '350100',
      'name': '台江区',
      'children': null
    }, {
      'regionId': '350104',
      'parentId': '350100',
      'name': '仓山区',
      'children': null
    }, {
      'regionId': '350105',
      'parentId': '350100',
      'name': '马尾区',
      'children': null
    }, {
      'regionId': '350111',
      'parentId': '350100',
      'name': '晋安区',
      'children': null
    }, {
      'regionId': '350112',
      'parentId': '350100',
      'name': '长乐区',
      'children': null
    }, {
      'regionId': '350121',
      'parentId': '350100',
      'name': '闽侯县',
      'children': null
    }, {
      'regionId': '350122',
      'parentId': '350100',
      'name': '连江县',
      'children': null
    }, {
      'regionId': '350123',
      'parentId': '350100',
      'name': '罗源县',
      'children': null
    }, {
      'regionId': '350124',
      'parentId': '350100',
      'name': '闽清县',
      'children': null
    }, {
      'regionId': '350125',
      'parentId': '350100',
      'name': '永泰县',
      'children': null
    }, {
      'regionId': '350128',
      'parentId': '350100',
      'name': '平潭县',
      'children': null
    }, {
      'regionId': '350181',
      'parentId': '350100',
      'name': '福清市',
      'children': null
    }]
  }, {
    'regionId': '350200',
    'parentId': '350000',
    'name': '厦门市',
    'children': [{
      'regionId': '350203',
      'parentId': '350200',
      'name': '思明区',
      'children': null
    }, {
      'regionId': '350205',
      'parentId': '350200',
      'name': '海沧区',
      'children': null
    }, {
      'regionId': '350206',
      'parentId': '350200',
      'name': '湖里区',
      'children': null
    }, {
      'regionId': '350211',
      'parentId': '350200',
      'name': '集美区',
      'children': null
    }, {
      'regionId': '350212',
      'parentId': '350200',
      'name': '同安区',
      'children': null
    }, {
      'regionId': '350213',
      'parentId': '350200',
      'name': '翔安区',
      'children': null
    }]
  }, {
    'regionId': '350300',
    'parentId': '350000',
    'name': '莆田市',
    'children': [{
      'regionId': '350302',
      'parentId': '350300',
      'name': '城厢区',
      'children': null
    }, {
      'regionId': '350303',
      'parentId': '350300',
      'name': '涵江区',
      'children': null
    }, {
      'regionId': '350304',
      'parentId': '350300',
      'name': '荔城区',
      'children': null
    }, {
      'regionId': '350305',
      'parentId': '350300',
      'name': '秀屿区',
      'children': null
    }, {
      'regionId': '350322',
      'parentId': '350300',
      'name': '仙游县',
      'children': null
    }]
  }, {
    'regionId': '350400',
    'parentId': '350000',
    'name': '三明市',
    'children': [{
      'regionId': '350403',
      'parentId': '350400',
      'name': '三元区',
      'children': null
    }, {
      'regionId': '350421',
      'parentId': '350400',
      'name': '明溪县',
      'children': null
    }, {
      'regionId': '350423',
      'parentId': '350400',
      'name': '清流县',
      'children': null
    }, {
      'regionId': '350424',
      'parentId': '350400',
      'name': '宁化县',
      'children': null
    }, {
      'regionId': '350425',
      'parentId': '350400',
      'name': '大田县',
      'children': null
    }, {
      'regionId': '350426',
      'parentId': '350400',
      'name': '尤溪县',
      'children': null
    }, {
      'regionId': '350427',
      'parentId': '350400',
      'name': '沙县区',
      'children': null
    }, {
      'regionId': '350428',
      'parentId': '350400',
      'name': '将乐县',
      'children': null
    }, {
      'regionId': '350429',
      'parentId': '350400',
      'name': '泰宁县',
      'children': null
    }, {
      'regionId': '350430',
      'parentId': '350400',
      'name': '建宁县',
      'children': null
    }, {
      'regionId': '350481',
      'parentId': '350400',
      'name': '永安市',
      'children': null
    }]
  }, {
    'regionId': '350500',
    'parentId': '350000',
    'name': '泉州市',
    'children': [{
      'regionId': '350502',
      'parentId': '350500',
      'name': '鲤城区',
      'children': null
    }, {
      'regionId': '350503',
      'parentId': '350500',
      'name': '丰泽区',
      'children': null
    }, {
      'regionId': '350504',
      'parentId': '350500',
      'name': '洛江区',
      'children': null
    }, {
      'regionId': '350505',
      'parentId': '350500',
      'name': '泉港区',
      'children': null
    }, {
      'regionId': '350521',
      'parentId': '350500',
      'name': '惠安县',
      'children': null
    }, {
      'regionId': '350524',
      'parentId': '350500',
      'name': '安溪县',
      'children': null
    }, {
      'regionId': '350525',
      'parentId': '350500',
      'name': '永春县',
      'children': null
    }, {
      'regionId': '350526',
      'parentId': '350500',
      'name': '德化县',
      'children': null
    }, {
      'regionId': '350527',
      'parentId': '350500',
      'name': '金门县',
      'children': null
    }, {
      'regionId': '350581',
      'parentId': '350500',
      'name': '石狮市',
      'children': null
    }, {
      'regionId': '350582',
      'parentId': '350500',
      'name': '晋江市',
      'children': null
    }, {
      'regionId': '350583',
      'parentId': '350500',
      'name': '南安市',
      'children': null
    }]
  }, {
    'regionId': '350600',
    'parentId': '350000',
    'name': '漳州市',
    'children': [{
      'regionId': '350602',
      'parentId': '350600',
      'name': '芗城区',
      'children': null
    }, {
      'regionId': '350603',
      'parentId': '350600',
      'name': '龙文区',
      'children': null
    }, {
      'regionId': '350622',
      'parentId': '350600',
      'name': '云霄县',
      'children': null
    }, {
      'regionId': '350623',
      'parentId': '350600',
      'name': '漳浦县',
      'children': null
    }, {
      'regionId': '350624',
      'parentId': '350600',
      'name': '诏安县',
      'children': null
    }, {
      'regionId': '350625',
      'parentId': '350600',
      'name': '长泰区',
      'children': null
    }, {
      'regionId': '350626',
      'parentId': '350600',
      'name': '东山县',
      'children': null
    }, {
      'regionId': '350627',
      'parentId': '350600',
      'name': '南靖县',
      'children': null
    }, {
      'regionId': '350628',
      'parentId': '350600',
      'name': '平和县',
      'children': null
    }, {
      'regionId': '350629',
      'parentId': '350600',
      'name': '华安县',
      'children': null
    }, {
      'regionId': '350681',
      'parentId': '350600',
      'name': '龙海区',
      'children': null
    }]
  }, {
    'regionId': '350700',
    'parentId': '350000',
    'name': '南平市',
    'children': [{
      'regionId': '350702',
      'parentId': '350700',
      'name': '延平区',
      'children': null
    }, {
      'regionId': '350703',
      'parentId': '350700',
      'name': '建阳区',
      'children': null
    }, {
      'regionId': '350721',
      'parentId': '350700',
      'name': '顺昌县',
      'children': null
    }, {
      'regionId': '350722',
      'parentId': '350700',
      'name': '浦城县',
      'children': null
    }, {
      'regionId': '350723',
      'parentId': '350700',
      'name': '光泽县',
      'children': null
    }, {
      'regionId': '350724',
      'parentId': '350700',
      'name': '松溪县',
      'children': null
    }, {
      'regionId': '350725',
      'parentId': '350700',
      'name': '政和县',
      'children': null
    }, {
      'regionId': '350781',
      'parentId': '350700',
      'name': '邵武市',
      'children': null
    }, {
      'regionId': '350782',
      'parentId': '350700',
      'name': '武夷山市',
      'children': null
    }, {
      'regionId': '350783',
      'parentId': '350700',
      'name': '建瓯市',
      'children': null
    }]
  }, {
    'regionId': '350800',
    'parentId': '350000',
    'name': '龙岩市',
    'children': [{
      'regionId': '350802',
      'parentId': '350800',
      'name': '新罗区',
      'children': null
    }, {
      'regionId': '350803',
      'parentId': '350800',
      'name': '永定区',
      'children': null
    }, {
      'regionId': '350821',
      'parentId': '350800',
      'name': '长汀县',
      'children': null
    }, {
      'regionId': '350823',
      'parentId': '350800',
      'name': '上杭县',
      'children': null
    }, {
      'regionId': '350824',
      'parentId': '350800',
      'name': '武平县',
      'children': null
    }, {
      'regionId': '350825',
      'parentId': '350800',
      'name': '连城县',
      'children': null
    }, {
      'regionId': '350881',
      'parentId': '350800',
      'name': '漳平市',
      'children': null
    }]
  }, {
    'regionId': '350900',
    'parentId': '350000',
    'name': '宁德市',
    'children': [{
      'regionId': '350902',
      'parentId': '350900',
      'name': '蕉城区',
      'children': null
    }, {
      'regionId': '350921',
      'parentId': '350900',
      'name': '霞浦县',
      'children': null
    }, {
      'regionId': '350922',
      'parentId': '350900',
      'name': '古田县',
      'children': null
    }, {
      'regionId': '350923',
      'parentId': '350900',
      'name': '屏南县',
      'children': null
    }, {
      'regionId': '350924',
      'parentId': '350900',
      'name': '寿宁县',
      'children': null
    }, {
      'regionId': '350925',
      'parentId': '350900',
      'name': '周宁县',
      'children': null
    }, {
      'regionId': '350926',
      'parentId': '350900',
      'name': '柘荣县',
      'children': null
    }, {
      'regionId': '350981',
      'parentId': '350900',
      'name': '福安市',
      'children': null
    }, {
      'regionId': '350982',
      'parentId': '350900',
      'name': '福鼎市',
      'children': null
    }]
  }]
}, {
  'regionId': '360000',
  'parentId': '0',
  'name': '江西省',
  'children': [{
    'regionId': '360100',
    'parentId': '360000',
    'name': '南昌市',
    'children': [{
      'regionId': '360102',
      'parentId': '360100',
      'name': '东湖区',
      'children': null
    }, {
      'regionId': '360103',
      'parentId': '360100',
      'name': '西湖区',
      'children': null
    }, {
      'regionId': '360104',
      'parentId': '360100',
      'name': '青云谱区',
      'children': null
    }, {
      'regionId': '360111',
      'parentId': '360100',
      'name': '青山湖区',
      'children': null
    }, {
      'regionId': '360112',
      'parentId': '360100',
      'name': '新建区',
      'children': null
    }, {
      'regionId': '360113',
      'parentId': '360100',
      'name': '红谷滩区',
      'children': null
    }, {
      'regionId': '360121',
      'parentId': '360100',
      'name': '南昌县',
      'children': null
    }, {
      'regionId': '360123',
      'parentId': '360100',
      'name': '安义县',
      'children': null
    }, {
      'regionId': '360124',
      'parentId': '360100',
      'name': '进贤县',
      'children': null
    }]
  }, {
    'regionId': '360200',
    'parentId': '360000',
    'name': '景德镇市',
    'children': [{
      'regionId': '360202',
      'parentId': '360200',
      'name': '昌江区',
      'children': null
    }, {
      'regionId': '360203',
      'parentId': '360200',
      'name': '珠山区',
      'children': null
    }, {
      'regionId': '360222',
      'parentId': '360200',
      'name': '浮梁县',
      'children': null
    }, {
      'regionId': '360281',
      'parentId': '360200',
      'name': '乐平市',
      'children': null
    }]
  }, {
    'regionId': '360300',
    'parentId': '360000',
    'name': '萍乡市',
    'children': [{
      'regionId': '360302',
      'parentId': '360300',
      'name': '安源区',
      'children': null
    }, {
      'regionId': '360313',
      'parentId': '360300',
      'name': '湘东区',
      'children': null
    }, {
      'regionId': '360321',
      'parentId': '360300',
      'name': '莲花县',
      'children': null
    }, {
      'regionId': '360322',
      'parentId': '360300',
      'name': '上栗县',
      'children': null
    }, {
      'regionId': '360323',
      'parentId': '360300',
      'name': '芦溪县',
      'children': null
    }]
  }, {
    'regionId': '360400',
    'parentId': '360000',
    'name': '九江市',
    'children': [{
      'regionId': '360402',
      'parentId': '360400',
      'name': '濂溪区',
      'children': null
    }, {
      'regionId': '360403',
      'parentId': '360400',
      'name': '浔阳区',
      'children': null
    }, {
      'regionId': '360404',
      'parentId': '360400',
      'name': '柴桑区',
      'children': null
    }, {
      'regionId': '360423',
      'parentId': '360400',
      'name': '武宁县',
      'children': null
    }, {
      'regionId': '360424',
      'parentId': '360400',
      'name': '修水县',
      'children': null
    }, {
      'regionId': '360425',
      'parentId': '360400',
      'name': '永修县',
      'children': null
    }, {
      'regionId': '360426',
      'parentId': '360400',
      'name': '德安县',
      'children': null
    }, {
      'regionId': '360428',
      'parentId': '360400',
      'name': '都昌县',
      'children': null
    }, {
      'regionId': '360429',
      'parentId': '360400',
      'name': '湖口县',
      'children': null
    }, {
      'regionId': '360430',
      'parentId': '360400',
      'name': '彭泽县',
      'children': null
    }, {
      'regionId': '360481',
      'parentId': '360400',
      'name': '瑞昌市',
      'children': null
    }, {
      'regionId': '360482',
      'parentId': '360400',
      'name': '共青城市',
      'children': null
    }, {
      'regionId': '360483',
      'parentId': '360400',
      'name': '庐山市',
      'children': null
    }]
  }, {
    'regionId': '360500',
    'parentId': '360000',
    'name': '新余市',
    'children': [{
      'regionId': '360502',
      'parentId': '360500',
      'name': '渝水区',
      'children': null
    }, {
      'regionId': '360521',
      'parentId': '360500',
      'name': '分宜县',
      'children': null
    }]
  }, {
    'regionId': '360600',
    'parentId': '360000',
    'name': '鹰潭市',
    'children': [{
      'regionId': '360602',
      'parentId': '360600',
      'name': '月湖区',
      'children': null
    }, {
      'regionId': '360603',
      'parentId': '360600',
      'name': '余江区',
      'children': null
    }, {
      'regionId': '360681',
      'parentId': '360600',
      'name': '贵溪市',
      'children': null
    }]
  }, {
    'regionId': '360700',
    'parentId': '360000',
    'name': '赣州市',
    'children': [{
      'regionId': '360702',
      'parentId': '360700',
      'name': '章贡区',
      'children': null
    }, {
      'regionId': '360703',
      'parentId': '360700',
      'name': '南康区',
      'children': null
    }, {
      'regionId': '360704',
      'parentId': '360700',
      'name': '赣县区',
      'children': null
    }, {
      'regionId': '360722',
      'parentId': '360700',
      'name': '信丰县',
      'children': null
    }, {
      'regionId': '360723',
      'parentId': '360700',
      'name': '大余县',
      'children': null
    }, {
      'regionId': '360724',
      'parentId': '360700',
      'name': '上犹县',
      'children': null
    }, {
      'regionId': '360725',
      'parentId': '360700',
      'name': '崇义县',
      'children': null
    }, {
      'regionId': '360726',
      'parentId': '360700',
      'name': '安远县',
      'children': null
    }, {
      'regionId': '360728',
      'parentId': '360700',
      'name': '定南县',
      'children': null
    }, {
      'regionId': '360729',
      'parentId': '360700',
      'name': '全南县',
      'children': null
    }, {
      'regionId': '360730',
      'parentId': '360700',
      'name': '宁都县',
      'children': null
    }, {
      'regionId': '360731',
      'parentId': '360700',
      'name': '于都县',
      'children': null
    }, {
      'regionId': '360732',
      'parentId': '360700',
      'name': '兴国县',
      'children': null
    }, {
      'regionId': '360733',
      'parentId': '360700',
      'name': '会昌县',
      'children': null
    }, {
      'regionId': '360734',
      'parentId': '360700',
      'name': '寻乌县',
      'children': null
    }, {
      'regionId': '360735',
      'parentId': '360700',
      'name': '石城县',
      'children': null
    }, {
      'regionId': '360781',
      'parentId': '360700',
      'name': '瑞金市',
      'children': null
    }]
  }, {
    'regionId': '360800',
    'parentId': '360000',
    'name': '吉安市',
    'children': [{
      'regionId': '360802',
      'parentId': '360800',
      'name': '吉州区',
      'children': null
    }, {
      'regionId': '360803',
      'parentId': '360800',
      'name': '青原区',
      'children': null
    }, {
      'regionId': '360821',
      'parentId': '360800',
      'name': '吉安县',
      'children': null
    }, {
      'regionId': '360822',
      'parentId': '360800',
      'name': '吉水县',
      'children': null
    }, {
      'regionId': '360823',
      'parentId': '360800',
      'name': '峡江县',
      'children': null
    }, {
      'regionId': '360824',
      'parentId': '360800',
      'name': '新干县',
      'children': null
    }, {
      'regionId': '360825',
      'parentId': '360800',
      'name': '永丰县',
      'children': null
    }, {
      'regionId': '360826',
      'parentId': '360800',
      'name': '泰和县',
      'children': null
    }, {
      'regionId': '360827',
      'parentId': '360800',
      'name': '遂川县',
      'children': null
    }, {
      'regionId': '360828',
      'parentId': '360800',
      'name': '万安县',
      'children': null
    }, {
      'regionId': '360829',
      'parentId': '360800',
      'name': '安福县',
      'children': null
    }, {
      'regionId': '360830',
      'parentId': '360800',
      'name': '永新县',
      'children': null
    }, {
      'regionId': '360881',
      'parentId': '360800',
      'name': '井冈山市',
      'children': null
    }]
  }, {
    'regionId': '360900',
    'parentId': '360000',
    'name': '宜春市',
    'children': [{
      'regionId': '360902',
      'parentId': '360900',
      'name': '袁州区',
      'children': null
    }, {
      'regionId': '360921',
      'parentId': '360900',
      'name': '奉新县',
      'children': null
    }, {
      'regionId': '360922',
      'parentId': '360900',
      'name': '万载县',
      'children': null
    }, {
      'regionId': '360923',
      'parentId': '360900',
      'name': '上高县',
      'children': null
    }, {
      'regionId': '360924',
      'parentId': '360900',
      'name': '宜丰县',
      'children': null
    }, {
      'regionId': '360925',
      'parentId': '360900',
      'name': '靖安县',
      'children': null
    }, {
      'regionId': '360926',
      'parentId': '360900',
      'name': '铜鼓县',
      'children': null
    }, {
      'regionId': '360981',
      'parentId': '360900',
      'name': '丰城市',
      'children': null
    }, {
      'regionId': '360982',
      'parentId': '360900',
      'name': '樟树市',
      'children': null
    }, {
      'regionId': '360983',
      'parentId': '360900',
      'name': '高安市',
      'children': null
    }]
  }, {
    'regionId': '361000',
    'parentId': '360000',
    'name': '抚州市',
    'children': [{
      'regionId': '361002',
      'parentId': '361000',
      'name': '临川区',
      'children': null
    }, {
      'regionId': '361003',
      'parentId': '361000',
      'name': '东乡区',
      'children': null
    }, {
      'regionId': '361021',
      'parentId': '361000',
      'name': '南城县',
      'children': null
    }, {
      'regionId': '361022',
      'parentId': '361000',
      'name': '黎川县',
      'children': null
    }, {
      'regionId': '361023',
      'parentId': '361000',
      'name': '南丰县',
      'children': null
    }, {
      'regionId': '361024',
      'parentId': '361000',
      'name': '崇仁县',
      'children': null
    }, {
      'regionId': '361025',
      'parentId': '361000',
      'name': '乐安县',
      'children': null
    }, {
      'regionId': '361026',
      'parentId': '361000',
      'name': '宜黄县',
      'children': null
    }, {
      'regionId': '361027',
      'parentId': '361000',
      'name': '金溪县',
      'children': null
    }, {
      'regionId': '361028',
      'parentId': '361000',
      'name': '资溪县',
      'children': null
    }, {
      'regionId': '361030',
      'parentId': '361000',
      'name': '广昌县',
      'children': null
    }]
  }, {
    'regionId': '361100',
    'parentId': '360000',
    'name': '上饶市',
    'children': [{
      'regionId': '361102',
      'parentId': '361100',
      'name': '信州区',
      'children': null
    }, {
      'regionId': '361103',
      'parentId': '361100',
      'name': '广丰区',
      'children': null
    }, {
      'regionId': '361104',
      'parentId': '361100',
      'name': '广信区',
      'children': null
    }, {
      'regionId': '361123',
      'parentId': '361100',
      'name': '玉山县',
      'children': null
    }, {
      'regionId': '361124',
      'parentId': '361100',
      'name': '铅山县',
      'children': null
    }, {
      'regionId': '361125',
      'parentId': '361100',
      'name': '横峰县',
      'children': null
    }, {
      'regionId': '361126',
      'parentId': '361100',
      'name': '弋阳县',
      'children': null
    }, {
      'regionId': '361127',
      'parentId': '361100',
      'name': '余干县',
      'children': null
    }, {
      'regionId': '361128',
      'parentId': '361100',
      'name': '鄱阳县',
      'children': null
    }, {
      'regionId': '361129',
      'parentId': '361100',
      'name': '万年县',
      'children': null
    }, {
      'regionId': '361130',
      'parentId': '361100',
      'name': '婺源县',
      'children': null
    }, {
      'regionId': '361181',
      'parentId': '361100',
      'name': '德兴市',
      'children': null
    }]
  }]
}, {
  'regionId': '370000',
  'parentId': '0',
  'name': '山东省',
  'children': [{
    'regionId': '370100',
    'parentId': '370000',
    'name': '济南市',
    'children': [{
      'regionId': '370102',
      'parentId': '370100',
      'name': '历下区',
      'children': null
    }, {
      'regionId': '370103',
      'parentId': '370100',
      'name': '市中区',
      'children': null
    }, {
      'regionId': '370104',
      'parentId': '370100',
      'name': '槐荫区',
      'children': null
    }, {
      'regionId': '370105',
      'parentId': '370100',
      'name': '天桥区',
      'children': null
    }, {
      'regionId': '370112',
      'parentId': '370100',
      'name': '历城区',
      'children': null
    }, {
      'regionId': '370113',
      'parentId': '370100',
      'name': '长清区',
      'children': null
    }, {
      'regionId': '370114',
      'parentId': '370100',
      'name': '章丘区',
      'children': null
    }, {
      'regionId': '370115',
      'parentId': '370100',
      'name': '济阳区',
      'children': null
    }, {
      'regionId': '370116',
      'parentId': '370100',
      'name': '莱芜区',
      'children': null
    }, {
      'regionId': '370117',
      'parentId': '370100',
      'name': '钢城区',
      'children': null
    }, {
      'regionId': '370124',
      'parentId': '370100',
      'name': '平阴县',
      'children': null
    }, {
      'regionId': '370126',
      'parentId': '370100',
      'name': '商河县',
      'children': null
    }]
  }, {
    'regionId': '370200',
    'parentId': '370000',
    'name': '青岛市',
    'children': [{
      'regionId': '370202',
      'parentId': '370200',
      'name': '市南区',
      'children': null
    }, {
      'regionId': '370203',
      'parentId': '370200',
      'name': '市北区',
      'children': null
    }, {
      'regionId': '370211',
      'parentId': '370200',
      'name': '黄岛区',
      'children': null
    }, {
      'regionId': '370212',
      'parentId': '370200',
      'name': '崂山区',
      'children': null
    }, {
      'regionId': '370213',
      'parentId': '370200',
      'name': '李沧区',
      'children': null
    }, {
      'regionId': '370214',
      'parentId': '370200',
      'name': '城阳区',
      'children': null
    }, {
      'regionId': '370215',
      'parentId': '370200',
      'name': '即墨区',
      'children': null
    }, {
      'regionId': '370281',
      'parentId': '370200',
      'name': '胶州市',
      'children': null
    }, {
      'regionId': '370283',
      'parentId': '370200',
      'name': '平度市',
      'children': null
    }, {
      'regionId': '370285',
      'parentId': '370200',
      'name': '莱西市',
      'children': null
    }]
  }, {
    'regionId': '370300',
    'parentId': '370000',
    'name': '淄博市',
    'children': [{
      'regionId': '370302',
      'parentId': '370300',
      'name': '淄川区',
      'children': null
    }, {
      'regionId': '370303',
      'parentId': '370300',
      'name': '张店区',
      'children': null
    }, {
      'regionId': '370304',
      'parentId': '370300',
      'name': '博山区',
      'children': null
    }, {
      'regionId': '370305',
      'parentId': '370300',
      'name': '临淄区',
      'children': null
    }, {
      'regionId': '370306',
      'parentId': '370300',
      'name': '周村区',
      'children': null
    }, {
      'regionId': '370321',
      'parentId': '370300',
      'name': '桓台县',
      'children': null
    }, {
      'regionId': '370322',
      'parentId': '370300',
      'name': '高青县',
      'children': null
    }, {
      'regionId': '370323',
      'parentId': '370300',
      'name': '沂源县',
      'children': null
    }]
  }, {
    'regionId': '370400',
    'parentId': '370000',
    'name': '枣庄市',
    'children': [{
      'regionId': '370402',
      'parentId': '370400',
      'name': '市中区',
      'children': null
    }, {
      'regionId': '370403',
      'parentId': '370400',
      'name': '薛城区',
      'children': null
    }, {
      'regionId': '370404',
      'parentId': '370400',
      'name': '峄城区',
      'children': null
    }, {
      'regionId': '370405',
      'parentId': '370400',
      'name': '台儿庄区',
      'children': null
    }, {
      'regionId': '370406',
      'parentId': '370400',
      'name': '山亭区',
      'children': null
    }, {
      'regionId': '370481',
      'parentId': '370400',
      'name': '滕州市',
      'children': null
    }]
  }, {
    'regionId': '370500',
    'parentId': '370000',
    'name': '东营市',
    'children': [{
      'regionId': '370502',
      'parentId': '370500',
      'name': '东营区',
      'children': null
    }, {
      'regionId': '370503',
      'parentId': '370500',
      'name': '河口区',
      'children': null
    }, {
      'regionId': '370505',
      'parentId': '370500',
      'name': '垦利区',
      'children': null
    }, {
      'regionId': '370522',
      'parentId': '370500',
      'name': '利津县',
      'children': null
    }, {
      'regionId': '370523',
      'parentId': '370500',
      'name': '广饶县',
      'children': null
    }]
  }, {
    'regionId': '370600',
    'parentId': '370000',
    'name': '烟台市',
    'children': [{
      'regionId': '370602',
      'parentId': '370600',
      'name': '芝罘区',
      'children': null
    }, {
      'regionId': '370611',
      'parentId': '370600',
      'name': '福山区',
      'children': null
    }, {
      'regionId': '370612',
      'parentId': '370600',
      'name': '牟平区',
      'children': null
    }, {
      'regionId': '370613',
      'parentId': '370600',
      'name': '莱山区',
      'children': null
    }, {
      'regionId': '370681',
      'parentId': '370600',
      'name': '龙口市',
      'children': null
    }, {
      'regionId': '370682',
      'parentId': '370600',
      'name': '莱阳市',
      'children': null
    }, {
      'regionId': '370683',
      'parentId': '370600',
      'name': '莱州市',
      'children': null
    }, {
      'regionId': '370685',
      'parentId': '370600',
      'name': '招远市',
      'children': null
    }, {
      'regionId': '370686',
      'parentId': '370600',
      'name': '栖霞市',
      'children': null
    }, {
      'regionId': '370687',
      'parentId': '370600',
      'name': '海阳市',
      'children': null
    }]
  }, {
    'regionId': '370700',
    'parentId': '370000',
    'name': '潍坊市',
    'children': [{
      'regionId': '370702',
      'parentId': '370700',
      'name': '潍城区',
      'children': null
    }, {
      'regionId': '370703',
      'parentId': '370700',
      'name': '寒亭区',
      'children': null
    }, {
      'regionId': '370704',
      'parentId': '370700',
      'name': '坊子区',
      'children': null
    }, {
      'regionId': '370705',
      'parentId': '370700',
      'name': '奎文区',
      'children': null
    }, {
      'regionId': '370724',
      'parentId': '370700',
      'name': '临朐县',
      'children': null
    }, {
      'regionId': '370725',
      'parentId': '370700',
      'name': '昌乐县',
      'children': null
    }, {
      'regionId': '370781',
      'parentId': '370700',
      'name': '青州市',
      'children': null
    }, {
      'regionId': '370782',
      'parentId': '370700',
      'name': '诸城市',
      'children': null
    }, {
      'regionId': '370783',
      'parentId': '370700',
      'name': '寿光市',
      'children': null
    }, {
      'regionId': '370784',
      'parentId': '370700',
      'name': '安丘市',
      'children': null
    }, {
      'regionId': '370785',
      'parentId': '370700',
      'name': '高密市',
      'children': null
    }, {
      'regionId': '370786',
      'parentId': '370700',
      'name': '昌邑市',
      'children': null
    }]
  }, {
    'regionId': '370800',
    'parentId': '370000',
    'name': '济宁市',
    'children': [{
      'regionId': '370811',
      'parentId': '370800',
      'name': '任城区',
      'children': null
    }, {
      'regionId': '370812',
      'parentId': '370800',
      'name': '兖州区',
      'children': null
    }, {
      'regionId': '370826',
      'parentId': '370800',
      'name': '微山县',
      'children': null
    }, {
      'regionId': '370827',
      'parentId': '370800',
      'name': '鱼台县',
      'children': null
    }, {
      'regionId': '370828',
      'parentId': '370800',
      'name': '金乡县',
      'children': null
    }, {
      'regionId': '370829',
      'parentId': '370800',
      'name': '嘉祥县',
      'children': null
    }, {
      'regionId': '370830',
      'parentId': '370800',
      'name': '汶上县',
      'children': null
    }, {
      'regionId': '370831',
      'parentId': '370800',
      'name': '泗水县',
      'children': null
    }, {
      'regionId': '370832',
      'parentId': '370800',
      'name': '梁山县',
      'children': null
    }, {
      'regionId': '370881',
      'parentId': '370800',
      'name': '曲阜市',
      'children': null
    }, {
      'regionId': '370883',
      'parentId': '370800',
      'name': '邹城市',
      'children': null
    }]
  }, {
    'regionId': '370900',
    'parentId': '370000',
    'name': '泰安市',
    'children': [{
      'regionId': '370902',
      'parentId': '370900',
      'name': '泰山区',
      'children': null
    }, {
      'regionId': '370911',
      'parentId': '370900',
      'name': '岱岳区',
      'children': null
    }, {
      'regionId': '370921',
      'parentId': '370900',
      'name': '宁阳县',
      'children': null
    }, {
      'regionId': '370923',
      'parentId': '370900',
      'name': '东平县',
      'children': null
    }, {
      'regionId': '370982',
      'parentId': '370900',
      'name': '新泰市',
      'children': null
    }, {
      'regionId': '370983',
      'parentId': '370900',
      'name': '肥城市',
      'children': null
    }]
  }, {
    'regionId': '371000',
    'parentId': '370000',
    'name': '威海市',
    'children': [{
      'regionId': '371002',
      'parentId': '371000',
      'name': '环翠区',
      'children': null
    }, {
      'regionId': '371003',
      'parentId': '371000',
      'name': '文登区',
      'children': null
    }, {
      'regionId': '371082',
      'parentId': '371000',
      'name': '荣成市',
      'children': null
    }, {
      'regionId': '371083',
      'parentId': '371000',
      'name': '乳山市',
      'children': null
    }]
  }, {
    'regionId': '371100',
    'parentId': '370000',
    'name': '日照市',
    'children': [{
      'regionId': '371102',
      'parentId': '371100',
      'name': '东港区',
      'children': null
    }, {
      'regionId': '371103',
      'parentId': '371100',
      'name': '岚山区',
      'children': null
    }, {
      'regionId': '371121',
      'parentId': '371100',
      'name': '五莲县',
      'children': null
    }, {
      'regionId': '371122',
      'parentId': '371100',
      'name': '莒县',
      'children': null
    }]
  }, {
    'regionId': '371300',
    'parentId': '370000',
    'name': '临沂市',
    'children': [{
      'regionId': '371302',
      'parentId': '371300',
      'name': '兰山区',
      'children': null
    }, {
      'regionId': '371311',
      'parentId': '371300',
      'name': '罗庄区',
      'children': null
    }, {
      'regionId': '371312',
      'parentId': '371300',
      'name': '河东区',
      'children': null
    }, {
      'regionId': '371321',
      'parentId': '371300',
      'name': '沂南县',
      'children': null
    }, {
      'regionId': '371322',
      'parentId': '371300',
      'name': '郯城县',
      'children': null
    }, {
      'regionId': '371323',
      'parentId': '371300',
      'name': '沂水县',
      'children': null
    }, {
      'regionId': '371324',
      'parentId': '371300',
      'name': '兰陵县',
      'children': null
    }, {
      'regionId': '371325',
      'parentId': '371300',
      'name': '费县',
      'children': null
    }, {
      'regionId': '371326',
      'parentId': '371300',
      'name': '平邑县',
      'children': null
    }, {
      'regionId': '371327',
      'parentId': '371300',
      'name': '莒南县',
      'children': null
    }, {
      'regionId': '371328',
      'parentId': '371300',
      'name': '蒙阴县',
      'children': null
    }, {
      'regionId': '371329',
      'parentId': '371300',
      'name': '临沭县',
      'children': null
    }]
  }, {
    'regionId': '371400',
    'parentId': '370000',
    'name': '德州市',
    'children': [{
      'regionId': '371402',
      'parentId': '371400',
      'name': '德城区',
      'children': null
    }, {
      'regionId': '371403',
      'parentId': '371400',
      'name': '陵城区',
      'children': null
    }, {
      'regionId': '371422',
      'parentId': '371400',
      'name': '宁津县',
      'children': null
    }, {
      'regionId': '371423',
      'parentId': '371400',
      'name': '庆云县',
      'children': null
    }, {
      'regionId': '371424',
      'parentId': '371400',
      'name': '临邑县',
      'children': null
    }, {
      'regionId': '371425',
      'parentId': '371400',
      'name': '齐河县',
      'children': null
    }, {
      'regionId': '371426',
      'parentId': '371400',
      'name': '平原县',
      'children': null
    }, {
      'regionId': '371427',
      'parentId': '371400',
      'name': '夏津县',
      'children': null
    }, {
      'regionId': '371428',
      'parentId': '371400',
      'name': '武城县',
      'children': null
    }, {
      'regionId': '371481',
      'parentId': '371400',
      'name': '乐陵市',
      'children': null
    }, {
      'regionId': '371482',
      'parentId': '371400',
      'name': '禹城市',
      'children': null
    }]
  }, {
    'regionId': '371500',
    'parentId': '370000',
    'name': '聊城市',
    'children': [{
      'regionId': '371502',
      'parentId': '371500',
      'name': '东昌府区',
      'children': null
    }, {
      'regionId': '371503',
      'parentId': '371500',
      'name': '茌平区',
      'children': null
    }, {
      'regionId': '371521',
      'parentId': '371500',
      'name': '阳谷县',
      'children': null
    }, {
      'regionId': '371522',
      'parentId': '371500',
      'name': '莘县',
      'children': null
    }, {
      'regionId': '371524',
      'parentId': '371500',
      'name': '东阿县',
      'children': null
    }, {
      'regionId': '371525',
      'parentId': '371500',
      'name': '冠县',
      'children': null
    }, {
      'regionId': '371526',
      'parentId': '371500',
      'name': '高唐县',
      'children': null
    }, {
      'regionId': '371581',
      'parentId': '371500',
      'name': '临清市',
      'children': null
    }]
  }, {
    'regionId': '371600',
    'parentId': '370000',
    'name': '滨州市',
    'children': [{
      'regionId': '371602',
      'parentId': '371600',
      'name': '滨城区',
      'children': null
    }, {
      'regionId': '371603',
      'parentId': '371600',
      'name': '沾化区',
      'children': null
    }, {
      'regionId': '371621',
      'parentId': '371600',
      'name': '惠民县',
      'children': null
    }, {
      'regionId': '371622',
      'parentId': '371600',
      'name': '阳信县',
      'children': null
    }, {
      'regionId': '371623',
      'parentId': '371600',
      'name': '无棣县',
      'children': null
    }, {
      'regionId': '371625',
      'parentId': '371600',
      'name': '博兴县',
      'children': null
    }, {
      'regionId': '371681',
      'parentId': '371600',
      'name': '邹平市',
      'children': null
    }]
  }, {
    'regionId': '371700',
    'parentId': '370000',
    'name': '菏泽市',
    'children': [{
      'regionId': '371702',
      'parentId': '371700',
      'name': '牡丹区',
      'children': null
    }, {
      'regionId': '371703',
      'parentId': '371700',
      'name': '定陶区',
      'children': null
    }, {
      'regionId': '371721',
      'parentId': '371700',
      'name': '曹县',
      'children': null
    }, {
      'regionId': '371722',
      'parentId': '371700',
      'name': '单县',
      'children': null
    }, {
      'regionId': '371723',
      'parentId': '371700',
      'name': '成武县',
      'children': null
    }, {
      'regionId': '371724',
      'parentId': '371700',
      'name': '巨野县',
      'children': null
    }, {
      'regionId': '371725',
      'parentId': '371700',
      'name': '郓城县',
      'children': null
    }, {
      'regionId': '371726',
      'parentId': '371700',
      'name': '鄄城县',
      'children': null
    }, {
      'regionId': '371728',
      'parentId': '371700',
      'name': '东明县',
      'children': null
    }]
  }]
}, {
  'regionId': '410000',
  'parentId': '0',
  'name': '河南省',
  'children': [{
    'regionId': '410100',
    'parentId': '410000',
    'name': '郑州市',
    'children': [{
      'regionId': '410102',
      'parentId': '410100',
      'name': '中原区',
      'children': null
    }, {
      'regionId': '410103',
      'parentId': '410100',
      'name': '二七区',
      'children': null
    }, {
      'regionId': '410104',
      'parentId': '410100',
      'name': '管城回族区',
      'children': null
    }, {
      'regionId': '410105',
      'parentId': '410100',
      'name': '金水区',
      'children': null
    }, {
      'regionId': '410106',
      'parentId': '410100',
      'name': '上街区',
      'children': null
    }, {
      'regionId': '410108',
      'parentId': '410100',
      'name': '惠济区',
      'children': null
    }, {
      'regionId': '410122',
      'parentId': '410100',
      'name': '中牟县',
      'children': null
    }, {
      'regionId': '410181',
      'parentId': '410100',
      'name': '巩义市',
      'children': null
    }, {
      'regionId': '410182',
      'parentId': '410100',
      'name': '荥阳市',
      'children': null
    }, {
      'regionId': '410183',
      'parentId': '410100',
      'name': '新密市',
      'children': null
    }, {
      'regionId': '410184',
      'parentId': '410100',
      'name': '新郑市',
      'children': null
    }, {
      'regionId': '410185',
      'parentId': '410100',
      'name': '登封市',
      'children': null
    }]
  }, {
    'regionId': '410200',
    'parentId': '410000',
    'name': '开封市',
    'children': [{
      'regionId': '410202',
      'parentId': '410200',
      'name': '龙亭区',
      'children': null
    }, {
      'regionId': '410203',
      'parentId': '410200',
      'name': '顺河回族区',
      'children': null
    }, {
      'regionId': '410204',
      'parentId': '410200',
      'name': '鼓楼区',
      'children': null
    }, {
      'regionId': '410205',
      'parentId': '410200',
      'name': '禹王台区',
      'children': null
    }, {
      'regionId': '410212',
      'parentId': '410200',
      'name': '祥符区',
      'children': null
    }, {
      'regionId': '410221',
      'parentId': '410200',
      'name': '杞县',
      'children': null
    }, {
      'regionId': '410222',
      'parentId': '410200',
      'name': '通许县',
      'children': null
    }, {
      'regionId': '410223',
      'parentId': '410200',
      'name': '尉氏县',
      'children': null
    }, {
      'regionId': '410225',
      'parentId': '410200',
      'name': '兰考县',
      'children': null
    }]
  }, {
    'regionId': '410300',
    'parentId': '410000',
    'name': '洛阳市',
    'children': [{
      'regionId': '410302',
      'parentId': '410300',
      'name': '老城区',
      'children': null
    }, {
      'regionId': '410303',
      'parentId': '410300',
      'name': '西工区',
      'children': null
    }, {
      'regionId': '410304',
      'parentId': '410300',
      'name': '瀍河回族区',
      'children': null
    }, {
      'regionId': '410305',
      'parentId': '410300',
      'name': '涧西区',
      'children': null
    }, {
      'regionId': '410306',
      'parentId': '410300',
      'name': '孟津区',
      'children': null
    }, {
      'regionId': '410311',
      'parentId': '410300',
      'name': '洛龙区',
      'children': null
    }, {
      'regionId': '410323',
      'parentId': '410300',
      'name': '新安县',
      'children': null
    }, {
      'regionId': '410324',
      'parentId': '410300',
      'name': '栾川县',
      'children': null
    }, {
      'regionId': '410325',
      'parentId': '410300',
      'name': '嵩县',
      'children': null
    }, {
      'regionId': '410326',
      'parentId': '410300',
      'name': '汝阳县',
      'children': null
    }, {
      'regionId': '410327',
      'parentId': '410300',
      'name': '宜阳县',
      'children': null
    }, {
      'regionId': '410328',
      'parentId': '410300',
      'name': '洛宁县',
      'children': null
    }, {
      'regionId': '410329',
      'parentId': '410300',
      'name': '伊川县',
      'children': null
    }, {
      'regionId': '410381',
      'parentId': '410300',
      'name': '偃师市',
      'children': null
    }]
  }, {
    'regionId': '410400',
    'parentId': '410000',
    'name': '平顶山市',
    'children': [{
      'regionId': '410402',
      'parentId': '410400',
      'name': '新华区',
      'children': null
    }, {
      'regionId': '410403',
      'parentId': '410400',
      'name': '卫东区',
      'children': null
    }, {
      'regionId': '410404',
      'parentId': '410400',
      'name': '石龙区',
      'children': null
    }, {
      'regionId': '410411',
      'parentId': '410400',
      'name': '湛河区',
      'children': null
    }, {
      'regionId': '410421',
      'parentId': '410400',
      'name': '宝丰县',
      'children': null
    }, {
      'regionId': '410422',
      'parentId': '410400',
      'name': '叶县',
      'children': null
    }, {
      'regionId': '410423',
      'parentId': '410400',
      'name': '鲁山县',
      'children': null
    }, {
      'regionId': '410425',
      'parentId': '410400',
      'name': '郏县',
      'children': null
    }, {
      'regionId': '410481',
      'parentId': '410400',
      'name': '舞钢市',
      'children': null
    }, {
      'regionId': '410482',
      'parentId': '410400',
      'name': '汝州市',
      'children': null
    }]
  }, {
    'regionId': '410500',
    'parentId': '410000',
    'name': '安阳市',
    'children': [{
      'regionId': '410502',
      'parentId': '410500',
      'name': '文峰区',
      'children': null
    }, {
      'regionId': '410503',
      'parentId': '410500',
      'name': '北关区',
      'children': null
    }, {
      'regionId': '410505',
      'parentId': '410500',
      'name': '殷都区',
      'children': null
    }, {
      'regionId': '410506',
      'parentId': '410500',
      'name': '龙安区',
      'children': null
    }, {
      'regionId': '410522',
      'parentId': '410500',
      'name': '安阳县',
      'children': null
    }, {
      'regionId': '410523',
      'parentId': '410500',
      'name': '汤阴县',
      'children': null
    }, {
      'regionId': '410526',
      'parentId': '410500',
      'name': '滑县',
      'children': null
    }, {
      'regionId': '410527',
      'parentId': '410500',
      'name': '内黄县',
      'children': null
    }, {
      'regionId': '410581',
      'parentId': '410500',
      'name': '林州市',
      'children': null
    }]
  }, {
    'regionId': '410600',
    'parentId': '410000',
    'name': '鹤壁市',
    'children': [{
      'regionId': '410602',
      'parentId': '410600',
      'name': '鹤山区',
      'children': null
    }, {
      'regionId': '410603',
      'parentId': '410600',
      'name': '山城区',
      'children': null
    }, {
      'regionId': '410611',
      'parentId': '410600',
      'name': '淇滨区',
      'children': null
    }, {
      'regionId': '410621',
      'parentId': '410600',
      'name': '浚县',
      'children': null
    }, {
      'regionId': '410622',
      'parentId': '410600',
      'name': '淇县',
      'children': null
    }]
  }, {
    'regionId': '410700',
    'parentId': '410000',
    'name': '新乡市',
    'children': [{
      'regionId': '410702',
      'parentId': '410700',
      'name': '红旗区',
      'children': null
    }, {
      'regionId': '410703',
      'parentId': '410700',
      'name': '卫滨区',
      'children': null
    }, {
      'regionId': '410704',
      'parentId': '410700',
      'name': '凤泉区',
      'children': null
    }, {
      'regionId': '410711',
      'parentId': '410700',
      'name': '牧野区',
      'children': null
    }, {
      'regionId': '410721',
      'parentId': '410700',
      'name': '新乡县',
      'children': null
    }, {
      'regionId': '410724',
      'parentId': '410700',
      'name': '获嘉县',
      'children': null
    }, {
      'regionId': '410725',
      'parentId': '410700',
      'name': '原阳县',
      'children': null
    }, {
      'regionId': '410726',
      'parentId': '410700',
      'name': '延津县',
      'children': null
    }, {
      'regionId': '410727',
      'parentId': '410700',
      'name': '封丘县',
      'children': null
    }, {
      'regionId': '410781',
      'parentId': '410700',
      'name': '卫辉市',
      'children': null
    }, {
      'regionId': '410782',
      'parentId': '410700',
      'name': '辉县市',
      'children': null
    }, {
      'regionId': '410783',
      'parentId': '410700',
      'name': '长垣市',
      'children': null
    }]
  }, {
    'regionId': '410800',
    'parentId': '410000',
    'name': '焦作市',
    'children': [{
      'regionId': '410802',
      'parentId': '410800',
      'name': '解放区',
      'children': null
    }, {
      'regionId': '410803',
      'parentId': '410800',
      'name': '中站区',
      'children': null
    }, {
      'regionId': '410804',
      'parentId': '410800',
      'name': '马村区',
      'children': null
    }, {
      'regionId': '410811',
      'parentId': '410800',
      'name': '山阳区',
      'children': null
    }, {
      'regionId': '410821',
      'parentId': '410800',
      'name': '修武县',
      'children': null
    }, {
      'regionId': '410822',
      'parentId': '410800',
      'name': '博爱县',
      'children': null
    }, {
      'regionId': '410823',
      'parentId': '410800',
      'name': '武陟县',
      'children': null
    }, {
      'regionId': '410825',
      'parentId': '410800',
      'name': '温县',
      'children': null
    }, {
      'regionId': '410882',
      'parentId': '410800',
      'name': '沁阳市',
      'children': null
    }, {
      'regionId': '410883',
      'parentId': '410800',
      'name': '孟州市',
      'children': null
    }]
  }, {
    'regionId': '410900',
    'parentId': '410000',
    'name': '濮阳市',
    'children': [{
      'regionId': '410902',
      'parentId': '410900',
      'name': '华龙区',
      'children': null
    }, {
      'regionId': '410922',
      'parentId': '410900',
      'name': '清丰县',
      'children': null
    }, {
      'regionId': '410923',
      'parentId': '410900',
      'name': '南乐县',
      'children': null
    }, {
      'regionId': '410926',
      'parentId': '410900',
      'name': '范县',
      'children': null
    }, {
      'regionId': '410927',
      'parentId': '410900',
      'name': '台前县',
      'children': null
    }, {
      'regionId': '410928',
      'parentId': '410900',
      'name': '濮阳县',
      'children': null
    }]
  }, {
    'regionId': '411000',
    'parentId': '410000',
    'name': '许昌市',
    'children': [{
      'regionId': '411002',
      'parentId': '411000',
      'name': '魏都区',
      'children': null
    }, {
      'regionId': '411003',
      'parentId': '411000',
      'name': '建安区',
      'children': null
    }, {
      'regionId': '411024',
      'parentId': '411000',
      'name': '鄢陵县',
      'children': null
    }, {
      'regionId': '411025',
      'parentId': '411000',
      'name': '襄城县',
      'children': null
    }, {
      'regionId': '411081',
      'parentId': '411000',
      'name': '禹州市',
      'children': null
    }, {
      'regionId': '411082',
      'parentId': '411000',
      'name': '长葛市',
      'children': null
    }]
  }, {
    'regionId': '411100',
    'parentId': '410000',
    'name': '漯河市',
    'children': [{
      'regionId': '411102',
      'parentId': '411100',
      'name': '源汇区',
      'children': null
    }, {
      'regionId': '411103',
      'parentId': '411100',
      'name': '郾城区',
      'children': null
    }, {
      'regionId': '411104',
      'parentId': '411100',
      'name': '召陵区',
      'children': null
    }, {
      'regionId': '411121',
      'parentId': '411100',
      'name': '舞阳县',
      'children': null
    }, {
      'regionId': '411122',
      'parentId': '411100',
      'name': '临颍县',
      'children': null
    }]
  }, {
    'regionId': '411200',
    'parentId': '410000',
    'name': '三门峡市',
    'children': [{
      'regionId': '411202',
      'parentId': '411200',
      'name': '湖滨区',
      'children': null
    }, {
      'regionId': '411203',
      'parentId': '411200',
      'name': '陕州区',
      'children': null
    }, {
      'regionId': '411221',
      'parentId': '411200',
      'name': '渑池县',
      'children': null
    }, {
      'regionId': '411224',
      'parentId': '411200',
      'name': '卢氏县',
      'children': null
    }, {
      'regionId': '411281',
      'parentId': '411200',
      'name': '义马市',
      'children': null
    }, {
      'regionId': '411282',
      'parentId': '411200',
      'name': '灵宝市',
      'children': null
    }]
  }, {
    'regionId': '411300',
    'parentId': '410000',
    'name': '南阳市',
    'children': [{
      'regionId': '411302',
      'parentId': '411300',
      'name': '宛城区',
      'children': null
    }, {
      'regionId': '411303',
      'parentId': '411300',
      'name': '卧龙区',
      'children': null
    }, {
      'regionId': '411321',
      'parentId': '411300',
      'name': '南召县',
      'children': null
    }, {
      'regionId': '411322',
      'parentId': '411300',
      'name': '方城县',
      'children': null
    }, {
      'regionId': '411323',
      'parentId': '411300',
      'name': '西峡县',
      'children': null
    }, {
      'regionId': '411324',
      'parentId': '411300',
      'name': '镇平县',
      'children': null
    }, {
      'regionId': '411325',
      'parentId': '411300',
      'name': '内乡县',
      'children': null
    }, {
      'regionId': '411326',
      'parentId': '411300',
      'name': '淅川县',
      'children': null
    }, {
      'regionId': '411327',
      'parentId': '411300',
      'name': '社旗县',
      'children': null
    }, {
      'regionId': '411328',
      'parentId': '411300',
      'name': '唐河县',
      'children': null
    }, {
      'regionId': '411329',
      'parentId': '411300',
      'name': '新野县',
      'children': null
    }, {
      'regionId': '411330',
      'parentId': '411300',
      'name': '桐柏县',
      'children': null
    }, {
      'regionId': '411381',
      'parentId': '411300',
      'name': '邓州市',
      'children': null
    }]
  }, {
    'regionId': '411400',
    'parentId': '410000',
    'name': '商丘市',
    'children': [{
      'regionId': '411402',
      'parentId': '411400',
      'name': '梁园区',
      'children': null
    }, {
      'regionId': '411403',
      'parentId': '411400',
      'name': '睢阳区',
      'children': null
    }, {
      'regionId': '411421',
      'parentId': '411400',
      'name': '民权县',
      'children': null
    }, {
      'regionId': '411422',
      'parentId': '411400',
      'name': '睢县',
      'children': null
    }, {
      'regionId': '411423',
      'parentId': '411400',
      'name': '宁陵县',
      'children': null
    }, {
      'regionId': '411424',
      'parentId': '411400',
      'name': '柘城县',
      'children': null
    }, {
      'regionId': '411425',
      'parentId': '411400',
      'name': '虞城县',
      'children': null
    }, {
      'regionId': '411426',
      'parentId': '411400',
      'name': '夏邑县',
      'children': null
    }, {
      'regionId': '411481',
      'parentId': '411400',
      'name': '永城市',
      'children': null
    }]
  }, {
    'regionId': '411500',
    'parentId': '410000',
    'name': '信阳市',
    'children': [{
      'regionId': '411502',
      'parentId': '411500',
      'name': '浉河区',
      'children': null
    }, {
      'regionId': '411503',
      'parentId': '411500',
      'name': '平桥区',
      'children': null
    }, {
      'regionId': '411521',
      'parentId': '411500',
      'name': '罗山县',
      'children': null
    }, {
      'regionId': '411522',
      'parentId': '411500',
      'name': '光山县',
      'children': null
    }, {
      'regionId': '411523',
      'parentId': '411500',
      'name': '新县',
      'children': null
    }, {
      'regionId': '411524',
      'parentId': '411500',
      'name': '商城县',
      'children': null
    }, {
      'regionId': '411525',
      'parentId': '411500',
      'name': '固始县',
      'children': null
    }, {
      'regionId': '411526',
      'parentId': '411500',
      'name': '潢川县',
      'children': null
    }, {
      'regionId': '411527',
      'parentId': '411500',
      'name': '淮滨县',
      'children': null
    }, {
      'regionId': '411528',
      'parentId': '411500',
      'name': '息县',
      'children': null
    }]
  }, {
    'regionId': '411600',
    'parentId': '410000',
    'name': '周口市',
    'children': [{
      'regionId': '411602',
      'parentId': '411600',
      'name': '川汇区',
      'children': null
    }, {
      'regionId': '411603',
      'parentId': '411600',
      'name': '淮阳区',
      'children': null
    }, {
      'regionId': '411621',
      'parentId': '411600',
      'name': '扶沟县',
      'children': null
    }, {
      'regionId': '411622',
      'parentId': '411600',
      'name': '西华县',
      'children': null
    }, {
      'regionId': '411623',
      'parentId': '411600',
      'name': '商水县',
      'children': null
    }, {
      'regionId': '411624',
      'parentId': '411600',
      'name': '沈丘县',
      'children': null
    }, {
      'regionId': '411625',
      'parentId': '411600',
      'name': '郸城县',
      'children': null
    }, {
      'regionId': '411627',
      'parentId': '411600',
      'name': '太康县',
      'children': null
    }, {
      'regionId': '411628',
      'parentId': '411600',
      'name': '鹿邑县',
      'children': null
    }, {
      'regionId': '411681',
      'parentId': '411600',
      'name': '项城市',
      'children': null
    }]
  }, {
    'regionId': '411700',
    'parentId': '410000',
    'name': '驻马店市',
    'children': [{
      'regionId': '411702',
      'parentId': '411700',
      'name': '驿城区',
      'children': null
    }, {
      'regionId': '411721',
      'parentId': '411700',
      'name': '西平县',
      'children': null
    }, {
      'regionId': '411722',
      'parentId': '411700',
      'name': '上蔡县',
      'children': null
    }, {
      'regionId': '411723',
      'parentId': '411700',
      'name': '平舆县',
      'children': null
    }, {
      'regionId': '411724',
      'parentId': '411700',
      'name': '正阳县',
      'children': null
    }, {
      'regionId': '411725',
      'parentId': '411700',
      'name': '确山县',
      'children': null
    }, {
      'regionId': '411726',
      'parentId': '411700',
      'name': '泌阳县',
      'children': null
    }, {
      'regionId': '411727',
      'parentId': '411700',
      'name': '汝南县',
      'children': null
    }, {
      'regionId': '411728',
      'parentId': '411700',
      'name': '遂平县',
      'children': null
    }, {
      'regionId': '411729',
      'parentId': '411700',
      'name': '新蔡县',
      'children': null
    }]
  }, {
    'regionId': '419001',
    'parentId': '410000',
    'name': '济源市',
    'children': null
  }]
}, {
  'regionId': '420000',
  'parentId': '0',
  'name': '湖北省',
  'children': [{
    'regionId': '420100',
    'parentId': '420000',
    'name': '武汉市',
    'children': [{
      'regionId': '420101',
      'parentId': '420100',
      'name': '市辖区',
      'children': null
    }, {
      'regionId': '420102',
      'parentId': '420100',
      'name': '江岸区',
      'children': null
    }, {
      'regionId': '420103',
      'parentId': '420100',
      'name': '江汉区',
      'children': null
    }, {
      'regionId': '420104',
      'parentId': '420100',
      'name': '硚口区',
      'children': null
    }, {
      'regionId': '420105',
      'parentId': '420100',
      'name': '汉阳区',
      'children': null
    }, {
      'regionId': '420106',
      'parentId': '420100',
      'name': '武昌区',
      'children': null
    }, {
      'regionId': '420107',
      'parentId': '420100',
      'name': '青山区',
      'children': null
    }, {
      'regionId': '420111',
      'parentId': '420100',
      'name': '洪山区',
      'children': null
    }, {
      'regionId': '420112',
      'parentId': '420100',
      'name': '东西湖区',
      'children': null
    }, {
      'regionId': '420113',
      'parentId': '420100',
      'name': '汉南区',
      'children': null
    }, {
      'regionId': '420114',
      'parentId': '420100',
      'name': '蔡甸区',
      'children': null
    }, {
      'regionId': '420115',
      'parentId': '420100',
      'name': '江夏区',
      'children': null
    }, {
      'regionId': '420116',
      'parentId': '420100',
      'name': '黄陂区',
      'children': null
    }, {
      'regionId': '420117',
      'parentId': '420100',
      'name': '新洲区',
      'children': null
    }]
  }, {
    'regionId': '420200',
    'parentId': '420000',
    'name': '黄石市',
    'children': [{
      'regionId': '420202',
      'parentId': '420200',
      'name': '黄石港区',
      'children': null
    }, {
      'regionId': '420203',
      'parentId': '420200',
      'name': '西塞山区',
      'children': null
    }, {
      'regionId': '420204',
      'parentId': '420200',
      'name': '下陆区',
      'children': null
    }, {
      'regionId': '420205',
      'parentId': '420200',
      'name': '铁山区',
      'children': null
    }, {
      'regionId': '420222',
      'parentId': '420200',
      'name': '阳新县',
      'children': null
    }, {
      'regionId': '420281',
      'parentId': '420200',
      'name': '大冶市',
      'children': null
    }]
  }, {
    'regionId': '420300',
    'parentId': '420000',
    'name': '十堰市',
    'children': [{
      'regionId': '420302',
      'parentId': '420300',
      'name': '茅箭区',
      'children': null
    }, {
      'regionId': '420303',
      'parentId': '420300',
      'name': '张湾区',
      'children': null
    }, {
      'regionId': '420304',
      'parentId': '420300',
      'name': '郧阳区',
      'children': null
    }, {
      'regionId': '420322',
      'parentId': '420300',
      'name': '郧西县',
      'children': null
    }, {
      'regionId': '420323',
      'parentId': '420300',
      'name': '竹山县',
      'children': null
    }, {
      'regionId': '420324',
      'parentId': '420300',
      'name': '竹溪县',
      'children': null
    }, {
      'regionId': '420325',
      'parentId': '420300',
      'name': '房县',
      'children': null
    }, {
      'regionId': '420381',
      'parentId': '420300',
      'name': '丹江口市',
      'children': null
    }]
  }, {
    'regionId': '420500',
    'parentId': '420000',
    'name': '宜昌市',
    'children': [{
      'regionId': '420502',
      'parentId': '420500',
      'name': '西陵区',
      'children': null
    }, {
      'regionId': '420503',
      'parentId': '420500',
      'name': '伍家岗区',
      'children': null
    }, {
      'regionId': '420504',
      'parentId': '420500',
      'name': '点军区',
      'children': null
    }, {
      'regionId': '420505',
      'parentId': '420500',
      'name': '猇亭区',
      'children': null
    }, {
      'regionId': '420506',
      'parentId': '420500',
      'name': '夷陵区',
      'children': null
    }, {
      'regionId': '420525',
      'parentId': '420500',
      'name': '远安县',
      'children': null
    }, {
      'regionId': '420526',
      'parentId': '420500',
      'name': '兴山县',
      'children': null
    }, {
      'regionId': '420527',
      'parentId': '420500',
      'name': '秭归县',
      'children': null
    }, {
      'regionId': '420528',
      'parentId': '420500',
      'name': '长阳土家族自治县',
      'children': null
    }, {
      'regionId': '420529',
      'parentId': '420500',
      'name': '五峰土家族自治县',
      'children': null
    }, {
      'regionId': '420581',
      'parentId': '420500',
      'name': '宜都市',
      'children': null
    }, {
      'regionId': '420582',
      'parentId': '420500',
      'name': '当阳市',
      'children': null
    }, {
      'regionId': '420583',
      'parentId': '420500',
      'name': '枝江市',
      'children': null
    }]
  }, {
    'regionId': '420600',
    'parentId': '420000',
    'name': '襄阳市',
    'children': [{
      'regionId': '420602',
      'parentId': '420600',
      'name': '襄城区',
      'children': null
    }, {
      'regionId': '420606',
      'parentId': '420600',
      'name': '樊城区',
      'children': null
    }, {
      'regionId': '420607',
      'parentId': '420600',
      'name': '襄州区',
      'children': null
    }, {
      'regionId': '420624',
      'parentId': '420600',
      'name': '南漳县',
      'children': null
    }, {
      'regionId': '420625',
      'parentId': '420600',
      'name': '谷城县',
      'children': null
    }, {
      'regionId': '420626',
      'parentId': '420600',
      'name': '保康县',
      'children': null
    }, {
      'regionId': '420682',
      'parentId': '420600',
      'name': '老河口市',
      'children': null
    }, {
      'regionId': '420683',
      'parentId': '420600',
      'name': '枣阳市',
      'children': null
    }, {
      'regionId': '420684',
      'parentId': '420600',
      'name': '宜城市',
      'children': null
    }]
  }, {
    'regionId': '420700',
    'parentId': '420000',
    'name': '鄂州市',
    'children': [{
      'regionId': '420702',
      'parentId': '420700',
      'name': '梁子湖区',
      'children': null
    }, {
      'regionId': '420703',
      'parentId': '420700',
      'name': '华容区',
      'children': null
    }, {
      'regionId': '420704',
      'parentId': '420700',
      'name': '鄂城区',
      'children': null
    }]
  }, {
    'regionId': '420800',
    'parentId': '420000',
    'name': '荆门市',
    'children': [{
      'regionId': '420802',
      'parentId': '420800',
      'name': '东宝区',
      'children': null
    }, {
      'regionId': '420804',
      'parentId': '420800',
      'name': '掇刀区',
      'children': null
    }, {
      'regionId': '420822',
      'parentId': '420800',
      'name': '沙洋县',
      'children': null
    }, {
      'regionId': '420881',
      'parentId': '420800',
      'name': '钟祥市',
      'children': null
    }, {
      'regionId': '420882',
      'parentId': '420800',
      'name': '京山市',
      'children': null
    }]
  }, {
    'regionId': '420900',
    'parentId': '420000',
    'name': '孝感市',
    'children': [{
      'regionId': '420902',
      'parentId': '420900',
      'name': '孝南区',
      'children': null
    }, {
      'regionId': '420921',
      'parentId': '420900',
      'name': '孝昌县',
      'children': null
    }, {
      'regionId': '420922',
      'parentId': '420900',
      'name': '大悟县',
      'children': null
    }, {
      'regionId': '420923',
      'parentId': '420900',
      'name': '云梦县',
      'children': null
    }, {
      'regionId': '420981',
      'parentId': '420900',
      'name': '应城市',
      'children': null
    }, {
      'regionId': '420982',
      'parentId': '420900',
      'name': '安陆市',
      'children': null
    }, {
      'regionId': '420984',
      'parentId': '420900',
      'name': '汉川市',
      'children': null
    }]
  }, {
    'regionId': '421000',
    'parentId': '420000',
    'name': '荆州市',
    'children': [{
      'regionId': '421002',
      'parentId': '421000',
      'name': '沙市区',
      'children': null
    }, {
      'regionId': '421003',
      'parentId': '421000',
      'name': '荆州区',
      'children': null
    }, {
      'regionId': '421022',
      'parentId': '421000',
      'name': '公安县',
      'children': null
    }, {
      'regionId': '421024',
      'parentId': '421000',
      'name': '江陵县',
      'children': null
    }, {
      'regionId': '421081',
      'parentId': '421000',
      'name': '石首市',
      'children': null
    }, {
      'regionId': '421083',
      'parentId': '421000',
      'name': '洪湖市',
      'children': null
    }, {
      'regionId': '421087',
      'parentId': '421000',
      'name': '松滋市',
      'children': null
    }]
  }, {
    'regionId': '421100',
    'parentId': '420000',
    'name': '黄冈市',
    'children': [{
      'regionId': '421102',
      'parentId': '421100',
      'name': '黄州区',
      'children': null
    }, {
      'regionId': '421121',
      'parentId': '421100',
      'name': '团风县',
      'children': null
    }, {
      'regionId': '421122',
      'parentId': '421100',
      'name': '红安县',
      'children': null
    }, {
      'regionId': '421123',
      'parentId': '421100',
      'name': '罗田县',
      'children': null
    }, {
      'regionId': '421124',
      'parentId': '421100',
      'name': '英山县',
      'children': null
    }, {
      'regionId': '421125',
      'parentId': '421100',
      'name': '浠水县',
      'children': null
    }, {
      'regionId': '421126',
      'parentId': '421100',
      'name': '蕲春县',
      'children': null
    }, {
      'regionId': '421127',
      'parentId': '421100',
      'name': '黄梅县',
      'children': null
    }, {
      'regionId': '421181',
      'parentId': '421100',
      'name': '麻城市',
      'children': null
    }, {
      'regionId': '421182',
      'parentId': '421100',
      'name': '武穴市',
      'children': null
    }]
  }, {
    'regionId': '421200',
    'parentId': '420000',
    'name': '咸宁市',
    'children': [{
      'regionId': '421202',
      'parentId': '421200',
      'name': '咸安区',
      'children': null
    }, {
      'regionId': '421221',
      'parentId': '421200',
      'name': '嘉鱼县',
      'children': null
    }, {
      'regionId': '421222',
      'parentId': '421200',
      'name': '通城县',
      'children': null
    }, {
      'regionId': '421223',
      'parentId': '421200',
      'name': '崇阳县',
      'children': null
    }, {
      'regionId': '421224',
      'parentId': '421200',
      'name': '通山县',
      'children': null
    }, {
      'regionId': '421281',
      'parentId': '421200',
      'name': '赤壁市',
      'children': null
    }]
  }, {
    'regionId': '421300',
    'parentId': '420000',
    'name': '随州市',
    'children': [{
      'regionId': '421303',
      'parentId': '421300',
      'name': '曾都区',
      'children': null
    }, {
      'regionId': '421321',
      'parentId': '421300',
      'name': '随县',
      'children': null
    }, {
      'regionId': '421381',
      'parentId': '421300',
      'name': '广水市',
      'children': null
    }]
  }, {
    'regionId': '422800',
    'parentId': '420000',
    'name': '恩施土家族苗族自治州',
    'children': [{
      'regionId': '422801',
      'parentId': '422800',
      'name': '恩施市',
      'children': null
    }, {
      'regionId': '422802',
      'parentId': '422800',
      'name': '利川市',
      'children': null
    }, {
      'regionId': '422822',
      'parentId': '422800',
      'name': '建始县',
      'children': null
    }, {
      'regionId': '422823',
      'parentId': '422800',
      'name': '巴东县',
      'children': null
    }, {
      'regionId': '422825',
      'parentId': '422800',
      'name': '宣恩县',
      'children': null
    }, {
      'regionId': '422826',
      'parentId': '422800',
      'name': '咸丰县',
      'children': null
    }, {
      'regionId': '422827',
      'parentId': '422800',
      'name': '来凤县',
      'children': null
    }, {
      'regionId': '422828',
      'parentId': '422800',
      'name': '鹤峰县',
      'children': null
    }]
  }, {
    'regionId': '429004',
    'parentId': '420000',
    'name': '仙桃市',
    'children': null
  }, {
    'regionId': '429005',
    'parentId': '420000',
    'name': '潜江市',
    'children': null
  }, {
    'regionId': '429006',
    'parentId': '420000',
    'name': '天门市',
    'children': null
  }, {
    'regionId': '429021',
    'parentId': '420000',
    'name': '神农架林区',
    'children': null
  }]
}, {
  'regionId': '430000',
  'parentId': '0',
  'name': '湖南省',
  'children': [{
    'regionId': '430100',
    'parentId': '430000',
    'name': '长沙市',
    'children': [{
      'regionId': '430102',
      'parentId': '430100',
      'name': '芙蓉区',
      'children': null
    }, {
      'regionId': '430103',
      'parentId': '430100',
      'name': '天心区',
      'children': null
    }, {
      'regionId': '430104',
      'parentId': '430100',
      'name': '岳麓区',
      'children': null
    }, {
      'regionId': '430105',
      'parentId': '430100',
      'name': '开福区',
      'children': null
    }, {
      'regionId': '430111',
      'parentId': '430100',
      'name': '雨花区',
      'children': null
    }, {
      'regionId': '430112',
      'parentId': '430100',
      'name': '望城区',
      'children': null
    }, {
      'regionId': '430121',
      'parentId': '430100',
      'name': '长沙县',
      'children': null
    }, {
      'regionId': '430181',
      'parentId': '430100',
      'name': '浏阳市',
      'children': null
    }, {
      'regionId': '430182',
      'parentId': '430100',
      'name': '宁乡市',
      'children': null
    }]
  }, {
    'regionId': '430200',
    'parentId': '430000',
    'name': '株洲市',
    'children': [{
      'regionId': '430202',
      'parentId': '430200',
      'name': '荷塘区',
      'children': null
    }, {
      'regionId': '430203',
      'parentId': '430200',
      'name': '芦淞区',
      'children': null
    }, {
      'regionId': '430204',
      'parentId': '430200',
      'name': '石峰区',
      'children': null
    }, {
      'regionId': '430211',
      'parentId': '430200',
      'name': '天元区',
      'children': null
    }, {
      'regionId': '430212',
      'parentId': '430200',
      'name': '渌口区',
      'children': null
    }, {
      'regionId': '430223',
      'parentId': '430200',
      'name': '攸县',
      'children': null
    }, {
      'regionId': '430224',
      'parentId': '430200',
      'name': '茶陵县',
      'children': null
    }, {
      'regionId': '430225',
      'parentId': '430200',
      'name': '炎陵县',
      'children': null
    }, {
      'regionId': '430281',
      'parentId': '430200',
      'name': '醴陵市',
      'children': null
    }]
  }, {
    'regionId': '430300',
    'parentId': '430000',
    'name': '湘潭市',
    'children': [{
      'regionId': '430302',
      'parentId': '430300',
      'name': '雨湖区',
      'children': null
    }, {
      'regionId': '430304',
      'parentId': '430300',
      'name': '岳塘区',
      'children': null
    }, {
      'regionId': '430321',
      'parentId': '430300',
      'name': '湘潭县',
      'children': null
    }, {
      'regionId': '430381',
      'parentId': '430300',
      'name': '湘乡市',
      'children': null
    }, {
      'regionId': '430382',
      'parentId': '430300',
      'name': '韶山市',
      'children': null
    }]
  }, {
    'regionId': '430400',
    'parentId': '430000',
    'name': '衡阳市',
    'children': [{
      'regionId': '430405',
      'parentId': '430400',
      'name': '珠晖区',
      'children': null
    }, {
      'regionId': '430406',
      'parentId': '430400',
      'name': '雁峰区',
      'children': null
    }, {
      'regionId': '430407',
      'parentId': '430400',
      'name': '石鼓区',
      'children': null
    }, {
      'regionId': '430408',
      'parentId': '430400',
      'name': '蒸湘区',
      'children': null
    }, {
      'regionId': '430412',
      'parentId': '430400',
      'name': '南岳区',
      'children': null
    }, {
      'regionId': '430421',
      'parentId': '430400',
      'name': '衡阳县',
      'children': null
    }, {
      'regionId': '430422',
      'parentId': '430400',
      'name': '衡南县',
      'children': null
    }, {
      'regionId': '430423',
      'parentId': '430400',
      'name': '衡山县',
      'children': null
    }, {
      'regionId': '430424',
      'parentId': '430400',
      'name': '衡东县',
      'children': null
    }, {
      'regionId': '430426',
      'parentId': '430400',
      'name': '祁东县',
      'children': null
    }, {
      'regionId': '430481',
      'parentId': '430400',
      'name': '耒阳市',
      'children': null
    }, {
      'regionId': '430482',
      'parentId': '430400',
      'name': '常宁市',
      'children': null
    }]
  }, {
    'regionId': '430500',
    'parentId': '430000',
    'name': '邵阳市',
    'children': [{
      'regionId': '430502',
      'parentId': '430500',
      'name': '双清区',
      'children': null
    }, {
      'regionId': '430503',
      'parentId': '430500',
      'name': '大祥区',
      'children': null
    }, {
      'regionId': '430511',
      'parentId': '430500',
      'name': '北塔区',
      'children': null
    }, {
      'regionId': '430522',
      'parentId': '430500',
      'name': '新邵县',
      'children': null
    }, {
      'regionId': '430523',
      'parentId': '430500',
      'name': '邵阳县',
      'children': null
    }, {
      'regionId': '430524',
      'parentId': '430500',
      'name': '隆回县',
      'children': null
    }, {
      'regionId': '430525',
      'parentId': '430500',
      'name': '洞口县',
      'children': null
    }, {
      'regionId': '430527',
      'parentId': '430500',
      'name': '绥宁县',
      'children': null
    }, {
      'regionId': '430528',
      'parentId': '430500',
      'name': '新宁县',
      'children': null
    }, {
      'regionId': '430529',
      'parentId': '430500',
      'name': '城步苗族自治县',
      'children': null
    }, {
      'regionId': '430581',
      'parentId': '430500',
      'name': '武冈市',
      'children': null
    }, {
      'regionId': '430582',
      'parentId': '430500',
      'name': '邵东市',
      'children': null
    }]
  }, {
    'regionId': '430600',
    'parentId': '430000',
    'name': '岳阳市',
    'children': [{
      'regionId': '430602',
      'parentId': '430600',
      'name': '岳阳楼区',
      'children': null
    }, {
      'regionId': '430603',
      'parentId': '430600',
      'name': '云溪区',
      'children': null
    }, {
      'regionId': '430611',
      'parentId': '430600',
      'name': '君山区',
      'children': null
    }, {
      'regionId': '430621',
      'parentId': '430600',
      'name': '岳阳县',
      'children': null
    }, {
      'regionId': '430623',
      'parentId': '430600',
      'name': '华容县',
      'children': null
    }, {
      'regionId': '430624',
      'parentId': '430600',
      'name': '湘阴县',
      'children': null
    }, {
      'regionId': '430626',
      'parentId': '430600',
      'name': '平江县',
      'children': null
    }, {
      'regionId': '430681',
      'parentId': '430600',
      'name': '汨罗市',
      'children': null
    }, {
      'regionId': '430682',
      'parentId': '430600',
      'name': '临湘市',
      'children': null
    }]
  }, {
    'regionId': '430700',
    'parentId': '430000',
    'name': '常德市',
    'children': [{
      'regionId': '430702',
      'parentId': '430700',
      'name': '武陵区',
      'children': null
    }, {
      'regionId': '430703',
      'parentId': '430700',
      'name': '鼎城区',
      'children': null
    }, {
      'regionId': '430721',
      'parentId': '430700',
      'name': '安乡县',
      'children': null
    }, {
      'regionId': '430722',
      'parentId': '430700',
      'name': '汉寿县',
      'children': null
    }, {
      'regionId': '430723',
      'parentId': '430700',
      'name': '澧县',
      'children': null
    }, {
      'regionId': '430724',
      'parentId': '430700',
      'name': '临澧县',
      'children': null
    }, {
      'regionId': '430725',
      'parentId': '430700',
      'name': '桃源县',
      'children': null
    }, {
      'regionId': '430726',
      'parentId': '430700',
      'name': '石门县',
      'children': null
    }, {
      'regionId': '430781',
      'parentId': '430700',
      'name': '津市市',
      'children': null
    }]
  }, {
    'regionId': '430800',
    'parentId': '430000',
    'name': '张家界市',
    'children': [{
      'regionId': '430802',
      'parentId': '430800',
      'name': '永定区',
      'children': null
    }, {
      'regionId': '430811',
      'parentId': '430800',
      'name': '武陵源区',
      'children': null
    }, {
      'regionId': '430821',
      'parentId': '430800',
      'name': '慈利县',
      'children': null
    }, {
      'regionId': '430822',
      'parentId': '430800',
      'name': '桑植县',
      'children': null
    }]
  }, {
    'regionId': '430900',
    'parentId': '430000',
    'name': '益阳市',
    'children': [{
      'regionId': '430902',
      'parentId': '430900',
      'name': '资阳区',
      'children': null
    }, {
      'regionId': '430903',
      'parentId': '430900',
      'name': '赫山区',
      'children': null
    }, {
      'regionId': '430921',
      'parentId': '430900',
      'name': '南县',
      'children': null
    }, {
      'regionId': '430922',
      'parentId': '430900',
      'name': '桃江县',
      'children': null
    }, {
      'regionId': '430923',
      'parentId': '430900',
      'name': '安化县',
      'children': null
    }, {
      'regionId': '430981',
      'parentId': '430900',
      'name': '沅江市',
      'children': null
    }]
  }, {
    'regionId': '431000',
    'parentId': '430000',
    'name': '郴州市',
    'children': [{
      'regionId': '431002',
      'parentId': '431000',
      'name': '北湖区',
      'children': null
    }, {
      'regionId': '431003',
      'parentId': '431000',
      'name': '苏仙区',
      'children': null
    }, {
      'regionId': '431021',
      'parentId': '431000',
      'name': '桂阳县',
      'children': null
    }, {
      'regionId': '431022',
      'parentId': '431000',
      'name': '宜章县',
      'children': null
    }, {
      'regionId': '431023',
      'parentId': '431000',
      'name': '永兴县',
      'children': null
    }, {
      'regionId': '431024',
      'parentId': '431000',
      'name': '嘉禾县',
      'children': null
    }, {
      'regionId': '431025',
      'parentId': '431000',
      'name': '临武县',
      'children': null
    }, {
      'regionId': '431026',
      'parentId': '431000',
      'name': '汝城县',
      'children': null
    }, {
      'regionId': '431027',
      'parentId': '431000',
      'name': '桂东县',
      'children': null
    }, {
      'regionId': '431028',
      'parentId': '431000',
      'name': '安仁县',
      'children': null
    }, {
      'regionId': '431081',
      'parentId': '431000',
      'name': '资兴市',
      'children': null
    }]
  }, {
    'regionId': '431100',
    'parentId': '430000',
    'name': '永州市',
    'children': [{
      'regionId': '431102',
      'parentId': '431100',
      'name': '零陵区',
      'children': null
    }, {
      'regionId': '431103',
      'parentId': '431100',
      'name': '冷水滩区',
      'children': null
    }, {
      'regionId': '431121',
      'parentId': '431100',
      'name': '祁阳县',
      'children': null
    }, {
      'regionId': '431122',
      'parentId': '431100',
      'name': '东安县',
      'children': null
    }, {
      'regionId': '431123',
      'parentId': '431100',
      'name': '双牌县',
      'children': null
    }, {
      'regionId': '431124',
      'parentId': '431100',
      'name': '道县',
      'children': null
    }, {
      'regionId': '431125',
      'parentId': '431100',
      'name': '江永县',
      'children': null
    }, {
      'regionId': '431126',
      'parentId': '431100',
      'name': '宁远县',
      'children': null
    }, {
      'regionId': '431127',
      'parentId': '431100',
      'name': '蓝山县',
      'children': null
    }, {
      'regionId': '431128',
      'parentId': '431100',
      'name': '新田县',
      'children': null
    }, {
      'regionId': '431129',
      'parentId': '431100',
      'name': '江华瑶族自治县',
      'children': null
    }]
  }, {
    'regionId': '431200',
    'parentId': '430000',
    'name': '怀化市',
    'children': [{
      'regionId': '431202',
      'parentId': '431200',
      'name': '鹤城区',
      'children': null
    }, {
      'regionId': '431221',
      'parentId': '431200',
      'name': '中方县',
      'children': null
    }, {
      'regionId': '431222',
      'parentId': '431200',
      'name': '沅陵县',
      'children': null
    }, {
      'regionId': '431223',
      'parentId': '431200',
      'name': '辰溪县',
      'children': null
    }, {
      'regionId': '431224',
      'parentId': '431200',
      'name': '溆浦县',
      'children': null
    }, {
      'regionId': '431225',
      'parentId': '431200',
      'name': '会同县',
      'children': null
    }, {
      'regionId': '431226',
      'parentId': '431200',
      'name': '麻阳苗族自治县',
      'children': null
    }, {
      'regionId': '431227',
      'parentId': '431200',
      'name': '新晃侗族自治县',
      'children': null
    }, {
      'regionId': '431228',
      'parentId': '431200',
      'name': '芷江侗族自治县',
      'children': null
    }, {
      'regionId': '431229',
      'parentId': '431200',
      'name': '靖州苗族侗族自治县',
      'children': null
    }, {
      'regionId': '431230',
      'parentId': '431200',
      'name': '通道侗族自治县',
      'children': null
    }, {
      'regionId': '431281',
      'parentId': '431200',
      'name': '洪江市',
      'children': null
    }]
  }, {
    'regionId': '431300',
    'parentId': '430000',
    'name': '娄底市',
    'children': [{
      'regionId': '431302',
      'parentId': '431300',
      'name': '娄星区',
      'children': null
    }, {
      'regionId': '431321',
      'parentId': '431300',
      'name': '双峰县',
      'children': null
    }, {
      'regionId': '431322',
      'parentId': '431300',
      'name': '新化县',
      'children': null
    }, {
      'regionId': '431381',
      'parentId': '431300',
      'name': '冷水江市',
      'children': null
    }, {
      'regionId': '431382',
      'parentId': '431300',
      'name': '涟源市',
      'children': null
    }]
  }, {
    'regionId': '433100',
    'parentId': '430000',
    'name': '湘西土家族苗族自治州',
    'children': [{
      'regionId': '433101',
      'parentId': '433100',
      'name': '吉首市',
      'children': null
    }, {
      'regionId': '433122',
      'parentId': '433100',
      'name': '泸溪县',
      'children': null
    }, {
      'regionId': '433123',
      'parentId': '433100',
      'name': '凤凰县',
      'children': null
    }, {
      'regionId': '433124',
      'parentId': '433100',
      'name': '花垣县',
      'children': null
    }, {
      'regionId': '433125',
      'parentId': '433100',
      'name': '保靖县',
      'children': null
    }, {
      'regionId': '433126',
      'parentId': '433100',
      'name': '古丈县',
      'children': null
    }, {
      'regionId': '433127',
      'parentId': '433100',
      'name': '永顺县',
      'children': null
    }, {
      'regionId': '433130',
      'parentId': '433100',
      'name': '龙山县',
      'children': null
    }]
  }]
}, {
  'regionId': '440000',
  'parentId': '0',
  'name': '广东省',
  'children': [{
    'regionId': '440100',
    'parentId': '440000',
    'name': '广州市',
    'children': [{
      'regionId': '440103',
      'parentId': '440100',
      'name': '荔湾区',
      'children': null
    }, {
      'regionId': '440104',
      'parentId': '440100',
      'name': '越秀区',
      'children': null
    }, {
      'regionId': '440105',
      'parentId': '440100',
      'name': '海珠区',
      'children': null
    }, {
      'regionId': '440106',
      'parentId': '440100',
      'name': '天河区',
      'children': null
    }, {
      'regionId': '440111',
      'parentId': '440100',
      'name': '白云区',
      'children': null
    }, {
      'regionId': '440112',
      'parentId': '440100',
      'name': '黄埔区',
      'children': null
    }, {
      'regionId': '440113',
      'parentId': '440100',
      'name': '番禺区',
      'children': null
    }, {
      'regionId': '440114',
      'parentId': '440100',
      'name': '花都区',
      'children': null
    }, {
      'regionId': '440115',
      'parentId': '440100',
      'name': '南沙区',
      'children': null
    }, {
      'regionId': '440117',
      'parentId': '440100',
      'name': '从化区',
      'children': null
    }, {
      'regionId': '440118',
      'parentId': '440100',
      'name': '增城区',
      'children': null
    }]
  }, {
    'regionId': '440200',
    'parentId': '440000',
    'name': '韶关市',
    'children': [{
      'regionId': '440203',
      'parentId': '440200',
      'name': '武江区',
      'children': null
    }, {
      'regionId': '440204',
      'parentId': '440200',
      'name': '浈江区',
      'children': null
    }, {
      'regionId': '440205',
      'parentId': '440200',
      'name': '曲江区',
      'children': null
    }, {
      'regionId': '440222',
      'parentId': '440200',
      'name': '始兴县',
      'children': null
    }, {
      'regionId': '440224',
      'parentId': '440200',
      'name': '仁化县',
      'children': null
    }, {
      'regionId': '440229',
      'parentId': '440200',
      'name': '翁源县',
      'children': null
    }, {
      'regionId': '440232',
      'parentId': '440200',
      'name': '乳源瑶族自治县',
      'children': null
    }, {
      'regionId': '440233',
      'parentId': '440200',
      'name': '新丰县',
      'children': null
    }, {
      'regionId': '440281',
      'parentId': '440200',
      'name': '乐昌市',
      'children': null
    }, {
      'regionId': '440282',
      'parentId': '440200',
      'name': '南雄市',
      'children': null
    }]
  }, {
    'regionId': '440300',
    'parentId': '440000',
    'name': '深圳市',
    'children': [{
      'regionId': '440303',
      'parentId': '440300',
      'name': '罗湖区',
      'children': null
    }, {
      'regionId': '440304',
      'parentId': '440300',
      'name': '福田区',
      'children': null
    }, {
      'regionId': '440305',
      'parentId': '440300',
      'name': '南山区',
      'children': null
    }, {
      'regionId': '440306',
      'parentId': '440300',
      'name': '宝安区',
      'children': null
    }, {
      'regionId': '440307',
      'parentId': '440300',
      'name': '龙岗区',
      'children': null
    }, {
      'regionId': '440308',
      'parentId': '440300',
      'name': '盐田区',
      'children': null
    }, {
      'regionId': '440309',
      'parentId': '440300',
      'name': '龙华区',
      'children': null
    }, {
      'regionId': '440310',
      'parentId': '440300',
      'name': '坪山区',
      'children': null
    }, {
      'regionId': '440311',
      'parentId': '440300',
      'name': '光明区',
      'children': null
    }]
  }, {
    'regionId': '440400',
    'parentId': '440000',
    'name': '珠海市',
    'children': [{
      'regionId': '440402',
      'parentId': '440400',
      'name': '香洲区',
      'children': null
    }, {
      'regionId': '440403',
      'parentId': '440400',
      'name': '斗门区',
      'children': null
    }, {
      'regionId': '440404',
      'parentId': '440400',
      'name': '金湾区',
      'children': null
    }]
  }, {
    'regionId': '440500',
    'parentId': '440000',
    'name': '汕头市',
    'children': [{
      'regionId': '440507',
      'parentId': '440500',
      'name': '龙湖区',
      'children': null
    }, {
      'regionId': '440511',
      'parentId': '440500',
      'name': '金平区',
      'children': null
    }, {
      'regionId': '440512',
      'parentId': '440500',
      'name': '濠江区',
      'children': null
    }, {
      'regionId': '440513',
      'parentId': '440500',
      'name': '潮阳区',
      'children': null
    }, {
      'regionId': '440514',
      'parentId': '440500',
      'name': '潮南区',
      'children': null
    }, {
      'regionId': '440515',
      'parentId': '440500',
      'name': '澄海区',
      'children': null
    }, {
      'regionId': '440523',
      'parentId': '440500',
      'name': '南澳县',
      'children': null
    }]
  }, {
    'regionId': '440600',
    'parentId': '440000',
    'name': '佛山市',
    'children': [{
      'regionId': '440604',
      'parentId': '440600',
      'name': '禅城区',
      'children': null
    }, {
      'regionId': '440605',
      'parentId': '440600',
      'name': '南海区',
      'children': null
    }, {
      'regionId': '440606',
      'parentId': '440600',
      'name': '顺德区',
      'children': null
    }, {
      'regionId': '440607',
      'parentId': '440600',
      'name': '三水区',
      'children': null
    }, {
      'regionId': '440608',
      'parentId': '440600',
      'name': '高明区',
      'children': null
    }]
  }, {
    'regionId': '440700',
    'parentId': '440000',
    'name': '江门市',
    'children': [{
      'regionId': '440703',
      'parentId': '440700',
      'name': '蓬江区',
      'children': null
    }, {
      'regionId': '440704',
      'parentId': '440700',
      'name': '江海区',
      'children': null
    }, {
      'regionId': '440705',
      'parentId': '440700',
      'name': '新会区',
      'children': null
    }, {
      'regionId': '440781',
      'parentId': '440700',
      'name': '台山市',
      'children': null
    }, {
      'regionId': '440783',
      'parentId': '440700',
      'name': '开平市',
      'children': null
    }, {
      'regionId': '440784',
      'parentId': '440700',
      'name': '鹤山市',
      'children': null
    }, {
      'regionId': '440785',
      'parentId': '440700',
      'name': '恩平市',
      'children': null
    }]
  }, {
    'regionId': '440800',
    'parentId': '440000',
    'name': '湛江市',
    'children': [{
      'regionId': '440802',
      'parentId': '440800',
      'name': '赤坎区',
      'children': null
    }, {
      'regionId': '440803',
      'parentId': '440800',
      'name': '霞山区',
      'children': null
    }, {
      'regionId': '440804',
      'parentId': '440800',
      'name': '坡头区',
      'children': null
    }, {
      'regionId': '440811',
      'parentId': '440800',
      'name': '麻章区',
      'children': null
    }, {
      'regionId': '440823',
      'parentId': '440800',
      'name': '遂溪县',
      'children': null
    }, {
      'regionId': '440825',
      'parentId': '440800',
      'name': '徐闻县',
      'children': null
    }, {
      'regionId': '440881',
      'parentId': '440800',
      'name': '廉江市',
      'children': null
    }, {
      'regionId': '440882',
      'parentId': '440800',
      'name': '雷州市',
      'children': null
    }, {
      'regionId': '440883',
      'parentId': '440800',
      'name': '吴川市',
      'children': null
    }]
  }, {
    'regionId': '440900',
    'parentId': '440000',
    'name': '茂名市',
    'children': [{
      'regionId': '440902',
      'parentId': '440900',
      'name': '茂南区',
      'children': null
    }, {
      'regionId': '440904',
      'parentId': '440900',
      'name': '电白区',
      'children': null
    }, {
      'regionId': '440981',
      'parentId': '440900',
      'name': '高州市',
      'children': null
    }, {
      'regionId': '440982',
      'parentId': '440900',
      'name': '化州市',
      'children': null
    }, {
      'regionId': '440983',
      'parentId': '440900',
      'name': '信宜市',
      'children': null
    }]
  }, {
    'regionId': '441200',
    'parentId': '440000',
    'name': '肇庆市',
    'children': [{
      'regionId': '441202',
      'parentId': '441200',
      'name': '端州区',
      'children': null
    }, {
      'regionId': '441203',
      'parentId': '441200',
      'name': '鼎湖区',
      'children': null
    }, {
      'regionId': '441204',
      'parentId': '441200',
      'name': '高要区',
      'children': null
    }, {
      'regionId': '441223',
      'parentId': '441200',
      'name': '广宁县',
      'children': null
    }, {
      'regionId': '441224',
      'parentId': '441200',
      'name': '怀集县',
      'children': null
    }, {
      'regionId': '441225',
      'parentId': '441200',
      'name': '封开县',
      'children': null
    }, {
      'regionId': '441226',
      'parentId': '441200',
      'name': '德庆县',
      'children': null
    }, {
      'regionId': '441284',
      'parentId': '441200',
      'name': '四会市',
      'children': null
    }]
  }, {
    'regionId': '441300',
    'parentId': '440000',
    'name': '惠州市',
    'children': [{
      'regionId': '441302',
      'parentId': '441300',
      'name': '惠城区',
      'children': null
    }, {
      'regionId': '441303',
      'parentId': '441300',
      'name': '惠阳区',
      'children': null
    }, {
      'regionId': '441322',
      'parentId': '441300',
      'name': '博罗县',
      'children': null
    }, {
      'regionId': '441323',
      'parentId': '441300',
      'name': '惠东县',
      'children': null
    }, {
      'regionId': '441324',
      'parentId': '441300',
      'name': '龙门县',
      'children': null
    }]
  }, {
    'regionId': '441400',
    'parentId': '440000',
    'name': '梅州市',
    'children': [{
      'regionId': '441402',
      'parentId': '441400',
      'name': '梅江区',
      'children': null
    }, {
      'regionId': '441403',
      'parentId': '441400',
      'name': '梅县区',
      'children': null
    }, {
      'regionId': '441422',
      'parentId': '441400',
      'name': '大埔县',
      'children': null
    }, {
      'regionId': '441423',
      'parentId': '441400',
      'name': '丰顺县',
      'children': null
    }, {
      'regionId': '441424',
      'parentId': '441400',
      'name': '五华县',
      'children': null
    }, {
      'regionId': '441426',
      'parentId': '441400',
      'name': '平远县',
      'children': null
    }, {
      'regionId': '441427',
      'parentId': '441400',
      'name': '蕉岭县',
      'children': null
    }, {
      'regionId': '441481',
      'parentId': '441400',
      'name': '兴宁市',
      'children': null
    }]
  }, {
    'regionId': '441500',
    'parentId': '440000',
    'name': '汕尾市',
    'children': [{
      'regionId': '441502',
      'parentId': '441500',
      'name': '城区',
      'children': null
    }, {
      'regionId': '441521',
      'parentId': '441500',
      'name': '海丰县',
      'children': null
    }, {
      'regionId': '441523',
      'parentId': '441500',
      'name': '陆河县',
      'children': null
    }, {
      'regionId': '441581',
      'parentId': '441500',
      'name': '陆丰市',
      'children': null
    }]
  }, {
    'regionId': '441600',
    'parentId': '440000',
    'name': '河源市',
    'children': [{
      'regionId': '441602',
      'parentId': '441600',
      'name': '源城区',
      'children': null
    }, {
      'regionId': '441621',
      'parentId': '441600',
      'name': '紫金县',
      'children': null
    }, {
      'regionId': '441622',
      'parentId': '441600',
      'name': '龙川县',
      'children': null
    }, {
      'regionId': '441623',
      'parentId': '441600',
      'name': '连平县',
      'children': null
    }, {
      'regionId': '441624',
      'parentId': '441600',
      'name': '和平县',
      'children': null
    }, {
      'regionId': '441625',
      'parentId': '441600',
      'name': '东源县',
      'children': null
    }]
  }, {
    'regionId': '441700',
    'parentId': '440000',
    'name': '阳江市',
    'children': [{
      'regionId': '441702',
      'parentId': '441700',
      'name': '江城区',
      'children': null
    }, {
      'regionId': '441704',
      'parentId': '441700',
      'name': '阳东区',
      'children': null
    }, {
      'regionId': '441721',
      'parentId': '441700',
      'name': '阳西县',
      'children': null
    }, {
      'regionId': '441781',
      'parentId': '441700',
      'name': '阳春市',
      'children': null
    }]
  }, {
    'regionId': '441800',
    'parentId': '440000',
    'name': '清远市',
    'children': [{
      'regionId': '441802',
      'parentId': '441800',
      'name': '清城区',
      'children': null
    }, {
      'regionId': '441803',
      'parentId': '441800',
      'name': '清新区',
      'children': null
    }, {
      'regionId': '441821',
      'parentId': '441800',
      'name': '佛冈县',
      'children': null
    }, {
      'regionId': '441823',
      'parentId': '441800',
      'name': '阳山县',
      'children': null
    }, {
      'regionId': '441825',
      'parentId': '441800',
      'name': '连山壮族瑶族自治县',
      'children': null
    }, {
      'regionId': '441826',
      'parentId': '441800',
      'name': '连南瑶族自治县',
      'children': null
    }, {
      'regionId': '441881',
      'parentId': '441800',
      'name': '英德市',
      'children': null
    }, {
      'regionId': '441882',
      'parentId': '441800',
      'name': '连州市',
      'children': null
    }]
  }, {
    'regionId': '441900',
    'parentId': '440000',
    'name': '东莞市',
    'children': null
  }, {
    'regionId': '442000',
    'parentId': '440000',
    'name': '中山市',
    'children': null
  }, {
    'regionId': '445100',
    'parentId': '440000',
    'name': '潮州市',
    'children': [{
      'regionId': '445102',
      'parentId': '445100',
      'name': '湘桥区',
      'children': null
    }, {
      'regionId': '445103',
      'parentId': '445100',
      'name': '潮安区',
      'children': null
    }, {
      'regionId': '445122',
      'parentId': '445100',
      'name': '饶平县',
      'children': null
    }]
  }, {
    'regionId': '445200',
    'parentId': '440000',
    'name': '揭阳市',
    'children': [{
      'regionId': '445202',
      'parentId': '445200',
      'name': '榕城区',
      'children': null
    }, {
      'regionId': '445203',
      'parentId': '445200',
      'name': '揭东区',
      'children': null
    }, {
      'regionId': '445222',
      'parentId': '445200',
      'name': '揭西县',
      'children': null
    }, {
      'regionId': '445224',
      'parentId': '445200',
      'name': '惠来县',
      'children': null
    }, {
      'regionId': '445281',
      'parentId': '445200',
      'name': '普宁市',
      'children': null
    }]
  }, {
    'regionId': '445300',
    'parentId': '440000',
    'name': '云浮市',
    'children': [{
      'regionId': '445302',
      'parentId': '445300',
      'name': '云城区',
      'children': null
    }, {
      'regionId': '445303',
      'parentId': '445300',
      'name': '云安区',
      'children': null
    }, {
      'regionId': '445321',
      'parentId': '445300',
      'name': '新兴县',
      'children': null
    }, {
      'regionId': '445322',
      'parentId': '445300',
      'name': '郁南县',
      'children': null
    }, {
      'regionId': '445381',
      'parentId': '445300',
      'name': '罗定市',
      'children': null
    }]
  }]
}, {
  'regionId': '450000',
  'parentId': '0',
  'name': '广西壮族自治区',
  'children': [{
    'regionId': '450100',
    'parentId': '450000',
    'name': '南宁市',
    'children': [{
      'regionId': '450102',
      'parentId': '450100',
      'name': '兴宁区',
      'children': null
    }, {
      'regionId': '450103',
      'parentId': '450100',
      'name': '青秀区',
      'children': null
    }, {
      'regionId': '450105',
      'parentId': '450100',
      'name': '江南区',
      'children': null
    }, {
      'regionId': '450107',
      'parentId': '450100',
      'name': '西乡塘区',
      'children': null
    }, {
      'regionId': '450108',
      'parentId': '450100',
      'name': '良庆区',
      'children': null
    }, {
      'regionId': '450109',
      'parentId': '450100',
      'name': '邕宁区',
      'children': null
    }, {
      'regionId': '450110',
      'parentId': '450100',
      'name': '武鸣区',
      'children': null
    }, {
      'regionId': '450123',
      'parentId': '450100',
      'name': '隆安县',
      'children': null
    }, {
      'regionId': '450124',
      'parentId': '450100',
      'name': '马山县',
      'children': null
    }, {
      'regionId': '450125',
      'parentId': '450100',
      'name': '上林县',
      'children': null
    }, {
      'regionId': '450126',
      'parentId': '450100',
      'name': '宾阳县',
      'children': null
    }, {
      'regionId': '450127',
      'parentId': '450100',
      'name': '横县',
      'children': null
    }]
  }, {
    'regionId': '450200',
    'parentId': '450000',
    'name': '柳州市',
    'children': [{
      'regionId': '450202',
      'parentId': '450200',
      'name': '城中区',
      'children': null
    }, {
      'regionId': '450203',
      'parentId': '450200',
      'name': '鱼峰区',
      'children': null
    }, {
      'regionId': '450204',
      'parentId': '450200',
      'name': '柳南区',
      'children': null
    }, {
      'regionId': '450205',
      'parentId': '450200',
      'name': '柳北区',
      'children': null
    }, {
      'regionId': '450206',
      'parentId': '450200',
      'name': '柳江区',
      'children': null
    }, {
      'regionId': '450222',
      'parentId': '450200',
      'name': '柳城县',
      'children': null
    }, {
      'regionId': '450223',
      'parentId': '450200',
      'name': '鹿寨县',
      'children': null
    }, {
      'regionId': '450224',
      'parentId': '450200',
      'name': '融安县',
      'children': null
    }, {
      'regionId': '450225',
      'parentId': '450200',
      'name': '融水苗族自治县',
      'children': null
    }, {
      'regionId': '450226',
      'parentId': '450200',
      'name': '三江侗族自治县',
      'children': null
    }]
  }, {
    'regionId': '450300',
    'parentId': '450000',
    'name': '桂林市',
    'children': [{
      'regionId': '450302',
      'parentId': '450300',
      'name': '秀峰区',
      'children': null
    }, {
      'regionId': '450303',
      'parentId': '450300',
      'name': '叠彩区',
      'children': null
    }, {
      'regionId': '450304',
      'parentId': '450300',
      'name': '象山区',
      'children': null
    }, {
      'regionId': '450305',
      'parentId': '450300',
      'name': '七星区',
      'children': null
    }, {
      'regionId': '450311',
      'parentId': '450300',
      'name': '雁山区',
      'children': null
    }, {
      'regionId': '450312',
      'parentId': '450300',
      'name': '临桂区',
      'children': null
    }, {
      'regionId': '450321',
      'parentId': '450300',
      'name': '阳朔县',
      'children': null
    }, {
      'regionId': '450323',
      'parentId': '450300',
      'name': '灵川县',
      'children': null
    }, {
      'regionId': '450324',
      'parentId': '450300',
      'name': '全州县',
      'children': null
    }, {
      'regionId': '450325',
      'parentId': '450300',
      'name': '兴安县',
      'children': null
    }, {
      'regionId': '450326',
      'parentId': '450300',
      'name': '永福县',
      'children': null
    }, {
      'regionId': '450327',
      'parentId': '450300',
      'name': '灌阳县',
      'children': null
    }, {
      'regionId': '450328',
      'parentId': '450300',
      'name': '龙胜各族自治县',
      'children': null
    }, {
      'regionId': '450329',
      'parentId': '450300',
      'name': '资源县',
      'children': null
    }, {
      'regionId': '450330',
      'parentId': '450300',
      'name': '平乐县',
      'children': null
    }, {
      'regionId': '450332',
      'parentId': '450300',
      'name': '恭城瑶族自治县',
      'children': null
    }, {
      'regionId': '450381',
      'parentId': '450300',
      'name': '荔浦市',
      'children': null
    }]
  }, {
    'regionId': '450400',
    'parentId': '450000',
    'name': '梧州市',
    'children': [{
      'regionId': '450403',
      'parentId': '450400',
      'name': '万秀区',
      'children': null
    }, {
      'regionId': '450405',
      'parentId': '450400',
      'name': '长洲区',
      'children': null
    }, {
      'regionId': '450406',
      'parentId': '450400',
      'name': '龙圩区',
      'children': null
    }, {
      'regionId': '450421',
      'parentId': '450400',
      'name': '苍梧县',
      'children': null
    }, {
      'regionId': '450422',
      'parentId': '450400',
      'name': '藤县',
      'children': null
    }, {
      'regionId': '450423',
      'parentId': '450400',
      'name': '蒙山县',
      'children': null
    }, {
      'regionId': '450481',
      'parentId': '450400',
      'name': '岑溪市',
      'children': null
    }]
  }, {
    'regionId': '450500',
    'parentId': '450000',
    'name': '北海市',
    'children': [{
      'regionId': '450502',
      'parentId': '450500',
      'name': '海城区',
      'children': null
    }, {
      'regionId': '450503',
      'parentId': '450500',
      'name': '银海区',
      'children': null
    }, {
      'regionId': '450512',
      'parentId': '450500',
      'name': '铁山港区',
      'children': null
    }, {
      'regionId': '450521',
      'parentId': '450500',
      'name': '合浦县',
      'children': null
    }]
  }, {
    'regionId': '450600',
    'parentId': '450000',
    'name': '防城港市',
    'children': [{
      'regionId': '450602',
      'parentId': '450600',
      'name': '港口区',
      'children': null
    }, {
      'regionId': '450603',
      'parentId': '450600',
      'name': '防城区',
      'children': null
    }, {
      'regionId': '450621',
      'parentId': '450600',
      'name': '上思县',
      'children': null
    }, {
      'regionId': '450681',
      'parentId': '450600',
      'name': '东兴市',
      'children': null
    }]
  }, {
    'regionId': '450700',
    'parentId': '450000',
    'name': '钦州市',
    'children': [{
      'regionId': '450702',
      'parentId': '450700',
      'name': '钦南区',
      'children': null
    }, {
      'regionId': '450703',
      'parentId': '450700',
      'name': '钦北区',
      'children': null
    }, {
      'regionId': '450721',
      'parentId': '450700',
      'name': '灵山县',
      'children': null
    }, {
      'regionId': '450722',
      'parentId': '450700',
      'name': '浦北县',
      'children': null
    }]
  }, {
    'regionId': '450800',
    'parentId': '450000',
    'name': '贵港市',
    'children': [{
      'regionId': '450802',
      'parentId': '450800',
      'name': '港北区',
      'children': null
    }, {
      'regionId': '450803',
      'parentId': '450800',
      'name': '港南区',
      'children': null
    }, {
      'regionId': '450804',
      'parentId': '450800',
      'name': '覃塘区',
      'children': null
    }, {
      'regionId': '450821',
      'parentId': '450800',
      'name': '平南县',
      'children': null
    }, {
      'regionId': '450881',
      'parentId': '450800',
      'name': '桂平市',
      'children': null
    }]
  }, {
    'regionId': '450900',
    'parentId': '450000',
    'name': '玉林市',
    'children': [{
      'regionId': '450902',
      'parentId': '450900',
      'name': '玉州区',
      'children': null
    }, {
      'regionId': '450903',
      'parentId': '450900',
      'name': '福绵区',
      'children': null
    }, {
      'regionId': '450921',
      'parentId': '450900',
      'name': '容县',
      'children': null
    }, {
      'regionId': '450922',
      'parentId': '450900',
      'name': '陆川县',
      'children': null
    }, {
      'regionId': '450923',
      'parentId': '450900',
      'name': '博白县',
      'children': null
    }, {
      'regionId': '450924',
      'parentId': '450900',
      'name': '兴业县',
      'children': null
    }, {
      'regionId': '450981',
      'parentId': '450900',
      'name': '北流市',
      'children': null
    }]
  }, {
    'regionId': '451000',
    'parentId': '450000',
    'name': '百色市',
    'children': [{
      'regionId': '451002',
      'parentId': '451000',
      'name': '右江区',
      'children': null
    }, {
      'regionId': '451003',
      'parentId': '451000',
      'name': '田阳区',
      'children': null
    }, {
      'regionId': '451022',
      'parentId': '451000',
      'name': '田东县',
      'children': null
    }, {
      'regionId': '451024',
      'parentId': '451000',
      'name': '德保县',
      'children': null
    }, {
      'regionId': '451026',
      'parentId': '451000',
      'name': '那坡县',
      'children': null
    }, {
      'regionId': '451027',
      'parentId': '451000',
      'name': '凌云县',
      'children': null
    }, {
      'regionId': '451028',
      'parentId': '451000',
      'name': '乐业县',
      'children': null
    }, {
      'regionId': '451029',
      'parentId': '451000',
      'name': '田林县',
      'children': null
    }, {
      'regionId': '451030',
      'parentId': '451000',
      'name': '西林县',
      'children': null
    }, {
      'regionId': '451031',
      'parentId': '451000',
      'name': '隆林各族自治县',
      'children': null
    }, {
      'regionId': '451081',
      'parentId': '451000',
      'name': '靖西市',
      'children': null
    }, {
      'regionId': '451082',
      'parentId': '451000',
      'name': '平果市',
      'children': null
    }]
  }, {
    'regionId': '451100',
    'parentId': '450000',
    'name': '贺州市',
    'children': [{
      'regionId': '451102',
      'parentId': '451100',
      'name': '八步区',
      'children': null
    }, {
      'regionId': '451103',
      'parentId': '451100',
      'name': '平桂区',
      'children': null
    }, {
      'regionId': '451121',
      'parentId': '451100',
      'name': '昭平县',
      'children': null
    }, {
      'regionId': '451122',
      'parentId': '451100',
      'name': '钟山县',
      'children': null
    }, {
      'regionId': '451123',
      'parentId': '451100',
      'name': '富川瑶族自治县',
      'children': null
    }]
  }, {
    'regionId': '451200',
    'parentId': '450000',
    'name': '河池市',
    'children': [{
      'regionId': '451202',
      'parentId': '451200',
      'name': '金城江区',
      'children': null
    }, {
      'regionId': '451203',
      'parentId': '451200',
      'name': '宜州区',
      'children': null
    }, {
      'regionId': '451221',
      'parentId': '451200',
      'name': '南丹县',
      'children': null
    }, {
      'regionId': '451222',
      'parentId': '451200',
      'name': '天峨县',
      'children': null
    }, {
      'regionId': '451223',
      'parentId': '451200',
      'name': '凤山县',
      'children': null
    }, {
      'regionId': '451224',
      'parentId': '451200',
      'name': '东兰县',
      'children': null
    }, {
      'regionId': '451225',
      'parentId': '451200',
      'name': '罗城仫佬族自治县',
      'children': null
    }, {
      'regionId': '451226',
      'parentId': '451200',
      'name': '环江毛南族自治县',
      'children': null
    }, {
      'regionId': '451227',
      'parentId': '451200',
      'name': '巴马瑶族自治县',
      'children': null
    }, {
      'regionId': '451228',
      'parentId': '451200',
      'name': '都安瑶族自治县',
      'children': null
    }, {
      'regionId': '451229',
      'parentId': '451200',
      'name': '大化瑶族自治县',
      'children': null
    }]
  }, {
    'regionId': '451300',
    'parentId': '450000',
    'name': '来宾市',
    'children': [{
      'regionId': '451302',
      'parentId': '451300',
      'name': '兴宾区',
      'children': null
    }, {
      'regionId': '451321',
      'parentId': '451300',
      'name': '忻城县',
      'children': null
    }, {
      'regionId': '451322',
      'parentId': '451300',
      'name': '象州县',
      'children': null
    }, {
      'regionId': '451323',
      'parentId': '451300',
      'name': '武宣县',
      'children': null
    }, {
      'regionId': '451324',
      'parentId': '451300',
      'name': '金秀瑶族自治县',
      'children': null
    }, {
      'regionId': '451381',
      'parentId': '451300',
      'name': '合山市',
      'children': null
    }]
  }, {
    'regionId': '451400',
    'parentId': '450000',
    'name': '崇左市',
    'children': [{
      'regionId': '451402',
      'parentId': '451400',
      'name': '江州区',
      'children': null
    }, {
      'regionId': '451421',
      'parentId': '451400',
      'name': '扶绥县',
      'children': null
    }, {
      'regionId': '451422',
      'parentId': '451400',
      'name': '宁明县',
      'children': null
    }, {
      'regionId': '451423',
      'parentId': '451400',
      'name': '龙州县',
      'children': null
    }, {
      'regionId': '451424',
      'parentId': '451400',
      'name': '大新县',
      'children': null
    }, {
      'regionId': '451425',
      'parentId': '451400',
      'name': '天等县',
      'children': null
    }, {
      'regionId': '451481',
      'parentId': '451400',
      'name': '凭祥市',
      'children': null
    }]
  }]
}, {
  'regionId': '460000',
  'parentId': '0',
  'name': '海南省',
  'children': [{
    'regionId': '460100',
    'parentId': '460000',
    'name': '海口市',
    'children': [{
      'regionId': '460105',
      'parentId': '460100',
      'name': '秀英区',
      'children': null
    }, {
      'regionId': '460106',
      'parentId': '460100',
      'name': '龙华区',
      'children': null
    }, {
      'regionId': '460107',
      'parentId': '460100',
      'name': '琼山区',
      'children': null
    }, {
      'regionId': '460108',
      'parentId': '460100',
      'name': '美兰区',
      'children': null
    }]
  }, {
    'regionId': '460200',
    'parentId': '460000',
    'name': '三亚市',
    'children': [{
      'regionId': '460202',
      'parentId': '460200',
      'name': '海棠区',
      'children': null
    }, {
      'regionId': '460203',
      'parentId': '460200',
      'name': '吉阳区',
      'children': null
    }, {
      'regionId': '460204',
      'parentId': '460200',
      'name': '天涯区',
      'children': null
    }, {
      'regionId': '460205',
      'parentId': '460200',
      'name': '崖州区',
      'children': null
    }]
  }, {
    'regionId': '460300',
    'parentId': '460000',
    'name': '三沙市',
    'children': null
  }, {
    'regionId': '460400',
    'parentId': '460000',
    'name': '儋州市',
    'children': null
  }, {
    'regionId': '469001',
    'parentId': '460000',
    'name': '五指山市',
    'children': null
  }, {
    'regionId': '469002',
    'parentId': '460000',
    'name': '琼海市',
    'children': null
  }, {
    'regionId': '469005',
    'parentId': '460000',
    'name': '文昌市',
    'children': null
  }, {
    'regionId': '469006',
    'parentId': '460000',
    'name': '万宁市',
    'children': null
  }, {
    'regionId': '469007',
    'parentId': '460000',
    'name': '东方市',
    'children': null
  }, {
    'regionId': '469021',
    'parentId': '460000',
    'name': '定安县',
    'children': null
  }, {
    'regionId': '469022',
    'parentId': '460000',
    'name': '屯昌县',
    'children': null
  }, {
    'regionId': '469023',
    'parentId': '460000',
    'name': '澄迈县',
    'children': null
  }, {
    'regionId': '469024',
    'parentId': '460000',
    'name': '临高县',
    'children': null
  }, {
    'regionId': '469025',
    'parentId': '460000',
    'name': '白沙黎族自治县',
    'children': null
  }, {
    'regionId': '469026',
    'parentId': '460000',
    'name': '昌江黎族自治县',
    'children': null
  }, {
    'regionId': '469027',
    'parentId': '460000',
    'name': '乐东黎族自治县',
    'children': null
  }, {
    'regionId': '469028',
    'parentId': '460000',
    'name': '陵水黎族自治县',
    'children': null
  }, {
    'regionId': '469029',
    'parentId': '460000',
    'name': '保亭黎族苗族自治县',
    'children': null
  }, {
    'regionId': '469030',
    'parentId': '460000',
    'name': '琼中黎族苗族自治县',
    'children': null
  }]
}, {
  'regionId': '500000',
  'parentId': '0',
  'name': '重庆市',
  'children': [{
    'regionId': '500100',
    'parentId': '500000',
    'name': '市辖区',
    'children': [{
      'regionId': '500101',
      'parentId': '500100',
      'name': '万州区',
      'children': null
    }, {
      'regionId': '500102',
      'parentId': '500100',
      'name': '涪陵区',
      'children': null
    }, {
      'regionId': '500103',
      'parentId': '500100',
      'name': '渝中区',
      'children': null
    }, {
      'regionId': '500104',
      'parentId': '500100',
      'name': '大渡口区',
      'children': null
    }, {
      'regionId': '500105',
      'parentId': '500100',
      'name': '江北区',
      'children': null
    }, {
      'regionId': '500106',
      'parentId': '500100',
      'name': '沙坪坝区',
      'children': null
    }, {
      'regionId': '500107',
      'parentId': '500100',
      'name': '九龙坡区',
      'children': null
    }, {
      'regionId': '500108',
      'parentId': '500100',
      'name': '南岸区',
      'children': null
    }, {
      'regionId': '500109',
      'parentId': '500100',
      'name': '北碚区',
      'children': null
    }, {
      'regionId': '500110',
      'parentId': '500100',
      'name': '綦江区',
      'children': null
    }, {
      'regionId': '500111',
      'parentId': '500100',
      'name': '大足区',
      'children': null
    }, {
      'regionId': '500112',
      'parentId': '500100',
      'name': '渝北区',
      'children': null
    }, {
      'regionId': '500113',
      'parentId': '500100',
      'name': '巴南区',
      'children': null
    }, {
      'regionId': '500114',
      'parentId': '500100',
      'name': '黔江区',
      'children': null
    }, {
      'regionId': '500115',
      'parentId': '500100',
      'name': '长寿区',
      'children': null
    }, {
      'regionId': '500116',
      'parentId': '500100',
      'name': '江津区',
      'children': null
    }, {
      'regionId': '500117',
      'parentId': '500100',
      'name': '合川区',
      'children': null
    }, {
      'regionId': '500118',
      'parentId': '500100',
      'name': '永川区',
      'children': null
    }, {
      'regionId': '500119',
      'parentId': '500100',
      'name': '南川区',
      'children': null
    }, {
      'regionId': '500120',
      'parentId': '500100',
      'name': '璧山区',
      'children': null
    }, {
      'regionId': '500151',
      'parentId': '500100',
      'name': '铜梁区',
      'children': null
    }, {
      'regionId': '500152',
      'parentId': '500100',
      'name': '潼南区',
      'children': null
    }, {
      'regionId': '500153',
      'parentId': '500100',
      'name': '荣昌区',
      'children': null
    }, {
      'regionId': '500154',
      'parentId': '500100',
      'name': '开州区',
      'children': null
    }, {
      'regionId': '500155',
      'parentId': '500100',
      'name': '梁平区',
      'children': null
    }, {
      'regionId': '500156',
      'parentId': '500100',
      'name': '武隆区',
      'children': null
    }]
  }, {
    'regionId': '500200',
    'parentId': '500000',
    'name': '县',
    'children': [{
      'regionId': '500229',
      'parentId': '500200',
      'name': '城口县',
      'children': null
    }, {
      'regionId': '500230',
      'parentId': '500200',
      'name': '丰都县',
      'children': null
    }, {
      'regionId': '500231',
      'parentId': '500200',
      'name': '垫江县',
      'children': null
    }, {
      'regionId': '500233',
      'parentId': '500200',
      'name': '忠县',
      'children': null
    }, {
      'regionId': '500235',
      'parentId': '500200',
      'name': '云阳县',
      'children': null
    }, {
      'regionId': '500236',
      'parentId': '500200',
      'name': '奉节县',
      'children': null
    }, {
      'regionId': '500237',
      'parentId': '500200',
      'name': '巫山县',
      'children': null
    }, {
      'regionId': '500238',
      'parentId': '500200',
      'name': '巫溪县',
      'children': null
    }, {
      'regionId': '500240',
      'parentId': '500200',
      'name': '石柱土家族自治县',
      'children': null
    }, {
      'regionId': '500241',
      'parentId': '500200',
      'name': '秀山土家族苗族自治县',
      'children': null
    }, {
      'regionId': '500242',
      'parentId': '500200',
      'name': '酉阳土家族苗族自治县',
      'children': null
    }, {
      'regionId': '500243',
      'parentId': '500200',
      'name': '彭水苗族土家族自治县',
      'children': null
    }]
  }]
}, {
  'regionId': '510000',
  'parentId': '0',
  'name': '四川省',
  'children': [{
    'regionId': '510100',
    'parentId': '510000',
    'name': '成都市',
    'children': [{
      'regionId': '510104',
      'parentId': '510100',
      'name': '锦江区',
      'children': null
    }, {
      'regionId': '510105',
      'parentId': '510100',
      'name': '青羊区',
      'children': null
    }, {
      'regionId': '510106',
      'parentId': '510100',
      'name': '金牛区',
      'children': null
    }, {
      'regionId': '510107',
      'parentId': '510100',
      'name': '武侯区',
      'children': null
    }, {
      'regionId': '510108',
      'parentId': '510100',
      'name': '成华区',
      'children': null
    }, {
      'regionId': '510112',
      'parentId': '510100',
      'name': '龙泉驿区',
      'children': null
    }, {
      'regionId': '510113',
      'parentId': '510100',
      'name': '青白江区',
      'children': null
    }, {
      'regionId': '510114',
      'parentId': '510100',
      'name': '新都区',
      'children': null
    }, {
      'regionId': '510115',
      'parentId': '510100',
      'name': '温江区',
      'children': null
    }, {
      'regionId': '510116',
      'parentId': '510100',
      'name': '双流区',
      'children': null
    }, {
      'regionId': '510117',
      'parentId': '510100',
      'name': '郫都区',
      'children': null
    }, {
      'regionId': '510121',
      'parentId': '510100',
      'name': '金堂县',
      'children': null
    }, {
      'regionId': '510129',
      'parentId': '510100',
      'name': '大邑县',
      'children': null
    }, {
      'regionId': '510131',
      'parentId': '510100',
      'name': '蒲江县',
      'children': null
    }, {
      'regionId': '510181',
      'parentId': '510100',
      'name': '都江堰市',
      'children': null
    }, {
      'regionId': '510182',
      'parentId': '510100',
      'name': '彭州市',
      'children': null
    }, {
      'regionId': '510183',
      'parentId': '510100',
      'name': '邛崃市',
      'children': null
    }, {
      'regionId': '510184',
      'parentId': '510100',
      'name': '崇州市',
      'children': null
    }, {
      'regionId': '510185',
      'parentId': '510100',
      'name': '简阳市',
      'children': null
    }]
  }, {
    'regionId': '510300',
    'parentId': '510000',
    'name': '自贡市',
    'children': [{
      'regionId': '510302',
      'parentId': '510300',
      'name': '自流井区',
      'children': null
    }, {
      'regionId': '510303',
      'parentId': '510300',
      'name': '贡井区',
      'children': null
    }, {
      'regionId': '510304',
      'parentId': '510300',
      'name': '大安区',
      'children': null
    }, {
      'regionId': '510311',
      'parentId': '510300',
      'name': '沿滩区',
      'children': null
    }, {
      'regionId': '510321',
      'parentId': '510300',
      'name': '荣县',
      'children': null
    }, {
      'regionId': '510322',
      'parentId': '510300',
      'name': '富顺县',
      'children': null
    }]
  }, {
    'regionId': '510400',
    'parentId': '510000',
    'name': '攀枝花市',
    'children': [{
      'regionId': '510402',
      'parentId': '510400',
      'name': '东区',
      'children': null
    }, {
      'regionId': '510403',
      'parentId': '510400',
      'name': '西区',
      'children': null
    }, {
      'regionId': '510411',
      'parentId': '510400',
      'name': '仁和区',
      'children': null
    }, {
      'regionId': '510421',
      'parentId': '510400',
      'name': '米易县',
      'children': null
    }, {
      'regionId': '510422',
      'parentId': '510400',
      'name': '盐边县',
      'children': null
    }]
  }, {
    'regionId': '510500',
    'parentId': '510000',
    'name': '泸州市',
    'children': [{
      'regionId': '510502',
      'parentId': '510500',
      'name': '江阳区',
      'children': null
    }, {
      'regionId': '510503',
      'parentId': '510500',
      'name': '纳溪区',
      'children': null
    }, {
      'regionId': '510504',
      'parentId': '510500',
      'name': '龙马潭区',
      'children': null
    }, {
      'regionId': '510521',
      'parentId': '510500',
      'name': '泸县',
      'children': null
    }, {
      'regionId': '510522',
      'parentId': '510500',
      'name': '合江县',
      'children': null
    }, {
      'regionId': '510524',
      'parentId': '510500',
      'name': '叙永县',
      'children': null
    }, {
      'regionId': '510525',
      'parentId': '510500',
      'name': '古蔺县',
      'children': null
    }]
  }, {
    'regionId': '510600',
    'parentId': '510000',
    'name': '德阳市',
    'children': [{
      'regionId': '510603',
      'parentId': '510600',
      'name': '旌阳区',
      'children': null
    }, {
      'regionId': '510604',
      'parentId': '510600',
      'name': '罗江区',
      'children': null
    }, {
      'regionId': '510623',
      'parentId': '510600',
      'name': '中江县',
      'children': null
    }, {
      'regionId': '510681',
      'parentId': '510600',
      'name': '广汉市',
      'children': null
    }, {
      'regionId': '510682',
      'parentId': '510600',
      'name': '什邡市',
      'children': null
    }, {
      'regionId': '510683',
      'parentId': '510600',
      'name': '绵竹市',
      'children': null
    }]
  }, {
    'regionId': '510700',
    'parentId': '510000',
    'name': '绵阳市',
    'children': [{
      'regionId': '510703',
      'parentId': '510700',
      'name': '涪城区',
      'children': null
    }, {
      'regionId': '510704',
      'parentId': '510700',
      'name': '游仙区',
      'children': null
    }, {
      'regionId': '510705',
      'parentId': '510700',
      'name': '安州区',
      'children': null
    }, {
      'regionId': '510722',
      'parentId': '510700',
      'name': '三台县',
      'children': null
    }, {
      'regionId': '510723',
      'parentId': '510700',
      'name': '盐亭县',
      'children': null
    }, {
      'regionId': '510725',
      'parentId': '510700',
      'name': '梓潼县',
      'children': null
    }, {
      'regionId': '510726',
      'parentId': '510700',
      'name': '北川羌族自治县',
      'children': null
    }, {
      'regionId': '510727',
      'parentId': '510700',
      'name': '平武县',
      'children': null
    }, {
      'regionId': '510781',
      'parentId': '510700',
      'name': '江油市',
      'children': null
    }]
  }, {
    'regionId': '510800',
    'parentId': '510000',
    'name': '广元市',
    'children': [{
      'regionId': '510802',
      'parentId': '510800',
      'name': '利州区',
      'children': null
    }, {
      'regionId': '510811',
      'parentId': '510800',
      'name': '昭化区',
      'children': null
    }, {
      'regionId': '510812',
      'parentId': '510800',
      'name': '朝天区',
      'children': null
    }, {
      'regionId': '510821',
      'parentId': '510800',
      'name': '旺苍县',
      'children': null
    }, {
      'regionId': '510822',
      'parentId': '510800',
      'name': '青川县',
      'children': null
    }, {
      'regionId': '510823',
      'parentId': '510800',
      'name': '剑阁县',
      'children': null
    }, {
      'regionId': '510824',
      'parentId': '510800',
      'name': '苍溪县',
      'children': null
    }]
  }, {
    'regionId': '510900',
    'parentId': '510000',
    'name': '遂宁市',
    'children': [{
      'regionId': '510903',
      'parentId': '510900',
      'name': '船山区',
      'children': null
    }, {
      'regionId': '510904',
      'parentId': '510900',
      'name': '安居区',
      'children': null
    }, {
      'regionId': '510921',
      'parentId': '510900',
      'name': '蓬溪县',
      'children': null
    }, {
      'regionId': '510923',
      'parentId': '510900',
      'name': '大英县',
      'children': null
    }, {
      'regionId': '510981',
      'parentId': '510900',
      'name': '射洪市',
      'children': null
    }]
  }, {
    'regionId': '511000',
    'parentId': '510000',
    'name': '内江市',
    'children': [{
      'regionId': '511002',
      'parentId': '511000',
      'name': '市中区',
      'children': null
    }, {
      'regionId': '511011',
      'parentId': '511000',
      'name': '东兴区',
      'children': null
    }, {
      'regionId': '511024',
      'parentId': '511000',
      'name': '威远县',
      'children': null
    }, {
      'regionId': '511025',
      'parentId': '511000',
      'name': '资中县',
      'children': null
    }, {
      'regionId': '511083',
      'parentId': '511000',
      'name': '隆昌市',
      'children': null
    }]
  }, {
    'regionId': '511100',
    'parentId': '510000',
    'name': '乐山市',
    'children': [{
      'regionId': '511102',
      'parentId': '511100',
      'name': '市中区',
      'children': null
    }, {
      'regionId': '511111',
      'parentId': '511100',
      'name': '沙湾区',
      'children': null
    }, {
      'regionId': '511112',
      'parentId': '511100',
      'name': '五通桥区',
      'children': null
    }, {
      'regionId': '511113',
      'parentId': '511100',
      'name': '金口河区',
      'children': null
    }, {
      'regionId': '511123',
      'parentId': '511100',
      'name': '犍为县',
      'children': null
    }, {
      'regionId': '511124',
      'parentId': '511100',
      'name': '井研县',
      'children': null
    }, {
      'regionId': '511126',
      'parentId': '511100',
      'name': '夹江县',
      'children': null
    }, {
      'regionId': '511129',
      'parentId': '511100',
      'name': '沐川县',
      'children': null
    }, {
      'regionId': '511132',
      'parentId': '511100',
      'name': '峨边彝族自治县',
      'children': null
    }, {
      'regionId': '511133',
      'parentId': '511100',
      'name': '马边彝族自治县',
      'children': null
    }, {
      'regionId': '511181',
      'parentId': '511100',
      'name': '峨眉山市',
      'children': null
    }]
  }, {
    'regionId': '511300',
    'parentId': '510000',
    'name': '南充市',
    'children': [{
      'regionId': '511302',
      'parentId': '511300',
      'name': '顺庆区',
      'children': null
    }, {
      'regionId': '511303',
      'parentId': '511300',
      'name': '高坪区',
      'children': null
    }, {
      'regionId': '511304',
      'parentId': '511300',
      'name': '嘉陵区',
      'children': null
    }, {
      'regionId': '511321',
      'parentId': '511300',
      'name': '南部县',
      'children': null
    }, {
      'regionId': '511322',
      'parentId': '511300',
      'name': '营山县',
      'children': null
    }, {
      'regionId': '511323',
      'parentId': '511300',
      'name': '蓬安县',
      'children': null
    }, {
      'regionId': '511324',
      'parentId': '511300',
      'name': '仪陇县',
      'children': null
    }, {
      'regionId': '511325',
      'parentId': '511300',
      'name': '西充县',
      'children': null
    }, {
      'regionId': '511381',
      'parentId': '511300',
      'name': '阆中市',
      'children': null
    }]
  }, {
    'regionId': '511400',
    'parentId': '510000',
    'name': '眉山市',
    'children': [{
      'regionId': '511402',
      'parentId': '511400',
      'name': '东坡区',
      'children': null
    }, {
      'regionId': '511403',
      'parentId': '511400',
      'name': '彭山区',
      'children': null
    }, {
      'regionId': '511421',
      'parentId': '511400',
      'name': '仁寿县',
      'children': null
    }, {
      'regionId': '511423',
      'parentId': '511400',
      'name': '洪雅县',
      'children': null
    }, {
      'regionId': '511424',
      'parentId': '511400',
      'name': '丹棱县',
      'children': null
    }, {
      'regionId': '511425',
      'parentId': '511400',
      'name': '青神县',
      'children': null
    }]
  }, {
    'regionId': '511500',
    'parentId': '510000',
    'name': '宜宾市',
    'children': [{
      'regionId': '511502',
      'parentId': '511500',
      'name': '翠屏区',
      'children': null
    }, {
      'regionId': '511503',
      'parentId': '511500',
      'name': '南溪区',
      'children': null
    }, {
      'regionId': '511504',
      'parentId': '511500',
      'name': '叙州区',
      'children': null
    }, {
      'regionId': '511523',
      'parentId': '511500',
      'name': '江安县',
      'children': null
    }, {
      'regionId': '511524',
      'parentId': '511500',
      'name': '长宁县',
      'children': null
    }, {
      'regionId': '511525',
      'parentId': '511500',
      'name': '高县',
      'children': null
    }, {
      'regionId': '511526',
      'parentId': '511500',
      'name': '珙县',
      'children': null
    }, {
      'regionId': '511527',
      'parentId': '511500',
      'name': '筠连县',
      'children': null
    }, {
      'regionId': '511528',
      'parentId': '511500',
      'name': '兴文县',
      'children': null
    }, {
      'regionId': '511529',
      'parentId': '511500',
      'name': '屏山县',
      'children': null
    }]
  }, {
    'regionId': '511600',
    'parentId': '510000',
    'name': '广安市',
    'children': [{
      'regionId': '511602',
      'parentId': '511600',
      'name': '广安区',
      'children': null
    }, {
      'regionId': '511603',
      'parentId': '511600',
      'name': '前锋区',
      'children': null
    }, {
      'regionId': '511621',
      'parentId': '511600',
      'name': '岳池县',
      'children': null
    }, {
      'regionId': '511622',
      'parentId': '511600',
      'name': '武胜县',
      'children': null
    }, {
      'regionId': '511623',
      'parentId': '511600',
      'name': '邻水县',
      'children': null
    }, {
      'regionId': '511681',
      'parentId': '511600',
      'name': '华蓥市',
      'children': null
    }]
  }, {
    'regionId': '511700',
    'parentId': '510000',
    'name': '达州市',
    'children': [{
      'regionId': '511702',
      'parentId': '511700',
      'name': '通川区',
      'children': null
    }, {
      'regionId': '511703',
      'parentId': '511700',
      'name': '达川区',
      'children': null
    }, {
      'regionId': '511722',
      'parentId': '511700',
      'name': '宣汉县',
      'children': null
    }, {
      'regionId': '511723',
      'parentId': '511700',
      'name': '开江县',
      'children': null
    }, {
      'regionId': '511724',
      'parentId': '511700',
      'name': '大竹县',
      'children': null
    }, {
      'regionId': '511725',
      'parentId': '511700',
      'name': '渠县',
      'children': null
    }, {
      'regionId': '511781',
      'parentId': '511700',
      'name': '万源市',
      'children': null
    }]
  }, {
    'regionId': '511800',
    'parentId': '510000',
    'name': '雅安市',
    'children': [{
      'regionId': '511802',
      'parentId': '511800',
      'name': '雨城区',
      'children': null
    }, {
      'regionId': '511803',
      'parentId': '511800',
      'name': '名山区',
      'children': null
    }, {
      'regionId': '511822',
      'parentId': '511800',
      'name': '荥经县',
      'children': null
    }, {
      'regionId': '511823',
      'parentId': '511800',
      'name': '汉源县',
      'children': null
    }, {
      'regionId': '511824',
      'parentId': '511800',
      'name': '石棉县',
      'children': null
    }, {
      'regionId': '511825',
      'parentId': '511800',
      'name': '天全县',
      'children': null
    }, {
      'regionId': '511826',
      'parentId': '511800',
      'name': '芦山县',
      'children': null
    }, {
      'regionId': '511827',
      'parentId': '511800',
      'name': '宝兴县',
      'children': null
    }]
  }, {
    'regionId': '511900',
    'parentId': '510000',
    'name': '巴中市',
    'children': [{
      'regionId': '511902',
      'parentId': '511900',
      'name': '巴州区',
      'children': null
    }, {
      'regionId': '511903',
      'parentId': '511900',
      'name': '恩阳区',
      'children': null
    }, {
      'regionId': '511921',
      'parentId': '511900',
      'name': '通江县',
      'children': null
    }, {
      'regionId': '511922',
      'parentId': '511900',
      'name': '南江县',
      'children': null
    }, {
      'regionId': '511923',
      'parentId': '511900',
      'name': '平昌县',
      'children': null
    }]
  }, {
    'regionId': '512000',
    'parentId': '510000',
    'name': '资阳市',
    'children': [{
      'regionId': '512002',
      'parentId': '512000',
      'name': '雁江区',
      'children': null
    }, {
      'regionId': '512021',
      'parentId': '512000',
      'name': '安岳县',
      'children': null
    }, {
      'regionId': '512022',
      'parentId': '512000',
      'name': '乐至县',
      'children': null
    }]
  }, {
    'regionId': '513200',
    'parentId': '510000',
    'name': '阿坝藏族羌族自治州',
    'children': [{
      'regionId': '513201',
      'parentId': '513200',
      'name': '马尔康市',
      'children': null
    }, {
      'regionId': '513221',
      'parentId': '513200',
      'name': '汶川县',
      'children': null
    }, {
      'regionId': '513222',
      'parentId': '513200',
      'name': '理县',
      'children': null
    }, {
      'regionId': '513223',
      'parentId': '513200',
      'name': '茂县',
      'children': null
    }, {
      'regionId': '513224',
      'parentId': '513200',
      'name': '松潘县',
      'children': null
    }, {
      'regionId': '513225',
      'parentId': '513200',
      'name': '九寨沟县',
      'children': null
    }, {
      'regionId': '513226',
      'parentId': '513200',
      'name': '金川县',
      'children': null
    }, {
      'regionId': '513227',
      'parentId': '513200',
      'name': '小金县',
      'children': null
    }, {
      'regionId': '513228',
      'parentId': '513200',
      'name': '黑水县',
      'children': null
    }, {
      'regionId': '513230',
      'parentId': '513200',
      'name': '壤塘县',
      'children': null
    }, {
      'regionId': '513231',
      'parentId': '513200',
      'name': '阿坝县',
      'children': null
    }, {
      'regionId': '513232',
      'parentId': '513200',
      'name': '若尔盖县',
      'children': null
    }, {
      'regionId': '513233',
      'parentId': '513200',
      'name': '红原县',
      'children': null
    }]
  }, {
    'regionId': '513300',
    'parentId': '510000',
    'name': '甘孜藏族自治州',
    'children': [{
      'regionId': '513301',
      'parentId': '513300',
      'name': '康定市',
      'children': null
    }, {
      'regionId': '513322',
      'parentId': '513300',
      'name': '泸定县',
      'children': null
    }, {
      'regionId': '513323',
      'parentId': '513300',
      'name': '丹巴县',
      'children': null
    }, {
      'regionId': '513324',
      'parentId': '513300',
      'name': '九龙县',
      'children': null
    }, {
      'regionId': '513325',
      'parentId': '513300',
      'name': '雅江县',
      'children': null
    }, {
      'regionId': '513326',
      'parentId': '513300',
      'name': '道孚县',
      'children': null
    }, {
      'regionId': '513327',
      'parentId': '513300',
      'name': '炉霍县',
      'children': null
    }, {
      'regionId': '513328',
      'parentId': '513300',
      'name': '甘孜县',
      'children': null
    }, {
      'regionId': '513329',
      'parentId': '513300',
      'name': '新龙县',
      'children': null
    }, {
      'regionId': '513330',
      'parentId': '513300',
      'name': '德格县',
      'children': null
    }, {
      'regionId': '513331',
      'parentId': '513300',
      'name': '白玉县',
      'children': null
    }, {
      'regionId': '513332',
      'parentId': '513300',
      'name': '石渠县',
      'children': null
    }, {
      'regionId': '513333',
      'parentId': '513300',
      'name': '色达县',
      'children': null
    }, {
      'regionId': '513334',
      'parentId': '513300',
      'name': '理塘县',
      'children': null
    }, {
      'regionId': '513335',
      'parentId': '513300',
      'name': '巴塘县',
      'children': null
    }, {
      'regionId': '513336',
      'parentId': '513300',
      'name': '乡城县',
      'children': null
    }, {
      'regionId': '513337',
      'parentId': '513300',
      'name': '稻城县',
      'children': null
    }, {
      'regionId': '513338',
      'parentId': '513300',
      'name': '得荣县',
      'children': null
    }]
  }, {
    'regionId': '513400',
    'parentId': '510000',
    'name': '凉山彝族自治州',
    'children': [{
      'regionId': '513401',
      'parentId': '513400',
      'name': '西昌市',
      'children': null
    }, {
      'regionId': '513422',
      'parentId': '513400',
      'name': '木里藏族自治县',
      'children': null
    }, {
      'regionId': '513423',
      'parentId': '513400',
      'name': '盐源县',
      'children': null
    }, {
      'regionId': '513424',
      'parentId': '513400',
      'name': '德昌县',
      'children': null
    }, {
      'regionId': '513425',
      'parentId': '513400',
      'name': '会理县',
      'children': null
    }, {
      'regionId': '513426',
      'parentId': '513400',
      'name': '会东县',
      'children': null
    }, {
      'regionId': '513427',
      'parentId': '513400',
      'name': '宁南县',
      'children': null
    }, {
      'regionId': '513428',
      'parentId': '513400',
      'name': '普格县',
      'children': null
    }, {
      'regionId': '513429',
      'parentId': '513400',
      'name': '布拖县',
      'children': null
    }, {
      'regionId': '513430',
      'parentId': '513400',
      'name': '金阳县',
      'children': null
    }, {
      'regionId': '513431',
      'parentId': '513400',
      'name': '昭觉县',
      'children': null
    }, {
      'regionId': '513432',
      'parentId': '513400',
      'name': '喜德县',
      'children': null
    }, {
      'regionId': '513433',
      'parentId': '513400',
      'name': '冕宁县',
      'children': null
    }, {
      'regionId': '513434',
      'parentId': '513400',
      'name': '越西县',
      'children': null
    }, {
      'regionId': '513435',
      'parentId': '513400',
      'name': '甘洛县',
      'children': null
    }, {
      'regionId': '513436',
      'parentId': '513400',
      'name': '美姑县',
      'children': null
    }, {
      'regionId': '513437',
      'parentId': '513400',
      'name': '雷波县',
      'children': null
    }]
  }]
}, {
  'regionId': '520000',
  'parentId': '0',
  'name': '贵州省',
  'children': [{
    'regionId': '520100',
    'parentId': '520000',
    'name': '贵阳市',
    'children': [{
      'regionId': '520102',
      'parentId': '520100',
      'name': '南明区',
      'children': null
    }, {
      'regionId': '520103',
      'parentId': '520100',
      'name': '云岩区',
      'children': null
    }, {
      'regionId': '520111',
      'parentId': '520100',
      'name': '花溪区',
      'children': null
    }, {
      'regionId': '520112',
      'parentId': '520100',
      'name': '乌当区',
      'children': null
    }, {
      'regionId': '520113',
      'parentId': '520100',
      'name': '白云区',
      'children': null
    }, {
      'regionId': '520115',
      'parentId': '520100',
      'name': '观山湖区',
      'children': null
    }, {
      'regionId': '520121',
      'parentId': '520100',
      'name': '开阳县',
      'children': null
    }, {
      'regionId': '520122',
      'parentId': '520100',
      'name': '息烽县',
      'children': null
    }, {
      'regionId': '520123',
      'parentId': '520100',
      'name': '修文县',
      'children': null
    }, {
      'regionId': '520181',
      'parentId': '520100',
      'name': '清镇市',
      'children': null
    }]
  }, {
    'regionId': '520200',
    'parentId': '520000',
    'name': '六盘水市',
    'children': [{
      'regionId': '520201',
      'parentId': '520200',
      'name': '钟山区',
      'children': null
    }, {
      'regionId': '520203',
      'parentId': '520200',
      'name': '六枝特区',
      'children': null
    }, {
      'regionId': '520281',
      'parentId': '520200',
      'name': '盘州市',
      'children': null
    }]
  }, {
    'regionId': '520300',
    'parentId': '520000',
    'name': '遵义市',
    'children': [{
      'regionId': '520302',
      'parentId': '520300',
      'name': '红花岗区',
      'children': null
    }, {
      'regionId': '520303',
      'parentId': '520300',
      'name': '汇川区',
      'children': null
    }, {
      'regionId': '520304',
      'parentId': '520300',
      'name': '播州区',
      'children': null
    }, {
      'regionId': '520322',
      'parentId': '520300',
      'name': '桐梓县',
      'children': null
    }, {
      'regionId': '520323',
      'parentId': '520300',
      'name': '绥阳县',
      'children': null
    }, {
      'regionId': '520324',
      'parentId': '520300',
      'name': '正安县',
      'children': null
    }, {
      'regionId': '520325',
      'parentId': '520300',
      'name': '道真仡佬族苗族自治县',
      'children': null
    }, {
      'regionId': '520326',
      'parentId': '520300',
      'name': '务川仡佬族苗族自治县',
      'children': null
    }, {
      'regionId': '520327',
      'parentId': '520300',
      'name': '凤冈县',
      'children': null
    }, {
      'regionId': '520328',
      'parentId': '520300',
      'name': '湄潭县',
      'children': null
    }, {
      'regionId': '520329',
      'parentId': '520300',
      'name': '余庆县',
      'children': null
    }, {
      'regionId': '520330',
      'parentId': '520300',
      'name': '习水县',
      'children': null
    }, {
      'regionId': '520381',
      'parentId': '520300',
      'name': '赤水市',
      'children': null
    }, {
      'regionId': '520382',
      'parentId': '520300',
      'name': '仁怀市',
      'children': null
    }]
  }, {
    'regionId': '520400',
    'parentId': '520000',
    'name': '安顺市',
    'children': [{
      'regionId': '520402',
      'parentId': '520400',
      'name': '西秀区',
      'children': null
    }, {
      'regionId': '520403',
      'parentId': '520400',
      'name': '平坝区',
      'children': null
    }, {
      'regionId': '520422',
      'parentId': '520400',
      'name': '普定县',
      'children': null
    }, {
      'regionId': '520423',
      'parentId': '520400',
      'name': '镇宁布依族苗族自治县',
      'children': null
    }, {
      'regionId': '520424',
      'parentId': '520400',
      'name': '关岭布依族苗族自治县',
      'children': null
    }, {
      'regionId': '520425',
      'parentId': '520400',
      'name': '紫云苗族布依族自治县',
      'children': null
    }]
  }, {
    'regionId': '520500',
    'parentId': '520000',
    'name': '毕节市',
    'children': [{
      'regionId': '520502',
      'parentId': '520500',
      'name': '七星关区',
      'children': null
    }, {
      'regionId': '520521',
      'parentId': '520500',
      'name': '大方县',
      'children': null
    }, {
      'regionId': '520522',
      'parentId': '520500',
      'name': '黔西县',
      'children': null
    }, {
      'regionId': '520523',
      'parentId': '520500',
      'name': '金沙县',
      'children': null
    }, {
      'regionId': '520524',
      'parentId': '520500',
      'name': '织金县',
      'children': null
    }, {
      'regionId': '520525',
      'parentId': '520500',
      'name': '纳雍县',
      'children': null
    }, {
      'regionId': '520526',
      'parentId': '520500',
      'name': '威宁彝族回族苗族自治县',
      'children': null
    }, {
      'regionId': '520527',
      'parentId': '520500',
      'name': '赫章县',
      'children': null
    }]
  }, {
    'regionId': '520600',
    'parentId': '520000',
    'name': '铜仁市',
    'children': [{
      'regionId': '520602',
      'parentId': '520600',
      'name': '碧江区',
      'children': null
    }, {
      'regionId': '520603',
      'parentId': '520600',
      'name': '万山区',
      'children': null
    }, {
      'regionId': '520621',
      'parentId': '520600',
      'name': '江口县',
      'children': null
    }, {
      'regionId': '520622',
      'parentId': '520600',
      'name': '玉屏侗族自治县',
      'children': null
    }, {
      'regionId': '520623',
      'parentId': '520600',
      'name': '石阡县',
      'children': null
    }, {
      'regionId': '520624',
      'parentId': '520600',
      'name': '思南县',
      'children': null
    }, {
      'regionId': '520625',
      'parentId': '520600',
      'name': '印江土家族苗族自治县',
      'children': null
    }, {
      'regionId': '520626',
      'parentId': '520600',
      'name': '德江县',
      'children': null
    }, {
      'regionId': '520627',
      'parentId': '520600',
      'name': '沿河土家族自治县',
      'children': null
    }, {
      'regionId': '520628',
      'parentId': '520600',
      'name': '松桃苗族自治县',
      'children': null
    }]
  }, {
    'regionId': '522300',
    'parentId': '520000',
    'name': '黔西南布依族苗族自治州',
    'children': [{
      'regionId': '522301',
      'parentId': '522300',
      'name': '兴义市',
      'children': null
    }, {
      'regionId': '522302',
      'parentId': '522300',
      'name': '兴仁市',
      'children': null
    }, {
      'regionId': '522323',
      'parentId': '522300',
      'name': '普安县',
      'children': null
    }, {
      'regionId': '522324',
      'parentId': '522300',
      'name': '晴隆县',
      'children': null
    }, {
      'regionId': '522325',
      'parentId': '522300',
      'name': '贞丰县',
      'children': null
    }, {
      'regionId': '522326',
      'parentId': '522300',
      'name': '望谟县',
      'children': null
    }, {
      'regionId': '522327',
      'parentId': '522300',
      'name': '册亨县',
      'children': null
    }, {
      'regionId': '522328',
      'parentId': '522300',
      'name': '安龙县',
      'children': null
    }]
  }, {
    'regionId': '522600',
    'parentId': '520000',
    'name': '黔东南苗族侗族自治州',
    'children': [{
      'regionId': '522601',
      'parentId': '522600',
      'name': '凯里市',
      'children': null
    }, {
      'regionId': '522622',
      'parentId': '522600',
      'name': '黄平县',
      'children': null
    }, {
      'regionId': '522623',
      'parentId': '522600',
      'name': '施秉县',
      'children': null
    }, {
      'regionId': '522624',
      'parentId': '522600',
      'name': '三穗县',
      'children': null
    }, {
      'regionId': '522625',
      'parentId': '522600',
      'name': '镇远县',
      'children': null
    }, {
      'regionId': '522626',
      'parentId': '522600',
      'name': '岑巩县',
      'children': null
    }, {
      'regionId': '522627',
      'parentId': '522600',
      'name': '天柱县',
      'children': null
    }, {
      'regionId': '522628',
      'parentId': '522600',
      'name': '锦屏县',
      'children': null
    }, {
      'regionId': '522629',
      'parentId': '522600',
      'name': '剑河县',
      'children': null
    }, {
      'regionId': '522630',
      'parentId': '522600',
      'name': '台江县',
      'children': null
    }, {
      'regionId': '522631',
      'parentId': '522600',
      'name': '黎平县',
      'children': null
    }, {
      'regionId': '522632',
      'parentId': '522600',
      'name': '榕江县',
      'children': null
    }, {
      'regionId': '522633',
      'parentId': '522600',
      'name': '从江县',
      'children': null
    }, {
      'regionId': '522634',
      'parentId': '522600',
      'name': '雷山县',
      'children': null
    }, {
      'regionId': '522635',
      'parentId': '522600',
      'name': '麻江县',
      'children': null
    }, {
      'regionId': '522636',
      'parentId': '522600',
      'name': '丹寨县',
      'children': null
    }]
  }, {
    'regionId': '522700',
    'parentId': '520000',
    'name': '黔南布依族苗族自治州',
    'children': [{
      'regionId': '522701',
      'parentId': '522700',
      'name': '都匀市',
      'children': null
    }, {
      'regionId': '522702',
      'parentId': '522700',
      'name': '福泉市',
      'children': null
    }, {
      'regionId': '522722',
      'parentId': '522700',
      'name': '荔波县',
      'children': null
    }, {
      'regionId': '522723',
      'parentId': '522700',
      'name': '贵定县',
      'children': null
    }, {
      'regionId': '522725',
      'parentId': '522700',
      'name': '瓮安县',
      'children': null
    }, {
      'regionId': '522726',
      'parentId': '522700',
      'name': '独山县',
      'children': null
    }, {
      'regionId': '522727',
      'parentId': '522700',
      'name': '平塘县',
      'children': null
    }, {
      'regionId': '522728',
      'parentId': '522700',
      'name': '罗甸县',
      'children': null
    }, {
      'regionId': '522729',
      'parentId': '522700',
      'name': '长顺县',
      'children': null
    }, {
      'regionId': '522730',
      'parentId': '522700',
      'name': '龙里县',
      'children': null
    }, {
      'regionId': '522731',
      'parentId': '522700',
      'name': '惠水县',
      'children': null
    }, {
      'regionId': '522732',
      'parentId': '522700',
      'name': '三都水族自治县',
      'children': null
    }]
  }]
}, {
  'regionId': '530000',
  'parentId': '0',
  'name': '云南省',
  'children': [{
    'regionId': '530100',
    'parentId': '530000',
    'name': '昆明市',
    'children': [{
      'regionId': '530102',
      'parentId': '530100',
      'name': '五华区',
      'children': null
    }, {
      'regionId': '530103',
      'parentId': '530100',
      'name': '盘龙区',
      'children': null
    }, {
      'regionId': '530111',
      'parentId': '530100',
      'name': '官渡区',
      'children': null
    }, {
      'regionId': '530112',
      'parentId': '530100',
      'name': '西山区',
      'children': null
    }, {
      'regionId': '530113',
      'parentId': '530100',
      'name': '东川区',
      'children': null
    }, {
      'regionId': '530114',
      'parentId': '530100',
      'name': '呈贡区',
      'children': null
    }, {
      'regionId': '530115',
      'parentId': '530100',
      'name': '晋宁区',
      'children': null
    }, {
      'regionId': '530124',
      'parentId': '530100',
      'name': '富民县',
      'children': null
    }, {
      'regionId': '530125',
      'parentId': '530100',
      'name': '宜良县',
      'children': null
    }, {
      'regionId': '530126',
      'parentId': '530100',
      'name': '石林彝族自治县',
      'children': null
    }, {
      'regionId': '530127',
      'parentId': '530100',
      'name': '嵩明县',
      'children': null
    }, {
      'regionId': '530128',
      'parentId': '530100',
      'name': '禄劝彝族苗族自治县',
      'children': null
    }, {
      'regionId': '530129',
      'parentId': '530100',
      'name': '寻甸回族彝族自治县',
      'children': null
    }, {
      'regionId': '530181',
      'parentId': '530100',
      'name': '安宁市',
      'children': null
    }]
  }, {
    'regionId': '530300',
    'parentId': '530000',
    'name': '曲靖市',
    'children': [{
      'regionId': '530302',
      'parentId': '530300',
      'name': '麒麟区',
      'children': null
    }, {
      'regionId': '530303',
      'parentId': '530300',
      'name': '沾益区',
      'children': null
    }, {
      'regionId': '530304',
      'parentId': '530300',
      'name': '马龙区',
      'children': null
    }, {
      'regionId': '530322',
      'parentId': '530300',
      'name': '陆良县',
      'children': null
    }, {
      'regionId': '530323',
      'parentId': '530300',
      'name': '师宗县',
      'children': null
    }, {
      'regionId': '530324',
      'parentId': '530300',
      'name': '罗平县',
      'children': null
    }, {
      'regionId': '530325',
      'parentId': '530300',
      'name': '富源县',
      'children': null
    }, {
      'regionId': '530326',
      'parentId': '530300',
      'name': '会泽县',
      'children': null
    }, {
      'regionId': '530381',
      'parentId': '530300',
      'name': '宣威市',
      'children': null
    }]
  }, {
    'regionId': '530400',
    'parentId': '530000',
    'name': '玉溪市',
    'children': [{
      'regionId': '530402',
      'parentId': '530400',
      'name': '红塔区',
      'children': null
    }, {
      'regionId': '530403',
      'parentId': '530400',
      'name': '江川区',
      'children': null
    }, {
      'regionId': '530423',
      'parentId': '530400',
      'name': '通海县',
      'children': null
    }, {
      'regionId': '530424',
      'parentId': '530400',
      'name': '华宁县',
      'children': null
    }, {
      'regionId': '530425',
      'parentId': '530400',
      'name': '易门县',
      'children': null
    }, {
      'regionId': '530426',
      'parentId': '530400',
      'name': '峨山彝族自治县',
      'children': null
    }, {
      'regionId': '530427',
      'parentId': '530400',
      'name': '新平彝族傣族自治县',
      'children': null
    }, {
      'regionId': '530428',
      'parentId': '530400',
      'name': '元江哈尼族彝族傣族自治县',
      'children': null
    }, {
      'regionId': '530481',
      'parentId': '530400',
      'name': '澄江市',
      'children': null
    }]
  }, {
    'regionId': '530500',
    'parentId': '530000',
    'name': '保山市',
    'children': [{
      'regionId': '530502',
      'parentId': '530500',
      'name': '隆阳区',
      'children': null
    }, {
      'regionId': '530521',
      'parentId': '530500',
      'name': '施甸县',
      'children': null
    }, {
      'regionId': '530523',
      'parentId': '530500',
      'name': '龙陵县',
      'children': null
    }, {
      'regionId': '530524',
      'parentId': '530500',
      'name': '昌宁县',
      'children': null
    }, {
      'regionId': '530581',
      'parentId': '530500',
      'name': '腾冲市',
      'children': null
    }]
  }, {
    'regionId': '530600',
    'parentId': '530000',
    'name': '昭通市',
    'children': [{
      'regionId': '530602',
      'parentId': '530600',
      'name': '昭阳区',
      'children': null
    }, {
      'regionId': '530621',
      'parentId': '530600',
      'name': '鲁甸县',
      'children': null
    }, {
      'regionId': '530622',
      'parentId': '530600',
      'name': '巧家县',
      'children': null
    }, {
      'regionId': '530623',
      'parentId': '530600',
      'name': '盐津县',
      'children': null
    }, {
      'regionId': '530624',
      'parentId': '530600',
      'name': '大关县',
      'children': null
    }, {
      'regionId': '530625',
      'parentId': '530600',
      'name': '永善县',
      'children': null
    }, {
      'regionId': '530626',
      'parentId': '530600',
      'name': '绥江县',
      'children': null
    }, {
      'regionId': '530627',
      'parentId': '530600',
      'name': '镇雄县',
      'children': null
    }, {
      'regionId': '530628',
      'parentId': '530600',
      'name': '彝良县',
      'children': null
    }, {
      'regionId': '530629',
      'parentId': '530600',
      'name': '威信县',
      'children': null
    }, {
      'regionId': '530681',
      'parentId': '530600',
      'name': '水富市',
      'children': null
    }]
  }, {
    'regionId': '530700',
    'parentId': '530000',
    'name': '丽江市',
    'children': [{
      'regionId': '530702',
      'parentId': '530700',
      'name': '古城区',
      'children': null
    }, {
      'regionId': '530721',
      'parentId': '530700',
      'name': '玉龙纳西族自治县',
      'children': null
    }, {
      'regionId': '530722',
      'parentId': '530700',
      'name': '永胜县',
      'children': null
    }, {
      'regionId': '530723',
      'parentId': '530700',
      'name': '华坪县',
      'children': null
    }, {
      'regionId': '530724',
      'parentId': '530700',
      'name': '宁蒗彝族自治县',
      'children': null
    }]
  }, {
    'regionId': '530800',
    'parentId': '530000',
    'name': '普洱市',
    'children': [{
      'regionId': '530802',
      'parentId': '530800',
      'name': '思茅区',
      'children': null
    }, {
      'regionId': '530821',
      'parentId': '530800',
      'name': '宁洱哈尼族彝族自治县',
      'children': null
    }, {
      'regionId': '530822',
      'parentId': '530800',
      'name': '墨江哈尼族自治县',
      'children': null
    }, {
      'regionId': '530823',
      'parentId': '530800',
      'name': '景东彝族自治县',
      'children': null
    }, {
      'regionId': '530824',
      'parentId': '530800',
      'name': '景谷傣族彝族自治县',
      'children': null
    }, {
      'regionId': '530825',
      'parentId': '530800',
      'name': '镇沅彝族哈尼族拉祜族自治县',
      'children': null
    }, {
      'regionId': '530826',
      'parentId': '530800',
      'name': '江城哈尼族彝族自治县',
      'children': null
    }, {
      'regionId': '530827',
      'parentId': '530800',
      'name': '孟连傣族拉祜族佤族自治县',
      'children': null
    }, {
      'regionId': '530828',
      'parentId': '530800',
      'name': '澜沧拉祜族自治县',
      'children': null
    }, {
      'regionId': '530829',
      'parentId': '530800',
      'name': '西盟佤族自治县',
      'children': null
    }]
  }, {
    'regionId': '530900',
    'parentId': '530000',
    'name': '临沧市',
    'children': [{
      'regionId': '530902',
      'parentId': '530900',
      'name': '临翔区',
      'children': null
    }, {
      'regionId': '530921',
      'parentId': '530900',
      'name': '凤庆县',
      'children': null
    }, {
      'regionId': '530922',
      'parentId': '530900',
      'name': '云县',
      'children': null
    }, {
      'regionId': '530923',
      'parentId': '530900',
      'name': '永德县',
      'children': null
    }, {
      'regionId': '530924',
      'parentId': '530900',
      'name': '镇康县',
      'children': null
    }, {
      'regionId': '530925',
      'parentId': '530900',
      'name': '双江拉祜族佤族布朗族傣族自治县',
      'children': null
    }, {
      'regionId': '530926',
      'parentId': '530900',
      'name': '耿马傣族佤族自治县',
      'children': null
    }, {
      'regionId': '530927',
      'parentId': '530900',
      'name': '沧源佤族自治县',
      'children': null
    }]
  }, {
    'regionId': '532300',
    'parentId': '530000',
    'name': '楚雄彝族自治州',
    'children': [{
      'regionId': '532301',
      'parentId': '532300',
      'name': '楚雄市',
      'children': null
    }, {
      'regionId': '532322',
      'parentId': '532300',
      'name': '双柏县',
      'children': null
    }, {
      'regionId': '532323',
      'parentId': '532300',
      'name': '牟定县',
      'children': null
    }, {
      'regionId': '532324',
      'parentId': '532300',
      'name': '南华县',
      'children': null
    }, {
      'regionId': '532325',
      'parentId': '532300',
      'name': '姚安县',
      'children': null
    }, {
      'regionId': '532326',
      'parentId': '532300',
      'name': '大姚县',
      'children': null
    }, {
      'regionId': '532327',
      'parentId': '532300',
      'name': '永仁县',
      'children': null
    }, {
      'regionId': '532328',
      'parentId': '532300',
      'name': '元谋县',
      'children': null
    }, {
      'regionId': '532329',
      'parentId': '532300',
      'name': '武定县',
      'children': null
    }, {
      'regionId': '532331',
      'parentId': '532300',
      'name': '禄丰县',
      'children': null
    }]
  }, {
    'regionId': '532500',
    'parentId': '530000',
    'name': '红河哈尼族彝族自治州',
    'children': [{
      'regionId': '532501',
      'parentId': '532500',
      'name': '个旧市',
      'children': null
    }, {
      'regionId': '532502',
      'parentId': '532500',
      'name': '开远市',
      'children': null
    }, {
      'regionId': '532503',
      'parentId': '532500',
      'name': '蒙自市',
      'children': null
    }, {
      'regionId': '532504',
      'parentId': '532500',
      'name': '弥勒市',
      'children': null
    }, {
      'regionId': '532523',
      'parentId': '532500',
      'name': '屏边苗族自治县',
      'children': null
    }, {
      'regionId': '532524',
      'parentId': '532500',
      'name': '建水县',
      'children': null
    }, {
      'regionId': '532525',
      'parentId': '532500',
      'name': '石屏县',
      'children': null
    }, {
      'regionId': '532527',
      'parentId': '532500',
      'name': '泸西县',
      'children': null
    }, {
      'regionId': '532528',
      'parentId': '532500',
      'name': '元阳县',
      'children': null
    }, {
      'regionId': '532529',
      'parentId': '532500',
      'name': '红河县',
      'children': null
    }, {
      'regionId': '532530',
      'parentId': '532500',
      'name': '金平苗族瑶族傣族自治县',
      'children': null
    }, {
      'regionId': '532531',
      'parentId': '532500',
      'name': '绿春县',
      'children': null
    }, {
      'regionId': '532532',
      'parentId': '532500',
      'name': '河口瑶族自治县',
      'children': null
    }]
  }, {
    'regionId': '532600',
    'parentId': '530000',
    'name': '文山壮族苗族自治州',
    'children': [{
      'regionId': '532601',
      'parentId': '532600',
      'name': '文山市',
      'children': null
    }, {
      'regionId': '532622',
      'parentId': '532600',
      'name': '砚山县',
      'children': null
    }, {
      'regionId': '532623',
      'parentId': '532600',
      'name': '西畴县',
      'children': null
    }, {
      'regionId': '532624',
      'parentId': '532600',
      'name': '麻栗坡县',
      'children': null
    }, {
      'regionId': '532625',
      'parentId': '532600',
      'name': '马关县',
      'children': null
    }, {
      'regionId': '532626',
      'parentId': '532600',
      'name': '丘北县',
      'children': null
    }, {
      'regionId': '532627',
      'parentId': '532600',
      'name': '广南县',
      'children': null
    }, {
      'regionId': '532628',
      'parentId': '532600',
      'name': '富宁县',
      'children': null
    }]
  }, {
    'regionId': '532800',
    'parentId': '530000',
    'name': '西双版纳傣族自治州',
    'children': [{
      'regionId': '532801',
      'parentId': '532800',
      'name': '景洪市',
      'children': null
    }, {
      'regionId': '532822',
      'parentId': '532800',
      'name': '勐海县',
      'children': null
    }, {
      'regionId': '532823',
      'parentId': '532800',
      'name': '勐腊县',
      'children': null
    }]
  }, {
    'regionId': '532900',
    'parentId': '530000',
    'name': '大理白族自治州',
    'children': [{
      'regionId': '532901',
      'parentId': '532900',
      'name': '大理市',
      'children': null
    }, {
      'regionId': '532922',
      'parentId': '532900',
      'name': '漾濞彝族自治县',
      'children': null
    }, {
      'regionId': '532923',
      'parentId': '532900',
      'name': '祥云县',
      'children': null
    }, {
      'regionId': '532924',
      'parentId': '532900',
      'name': '宾川县',
      'children': null
    }, {
      'regionId': '532925',
      'parentId': '532900',
      'name': '弥渡县',
      'children': null
    }, {
      'regionId': '532926',
      'parentId': '532900',
      'name': '南涧彝族自治县',
      'children': null
    }, {
      'regionId': '532927',
      'parentId': '532900',
      'name': '巍山彝族回族自治县',
      'children': null
    }, {
      'regionId': '532928',
      'parentId': '532900',
      'name': '永平县',
      'children': null
    }, {
      'regionId': '532929',
      'parentId': '532900',
      'name': '云龙县',
      'children': null
    }, {
      'regionId': '532930',
      'parentId': '532900',
      'name': '洱源县',
      'children': null
    }, {
      'regionId': '532931',
      'parentId': '532900',
      'name': '剑川县',
      'children': null
    }, {
      'regionId': '532932',
      'parentId': '532900',
      'name': '鹤庆县',
      'children': null
    }]
  }, {
    'regionId': '533100',
    'parentId': '530000',
    'name': '德宏傣族景颇族自治州',
    'children': [{
      'regionId': '533102',
      'parentId': '533100',
      'name': '瑞丽市',
      'children': null
    }, {
      'regionId': '533103',
      'parentId': '533100',
      'name': '芒市',
      'children': null
    }, {
      'regionId': '533122',
      'parentId': '533100',
      'name': '梁河县',
      'children': null
    }, {
      'regionId': '533123',
      'parentId': '533100',
      'name': '盈江县',
      'children': null
    }, {
      'regionId': '533124',
      'parentId': '533100',
      'name': '陇川县',
      'children': null
    }]
  }, {
    'regionId': '533300',
    'parentId': '530000',
    'name': '怒江傈僳族自治州',
    'children': [{
      'regionId': '533301',
      'parentId': '533300',
      'name': '泸水市',
      'children': null
    }, {
      'regionId': '533323',
      'parentId': '533300',
      'name': '福贡县',
      'children': null
    }, {
      'regionId': '533324',
      'parentId': '533300',
      'name': '贡山独龙族怒族自治县',
      'children': null
    }, {
      'regionId': '533325',
      'parentId': '533300',
      'name': '兰坪白族普米族自治县',
      'children': null
    }]
  }, {
    'regionId': '533400',
    'parentId': '530000',
    'name': '迪庆藏族自治州',
    'children': [{
      'regionId': '533401',
      'parentId': '533400',
      'name': '香格里拉市',
      'children': null
    }, {
      'regionId': '533422',
      'parentId': '533400',
      'name': '德钦县',
      'children': null
    }, {
      'regionId': '533423',
      'parentId': '533400',
      'name': '维西傈僳族自治县',
      'children': null
    }]
  }]
}, {
  'regionId': '540000',
  'parentId': '0',
  'name': '西藏自治区',
  'children': [{
    'regionId': '540100',
    'parentId': '540000',
    'name': '拉萨市',
    'children': [{
      'regionId': '540102',
      'parentId': '540100',
      'name': '城关区',
      'children': null
    }, {
      'regionId': '540103',
      'parentId': '540100',
      'name': '堆龙德庆区',
      'children': null
    }, {
      'regionId': '540104',
      'parentId': '540100',
      'name': '达孜区',
      'children': null
    }, {
      'regionId': '540121',
      'parentId': '540100',
      'name': '林周县',
      'children': null
    }, {
      'regionId': '540122',
      'parentId': '540100',
      'name': '当雄县',
      'children': null
    }, {
      'regionId': '540123',
      'parentId': '540100',
      'name': '尼木县',
      'children': null
    }, {
      'regionId': '540124',
      'parentId': '540100',
      'name': '曲水县',
      'children': null
    }, {
      'regionId': '540127',
      'parentId': '540100',
      'name': '墨竹工卡县',
      'children': null
    }]
  }, {
    'regionId': '540200',
    'parentId': '540000',
    'name': '日喀则市',
    'children': [{
      'regionId': '540202',
      'parentId': '540200',
      'name': '桑珠孜区',
      'children': null
    }, {
      'regionId': '540221',
      'parentId': '540200',
      'name': '南木林县',
      'children': null
    }, {
      'regionId': '540222',
      'parentId': '540200',
      'name': '江孜县',
      'children': null
    }, {
      'regionId': '540223',
      'parentId': '540200',
      'name': '定日县',
      'children': null
    }, {
      'regionId': '540224',
      'parentId': '540200',
      'name': '萨迦县',
      'children': null
    }, {
      'regionId': '540225',
      'parentId': '540200',
      'name': '拉孜县',
      'children': null
    }, {
      'regionId': '540226',
      'parentId': '540200',
      'name': '昂仁县',
      'children': null
    }, {
      'regionId': '540227',
      'parentId': '540200',
      'name': '谢通门县',
      'children': null
    }, {
      'regionId': '540228',
      'parentId': '540200',
      'name': '白朗县',
      'children': null
    }, {
      'regionId': '540229',
      'parentId': '540200',
      'name': '仁布县',
      'children': null
    }, {
      'regionId': '540230',
      'parentId': '540200',
      'name': '康马县',
      'children': null
    }, {
      'regionId': '540231',
      'parentId': '540200',
      'name': '定结县',
      'children': null
    }, {
      'regionId': '540232',
      'parentId': '540200',
      'name': '仲巴县',
      'children': null
    }, {
      'regionId': '540233',
      'parentId': '540200',
      'name': '亚东县',
      'children': null
    }, {
      'regionId': '540234',
      'parentId': '540200',
      'name': '吉隆县',
      'children': null
    }, {
      'regionId': '540235',
      'parentId': '540200',
      'name': '聂拉木县',
      'children': null
    }, {
      'regionId': '540236',
      'parentId': '540200',
      'name': '萨嘎县',
      'children': null
    }, {
      'regionId': '540237',
      'parentId': '540200',
      'name': '岗巴县',
      'children': null
    }]
  }, {
    'regionId': '540300',
    'parentId': '540000',
    'name': '昌都市',
    'children': [{
      'regionId': '540302',
      'parentId': '540300',
      'name': '卡若区',
      'children': null
    }, {
      'regionId': '540321',
      'parentId': '540300',
      'name': '江达县',
      'children': null
    }, {
      'regionId': '540322',
      'parentId': '540300',
      'name': '贡觉县',
      'children': null
    }, {
      'regionId': '540323',
      'parentId': '540300',
      'name': '类乌齐县',
      'children': null
    }, {
      'regionId': '540324',
      'parentId': '540300',
      'name': '丁青县',
      'children': null
    }, {
      'regionId': '540325',
      'parentId': '540300',
      'name': '察雅县',
      'children': null
    }, {
      'regionId': '540326',
      'parentId': '540300',
      'name': '八宿县',
      'children': null
    }, {
      'regionId': '540327',
      'parentId': '540300',
      'name': '左贡县',
      'children': null
    }, {
      'regionId': '540328',
      'parentId': '540300',
      'name': '芒康县',
      'children': null
    }, {
      'regionId': '540329',
      'parentId': '540300',
      'name': '洛隆县',
      'children': null
    }, {
      'regionId': '540330',
      'parentId': '540300',
      'name': '边坝县',
      'children': null
    }]
  }, {
    'regionId': '540400',
    'parentId': '540000',
    'name': '林芝市',
    'children': [{
      'regionId': '540402',
      'parentId': '540400',
      'name': '巴宜区',
      'children': null
    }, {
      'regionId': '540421',
      'parentId': '540400',
      'name': '工布江达县',
      'children': null
    }, {
      'regionId': '540422',
      'parentId': '540400',
      'name': '米林县',
      'children': null
    }, {
      'regionId': '540423',
      'parentId': '540400',
      'name': '墨脱县',
      'children': null
    }, {
      'regionId': '540424',
      'parentId': '540400',
      'name': '波密县',
      'children': null
    }, {
      'regionId': '540425',
      'parentId': '540400',
      'name': '察隅县',
      'children': null
    }, {
      'regionId': '540426',
      'parentId': '540400',
      'name': '朗县',
      'children': null
    }]
  }, {
    'regionId': '540500',
    'parentId': '540000',
    'name': '山南市',
    'children': [{
      'regionId': '540502',
      'parentId': '540500',
      'name': '乃东区',
      'children': null
    }, {
      'regionId': '540521',
      'parentId': '540500',
      'name': '扎囊县',
      'children': null
    }, {
      'regionId': '540522',
      'parentId': '540500',
      'name': '贡嘎县',
      'children': null
    }, {
      'regionId': '540523',
      'parentId': '540500',
      'name': '桑日县',
      'children': null
    }, {
      'regionId': '540524',
      'parentId': '540500',
      'name': '琼结县',
      'children': null
    }, {
      'regionId': '540525',
      'parentId': '540500',
      'name': '曲松县',
      'children': null
    }, {
      'regionId': '540526',
      'parentId': '540500',
      'name': '措美县',
      'children': null
    }, {
      'regionId': '540527',
      'parentId': '540500',
      'name': '洛扎县',
      'children': null
    }, {
      'regionId': '540528',
      'parentId': '540500',
      'name': '加查县',
      'children': null
    }, {
      'regionId': '540529',
      'parentId': '540500',
      'name': '隆子县',
      'children': null
    }, {
      'regionId': '540530',
      'parentId': '540500',
      'name': '错那县',
      'children': null
    }, {
      'regionId': '540531',
      'parentId': '540500',
      'name': '浪卡子县',
      'children': null
    }]
  }, {
    'regionId': '540600',
    'parentId': '540000',
    'name': '那曲市',
    'children': [{
      'regionId': '540602',
      'parentId': '540600',
      'name': '色尼区',
      'children': null
    }, {
      'regionId': '540621',
      'parentId': '540600',
      'name': '嘉黎县',
      'children': null
    }, {
      'regionId': '540622',
      'parentId': '540600',
      'name': '比如县',
      'children': null
    }, {
      'regionId': '540623',
      'parentId': '540600',
      'name': '聂荣县',
      'children': null
    }, {
      'regionId': '540624',
      'parentId': '540600',
      'name': '安多县',
      'children': null
    }, {
      'regionId': '540625',
      'parentId': '540600',
      'name': '申扎县',
      'children': null
    }, {
      'regionId': '540626',
      'parentId': '540600',
      'name': '索县',
      'children': null
    }, {
      'regionId': '540627',
      'parentId': '540600',
      'name': '班戈县',
      'children': null
    }, {
      'regionId': '540628',
      'parentId': '540600',
      'name': '巴青县',
      'children': null
    }, {
      'regionId': '540629',
      'parentId': '540600',
      'name': '尼玛县',
      'children': null
    }, {
      'regionId': '540630',
      'parentId': '540600',
      'name': '双湖县',
      'children': null
    }]
  }, {
    'regionId': '542500',
    'parentId': '540000',
    'name': '阿里地区',
    'children': [{
      'regionId': '542521',
      'parentId': '542500',
      'name': '普兰县',
      'children': null
    }, {
      'regionId': '542522',
      'parentId': '542500',
      'name': '札达县',
      'children': null
    }, {
      'regionId': '542523',
      'parentId': '542500',
      'name': '噶尔县',
      'children': null
    }, {
      'regionId': '542524',
      'parentId': '542500',
      'name': '日土县',
      'children': null
    }, {
      'regionId': '542525',
      'parentId': '542500',
      'name': '革吉县',
      'children': null
    }, {
      'regionId': '542526',
      'parentId': '542500',
      'name': '改则县',
      'children': null
    }, {
      'regionId': '542527',
      'parentId': '542500',
      'name': '措勤县',
      'children': null
    }]
  }]
}, {
  'regionId': '610000',
  'parentId': '0',
  'name': '陕西省',
  'children': [{
    'regionId': '610100',
    'parentId': '610000',
    'name': '西安市',
    'children': [{
      'regionId': '610102',
      'parentId': '610100',
      'name': '新城区',
      'children': null
    }, {
      'regionId': '610103',
      'parentId': '610100',
      'name': '碑林区',
      'children': null
    }, {
      'regionId': '610104',
      'parentId': '610100',
      'name': '莲湖区',
      'children': null
    }, {
      'regionId': '610111',
      'parentId': '610100',
      'name': '灞桥区',
      'children': null
    }, {
      'regionId': '610112',
      'parentId': '610100',
      'name': '未央区',
      'children': null
    }, {
      'regionId': '610113',
      'parentId': '610100',
      'name': '雁塔区',
      'children': null
    }, {
      'regionId': '610114',
      'parentId': '610100',
      'name': '阎良区',
      'children': null
    }, {
      'regionId': '610115',
      'parentId': '610100',
      'name': '临潼区',
      'children': null
    }, {
      'regionId': '610116',
      'parentId': '610100',
      'name': '长安区',
      'children': null
    }, {
      'regionId': '610117',
      'parentId': '610100',
      'name': '高陵区',
      'children': null
    }, {
      'regionId': '610118',
      'parentId': '610100',
      'name': '鄠邑区',
      'children': null
    }, {
      'regionId': '610122',
      'parentId': '610100',
      'name': '蓝田县',
      'children': null
    }, {
      'regionId': '610124',
      'parentId': '610100',
      'name': '周至县',
      'children': null
    }]
  }, {
    'regionId': '610200',
    'parentId': '610000',
    'name': '铜川市',
    'children': [{
      'regionId': '610202',
      'parentId': '610200',
      'name': '王益区',
      'children': null
    }, {
      'regionId': '610203',
      'parentId': '610200',
      'name': '印台区',
      'children': null
    }, {
      'regionId': '610204',
      'parentId': '610200',
      'name': '耀州区',
      'children': null
    }, {
      'regionId': '610222',
      'parentId': '610200',
      'name': '宜君县',
      'children': null
    }]
  }, {
    'regionId': '610300',
    'parentId': '610000',
    'name': '宝鸡市',
    'children': [{
      'regionId': '610302',
      'parentId': '610300',
      'name': '渭滨区',
      'children': null
    }, {
      'regionId': '610303',
      'parentId': '610300',
      'name': '金台区',
      'children': null
    }, {
      'regionId': '610304',
      'parentId': '610300',
      'name': '陈仓区',
      'children': null
    }, {
      'regionId': '610322',
      'parentId': '610300',
      'name': '凤翔县',
      'children': null
    }, {
      'regionId': '610323',
      'parentId': '610300',
      'name': '岐山县',
      'children': null
    }, {
      'regionId': '610324',
      'parentId': '610300',
      'name': '扶风县',
      'children': null
    }, {
      'regionId': '610326',
      'parentId': '610300',
      'name': '眉县',
      'children': null
    }, {
      'regionId': '610327',
      'parentId': '610300',
      'name': '陇县',
      'children': null
    }, {
      'regionId': '610328',
      'parentId': '610300',
      'name': '千阳县',
      'children': null
    }, {
      'regionId': '610329',
      'parentId': '610300',
      'name': '麟游县',
      'children': null
    }, {
      'regionId': '610330',
      'parentId': '610300',
      'name': '凤县',
      'children': null
    }, {
      'regionId': '610331',
      'parentId': '610300',
      'name': '太白县',
      'children': null
    }]
  }, {
    'regionId': '610400',
    'parentId': '610000',
    'name': '咸阳市',
    'children': [{
      'regionId': '610402',
      'parentId': '610400',
      'name': '秦都区',
      'children': null
    }, {
      'regionId': '610403',
      'parentId': '610400',
      'name': '杨陵区',
      'children': null
    }, {
      'regionId': '610404',
      'parentId': '610400',
      'name': '渭城区',
      'children': null
    }, {
      'regionId': '610422',
      'parentId': '610400',
      'name': '三原县',
      'children': null
    }, {
      'regionId': '610423',
      'parentId': '610400',
      'name': '泾阳县',
      'children': null
    }, {
      'regionId': '610424',
      'parentId': '610400',
      'name': '乾县',
      'children': null
    }, {
      'regionId': '610425',
      'parentId': '610400',
      'name': '礼泉县',
      'children': null
    }, {
      'regionId': '610426',
      'parentId': '610400',
      'name': '永寿县',
      'children': null
    }, {
      'regionId': '610428',
      'parentId': '610400',
      'name': '长武县',
      'children': null
    }, {
      'regionId': '610429',
      'parentId': '610400',
      'name': '旬邑县',
      'children': null
    }, {
      'regionId': '610430',
      'parentId': '610400',
      'name': '淳化县',
      'children': null
    }, {
      'regionId': '610431',
      'parentId': '610400',
      'name': '武功县',
      'children': null
    }, {
      'regionId': '610481',
      'parentId': '610400',
      'name': '兴平市',
      'children': null
    }, {
      'regionId': '610482',
      'parentId': '610400',
      'name': '彬州市',
      'children': null
    }]
  }, {
    'regionId': '610500',
    'parentId': '610000',
    'name': '渭南市',
    'children': [{
      'regionId': '610502',
      'parentId': '610500',
      'name': '临渭区',
      'children': null
    }, {
      'regionId': '610503',
      'parentId': '610500',
      'name': '华州区',
      'children': null
    }, {
      'regionId': '610522',
      'parentId': '610500',
      'name': '潼关县',
      'children': null
    }, {
      'regionId': '610523',
      'parentId': '610500',
      'name': '大荔县',
      'children': null
    }, {
      'regionId': '610524',
      'parentId': '610500',
      'name': '合阳县',
      'children': null
    }, {
      'regionId': '610525',
      'parentId': '610500',
      'name': '澄城县',
      'children': null
    }, {
      'regionId': '610526',
      'parentId': '610500',
      'name': '蒲城县',
      'children': null
    }, {
      'regionId': '610527',
      'parentId': '610500',
      'name': '白水县',
      'children': null
    }, {
      'regionId': '610528',
      'parentId': '610500',
      'name': '富平县',
      'children': null
    }, {
      'regionId': '610581',
      'parentId': '610500',
      'name': '韩城市',
      'children': null
    }, {
      'regionId': '610582',
      'parentId': '610500',
      'name': '华阴市',
      'children': null
    }]
  }, {
    'regionId': '610600',
    'parentId': '610000',
    'name': '延安市',
    'children': [{
      'regionId': '610602',
      'parentId': '610600',
      'name': '宝塔区',
      'children': null
    }, {
      'regionId': '610603',
      'parentId': '610600',
      'name': '安塞区',
      'children': null
    }, {
      'regionId': '610621',
      'parentId': '610600',
      'name': '延长县',
      'children': null
    }, {
      'regionId': '610622',
      'parentId': '610600',
      'name': '延川县',
      'children': null
    }, {
      'regionId': '610625',
      'parentId': '610600',
      'name': '志丹县',
      'children': null
    }, {
      'regionId': '610626',
      'parentId': '610600',
      'name': '吴起县',
      'children': null
    }, {
      'regionId': '610627',
      'parentId': '610600',
      'name': '甘泉县',
      'children': null
    }, {
      'regionId': '610628',
      'parentId': '610600',
      'name': '富县',
      'children': null
    }, {
      'regionId': '610629',
      'parentId': '610600',
      'name': '洛川县',
      'children': null
    }, {
      'regionId': '610630',
      'parentId': '610600',
      'name': '宜川县',
      'children': null
    }, {
      'regionId': '610631',
      'parentId': '610600',
      'name': '黄龙县',
      'children': null
    }, {
      'regionId': '610632',
      'parentId': '610600',
      'name': '黄陵县',
      'children': null
    }, {
      'regionId': '610681',
      'parentId': '610600',
      'name': '子长市',
      'children': null
    }]
  }, {
    'regionId': '610700',
    'parentId': '610000',
    'name': '汉中市',
    'children': [{
      'regionId': '610702',
      'parentId': '610700',
      'name': '汉台区',
      'children': null
    }, {
      'regionId': '610703',
      'parentId': '610700',
      'name': '南郑区',
      'children': null
    }, {
      'regionId': '610722',
      'parentId': '610700',
      'name': '城固县',
      'children': null
    }, {
      'regionId': '610723',
      'parentId': '610700',
      'name': '洋县',
      'children': null
    }, {
      'regionId': '610724',
      'parentId': '610700',
      'name': '西乡县',
      'children': null
    }, {
      'regionId': '610725',
      'parentId': '610700',
      'name': '勉县',
      'children': null
    }, {
      'regionId': '610726',
      'parentId': '610700',
      'name': '宁强县',
      'children': null
    }, {
      'regionId': '610727',
      'parentId': '610700',
      'name': '略阳县',
      'children': null
    }, {
      'regionId': '610728',
      'parentId': '610700',
      'name': '镇巴县',
      'children': null
    }, {
      'regionId': '610729',
      'parentId': '610700',
      'name': '留坝县',
      'children': null
    }, {
      'regionId': '610730',
      'parentId': '610700',
      'name': '佛坪县',
      'children': null
    }]
  }, {
    'regionId': '610800',
    'parentId': '610000',
    'name': '榆林市',
    'children': [{
      'regionId': '610802',
      'parentId': '610800',
      'name': '榆阳区',
      'children': null
    }, {
      'regionId': '610803',
      'parentId': '610800',
      'name': '横山区',
      'children': null
    }, {
      'regionId': '610822',
      'parentId': '610800',
      'name': '府谷县',
      'children': null
    }, {
      'regionId': '610824',
      'parentId': '610800',
      'name': '靖边县',
      'children': null
    }, {
      'regionId': '610825',
      'parentId': '610800',
      'name': '定边县',
      'children': null
    }, {
      'regionId': '610826',
      'parentId': '610800',
      'name': '绥德县',
      'children': null
    }, {
      'regionId': '610827',
      'parentId': '610800',
      'name': '米脂县',
      'children': null
    }, {
      'regionId': '610828',
      'parentId': '610800',
      'name': '佳县',
      'children': null
    }, {
      'regionId': '610829',
      'parentId': '610800',
      'name': '吴堡县',
      'children': null
    }, {
      'regionId': '610830',
      'parentId': '610800',
      'name': '清涧县',
      'children': null
    }, {
      'regionId': '610831',
      'parentId': '610800',
      'name': '子洲县',
      'children': null
    }, {
      'regionId': '610881',
      'parentId': '610800',
      'name': '神木市',
      'children': null
    }]
  }, {
    'regionId': '610900',
    'parentId': '610000',
    'name': '安康市',
    'children': [{
      'regionId': '610902',
      'parentId': '610900',
      'name': '汉滨区',
      'children': null
    }, {
      'regionId': '610921',
      'parentId': '610900',
      'name': '汉阴县',
      'children': null
    }, {
      'regionId': '610922',
      'parentId': '610900',
      'name': '石泉县',
      'children': null
    }, {
      'regionId': '610923',
      'parentId': '610900',
      'name': '宁陕县',
      'children': null
    }, {
      'regionId': '610924',
      'parentId': '610900',
      'name': '紫阳县',
      'children': null
    }, {
      'regionId': '610925',
      'parentId': '610900',
      'name': '岚皋县',
      'children': null
    }, {
      'regionId': '610926',
      'parentId': '610900',
      'name': '平利县',
      'children': null
    }, {
      'regionId': '610927',
      'parentId': '610900',
      'name': '镇坪县',
      'children': null
    }, {
      'regionId': '610928',
      'parentId': '610900',
      'name': '旬阳县',
      'children': null
    }, {
      'regionId': '610929',
      'parentId': '610900',
      'name': '白河县',
      'children': null
    }]
  }, {
    'regionId': '611000',
    'parentId': '610000',
    'name': '商洛市',
    'children': [{
      'regionId': '611002',
      'parentId': '611000',
      'name': '商州区',
      'children': null
    }, {
      'regionId': '611021',
      'parentId': '611000',
      'name': '洛南县',
      'children': null
    }, {
      'regionId': '611022',
      'parentId': '611000',
      'name': '丹凤县',
      'children': null
    }, {
      'regionId': '611023',
      'parentId': '611000',
      'name': '商南县',
      'children': null
    }, {
      'regionId': '611024',
      'parentId': '611000',
      'name': '山阳县',
      'children': null
    }, {
      'regionId': '611025',
      'parentId': '611000',
      'name': '镇安县',
      'children': null
    }, {
      'regionId': '611026',
      'parentId': '611000',
      'name': '柞水县',
      'children': null
    }]
  }]
}, {
  'regionId': '620000',
  'parentId': '0',
  'name': '甘肃省',
  'children': [{
    'regionId': '620100',
    'parentId': '620000',
    'name': '兰州市',
    'children': [{
      'regionId': '620102',
      'parentId': '620100',
      'name': '城关区',
      'children': null
    }, {
      'regionId': '620103',
      'parentId': '620100',
      'name': '七里河区',
      'children': null
    }, {
      'regionId': '620104',
      'parentId': '620100',
      'name': '西固区',
      'children': null
    }, {
      'regionId': '620105',
      'parentId': '620100',
      'name': '安宁区',
      'children': null
    }, {
      'regionId': '620111',
      'parentId': '620100',
      'name': '红古区',
      'children': null
    }, {
      'regionId': '620121',
      'parentId': '620100',
      'name': '永登县',
      'children': null
    }, {
      'regionId': '620122',
      'parentId': '620100',
      'name': '皋兰县',
      'children': null
    }, {
      'regionId': '620123',
      'parentId': '620100',
      'name': '榆中县',
      'children': null
    }]
  }, {
    'regionId': '620200',
    'parentId': '620000',
    'name': '嘉峪关市',
    'children': null
  }, {
    'regionId': '620300',
    'parentId': '620000',
    'name': '金昌市',
    'children': [{
      'regionId': '620302',
      'parentId': '620300',
      'name': '金川区',
      'children': null
    }, {
      'regionId': '620321',
      'parentId': '620300',
      'name': '永昌县',
      'children': null
    }]
  }, {
    'regionId': '620400',
    'parentId': '620000',
    'name': '白银市',
    'children': [{
      'regionId': '620402',
      'parentId': '620400',
      'name': '白银区',
      'children': null
    }, {
      'regionId': '620403',
      'parentId': '620400',
      'name': '平川区',
      'children': null
    }, {
      'regionId': '620421',
      'parentId': '620400',
      'name': '靖远县',
      'children': null
    }, {
      'regionId': '620422',
      'parentId': '620400',
      'name': '会宁县',
      'children': null
    }, {
      'regionId': '620423',
      'parentId': '620400',
      'name': '景泰县',
      'children': null
    }]
  }, {
    'regionId': '620500',
    'parentId': '620000',
    'name': '天水市',
    'children': [{
      'regionId': '620502',
      'parentId': '620500',
      'name': '秦州区',
      'children': null
    }, {
      'regionId': '620503',
      'parentId': '620500',
      'name': '麦积区',
      'children': null
    }, {
      'regionId': '620521',
      'parentId': '620500',
      'name': '清水县',
      'children': null
    }, {
      'regionId': '620522',
      'parentId': '620500',
      'name': '秦安县',
      'children': null
    }, {
      'regionId': '620523',
      'parentId': '620500',
      'name': '甘谷县',
      'children': null
    }, {
      'regionId': '620524',
      'parentId': '620500',
      'name': '武山县',
      'children': null
    }, {
      'regionId': '620525',
      'parentId': '620500',
      'name': '张家川回族自治县',
      'children': null
    }]
  }, {
    'regionId': '620600',
    'parentId': '620000',
    'name': '武威市',
    'children': [{
      'regionId': '620602',
      'parentId': '620600',
      'name': '凉州区',
      'children': null
    }, {
      'regionId': '620621',
      'parentId': '620600',
      'name': '民勤县',
      'children': null
    }, {
      'regionId': '620622',
      'parentId': '620600',
      'name': '古浪县',
      'children': null
    }, {
      'regionId': '620623',
      'parentId': '620600',
      'name': '天祝藏族自治县',
      'children': null
    }]
  }, {
    'regionId': '620700',
    'parentId': '620000',
    'name': '张掖市',
    'children': [{
      'regionId': '620702',
      'parentId': '620700',
      'name': '甘州区',
      'children': null
    }, {
      'regionId': '620721',
      'parentId': '620700',
      'name': '肃南裕固族自治县',
      'children': null
    }, {
      'regionId': '620722',
      'parentId': '620700',
      'name': '民乐县',
      'children': null
    }, {
      'regionId': '620723',
      'parentId': '620700',
      'name': '临泽县',
      'children': null
    }, {
      'regionId': '620724',
      'parentId': '620700',
      'name': '高台县',
      'children': null
    }, {
      'regionId': '620725',
      'parentId': '620700',
      'name': '山丹县',
      'children': null
    }]
  }, {
    'regionId': '620800',
    'parentId': '620000',
    'name': '平凉市',
    'children': [{
      'regionId': '620802',
      'parentId': '620800',
      'name': '崆峒区',
      'children': null
    }, {
      'regionId': '620821',
      'parentId': '620800',
      'name': '泾川县',
      'children': null
    }, {
      'regionId': '620822',
      'parentId': '620800',
      'name': '灵台县',
      'children': null
    }, {
      'regionId': '620823',
      'parentId': '620800',
      'name': '崇信县',
      'children': null
    }, {
      'regionId': '620825',
      'parentId': '620800',
      'name': '庄浪县',
      'children': null
    }, {
      'regionId': '620826',
      'parentId': '620800',
      'name': '静宁县',
      'children': null
    }, {
      'regionId': '620881',
      'parentId': '620800',
      'name': '华亭市',
      'children': null
    }]
  }, {
    'regionId': '620900',
    'parentId': '620000',
    'name': '酒泉市',
    'children': [{
      'regionId': '620902',
      'parentId': '620900',
      'name': '肃州区',
      'children': null
    }, {
      'regionId': '620921',
      'parentId': '620900',
      'name': '金塔县',
      'children': null
    }, {
      'regionId': '620922',
      'parentId': '620900',
      'name': '瓜州县',
      'children': null
    }, {
      'regionId': '620923',
      'parentId': '620900',
      'name': '肃北蒙古族自治县',
      'children': null
    }, {
      'regionId': '620924',
      'parentId': '620900',
      'name': '阿克塞哈萨克族自治县',
      'children': null
    }, {
      'regionId': '620981',
      'parentId': '620900',
      'name': '玉门市',
      'children': null
    }, {
      'regionId': '620982',
      'parentId': '620900',
      'name': '敦煌市',
      'children': null
    }]
  }, {
    'regionId': '621000',
    'parentId': '620000',
    'name': '庆阳市',
    'children': [{
      'regionId': '621002',
      'parentId': '621000',
      'name': '西峰区',
      'children': null
    }, {
      'regionId': '621021',
      'parentId': '621000',
      'name': '庆城县',
      'children': null
    }, {
      'regionId': '621022',
      'parentId': '621000',
      'name': '环县',
      'children': null
    }, {
      'regionId': '621023',
      'parentId': '621000',
      'name': '华池县',
      'children': null
    }, {
      'regionId': '621024',
      'parentId': '621000',
      'name': '合水县',
      'children': null
    }, {
      'regionId': '621025',
      'parentId': '621000',
      'name': '正宁县',
      'children': null
    }, {
      'regionId': '621026',
      'parentId': '621000',
      'name': '宁县',
      'children': null
    }, {
      'regionId': '621027',
      'parentId': '621000',
      'name': '镇原县',
      'children': null
    }]
  }, {
    'regionId': '621100',
    'parentId': '620000',
    'name': '定西市',
    'children': [{
      'regionId': '621102',
      'parentId': '621100',
      'name': '安定区',
      'children': null
    }, {
      'regionId': '621121',
      'parentId': '621100',
      'name': '通渭县',
      'children': null
    }, {
      'regionId': '621122',
      'parentId': '621100',
      'name': '陇西县',
      'children': null
    }, {
      'regionId': '621123',
      'parentId': '621100',
      'name': '渭源县',
      'children': null
    }, {
      'regionId': '621124',
      'parentId': '621100',
      'name': '临洮县',
      'children': null
    }, {
      'regionId': '621125',
      'parentId': '621100',
      'name': '漳县',
      'children': null
    }, {
      'regionId': '621126',
      'parentId': '621100',
      'name': '岷县',
      'children': null
    }]
  }, {
    'regionId': '621200',
    'parentId': '620000',
    'name': '陇南市',
    'children': [{
      'regionId': '621202',
      'parentId': '621200',
      'name': '武都区',
      'children': null
    }, {
      'regionId': '621221',
      'parentId': '621200',
      'name': '成县',
      'children': null
    }, {
      'regionId': '621222',
      'parentId': '621200',
      'name': '文县',
      'children': null
    }, {
      'regionId': '621223',
      'parentId': '621200',
      'name': '宕昌县',
      'children': null
    }, {
      'regionId': '621224',
      'parentId': '621200',
      'name': '康县',
      'children': null
    }, {
      'regionId': '621225',
      'parentId': '621200',
      'name': '西和县',
      'children': null
    }, {
      'regionId': '621226',
      'parentId': '621200',
      'name': '礼县',
      'children': null
    }, {
      'regionId': '621227',
      'parentId': '621200',
      'name': '徽县',
      'children': null
    }, {
      'regionId': '621228',
      'parentId': '621200',
      'name': '两当县',
      'children': null
    }]
  }, {
    'regionId': '622900',
    'parentId': '620000',
    'name': '临夏回族自治州',
    'children': [{
      'regionId': '622901',
      'parentId': '622900',
      'name': '临夏市',
      'children': null
    }, {
      'regionId': '622921',
      'parentId': '622900',
      'name': '临夏县',
      'children': null
    }, {
      'regionId': '622922',
      'parentId': '622900',
      'name': '康乐县',
      'children': null
    }, {
      'regionId': '622923',
      'parentId': '622900',
      'name': '永靖县',
      'children': null
    }, {
      'regionId': '622924',
      'parentId': '622900',
      'name': '广河县',
      'children': null
    }, {
      'regionId': '622925',
      'parentId': '622900',
      'name': '和政县',
      'children': null
    }, {
      'regionId': '622926',
      'parentId': '622900',
      'name': '东乡族自治县',
      'children': null
    }, {
      'regionId': '622927',
      'parentId': '622900',
      'name': '积石山保安族东乡族撒拉族自治县',
      'children': null
    }]
  }, {
    'regionId': '623000',
    'parentId': '620000',
    'name': '甘南藏族自治州',
    'children': [{
      'regionId': '623001',
      'parentId': '623000',
      'name': '合作市',
      'children': null
    }, {
      'regionId': '623021',
      'parentId': '623000',
      'name': '临潭县',
      'children': null
    }, {
      'regionId': '623022',
      'parentId': '623000',
      'name': '卓尼县',
      'children': null
    }, {
      'regionId': '623023',
      'parentId': '623000',
      'name': '舟曲县',
      'children': null
    }, {
      'regionId': '623024',
      'parentId': '623000',
      'name': '迭部县',
      'children': null
    }, {
      'regionId': '623025',
      'parentId': '623000',
      'name': '玛曲县',
      'children': null
    }, {
      'regionId': '623026',
      'parentId': '623000',
      'name': '碌曲县',
      'children': null
    }, {
      'regionId': '623027',
      'parentId': '623000',
      'name': '夏河县',
      'children': null
    }]
  }]
}, {
  'regionId': '630000',
  'parentId': '0',
  'name': '青海省',
  'children': [{
    'regionId': '630100',
    'parentId': '630000',
    'name': '西宁市',
    'children': [{
      'regionId': '630102',
      'parentId': '630100',
      'name': '城东区',
      'children': null
    }, {
      'regionId': '630103',
      'parentId': '630100',
      'name': '城中区',
      'children': null
    }, {
      'regionId': '630104',
      'parentId': '630100',
      'name': '城西区',
      'children': null
    }, {
      'regionId': '630105',
      'parentId': '630100',
      'name': '城北区',
      'children': null
    }, {
      'regionId': '630106',
      'parentId': '630100',
      'name': '湟中区',
      'children': null
    }, {
      'regionId': '630121',
      'parentId': '630100',
      'name': '大通回族土族自治县',
      'children': null
    }, {
      'regionId': '630123',
      'parentId': '630100',
      'name': '湟源县',
      'children': null
    }]
  }, {
    'regionId': '630200',
    'parentId': '630000',
    'name': '海东市',
    'children': [{
      'regionId': '630202',
      'parentId': '630200',
      'name': '乐都区',
      'children': null
    }, {
      'regionId': '630203',
      'parentId': '630200',
      'name': '平安区',
      'children': null
    }, {
      'regionId': '630222',
      'parentId': '630200',
      'name': '民和回族土族自治县',
      'children': null
    }, {
      'regionId': '630223',
      'parentId': '630200',
      'name': '互助土族自治县',
      'children': null
    }, {
      'regionId': '630224',
      'parentId': '630200',
      'name': '化隆回族自治县',
      'children': null
    }, {
      'regionId': '630225',
      'parentId': '630200',
      'name': '循化撒拉族自治县',
      'children': null
    }]
  }, {
    'regionId': '632200',
    'parentId': '630000',
    'name': '海北藏族自治州',
    'children': [{
      'regionId': '632221',
      'parentId': '632200',
      'name': '门源回族自治县',
      'children': null
    }, {
      'regionId': '632222',
      'parentId': '632200',
      'name': '祁连县',
      'children': null
    }, {
      'regionId': '632223',
      'parentId': '632200',
      'name': '海晏县',
      'children': null
    }, {
      'regionId': '632224',
      'parentId': '632200',
      'name': '刚察县',
      'children': null
    }]
  }, {
    'regionId': '632300',
    'parentId': '630000',
    'name': '黄南藏族自治州',
    'children': [{
      'regionId': '632322',
      'parentId': '632300',
      'name': '尖扎县',
      'children': null
    }, {
      'regionId': '632323',
      'parentId': '632300',
      'name': '泽库县',
      'children': null
    }, {
      'regionId': '632324',
      'parentId': '632300',
      'name': '河南蒙古族自治县',
      'children': null
    }]
  }, {
    'regionId': '632500',
    'parentId': '630000',
    'name': '海南藏族自治州',
    'children': [{
      'regionId': '632521',
      'parentId': '632500',
      'name': '共和县',
      'children': null
    }, {
      'regionId': '632522',
      'parentId': '632500',
      'name': '同德县',
      'children': null
    }, {
      'regionId': '632523',
      'parentId': '632500',
      'name': '贵德县',
      'children': null
    }, {
      'regionId': '632524',
      'parentId': '632500',
      'name': '兴海县',
      'children': null
    }, {
      'regionId': '632525',
      'parentId': '632500',
      'name': '贵南县',
      'children': null
    }]
  }, {
    'regionId': '632600',
    'parentId': '630000',
    'name': '果洛藏族自治州',
    'children': [{
      'regionId': '632621',
      'parentId': '632600',
      'name': '玛沁县',
      'children': null
    }, {
      'regionId': '632622',
      'parentId': '632600',
      'name': '班玛县',
      'children': null
    }, {
      'regionId': '632623',
      'parentId': '632600',
      'name': '甘德县',
      'children': null
    }, {
      'regionId': '632624',
      'parentId': '632600',
      'name': '达日县',
      'children': null
    }, {
      'regionId': '632625',
      'parentId': '632600',
      'name': '久治县',
      'children': null
    }, {
      'regionId': '632626',
      'parentId': '632600',
      'name': '玛多县',
      'children': null
    }]
  }, {
    'regionId': '632700',
    'parentId': '630000',
    'name': '玉树藏族自治州',
    'children': [{
      'regionId': '632701',
      'parentId': '632700',
      'name': '玉树市',
      'children': null
    }, {
      'regionId': '632722',
      'parentId': '632700',
      'name': '杂多县',
      'children': null
    }, {
      'regionId': '632723',
      'parentId': '632700',
      'name': '称多县',
      'children': null
    }, {
      'regionId': '632724',
      'parentId': '632700',
      'name': '治多县',
      'children': null
    }, {
      'regionId': '632725',
      'parentId': '632700',
      'name': '囊谦县',
      'children': null
    }, {
      'regionId': '632726',
      'parentId': '632700',
      'name': '曲麻莱县',
      'children': null
    }]
  }, {
    'regionId': '632800',
    'parentId': '630000',
    'name': '海西蒙古族藏族自治州',
    'children': [{
      'regionId': '632801',
      'parentId': '632800',
      'name': '格尔木市',
      'children': null
    }, {
      'regionId': '632802',
      'parentId': '632800',
      'name': '德令哈市',
      'children': null
    }, {
      'regionId': '632803',
      'parentId': '632800',
      'name': '茫崖市',
      'children': null
    }, {
      'regionId': '632821',
      'parentId': '632800',
      'name': '乌兰县',
      'children': null
    }, {
      'regionId': '632822',
      'parentId': '632800',
      'name': '都兰县',
      'children': null
    }, {
      'regionId': '632823',
      'parentId': '632800',
      'name': '天峻县',
      'children': null
    }]
  }]
}, {
  'regionId': '640000',
  'parentId': '0',
  'name': '宁夏回族自治区',
  'children': [{
    'regionId': '640100',
    'parentId': '640000',
    'name': '银川市',
    'children': [{
      'regionId': '640104',
      'parentId': '640100',
      'name': '兴庆区',
      'children': null
    }, {
      'regionId': '640105',
      'parentId': '640100',
      'name': '西夏区',
      'children': null
    }, {
      'regionId': '640106',
      'parentId': '640100',
      'name': '金凤区',
      'children': null
    }, {
      'regionId': '640121',
      'parentId': '640100',
      'name': '永宁县',
      'children': null
    }, {
      'regionId': '640122',
      'parentId': '640100',
      'name': '贺兰县',
      'children': null
    }, {
      'regionId': '640181',
      'parentId': '640100',
      'name': '灵武市',
      'children': null
    }]
  }, {
    'regionId': '640200',
    'parentId': '640000',
    'name': '石嘴山市',
    'children': [{
      'regionId': '640202',
      'parentId': '640200',
      'name': '大武口区',
      'children': null
    }, {
      'regionId': '640205',
      'parentId': '640200',
      'name': '惠农区',
      'children': null
    }, {
      'regionId': '640221',
      'parentId': '640200',
      'name': '平罗县',
      'children': null
    }]
  }, {
    'regionId': '640300',
    'parentId': '640000',
    'name': '吴忠市',
    'children': [{
      'regionId': '640302',
      'parentId': '640300',
      'name': '利通区',
      'children': null
    }, {
      'regionId': '640303',
      'parentId': '640300',
      'name': '红寺堡区',
      'children': null
    }, {
      'regionId': '640323',
      'parentId': '640300',
      'name': '盐池县',
      'children': null
    }, {
      'regionId': '640324',
      'parentId': '640300',
      'name': '同心县',
      'children': null
    }, {
      'regionId': '640381',
      'parentId': '640300',
      'name': '青铜峡市',
      'children': null
    }]
  }, {
    'regionId': '640400',
    'parentId': '640000',
    'name': '固原市',
    'children': [{
      'regionId': '640402',
      'parentId': '640400',
      'name': '原州区',
      'children': null
    }, {
      'regionId': '640422',
      'parentId': '640400',
      'name': '西吉县',
      'children': null
    }, {
      'regionId': '640423',
      'parentId': '640400',
      'name': '隆德县',
      'children': null
    }, {
      'regionId': '640424',
      'parentId': '640400',
      'name': '泾源县',
      'children': null
    }, {
      'regionId': '640425',
      'parentId': '640400',
      'name': '彭阳县',
      'children': null
    }]
  }, {
    'regionId': '640500',
    'parentId': '640000',
    'name': '中卫市',
    'children': [{
      'regionId': '640502',
      'parentId': '640500',
      'name': '沙坡头区',
      'children': null
    }, {
      'regionId': '640521',
      'parentId': '640500',
      'name': '中宁县',
      'children': null
    }, {
      'regionId': '640522',
      'parentId': '640500',
      'name': '海原县',
      'children': null
    }]
  }]
}, {
  'regionId': '650000',
  'parentId': '0',
  'name': '新疆维吾尔自治区',
  'children': [{
    'regionId': '650100',
    'parentId': '650000',
    'name': '乌鲁木齐市',
    'children': [{
      'regionId': '650102',
      'parentId': '650100',
      'name': '天山区',
      'children': null
    }, {
      'regionId': '650103',
      'parentId': '650100',
      'name': '沙依巴克区',
      'children': null
    }, {
      'regionId': '650104',
      'parentId': '650100',
      'name': '新市区',
      'children': null
    }, {
      'regionId': '650105',
      'parentId': '650100',
      'name': '水磨沟区',
      'children': null
    }, {
      'regionId': '650106',
      'parentId': '650100',
      'name': '头屯河区',
      'children': null
    }, {
      'regionId': '650107',
      'parentId': '650100',
      'name': '达坂城区',
      'children': null
    }, {
      'regionId': '650109',
      'parentId': '650100',
      'name': '米东区',
      'children': null
    }, {
      'regionId': '650121',
      'parentId': '650100',
      'name': '乌鲁木齐县',
      'children': null
    }]
  }, {
    'regionId': '650200',
    'parentId': '650000',
    'name': '克拉玛依市',
    'children': [{
      'regionId': '650202',
      'parentId': '650200',
      'name': '独山子区',
      'children': null
    }, {
      'regionId': '650203',
      'parentId': '650200',
      'name': '克拉玛依区',
      'children': null
    }, {
      'regionId': '650204',
      'parentId': '650200',
      'name': '白碱滩区',
      'children': null
    }, {
      'regionId': '650205',
      'parentId': '650200',
      'name': '乌尔禾区',
      'children': null
    }]
  }, {
    'regionId': '650400',
    'parentId': '650000',
    'name': '吐鲁番市',
    'children': [{
      'regionId': '650402',
      'parentId': '650400',
      'name': '高昌区',
      'children': null
    }, {
      'regionId': '650421',
      'parentId': '650400',
      'name': '鄯善县',
      'children': null
    }, {
      'regionId': '650422',
      'parentId': '650400',
      'name': '托克逊县',
      'children': null
    }]
  }, {
    'regionId': '650500',
    'parentId': '650000',
    'name': '哈密市',
    'children': [{
      'regionId': '650502',
      'parentId': '650500',
      'name': '伊州区',
      'children': null
    }, {
      'regionId': '650521',
      'parentId': '650500',
      'name': '巴里坤哈萨克自治县',
      'children': null
    }, {
      'regionId': '650522',
      'parentId': '650500',
      'name': '伊吾县',
      'children': null
    }]
  }, {
    'regionId': '652300',
    'parentId': '650000',
    'name': '昌吉回族自治州',
    'children': [{
      'regionId': '652301',
      'parentId': '652300',
      'name': '昌吉市',
      'children': null
    }, {
      'regionId': '652302',
      'parentId': '652300',
      'name': '阜康市',
      'children': null
    }, {
      'regionId': '652323',
      'parentId': '652300',
      'name': '呼图壁县',
      'children': null
    }, {
      'regionId': '652324',
      'parentId': '652300',
      'name': '玛纳斯县',
      'children': null
    }, {
      'regionId': '652325',
      'parentId': '652300',
      'name': '奇台县',
      'children': null
    }, {
      'regionId': '652327',
      'parentId': '652300',
      'name': '吉木萨尔县',
      'children': null
    }, {
      'regionId': '652328',
      'parentId': '652300',
      'name': '木垒哈萨克自治县',
      'children': null
    }]
  }, {
    'regionId': '652700',
    'parentId': '650000',
    'name': '博尔塔拉蒙古自治州',
    'children': [{
      'regionId': '652701',
      'parentId': '652700',
      'name': '博乐市',
      'children': null
    }, {
      'regionId': '652702',
      'parentId': '652700',
      'name': '阿拉山口市',
      'children': null
    }, {
      'regionId': '652722',
      'parentId': '652700',
      'name': '精河县',
      'children': null
    }, {
      'regionId': '652723',
      'parentId': '652700',
      'name': '温泉县',
      'children': null
    }]
  }, {
    'regionId': '652800',
    'parentId': '650000',
    'name': '巴音郭楞蒙古自治州',
    'children': [{
      'regionId': '652801',
      'parentId': '652800',
      'name': '库尔勒市',
      'children': null
    }, {
      'regionId': '652822',
      'parentId': '652800',
      'name': '轮台县',
      'children': null
    }, {
      'regionId': '652823',
      'parentId': '652800',
      'name': '尉犁县',
      'children': null
    }, {
      'regionId': '652824',
      'parentId': '652800',
      'name': '若羌县',
      'children': null
    }, {
      'regionId': '652825',
      'parentId': '652800',
      'name': '且末县',
      'children': null
    }, {
      'regionId': '652826',
      'parentId': '652800',
      'name': '焉耆回族自治县',
      'children': null
    }, {
      'regionId': '652827',
      'parentId': '652800',
      'name': '和静县',
      'children': null
    }, {
      'regionId': '652828',
      'parentId': '652800',
      'name': '和硕县',
      'children': null
    }, {
      'regionId': '652829',
      'parentId': '652800',
      'name': '博湖县',
      'children': null
    }]
  }, {
    'regionId': '652900',
    'parentId': '650000',
    'name': '阿克苏地区',
    'children': [{
      'regionId': '652901',
      'parentId': '652900',
      'name': '阿克苏市',
      'children': null
    }, {
      'regionId': '652902',
      'parentId': '652900',
      'name': '库车市',
      'children': null
    }, {
      'regionId': '652922',
      'parentId': '652900',
      'name': '温宿县',
      'children': null
    }, {
      'regionId': '652924',
      'parentId': '652900',
      'name': '沙雅县',
      'children': null
    }, {
      'regionId': '652925',
      'parentId': '652900',
      'name': '新和县',
      'children': null
    }, {
      'regionId': '652926',
      'parentId': '652900',
      'name': '拜城县',
      'children': null
    }, {
      'regionId': '652927',
      'parentId': '652900',
      'name': '乌什县',
      'children': null
    }, {
      'regionId': '652928',
      'parentId': '652900',
      'name': '阿瓦提县',
      'children': null
    }, {
      'regionId': '652929',
      'parentId': '652900',
      'name': '柯坪县',
      'children': null
    }]
  }, {
    'regionId': '653000',
    'parentId': '650000',
    'name': '克孜勒苏柯尔克孜自治州',
    'children': [{
      'regionId': '653001',
      'parentId': '653000',
      'name': '阿图什市',
      'children': null
    }, {
      'regionId': '653022',
      'parentId': '653000',
      'name': '阿克陶县',
      'children': null
    }, {
      'regionId': '653023',
      'parentId': '653000',
      'name': '阿合奇县',
      'children': null
    }, {
      'regionId': '653024',
      'parentId': '653000',
      'name': '乌恰县',
      'children': null
    }]
  }, {
    'regionId': '653100',
    'parentId': '650000',
    'name': '喀什地区',
    'children': [{
      'regionId': '653101',
      'parentId': '653100',
      'name': '喀什市',
      'children': null
    }, {
      'regionId': '653121',
      'parentId': '653100',
      'name': '疏附县',
      'children': null
    }, {
      'regionId': '653122',
      'parentId': '653100',
      'name': '疏勒县',
      'children': null
    }, {
      'regionId': '653123',
      'parentId': '653100',
      'name': '英吉沙县',
      'children': null
    }, {
      'regionId': '653124',
      'parentId': '653100',
      'name': '泽普县',
      'children': null
    }, {
      'regionId': '653125',
      'parentId': '653100',
      'name': '莎车县',
      'children': null
    }, {
      'regionId': '653126',
      'parentId': '653100',
      'name': '叶城县',
      'children': null
    }, {
      'regionId': '653127',
      'parentId': '653100',
      'name': '麦盖提县',
      'children': null
    }, {
      'regionId': '653128',
      'parentId': '653100',
      'name': '岳普湖县',
      'children': null
    }, {
      'regionId': '653129',
      'parentId': '653100',
      'name': '伽师县',
      'children': null
    }, {
      'regionId': '653130',
      'parentId': '653100',
      'name': '巴楚县',
      'children': null
    }, {
      'regionId': '653131',
      'parentId': '653100',
      'name': '塔什库尔干塔吉克自治县',
      'children': null
    }]
  }, {
    'regionId': '653200',
    'parentId': '650000',
    'name': '和田地区',
    'children': [{
      'regionId': '653201',
      'parentId': '653200',
      'name': '和田市',
      'children': null
    }, {
      'regionId': '653221',
      'parentId': '653200',
      'name': '和田县',
      'children': null
    }, {
      'regionId': '653222',
      'parentId': '653200',
      'name': '墨玉县',
      'children': null
    }, {
      'regionId': '653223',
      'parentId': '653200',
      'name': '皮山县',
      'children': null
    }, {
      'regionId': '653224',
      'parentId': '653200',
      'name': '洛浦县',
      'children': null
    }, {
      'regionId': '653225',
      'parentId': '653200',
      'name': '策勒县',
      'children': null
    }, {
      'regionId': '653226',
      'parentId': '653200',
      'name': '于田县',
      'children': null
    }, {
      'regionId': '653227',
      'parentId': '653200',
      'name': '民丰县',
      'children': null
    }]
  }, {
    'regionId': '654000',
    'parentId': '650000',
    'name': '伊犁哈萨克自治州',
    'children': [{
      'regionId': '654002',
      'parentId': '654000',
      'name': '伊宁市',
      'children': null
    }, {
      'regionId': '654003',
      'parentId': '654000',
      'name': '奎屯市',
      'children': null
    }, {
      'regionId': '654004',
      'parentId': '654000',
      'name': '霍尔果斯市',
      'children': null
    }, {
      'regionId': '654021',
      'parentId': '654000',
      'name': '伊宁县',
      'children': null
    }, {
      'regionId': '654022',
      'parentId': '654000',
      'name': '察布查尔锡伯自治县',
      'children': null
    }, {
      'regionId': '654023',
      'parentId': '654000',
      'name': '霍城县',
      'children': null
    }, {
      'regionId': '654024',
      'parentId': '654000',
      'name': '巩留县',
      'children': null
    }, {
      'regionId': '654025',
      'parentId': '654000',
      'name': '新源县',
      'children': null
    }, {
      'regionId': '654026',
      'parentId': '654000',
      'name': '昭苏县',
      'children': null
    }, {
      'regionId': '654027',
      'parentId': '654000',
      'name': '特克斯县',
      'children': null
    }, {
      'regionId': '654028',
      'parentId': '654000',
      'name': '尼勒克县',
      'children': null
    }]
  }, {
    'regionId': '654200',
    'parentId': '650000',
    'name': '塔城地区',
    'children': [{
      'regionId': '654201',
      'parentId': '654200',
      'name': '塔城市',
      'children': null
    }, {
      'regionId': '654202',
      'parentId': '654200',
      'name': '乌苏市',
      'children': null
    }, {
      'regionId': '654221',
      'parentId': '654200',
      'name': '额敏县',
      'children': null
    }, {
      'regionId': '654223',
      'parentId': '654200',
      'name': '沙湾县',
      'children': null
    }, {
      'regionId': '654224',
      'parentId': '654200',
      'name': '托里县',
      'children': null
    }, {
      'regionId': '654225',
      'parentId': '654200',
      'name': '裕民县',
      'children': null
    }, {
      'regionId': '654226',
      'parentId': '654200',
      'name': '和布克赛尔蒙古自治县',
      'children': null
    }]
  }, {
    'regionId': '654300',
    'parentId': '650000',
    'name': '阿勒泰地区',
    'children': [{
      'regionId': '654301',
      'parentId': '654300',
      'name': '阿勒泰市',
      'children': null
    }, {
      'regionId': '654321',
      'parentId': '654300',
      'name': '布尔津县',
      'children': null
    }, {
      'regionId': '654322',
      'parentId': '654300',
      'name': '富蕴县',
      'children': null
    }, {
      'regionId': '654323',
      'parentId': '654300',
      'name': '福海县',
      'children': null
    }, {
      'regionId': '654324',
      'parentId': '654300',
      'name': '哈巴河县',
      'children': null
    }, {
      'regionId': '654325',
      'parentId': '654300',
      'name': '青河县',
      'children': null
    }, {
      'regionId': '654326',
      'parentId': '654300',
      'name': '吉木乃县',
      'children': null
    }]
  }, {
    'regionId': '659001',
    'parentId': '650000',
    'name': '石河子市',
    'children': null
  }, {
    'regionId': '659002',
    'parentId': '650000',
    'name': '阿拉尔市',
    'children': null
  }, {
    'regionId': '659003',
    'parentId': '650000',
    'name': '图木舒克市',
    'children': null
  }, {
    'regionId': '659004',
    'parentId': '650000',
    'name': '五家渠市',
    'children': null
  }, {
    'regionId': '659005',
    'parentId': '650000',
    'name': '北屯市',
    'children': null
  }, {
    'regionId': '659006',
    'parentId': '650000',
    'name': '铁门关市',
    'children': null
  }, {
    'regionId': '659007',
    'parentId': '650000',
    'name': '双河市',
    'children': null
  }, {
    'regionId': '659008',
    'parentId': '650000',
    'name': '可克达拉市',
    'children': null
  }, {
    'regionId': '659009',
    'parentId': '650000',
    'name': '昆玉市',
    'children': null
  }, {
    'regionId': '659010',
    'parentId': '650000',
    'name': '胡杨河市',
    'children': null
  }]
}, {
  'regionId': '710000',
  'parentId': '0',
  'name': '台湾省',
  'children': null
}, {
  'regionId': '810000',
  'parentId': '0',
  'name': '香港特别行政区',
  'children': null
}, {
  'regionId': '820000',
  'parentId': '0',
  'name': '澳门特别行政区',
  'children': null
}];
export default data;