var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "preview" },
    [
      _c(
        "el-row",
        { attrs: { gutter: _vm.formConf.gutter } },
        [
          _c(
            "el-form",
            {
              ref: _vm.formConf.formModel,
              staticClass: "custom-form",
              attrs: {
                rules: _vm.rules,
                size: _vm.formConf.size,
                model: _vm.form,
                "label-position": _vm.formConf.labelPosition,
                disabled: _vm.formConf.disabled,
                "validate-on-rule-change": false,
                "label-width": _vm.formConf.labelWidth + "px"
              }
            },
            [
              _vm._l(_vm.list, function(element, index) {
                return [
                  element.compType === "row"
                    ? _c(
                        "preview-row-item",
                        { key: "row-" + index, attrs: { model: element } },
                        _vm._l(element.columns, function(column) {
                          return _c(
                            "el-col",
                            { key: column.index, attrs: { span: column.span } },
                            [
                              _vm._l(column.list, function(col) {
                                return [
                                  col.compType !== "dynamicTable"
                                    ? _c("preview-item", {
                                        key: col.id,
                                        attrs: { model: col },
                                        on: { valChange: _vm.handlerValChange },
                                        model: {
                                          value: _vm.form[col.id],
                                          callback: function($$v) {
                                            _vm.$set(_vm.form, col.id, $$v)
                                          },
                                          expression: "form[col.id]"
                                        }
                                      })
                                    : col.compType === "dynamicTable"
                                    ? _c("fancy-dynamic-table", {
                                        key: "dynamic-" + col.id,
                                        ref: "dynamicTable",
                                        refInFor: true,
                                        attrs: {
                                          data: _vm.form[col.id],
                                          conf: col,
                                          disabled: _vm.formConf.disabled
                                        },
                                        on: {
                                          addRow: _vm.handlerAddRow,
                                          deleteRow: _vm.handlerDeleteRow,
                                          batchDeleteRow:
                                            _vm.handlerBatchDeleteRow
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "item",
                                              fn: function(ref) {
                                                var rowScope = ref.rowScope
                                                var item = ref.item
                                                return [
                                                  _c(
                                                    "fancy-dynamic-table-item",
                                                    {
                                                      key:
                                                        "tableIndex-" +
                                                        rowScope.$index,
                                                      attrs: {
                                                        model: item,
                                                        parent: col,
                                                        index: rowScope.$index
                                                      },
                                                      on: {
                                                        valChange:
                                                          _vm.handlerDynamicValChange
                                                      },
                                                      model: {
                                                        value:
                                                          rowScope.row[item.id],
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            rowScope.row,
                                                            item.id,
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "rowScope.row[item.id]"
                                                      }
                                                    }
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      })
                                    : _vm._e()
                                ]
                              })
                            ],
                            2
                          )
                        }),
                        1
                      )
                    : element.compType === "dynamicTable"
                    ? _c("fancy-dynamic-table", {
                        key: "dynamic-" + index,
                        ref: element.id,
                        refInFor: true,
                        attrs: { data: _vm.form[element.id], conf: element },
                        on: {
                          addRow: _vm.handlerAddRow,
                          deleteRow: _vm.handlerDeleteRow,
                          batchDeleteRow: _vm.handlerBatchDeleteRow
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "item",
                              fn: function(ref) {
                                var rowScope = ref.rowScope
                                var item = ref.item
                                return [
                                  _c("fancy-dynamic-table-item", {
                                    key: "tableIndex-" + rowScope.$index,
                                    ref: item.id + rowScope.$index,
                                    refInFor: true,
                                    attrs: {
                                      model: item,
                                      parent: element,
                                      index: rowScope.$index
                                    },
                                    on: {
                                      valChange: _vm.handlerDynamicValChange
                                    },
                                    model: {
                                      value: rowScope.row[item.id],
                                      callback: function($$v) {
                                        _vm.$set(rowScope.row, item.id, $$v)
                                      },
                                      expression: "rowScope.row[item.id]"
                                    }
                                  })
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      })
                    : element.compType === "table"
                    ? _c("fancy-edit-table", {
                        key: "editTable-" + index,
                        attrs: {
                          "layout-array": element.layoutArray,
                          "td-style": element.tdStyle,
                          width: element.width,
                          height: element.height
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(ref) {
                                var td = ref.td
                                return [
                                  _vm._l(td.columns, function(col) {
                                    return [
                                      _c("preview-item", {
                                        key: "prevItem-" + col.id,
                                        attrs: { model: col },
                                        on: { valChange: _vm.handlerValChange },
                                        model: {
                                          value: _vm.form[col.id],
                                          callback: function($$v) {
                                            _vm.$set(_vm.form, col.id, $$v)
                                          },
                                          expression: "form[col.id]"
                                        }
                                      })
                                    ]
                                  })
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      })
                    : _c(
                        "el-col",
                        {
                          key: index,
                          staticClass: "drag-col-wrapper",
                          attrs: { span: element.span }
                        },
                        [
                          _c("preview-item", {
                            attrs: { model: element },
                            on: { valChange: _vm.handlerValChange },
                            model: {
                              value: _vm.form[element.id],
                              callback: function($$v) {
                                _vm.$set(_vm.form, element.id, $$v)
                              },
                              expression: "form[element.id]"
                            }
                          })
                        ],
                        1
                      )
                ]
              })
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }