var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.props.compType === "editor",
          expression: "props.compType === 'editor'"
        }
      ]
    },
    [
      _c(
        "el-form-item",
        { attrs: { label: "ID" } },
        [
          _c(
            "el-tooltip",
            {
              staticClass: "item",
              attrs: {
                effect: "dark",
                content: "请注意,ID的修改可能会导致该组件相关事件失效！",
                placement: "left"
              }
            },
            [
              _c("el-input", {
                staticClass: "input",
                on: { change: _vm.handlerChangeId },
                model: {
                  value: _vm.props.id,
                  callback: function($$v) {
                    _vm.$set(_vm.props, "id", $$v)
                  },
                  expression: "props.id"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "显示标签" } },
        [
          _c("el-switch", {
            on: { change: _vm.handlerShowLabel },
            model: {
              value: _vm.props.showLabel,
              callback: function($$v) {
                _vm.$set(_vm.props, "showLabel", $$v)
              },
              expression: "props.showLabel"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.props.showLabel,
              expression: "props.showLabel"
            }
          ],
          attrs: { label: "标签文字" }
        },
        [
          _c("el-input", {
            staticClass: "input",
            model: {
              value: _vm.props.label,
              callback: function($$v) {
                _vm.$set(_vm.props, "label", $$v)
              },
              expression: "props.label"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.props.showLabel,
              expression: "props.showLabel"
            }
          ],
          attrs: { label: "标签长度" }
        },
        [
          _c("el-input-number", {
            attrs: { min: 1, max: 200 },
            model: {
              value: _vm.props.labelWidth,
              callback: function($$v) {
                _vm.$set(_vm.props, "labelWidth", $$v)
              },
              expression: "props.labelWidth"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "必填" } },
        [
          _c("el-switch", {
            model: {
              value: _vm.props.required,
              callback: function($$v) {
                _vm.$set(_vm.props, "required", $$v)
              },
              expression: "props.required"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "验证最大字数" } },
        [
          _c("el-switch", {
            model: {
              value: _vm.props.validateMaxText,
              callback: function($$v) {
                _vm.$set(_vm.props, "validateMaxText", $$v)
              },
              expression: "props.validateMaxText"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "最大字数" } },
        [
          _c("el-input-number", {
            attrs: { min: 100 },
            model: {
              value: _vm.props.max,
              callback: function($$v) {
                _vm.$set(_vm.props, "max", $$v)
              },
              expression: "props.max"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }