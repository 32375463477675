import "core-js/modules/es6.string.iterator";
import "core-js/modules/es6.array.from";
import "core-js/modules/es6.function.name";
import "core-js/modules/web.dom.iterable";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import reg from "./custom/register";
export default {
  name: 'ConfigPanel',
  props: {
    activeItem: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    itemList: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      currentTab: 'field',
      cmps: reg,
      formIdArray: []
    };
  },
  created: function created() {
    this.cmps.forEach(function (c) {
      c.content = require("./custom/configs/".concat(c.name)).default;
    });
  },
  methods: {
    getFormId: function getFormId(itemId) {
      var _this = this;

      this.formIdArray = [];
      Array.from(this.itemList, function (item) {
        if (item.compType === 'row') {
          Array.from(item.columns, function (column) {
            Array.from(column.list, function (col) {
              if (col._id !== itemId) {
                _this.formIdArray.push(col.id);
              }
            });
          });
        } else {
          if (item._id !== itemId) {
            _this.formIdArray.push(item.id);
          }
        }
      });
      return this.formIdArray;
    }
  }
};