export var selectStaff = {
  id: '',
  _id: '',
  compType: 'selectStaff',
  // 控件名称
  compName: '选员工',
  // element标签
  ele: 'el-select',
  // 图标
  compIcon: 'select',
  // 展示表单的模式
  viewType: 'component',
  // 是否可配置
  config: true,
  // 控件左侧label内容
  label: '员工',
  placeholder: '请选择员工',
  // 最大长度
  maxLength: 50,
  // 表单栅格
  span: 24,
  // 栅格间隔
  gutter: '15',
  // 标签宽度
  labelWidth: 80,
  // 显示标签
  showLabel: true,
  // 必填
  required: false,
  // 多选
  multiple: false,
  'collapse-tags': false,
  // 禁用
  disabled: false,
  // 搜索
  filterable: true,
  // 选项列表
  options: [],
  // 数据类型 static，dynamic, store
  dataType: 'store',
  storeDispatchName: 'staff/getAllStaffSelector',
  // 默认渲染的数据
  action: '',
  // 是否可以清空
  clearable: true,
  value: '',
  rules: [],
  // 验证错误提示信息
  ruleError: '该字段不能为空'
};