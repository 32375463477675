import "core-js/modules/web.dom.iterable";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'RowConfig',
  // eslint-disable-next-line vue/require-prop-types
  props: ['props', 'getFormId'],
  data: function data() {
    return {};
  },
  mounted: function mounted() {},
  methods: {
    handlerAddCol: function handlerAddCol() {
      var colIndex = this.props.columns.length;
      this.props.columns.push({
        index: colIndex,
        span: 12,
        list: []
      });
    },
    handlerRemoveCol: function handlerRemoveCol(idx) {
      this.props.columns.splice(idx, 1);
      var i = 0;
      this.props.columns.forEach(function (c) {
        c.index = i++;
      });
    }
  }
};