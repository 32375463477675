import request from '@/utils/request';
var baseOrgPath = '/api/v1/user-base/organization';
var baseStaffPath = '/api/v1/user-base/staff';
var baseDepPath = '/api/v1/user-base/department';
var baseRoleGroupPath = '/api/v1/user-base/role-group';
var baseUrl = process.env.VUE_APP_USER_API;
export function listOrgRoleGroupList(query) {
  return request({
    baseURL: baseUrl,
    url: baseRoleGroupPath + '/list',
    method: 'get',
    params: query
  });
}
export function getOrgStructure(query) {
  return request({
    baseURL: baseUrl,
    url: baseOrgPath + '/org-structure',
    method: 'get',
    params: query
  });
}
export function listOrgStaff(query) {
  return request({
    baseURL: baseUrl,
    url: baseStaffPath + '/list-org-staff',
    method: 'get',
    params: query
  });
}
export function listDepTreeByOrg(query) {
  return request({
    baseURL: baseUrl,
    url: baseDepPath + '/list-org-department',
    method: 'get',
    params: query
  });
}
export function listBizDepByOrg() {
  return request({
    baseURL: baseUrl,
    url: baseDepPath + '/list-business-department',
    method: 'get'
  });
}
export function listBizDepByOrgs(query) {
  return request({
    baseURL: baseUrl,
    url: baseDepPath + '/business-department',
    method: 'get',
    params: query
  });
}
export function listBizDepByCorp() {
  return request({
    baseURL: baseUrl,
    url: baseDepPath + '/list-corp-business-department',
    method: 'get'
  });
}
export function listAllDep(query) {
  return request({
    baseURL: baseUrl,
    url: baseDepPath + '/list',
    method: 'get',
    params: query
  });
}
export function listDepByOrg(query) {
  return request({
    baseURL: baseUrl,
    url: baseDepPath + '/{orgId}/list',
    method: 'get',
    params: query
  });
}
export function geStaffInfo(query) {
  return request({
    baseURL: baseUrl,
    url: baseStaffPath + '/{staffId}',
    method: 'get',
    params: query
  });
}
export function fetchSubDep(query) {
  return request({
    baseURL: baseUrl,
    url: baseDepPath + '/list-sub-dep',
    method: 'get',
    params: query
  });
}
export function updateStaff(query, data) {
  return request({
    baseURL: baseUrl,
    url: baseStaffPath + '/{staffId}',
    method: 'put',
    params: query,
    data: {
      data: data
    }
  });
}
export function createStaff(data) {
  return request({
    baseURL: baseUrl,
    url: baseStaffPath + '/save',
    method: 'post',
    data: {
      data: data
    }
  });
}
export function resetPassword(data) {
  return request({
    baseURL: baseUrl,
    url: baseStaffPath + '/resetPassword',
    method: 'post',
    data: {
      data: data
    }
  });
}
export function getDep(query) {
  return request({
    baseURL: baseUrl,
    url: baseDepPath + '/{depId}',
    method: 'get',
    params: query
  });
}
export function createDep(data) {
  return request({
    baseURL: baseUrl,
    url: baseDepPath + '/save',
    method: 'post',
    data: {
      data: data
    }
  });
}
export function move(data) {
  return request({
    baseURL: baseUrl,
    url: baseStaffPath + '/move',
    method: 'post',
    data: {
      data: data
    }
  });
}
export function deleteRelation(query) {
  return request({
    baseURL: baseUrl,
    url: baseStaffPath + '/delete-relation/{relationId}',
    method: 'delete',
    params: query
  });
}
export function updateDep(query, data) {
  return request({
    baseURL: baseUrl,
    url: baseDepPath + '/{depId}',
    method: 'put',
    params: query,
    data: {
      data: data
    }
  });
}
export function deleteDep(query) {
  return request({
    baseURL: baseUrl,
    url: baseDepPath + '/{depId}',
    method: 'delete',
    params: query
  });
}
export function listPermManageDep(query) {
  return request({
    baseURL: baseUrl,
    url: baseDepPath + '/list-perm-dep',
    method: 'get',
    params: query
  });
}