import "core-js/modules/es6.object.keys";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
import "core-js/modules/es6.regexp.split";
import "core-js/modules/es6.function.name";
import { isAttr, jsonClone } from "../utils";
import childrenItem from "./slot/index";
import { remoteData } from "./mixin";
import { getToken } from '@/utils/auth'; // 先修改在这里,后续需要优化

function vModel(self, dataObject) {
  var _this = this;

  dataObject.props.value = self.value;

  dataObject.on.input = function (val) {
    self.$emit('input', val);
  }; // 判断是否为上传组件


  if (self.conf.compType === 'upload') {
    // 根据当前环境配置上传路径
    dataObject.attrs['action'] = process.env.VUE_APP_FILE_API + '/api/v1/file-base/file/upload';
    /**
     * 此处增加自定义的token,如果不能满足要求，可以重写此处代码
     */

    var token = getToken();
    dataObject.attrs['headers'] = {
      'access-token': token
    }; // console.log('dataObject.props.value', dataObject.props.value)

    if (dataObject.props.value !== undefined && dataObject.props.value !== '') {
      if (typeof dataObject.props.value === 'string') {
        var fileValue = JSON.parse(dataObject.props.value);
        dataObject.props['file-list'] = fileValue;
      } else {
        dataObject.props['file-list'] = dataObject.props.value;
      }
    }

    dataObject.attrs['before-upload'] = function (file) {
      // 非限定后缀不允许上传
      var fileName = file.name;
      var suffixName = fileName.split('.').pop();

      if (!self.conf.accept.includes(suffixName)) {
        self.$message.error('该后缀文件不允许上传');
        return false;
      }

      var fileSize = file.size;

      if (fileSize > dataObject.props.fileSize * 1024 * 1024) {
        self.$message.error('文件大小超出限制，请检查！');
        return false;
      }
    };

    dataObject.attrs['on-success'] = function (res) {
      console.log('on-success', res);
      var file = res.data;
      var filename = file.fileName; // 获取文件名称

      var fileObj = {
        name: filename,
        url: file.url
      }; // console.log('dataObject=', dataObject)
      // console.log('self.conf=', self.conf)

      var oldValue = [];

      if (dataObject.props.value) {
        if (typeof dataObject.props.value === 'string') {
          oldValue = JSON.parse(dataObject.props.value);
        } else {
          oldValue = dataObject.props.value;
        }
      } else {
        oldValue = [];
      }

      if (oldValue) {
        oldValue.push(fileObj);
      } else {
        oldValue = [fileObj];
      }

      self.$emit('input', JSON.stringify(oldValue));
      console.log('on-success value', oldValue);
    };

    dataObject.attrs['on-remove'] = function (file, fileList) {
      console.log('on-remove file,fileList', file, fileList);
      var oldValue = [];

      if (dataObject.props.value) {
        if (typeof dataObject.props.value === 'string') {
          oldValue = JSON.parse(dataObject.props.value);
        } else {
          oldValue = dataObject.props.value;
        }
      } else {
        oldValue = [];
      }

      console.log('on-remove oldValue', oldValue); // file 删除的文件
      // 过滤掉删除的文件

      var newValue = oldValue.filter(function (item) {
        return item.name !== file.name;
      });
      self.$emit('input', JSON.stringify(newValue));
      console.log('on-remove newValue', newValue);
    };

    dataObject.attrs['on-error'] = function (file) {
      console.log('on-error file', file);

      _this.$message.warning('文件上传失败');
    };

    dataObject.attrs['on-exceed'] = function (file) {
      console.log('on-exceed file', file);

      _this.$message.warning('请先删除原文件');
    };

    dataObject.attrs['on-preview'] = function (file) {
      console.log('on-preview file', file); // download(file);

      if (file) {
        document.location.href = file.url;
      }
    };
  }
}

export default {
  render: function render(h) {
    var dataObject = {
      attrs: {},
      props: {},
      on: {},
      style: {}
    }; // 远程获取数据

    if (this.conf.dataType === 'store') {
      this.getRemoteData();
    }

    var confClone = jsonClone(this.conf);
    var children = childrenItem(h, confClone);
    Object.keys(confClone).forEach(function (key) {
      var val = confClone[key];

      if (dataObject[key]) {
        dataObject[key] = val;
      } else if (key === 'width') {
        dataObject.style = 'width:' + val;
      } else if (!isAttr(key)) {
        dataObject.props[key] = val;
      } else {
        if (key !== 'value') {
          dataObject.attrs[key] = val;
        }
      }
    });
    /* 调整赋值模式，规避cascader组件赋值props会出现覆盖预制参数的bug */

    vModel(this, dataObject);
    return h(confClone.ele, dataObject, children);
  },
  props: ['conf', 'value'],
  mixins: [remoteData]
};