var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.props.compType === "cascader",
          expression: "props.compType === 'cascader'"
        }
      ]
    },
    [
      _c(
        "el-form-item",
        { attrs: { label: "ID" } },
        [
          _c(
            "el-tooltip",
            {
              staticClass: "item",
              attrs: {
                effect: "dark",
                content: "请注意,ID的修改可能会导致该组件相关事件失效！",
                placement: "left"
              }
            },
            [
              _c("el-input", {
                staticClass: "input",
                on: { change: _vm.handlerChangeId },
                model: {
                  value: _vm.props.id,
                  callback: function($$v) {
                    _vm.$set(_vm.props, "id", $$v)
                  },
                  expression: "props.id"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "标题" } },
        [
          _c("el-input", {
            staticClass: "input",
            model: {
              value: _vm.props.label,
              callback: function($$v) {
                _vm.$set(_vm.props, "label", $$v)
              },
              expression: "props.label"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "栅格" } },
        [
          _c("el-input-number", {
            attrs: { min: 1, max: 24 },
            model: {
              value: _vm.props.span,
              callback: function($$v) {
                _vm.$set(_vm.props, "span", $$v)
              },
              expression: "props.span"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "宽度" } },
        [
          _c("el-input-number", {
            attrs: { min: 0, max: 100 },
            model: {
              value: _vm.props.width,
              callback: function($$v) {
                _vm.$set(_vm.props, "width", $$v)
              },
              expression: "props.width"
            }
          }),
          _vm._v("% ")
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "提示符" } },
        [
          _c("el-input", {
            staticClass: "input",
            attrs: { placeholder: "请输入提示符" },
            model: {
              value: _vm.props.placeholder,
              callback: function($$v) {
                _vm.$set(_vm.props, "placeholder", $$v)
              },
              expression: "props.placeholder"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "表单栅格" } },
        [
          _c("el-slider", {
            staticClass: "input",
            attrs: { max: 24, min: 1, marks: { 12: "" } },
            model: {
              value: _vm.props.span,
              callback: function($$v) {
                _vm.$set(_vm.props, "span", $$v)
              },
              expression: "props.span"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "栅格间隔" } },
        [
          _c("el-input-number", {
            attrs: { min: 0 },
            model: {
              value: _vm.props.gutter,
              callback: function($$v) {
                _vm.$set(_vm.props, "gutter", $$v)
              },
              expression: "props.gutter"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "标签宽度" } },
        [
          _c("el-input-number", {
            attrs: { min: 0, max: 200 },
            model: {
              value: _vm.props.labelWidth,
              callback: function($$v) {
                _vm.$set(_vm.props, "labelWidth", $$v)
              },
              expression: "props.labelWidth"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "显示标签" } },
        [
          _c("el-switch", {
            on: { change: _vm.handlerChangeLabel },
            model: {
              value: _vm.props.showLabel,
              callback: function($$v) {
                _vm.$set(_vm.props, "showLabel", $$v)
              },
              expression: "props.showLabel"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "大小" } },
        [
          _c(
            "el-radio-group",
            {
              model: {
                value: _vm.props.size,
                callback: function($$v) {
                  _vm.$set(_vm.props, "size", $$v)
                },
                expression: "props.size"
              }
            },
            [
              _c("el-radio-button", { attrs: { label: "medium" } }, [
                _vm._v("正常")
              ]),
              _c("el-radio-button", { attrs: { label: "small" } }, [
                _vm._v("略小")
              ]),
              _c("el-radio-button", { attrs: { label: "mini" } }, [
                _vm._v("迷你")
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "清除" } },
        [
          _c("el-switch", {
            model: {
              value: _vm.props.clearable,
              callback: function($$v) {
                _vm.$set(_vm.props, "clearable", $$v)
              },
              expression: "props.clearable"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "禁用" } },
        [
          _c("el-switch", {
            model: {
              value: _vm.props.disabled,
              callback: function($$v) {
                _vm.$set(_vm.props, "disabled", $$v)
              },
              expression: "props.disabled"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "完整路径" } },
        [
          _c("el-switch", {
            model: {
              value: _vm.props.props["show-all-levels"],
              callback: function($$v) {
                _vm.$set(_vm.props.props, "show-all-levels", $$v)
              },
              expression: "props.props['show-all-levels']"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "分隔符" } },
        [
          _c("el-input", {
            model: {
              value: _vm.props.props.separator,
              callback: function($$v) {
                _vm.$set(_vm.props.props, "separator", $$v)
              },
              expression: "props.props.separator"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "搜索" } },
        [
          _c("el-switch", {
            model: {
              value: _vm.props.props.filterable,
              callback: function($$v) {
                _vm.$set(_vm.props.props, "filterable", $$v)
              },
              expression: "props.props.filterable"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "展开方式" } },
        [
          _c(
            "el-radio-group",
            {
              model: {
                value: _vm.props.props.props.expandTrigger,
                callback: function($$v) {
                  _vm.$set(_vm.props.props.props, "expandTrigger", $$v)
                },
                expression: "props.props.props.expandTrigger"
              }
            },
            [
              _c("el-radio-button", { attrs: { label: "click" } }, [
                _vm._v("点击")
              ]),
              _c("el-radio-button", { attrs: { label: "hover" } }, [
                _vm._v("悬停")
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "多选" } },
        [
          _c("el-switch", {
            model: {
              value: _vm.props.props.props.multiple,
              callback: function($$v) {
                _vm.$set(_vm.props.props.props, "multiple", $$v)
              },
              expression: "props.props.props.multiple"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "任一级可选" } },
        [
          _c("el-switch", {
            model: {
              value: _vm.props.props.props.checkStrictly,
              callback: function($$v) {
                _vm.$set(_vm.props.props.props, "checkStrictly", $$v)
              },
              expression: "props.props.props.checkStrictly"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "数据类型" } },
        [
          _c(
            "el-radio-group",
            {
              on: { change: _vm.handlerChangeDataType },
              model: {
                value: _vm.props.dataType,
                callback: function($$v) {
                  _vm.$set(_vm.props, "dataType", $$v)
                },
                expression: "props.dataType"
              }
            },
            [
              _c("el-radio-button", { attrs: { label: "static" } }, [
                _vm._v("静态数据")
              ]),
              _c("el-radio-button", { attrs: { label: "dynamic" } }, [
                _vm._v("动态数据")
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.props.dataType === "dynamic",
              expression: "props.dataType ==='dynamic'"
            }
          ]
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "地址" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.props.action,
                  callback: function($$v) {
                    _vm.$set(_vm.props, "action", $$v)
                  },
                  expression: "props.action"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "显示标识" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.props.props.props.label,
                  callback: function($$v) {
                    _vm.$set(_vm.props.props.props, "label", $$v)
                  },
                  expression: "props.props.props.label"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "值标识" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.props.props.props.value,
                  callback: function($$v) {
                    _vm.$set(_vm.props.props.props, "value", $$v)
                  },
                  expression: "props.props.props.value"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "下级标识" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.props.props.props.children,
                  callback: function($$v) {
                    _vm.$set(_vm.props.props.props, "children", $$v)
                  },
                  expression: "props.props.props.children"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.props.dataType === "static",
              expression: "props.dataType ==='static'"
            }
          ]
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "静态数据" } },
            [
              _c("el-button", {
                attrs: { icon: "el-icon-edit-outline", circle: "" },
                on: { click: _vm.handlerStaticData }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.staticDataVisible,
            width: "70%",
            "close-on-press-escape": false,
            "close-on-click-modal": false,
            "show-close": false,
            center: true,
            top: "20px"
          },
          on: {
            "update:visible": function($event) {
              _vm.staticDataVisible = $event
            }
          }
        },
        [
          _c("codemirror", {
            attrs: { options: _vm.codeMirror },
            model: {
              value: _vm.staticOptions,
              callback: function($$v) {
                _vm.staticOptions = $$v
              },
              expression: "staticOptions"
            }
          }),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handlerSave } },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }