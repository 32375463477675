import request from '@/utils/request';
var basePath = '/api/v1/finance-base/finance-income-cost-type';
var baseUrl = process.env.VUE_APP_FINANCE_API;
export function listFinanceIncomeCostType(query) {
  return request({
    baseURL: baseUrl,
    url: basePath + '/list-tree',
    method: 'get',
    params: query
  });
}