import request from '@/utils/request';
var basePath = '/api/v1/message-base/wechat-bot-message-template-rule';
var baseUrl = process.env.VUE_APP_MESSAGE_API;
export function listWechatMessageTemplateRule(query) {
  return request({
    baseURL: baseUrl,
    url: basePath + '/{templateId}/list',
    method: 'get',
    params: query
  });
}
export function getWechatMessageTemplateRule(query) {
  return request({
    baseURL: baseUrl,
    url: basePath + '/{ruleId}',
    method: 'get',
    params: query
  });
}
export function saveWechatMessageTemplateRule(data) {
  return request({
    baseURL: baseUrl,
    url: basePath + '/save',
    method: 'post',
    data: {
      data: data
    }
  });
}
export function enableWechatMessageTemplateRule(data) {
  return request({
    baseURL: baseUrl,
    url: basePath + '/enable',
    method: 'post',
    data: {
      data: data
    }
  });
}
export function disableWechatMessageTemplateRule(data) {
  return request({
    baseURL: baseUrl,
    url: basePath + '/disable',
    method: 'post',
    data: {
      data: data
    }
  });
}
export function deleteWechatMessageTemplateRule(query) {
  return request({
    baseURL: baseUrl,
    url: basePath + '/{ruleId}',
    method: 'delete',
    params: query
  });
}
export function listTriggerType(query) {
  return request({
    baseURL: baseUrl,
    url: basePath + '/list-trigger-rule',
    method: 'get',
    params: query
  });
}